import { Box, Slider } from "@mui/joy";
import React, { useEffect } from "react";

interface Props {
  maximum?: number;
  minimum?: number;
  onChange: (value: number[]) => void;
}
function valueText(value: number) {
  return `${value}°C`;
}

function SliderField(props: Props) {
  const { minimum = 0, maximum = 100, onChange } = props;
  const [value, setValue] = React.useState<number[]>([]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    onChange(newValue as number[]);
  };

  useEffect(() => {
    setValue([minimum, maximum]);
    onChange([minimum, maximum]);
  }, []);

  return (
    <Box width="100%">
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        step={1}
        max={maximum}
        min={minimum}
        getAriaValueText={valueText}
      />
    </Box>
  );
}

export default SliderField;
