import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import { Unmoderated } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../button/CustomButton";
import CustomDialog from "../dialog/CustomDialog";
import DetailBeforeAccept from "../dialog/invitations/DetailBeforeAccept";
import DetailBeforeUnmoderated from "../dialog/invitations/DetailBeforeUnmoderated";

interface Props {
  unmoderated: Unmoderated;
  showDefault?: boolean;
  refresh: () => void;
}

function LightsterUnmoderatedItem(props: Props) {
  const { unmoderated, refresh, showDefault } = props;
  const { sessionService, userService, showMessage, handleToggle } = useApi();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState({
    microphone: false,
    camera: false,
  });
  const [devices, setDevices] = useState({
    microphone: false,
    camera: false,
  });

  const [open, setOpen] = useState({
    decline: false,
    detail: false,
  });

  const handleComplateandExpiredTesting = async (bookingStatus:boolean) => {
    if (!bookingStatus) {
      const res = await sessionService.detailUnmoderated(unmoderated.id);
      refresh();
      if (!res) return;
      refresh();
    }
  };
  // const checkDevices = async () => {
  //   await navigator.mediaDevices
  //     .enumerateDevices()
  //     .then((stream) => {
  //       stream.forEach((device) => {
  //         if (device.kind === "audioinput") {
  //           if (device.label) {
  //             setPermissions({ ...permissions, microphone: true });
  //           }
  //           setDevices({ ...devices, microphone: true });
  //         }
  //         if (device.kind === "videoinput") {
  //           if (device.label) {
  //             setPermissions({ ...permissions, camera: true });
  //           }
  //           setDevices({ ...devices, camera: true });
  //         }
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(`${err.name}: ${err.message}`);
  //     });
  // };

  const handleDecline = async () => {
    const res = await sessionService.declineUnmoderated(unmoderated.id);
    if (!res) {
      return;
    }
    showMessage(
      "Declined You have successfully declined the Unmoderated Testing.",
      "success"
    );
    refresh();
  };

  const handleJoin = async () => {
    navigate(pagesName.requestPermissions, {
      state: { unmoderatedId: unmoderated.id },
    });
    return;
  };

  useEffect(() => {
    if (showDefault) {
      setOpen({ ...open, detail: true });
      handleComplateandExpiredTesting(unmoderated.userActive);
    }
    // checkDevices();
  }, []);

  const handleclickViewProfile = () => {
    window.open(
      `${pagesName.lightster.viewProfile}/${unmoderated.creatorId}`,
      "_blank"
    );
  };
  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} spacing={2} p={2}>
        <Avatar
          sx={{ width: 56, height: 56, cursor: "pointer" }}
          src={unmoderated.imageProfile}
          onClick={handleclickViewProfile}
        />
        <Box width={"100%"}>
          <Box className="box-row" sx={{ justifyContent: "space-between" }}>
            <Typography
              level="title-lg"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                width: "calc(100% - 100px)",
              }}
            >
              {unmoderated.name}
            </Typography>
            <Typography level="title-md" textAlign="right">
              ${unmoderated.reward} reward
            </Typography>
          </Box>
          <Typography level="body-md">
          {unmoderated.companydisplayname || "-"}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="body-md">
              {unmoderated.steps?.length || 0 } questions
            </Typography>

          </Stack>
          <Box className="box-row" sx={{ gap: 1, pt: 1 }}>
            <Button
              variant="solid"
              size="sm"
              sx={{ bgcolor: "black", borderRadius: 6, padding: "6px 12px", minHeight:0}}
              onClick={() => {
                setOpen({ ...open, detail: true });
                handleComplateandExpiredTesting(unmoderated.userActive);
                
              }}
            >
              Start now
            </Button>
            <Button
              variant="plain"
              size="sm"
              color="primary"
              sx={{
                borderRadius: 6,
                fontSize: 14,
                padding:"6px 12px",
                minHeight: 0,
              }}
              onClick={() => setOpen({ ...open, decline: true })}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </Stack>
      {open && (
        <CustomDialog
          open={open.decline}
          title="Are you sure you want to decline?"
          subtitle={
            <Stack direction={"column"} spacing={4}>
              <Typography level="body-lg">
                You are declining this Unmoderated Testing. You may be
                prevented from receiving further invitations.
              </Typography>

              <Stack
                direction={"row"}
                justifyContent={{ sm: "space-between", md: "end" }}
                spacing={2}
              >
                <PlainButton variant="plain" onClick={() => setOpen({...open, decline:false })}>
                  Go Back
                </PlainButton>
                <SolidButton
                  variant="solid"
                  onClick={() => {
                    setOpen({...open, decline:false })
                    handleDecline();
                  }}
                >
                  Yes
                </SolidButton>
              </Stack>
            </Stack>
          }
        />
      )}

      {open.detail && (
        <DetailBeforeUnmoderated
          open={open.detail}
          onClose={(value) => {
            if (!value) handleDecline();
            setOpen({ ...open, detail: false });
          }}
          unmoderated={unmoderated}
          onSelected={() => {
            handleJoin();
            setOpen({
              ...open,
              detail: false,
            });
          }}
        />
      )}
    </Box>
  );
}

export default LightsterUnmoderatedItem;
