import {
    CreditCardRounded,
    EastSharp,
    KeyboardReturnRounded,
} from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/joy";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import { useApi } from "../../../providers/ApiProvider";

import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import config from "../../../config";
import { StorageType } from "../../../constants/enum";

interface Props {}

function CreatorPayment(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { paymentService, handleToggle, showMessage } = useApi();
  const [creditCards, setCreditCards] = useState<Array<any>>([]);

  const getCreditCards = async () => {
    const res = await paymentService.getSubscriptions();
    if (!res) return;

    setCreditCards(res);
    if (res.length === 0) {
      await addCreaditCard();
    }
  };

  const addCreaditCard = async () => {
    const token = localStorage.getItem(StorageType.token);
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
        "X-LIGHTSTER-CLIENT": "WebAppShareScreen",
        "Access-Control-Expose-Headers": "Location",
        Accept: "*/*",
      },
    });
    instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    await instance
      .post(`${config.backendUrl}/creator/payment/cards`, { isWeb: true })
      .then(function (response: AxiosResponse) {
        var newWin = window.open(
          response.data.url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=50 ,left=50 ,width=500,height=600"
        );

        var timer = setInterval(function () {
          if (newWin?.closed) {
            clearInterval(timer);
            window.location.reload();
          }
        }, 1000);
      })
      .catch(function (error: AxiosResponse) {
        console.log(error);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (creditCards.length == 0) {
      return showMessage("Please add a credit card to proceed.", "warning");
    }
    handleToggle(true);
    const res = await paymentService.createSubscription(state);
    if (!res) {
      handleToggle(false);
      return;
    }
    setTimeout(() => {
      handleToggle(false);
      navigate(pagesName.creator.planSuccess, { state: state });
    }, 1000);
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <BackgroundRegister>
        <Stack width={"100%"} direction={"column"}>
          <Box height={180}>
            <BorderLinearProgress determinate value={100} sx={{ mt: 10 }} />
          </Box>
          <Stack direction={"column"} spacing={4} minHeight="400px">
            <Typography>Payment</Typography>
            <Button
              variant="solid"
              sx={{ textTransform: "none" }}
              fullWidth
              onClick={addCreaditCard}
            >
              {"Add a new card"}
            </Button>
            <Box>
              {creditCards.length > 0 &&
                creditCards.map((data: any, index: number) => (
                  <Stack
                    key={index}
                    className="credit-card"
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    mb={2}
                  >
                    <Stack direction={"column"} p={2}>
                      <Typography sx={{ textTransform: "uppercase" }}>
                        {data.card.brand}****{data.card.last4}
                      </Typography>
                      <Typography sx={{ color: "gray" }}>
                        Expires {data.card.exp_month}/{data.card.exp_year}
                      </Typography>
                    </Stack>
                    <Box p={2}>
                      <CreditCardRounded />
                    </Box>
                  </Stack>
                ))}
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={10}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ minWidth: 128 }}
              onClick={handleSubmit}
              type="submit"
              autoFocus
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </BackgroundRegister>
    </form>
  );
}

export default CreatorPayment;
