import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import { mapStatusText } from "../../constants/constants";
import { Session } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../button/CustomButton";
import CustomDialog from "../dialog/CustomDialog";
import { ProposeNewTimeDialog } from "../dialog/schedules/ProposeNewTimeDialog";
//import { pointer } from "@testing-library/user-event/dist/types/pointer";

interface Props {
  session: Session;
  refresh: () => void;
}

function CreatorSessionItem(props: Props) {
  const { session, refresh } = props;
  const { sessionService, showMessage, userService, handleToggle } = useApi();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState({
    microphone: false,
    camera: false,
  });
  const [devices, setDevices] = useState({
    microphone: false,
    camera: false,
  });

  const [open, setOpen] = useState({
    proposeNewTime: false,
    cancel: false,
  });
  const [timezone, setTimezone] = useState<number>();

  const checkDevices = async () => {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((stream) => {
        stream.forEach((device) => {
          if (device.kind === "audioinput") {
            if (device.label) {
              setPermissions({ ...permissions, microphone: true });
            }
            setDevices({ ...devices, microphone: true });
          }
          if (device.kind === "videoinput") {
            if (device.label) {
              setPermissions({ ...permissions, camera: true });
            }
            setDevices({ ...devices, camera: true });
          }
        });
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  };

  const handleDecline = async () => {
    const res = await sessionService.declineSession(session._id);
    if (!res) {
      return;
    }
    showMessage("You have successfully canceled the invitation.", "success");
    refresh();
  };

  const handleJoin = async () => {
    if (!inThirtyMinutes(session.request.proposedTime)) return;
    navigate(pagesName.requestPermissions, {
      state: { sessionId: session._id },
    });
    return;

    // handleToggle(true);
    // const res = await sessionService.startSession(session._id);
    // handleToggle(false);
    // if (!res) {
    //   return;
    // }

    // navigate(pagesName.videocall, {
    //   state: { sessionId: session._id },
    // });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(session.meetingURL);
    showMessage("Meeting ID Copied!", "success");
  };

  const inThirtyMinutes = (dateTime: string) => {
    const olderDate = moment(new Date(dateTime)).subtract(30, "minutes");
    const isSameOrAfter = moment(Date.now()).isSameOrAfter(olderDate);

    return isSameOrAfter;
  };

  const isJoined = () => {
    return session.creator.isJoined && session.lightster.isJoined;
  };

  const handleclickViewProfile = () => {
    window.open(
      `${pagesName.creator.viewProfile}/${session.lightster.id}`,
      "_blank"
    );
  };

  const getLightsterTimezone = async (lightsterId: string) => {
    if (!lightsterId) return;
    const res = await userService.creatorgetLightsterTimezone(lightsterId);
    if (!res) return;
    setTimezone(parseInt(res.timezone));
  };


  const handleNewProposeTime = (value: string) => {

    const payload = { type: 'reschedule', proposedTime: value, expectedLength: session.request.expectedLength };
    const res = sessionService.creatorChangeScheduld(payload, session._id);
    if (!res) return;
    showMessage('Request sent! Check the status on the Invitations tab.', 'success');
    setOpen({ ...open, proposeNewTime: false });
    refresh();
  }

  const handleAccept = async () => {
    const res = await sessionService.confirmSession(session._id);
    if (!res) {
      return;
    }
    showMessage("Invitation accepted!", "success");
    refresh();
  };

  useEffect(() => {
    checkDevices();
    getLightsterTimezone(session.lightster.id);
  }, []);
  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} spacing={2} p={2}>
        <Avatar
          sx={{ width: 56, height: 56, cursor: "pointer" }}
          src={session.lightster.imageProfile}
          onClick={handleclickViewProfile}
        />
        <Stack direction={"column"} width={"100%"}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <div style={{ maxWidth: "120px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace:"pre-line", cursor:"pointer"}}>
            <Typography
              level="title-lg"
              onClick={handleclickViewProfile}
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              '&:hover': {
                        color:"black",
                        textDecoration: 'underline',
                      },
                WebkitLineClamp: 1,
              }}
            >
              
              {session.lightster.displayname}
            </Typography>
            </div>
            <Dropdown>
              <MenuButton
                variant="plain"
                size="sm"
                color="primary"
                sx={{
                  paddingInline: 1,
                  justifyContent: "start",
                  fontWeight: 500,
                }}
                endDecorator={
                  <KeyboardArrowDownRounded
                    fontSize="small"
                    sx={{ color: "black" }}
                  />
                }
              >
                {mapStatusText(session.status)}
              </MenuButton>
              <Menu size="sm" placement="bottom-start">
                {['pending', 'confirmed', 'changing'].includes(session.status) && <><MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => {
                    setOpen({ ...open, proposeNewTime: true });
                  }}
                >
                  Propose new time
                </MenuItem>
                  <Divider /> </>}
                <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => setOpen({ ...open, cancel: true })}
                >
                  Cancel session
                </MenuItem>
                <Divider />
                <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={handleCopyLink}
                >
                  Copy meeting link for guest
                </MenuItem>
              </Menu>
            </Dropdown>
          </Stack>
          <Typography level="body-md">
            {moment(session.request.proposedTime).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography level="body-md">
              {session.request.expectedLength} minutes
            </Typography>
          </Stack>

          <Box className="box-row" sx={{ gap: 1, pt: 1 }}>
            {session.status === "changing" && session.request.requestBy == "lightster" && (
              <Box className="box-row" sx={{ gap: 1, pt: 1 }}>
                <Button
                  variant="solid"
                  size="sm"
                  sx={{
                    borderRadius: 6,
                    bgcolor: "black",
                    fontSize: 12,
                    paddingInline: 2,
                    minHeight: 32,
                  }}
                  onClick={() => handleAccept()}
                >
                  Accept
                </Button>
                <Button
                  variant="plain"
                  size="sm"
                  color="primary"
                  sx={{
                    borderRadius: 6,
                    fontSize: 12,
                    paddingInline: 2,
                    minHeight: 32,
                  }}
                  onClick={() => setOpen({ ...open, cancel: true })}
                >
                  Decline
                </Button>
              </Box>
            )}
            {session.status !== "pending" && !(session.status === "changing" && session.request.requestBy == "lightster") && (
              <Button
                variant="solid"
                size="sm"
                sx={{
                  borderRadius: 6,
                  fontSize: 12,
                  bgcolor: inThirtyMinutes(session.request.proposedTime)
                    ? "black"
                    : "#dcdcdc",
                  paddingInline: 2,
                  minHeight: 32,
                }}
                onClick={handleJoin}
              >
                Join
              </Button>
            )}
            {session.status === "pending" && (
              <Button
                variant="solid"
                size="sm"
                sx={{
                  borderRadius: 6,
                  fontSize: 12,
                  bgcolor: "black",
                  paddingInline: 2,
                  minHeight: 32,
                }}
                onClick={() => setOpen({ ...open, cancel: true })}
              >
                Cancel
              </Button>
            )}
            {["confirmed", "accepted"].includes(session.status) && <Button
              variant="plain"
              size="sm"
              color="primary"
              sx={{
                borderRadius: 6,
                fontSize: 12,
                paddingInline: 2,
                minHeight: 32,
              }}
              onClick={handleCopyLink}
            >
              Copy guest link
            </Button>}
          </Box>

          {isJoined() && (
            <Typography
              level="body-sm"
              color="success"
              sx={{ fontStyle: "italic" }}
            >
              You’ve joined on another device
            </Typography>
          )}
        </Stack>
      </Stack>
      {open.cancel && (
        <CustomDialog
          open={open.cancel}
          title="Are you sure you want to cancel?"
          subtitle={
            <Stack direction={"column"} spacing={4}>
              {( ( new Date(session.request.proposedTime).getTime() - new Date().getTime()) > 24 * 60 * 60 * 1000) ? (
                <Typography level="body-lg">
                  Please confirm that you would like to cancel this session. If you require any assistance to reschedule, please contact support@lightster.co.
                </Typography>
              ) : (
                <Typography level="body-lg">
                  You are cancelling this invitation under 24 hours and will be
                  charged for 50% of the transaction if you cancel.
                </Typography>
              )}


              <Stack
                direction={"row"}
                justifyContent={{ sm: "space-between", md: "end" }}
                spacing={2}
              >
                <PlainButton
                  variant="plain"
                  onClick={() => setOpen({ ...open, cancel: false })}
                >
                  Go Back
                </PlainButton>
                <SolidButton
                  variant="solid"
                  onClick={() => {
                    setOpen({ ...open, cancel: false });
                    handleDecline();
                  }}
                >
                  Yes
                </SolidButton>
              </Stack>
            </Stack>
          }
        />
      )}
      {open.proposeNewTime && (
        <ProposeNewTimeDialog
          open={open.proposeNewTime}
          timezone={timezone}
          defaultValue={session.request.proposedTime}
          onClose={(value: string | undefined) => {
            console.log('new value propose: ', value);
            if (value) {
              handleNewProposeTime(value);
              return;
            }
            setOpen({ ...open, proposeNewTime: false });

          }}
        />
      )}
    </Box>
  );
}

export default CreatorSessionItem;
