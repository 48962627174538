import { EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Textarea,
    Typography,
} from "@mui/joy";
import { Box } from "@mui/system";
import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import placeholder from "../../../assets/images/placeholder.png";
import BackgroundRegister from "../../../components/BackgroundRegister";
import LocationDialog from "../../../components/dialog/profile/LocationDialog";
import {
    ProfileParams,
    UploadImageParams,
} from "../../../constants/interfaces";
import { primaryPurple } from "../../../mui-styles";
import { useApi } from "../../../providers/ApiProvider";

import PickerWithJoyField from "../../../DatePickerJoy";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {}
const keyRequired = [
  "fullname",
  "lastnameinit",
  "location",
  "birthday",
  "gender",
];
function LightsterAboutYou(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const { profileService, userService, handleToggle, showMessage } = useApi();
  const [errors, setErrors] = useState({
    fullname: false,
    location: false,
    birthday: false,
    gender: false,
  });

  const [profile, setProfile] = useState(Array<ProfileParams>);
  const [imagePrrofile, setImageProfile] = useState<any>(null);
  const [fileImage, setFileImage] = useState<Blob>();
  const [open, setOpen] = useState(false);

  const handleOnChange = (field: string, value: any) => {
    const updatedObject = profile.map((p: ProfileParams) => {
      if (p.key === field) {
        const data: ProfileParams = {
          profileId: p.profileId,
          key: p.key,
          answers: value ? [value] : [],
          keywordList: p.keywordList,
        };
        return data;
      } else {
        return p;
      }
    });

    setProfile(updatedObject);
  };

  const getAboutYou = async () => {
    const res = await profileService.getAboutYou();
    if (!res) return;

    const list: Array<ProfileParams> = [];
    res.map((p: any) => {
      if (p.key === "picture url" && p.answers.length > 0) {
        setImageProfile(p.answers);
      }
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: p.answers,
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile(list);
  };

  const getYourProfile = async () => {
    const res = await profileService.getYourProfile();
    if (!res) return;
    const list: Array<ProfileParams> = [];

    res.map((p: any) => {
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: p.answers,
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile((pre) => [...pre, ...list]);
  };

  const getKeywordList = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data?.keywordList;
  };

  const getProfileField = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data;
  };

  const handleImageChange = (event: any) => {
    setImageProfile(URL.createObjectURL(event.target.files[0]));
    setFileImage(event.target.files[0]);
  };
  const getBase64 = (fileImage: Blob, callback: (value: any) => void) => {
    const file = fileImage;
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file as Blob);
  };

  const updateImageProfile = async () => {
    getBase64(fileImage as Blob, async (value: any) => {
      const params: UploadImageParams = {
        base64: `${value}`,
        type: `${fileImage?.type}`,
      };
      await userService.uploadImageProfile(params);
    });
  };

  const textEmpty = (value: any) => {
    return ["", null, undefined].includes(value);
  };
  const handleError = async () => {
    const newErrors: any = {};
    for (let index = 0; index < profile.length; index++) {
      if (
        textEmpty(profile[index].answers[0]) &&
        profile[index].key !== "lastnameinit"
      ) {
        newErrors[`${profile[index].key}`] = true;
      }
    }
    setErrors((pre: any) => newErrors);
    return newErrors;
  };

  const getLightsterParams = () => {
    let profiles: Array<any> = [];

    profile.map((pro) => {
      if (pro.answers.length > 0) {
        profiles.push({
          profileId: pro.profileId,
          answers: pro.answers,
        });
      }
    });

    return { profiles: profiles };
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const contained = await handleError();

    if (Object.values(contained).includes(true)) {
      showMessage("Please complete the information.", "error");
      return;
    }

    handleToggle(true);
    if (fileImage) {
      updateImageProfile();
    }
    const res = await profileService.updateLightProfile(getLightsterParams());
    handleToggle(false);
    if (!res) return;
    navigate(pagesName.lightster.aboutProfile);
  };
  useEffect(() => {
    const getStart = async () => {
      await getAboutYou();
      await getYourProfile();
    };

    getStart();

    const handleBeforeUnload = (event:any) => {
      localStorage.clear();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, []);
  console.log(errors.birthday);

  if (!profile) return <></>;
  return (
    <form onSubmit={handleSubmit}>
      <BackgroundRegister>
        <Stack direction={"column"} mt={"10vh"}>
          <BorderLinearProgress determinate value={60} sx={{ mb: 11 }} />
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                Tell us a bit about yourself
              </Typography>
              <Typography level="title-md" mt={1}>
                This information helps Creators get to know you more when you
                are matched.
              </Typography>
            </Box>
            <FormControl>
              <FormLabel>Upload a photo of yourself</FormLabel>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    height: 62,
                    width: 62,
                    borderRadius: "50px",
                  }}
                >
                  <img
                    src={imagePrrofile ?? placeholder}
                    style={{
                      width: 62,
                      height: 62,
                      objectFit: "cover",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="plain"
                    color="primary"
                    component="span"
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}
                  >
                    Choose file
                  </Button>
                </label>
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel>Your first and last name</FormLabel>
              <Input
                fullWidth
                autoFocus
                value={getProfileField("fullname")?.answers[0]}
                error={errors.fullname}
                onChange={(e) => {
                  if (errors.fullname && e.target.value) {
                    setErrors({ ...errors, fullname: false });
                  }

                  handleOnChange("fullname", e.target.value);
                }}
              />
            </FormControl>
            <FormControl onClick={() => setOpen(true)}>
              <FormLabel>Location</FormLabel>
              <Autocomplete
                readOnly
                error={errors.location}
                value={getProfileField("location")?.answers[0] ?? ""}
                options={[]}
              />
            </FormControl>
            {open && (
              <LocationDialog
                open={open}
                onClose={() => setOpen(false)}
                onSave={(value) => {
                  if (errors.location && value) {
                    setErrors({ ...errors, location: false });
                  }
                  handleOnChange("location", value);
                  setOpen(false);
                }}
              />
            )}
            <FormControl>
              <FormLabel>Birthday</FormLabel>
              <PickerWithJoyField
                sx={{
                  borderColor: errors.birthday ? "#F09898" : "black",
                }}
                value={
                  !getProfileField("birthday")?.answers[0]
                    ? null
                    : dayjs(getProfileField("birthday")?.answers[0])
                }
                onChange={(value) => {
                  if (errors.birthday && value) {
                    setErrors({ ...errors, birthday: false });
                  }
                  if (value) {
                    const date = `${value}`;
                    handleOnChange("birthday", date);
                  }
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Autocomplete
                value={getProfileField("gender")?.answers[0] ?? ""}
                error={errors.gender}
                onChange={(_, value: any) => {
                  if (errors.gender && value) {
                    setErrors({ ...errors, gender: false });
                  }
                  handleOnChange("gender", value);
                }}
                options={getKeywordList("gender") ?? []}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Short bio about yourself (optional)</FormLabel>
              <Textarea
                minRows={4}
                value={getProfileField("bio")?.answers[0]}
                onChange={(e) => {
                  handleOnChange("bio", e.target.value);
                }}
              />
            </FormControl>

            <Stack direction={"row"} spacing={1} alignItems="center">
              <i
                className="bi bi-check2-circle"
                style={{
                  fontSize: 24,
                  cursor: "pointer",
                  color:
                    getProfileField("lastnameinit")?.answers[0] === "true"
                      ? primaryPurple
                      : "grey",
                }}
                onClick={() => {
                  const value =
                    getProfileField("lastnameinit")?.answers[0] === "true";
                  handleOnChange("lastnameinit", `${!value}`);
                }}
              ></i>
              <Typography
                level="title-sm"
                sx={{
                  color:
                    getProfileField("lastnameinit")?.answers[0] === "true"
                      ? primaryPurple
                      : "grey",
                }}
              >
                Only show my last name initial
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={6}
            mb={6}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ minWidth: 128 }}
              type="submit"
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </BackgroundRegister>
    </form>
  );
}

export default LightsterAboutYou;
