import {
    Box,
    Modal,
    ModalDialog,
    Typography
} from "@mui/joy";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function UpgradeToViewUserDialog(props: Props) {
  const { open, onClose, onSubmit } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="variant-modal-title"
        aria-describedby="variant-modal-description"
        size="lg"
      >
        <Typography id="variant-modal-title" level="h3" sx={{ fontSize: 24 }}>
          Upgrade to view users
        </Typography>
        <Typography
          id="variant-modal-description"
          level="body-md"
          sx={{ textAlign: "start", color: "black" }}
        >
          This community contains more than 3 criteria, which is only available
          on the <Typography level="title-md">Premium Plan</Typography>.
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", sm: "row-reverse" },
          }}
        >
          <SolidButton variant="solid" color="primary" onClick={onSubmit}>
            Upgrade
          </SolidButton>
          <PlainButton variant="plain" color="primary" onClick={onClose}>
            Go back
          </PlainButton>
        </Box>
      </ModalDialog>
    </Modal>
  );
}

export default UpgradeToViewUserDialog;
