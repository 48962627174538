import { AddCircle, KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { InputType, StorageType } from "../../../constants/enum";
import { QuestionSurvey } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { PlainButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: (value: QuestionSurvey[] | null) => void;
  defaultData: QuestionSurvey[];
}

function QuestionSurveyDialog(props: Props) {
  const { open, onClose, defaultData } = props;

  const [selected, setSelected] = useState<QuestionSurvey | null>(null);
  const [edit, setEdit] = useState<QuestionSurvey | null>(null);

  const [index, setIndex] = useState<number | null>(null);
  const [questions, setQuestions] = useState<QuestionSurvey[]>([]);

  const handleAddQuestion = () => {
    const newQuestion: QuestionSurvey = {
      title: "",
      keywordList: ["", "", "", "", ""],
      inputType: InputType.SELECTLIST,
    };
    setSelected(newQuestion);
  };

  useEffect(() => {
    if (questions) {
      setQuestions(defaultData);
    }
  }, []);

  return (
    <Modal open={open}>
      <ModalDialog
        aria-labelledby="layout-modal-title "
        aria-describedby="layout-modal-description "
        size="lg"
        sx={{
          width: {
            xs: '480px',
            sm: '480px',
            md: '480px',
            lg: '640px',
            xl: '640px'
          },          
           overflow: "auto"
        }}
      >
        <Typography id="layout-modal-title" level="h2" sx={{ fontSize: 24 }}>
          {selected ? "Add a Question" : "Questions"}
        </Typography>
        {selected ? (
          <QuestionSelected
            selected={selected}
            onDelete={(value) => {
              setQuestions(questions.filter((ele) => ele != value));
              setEdit(null);
              setIndex(null);
              setSelected(null);
              sessionStorage.removeItem(StorageType.previousQuestionSurvey);
            }}
            onSave={(value) => {
              if (edit && index != null) {
                questions[index] = value;
                setQuestions(questions);
                setEdit(null);
                setIndex(null);
                setSelected(null);
              } else {
                setQuestions([...questions, value]);
                setSelected(null);
              }
            }}
            onCancel={() => {
              const data = sessionStorage.getItem(
                StorageType.previousQuestionSurvey
              );
              const prevValue = data ? JSON.parse(data) : null;

              if (index != null) {
                questions[index] = prevValue;
                setQuestions(questions);
                sessionStorage.removeItem(StorageType.previousQuestionSurvey);
              }
              setEdit(null);
              setIndex(null);
              setSelected(null);
            }}
          />
        ) : (
          <Box
            sx={{
              maxHeight: '70vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <Typography level="body-lg" sx={{ color: "black", mt: 2 }}>
              Add the questions that you want to ask in the survey. A question
              costs
            </Typography> */}

            <Stack direction={"column"} spacing={2} mt={2}
              sx={{
                overflowY: 'auto',
                flexGrow: 1,
                marginBottom: 2,
              }}>
              <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {questions.length === 0 ? (
                  <QuestionItem
                    title="Question 1"
                    showAdd={true}
                    onClick={handleAddQuestion}
                  />
                ) : (
                  questions.map((quest, i) => (
                    <QuestionItem
                      title={quest.title}
                      showAdd={false}
                      onClick={() => {
                        setIndex(i);
                        setEdit(quest);
                        setSelected(quest);
                      }}
                    />
                  ))
                )}
              </div>

              <Button
                variant="plain"
                sx={{ color: "black", width: 212, paddingInline: 2 }}
                startDecorator={<AddCircle sx={{ color: "black" }} />}
                onClick={handleAddQuestion}
              >
                Add another question
              </Button>
            </Stack>
            <Stack direction={"row"} spacing={2} justifyContent="end" mt={4}>
              <PlainButton
                variant="plain"
                onClick={() => {
                  onClose(null);
                }}
              >
                Cancel
              </PlainButton>
              <Button
                variant="solid"
                sx={{
                  bgcolor: (theme) =>
                    questions.length === 0
                      ? theme.palette.neutral[200]
                      : theme.palette.primary.darkChannel,
                  ":hover": {
                    bgcolor: (theme) =>
                      questions.length === 0
                        ? theme.palette.neutral[200]
                        : theme.palette.primary.lightChannel,
                    color: "white",
                  },
                }}
                onClick={() => onClose(questions)}
              >
                Save
              </Button>
            </Stack>
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
}

interface QuestionSelectedProps {
  selected: QuestionSurvey;
  onCancel: () => void;
  onSave: (value: QuestionSurvey) => void;
  onDelete: (value: QuestionSurvey) => void;
}
const QuestionSelected = ({
  selected,
  onCancel,
  onSave,
  onDelete,
}: QuestionSelectedProps) => {
  const { showMessage } = useApi();
  const [question, setQuestion] = useState<QuestionSurvey | null>(null);

  const handleChangeOption = (index: number, value: string) => {
    if (!question) return;
    question.keywordList[index] = value;
    setQuestion({ ...question, keywordList: question.keywordList });
  };

  const handleOnSave = () => {
    if (!question) return;
    if (question.title.trim().length === 0) {
      showMessage("Please enter a question.", "warning");
      return;
    }
    question.keywordList = question.keywordList.filter(
      (ele) => ele.trim().length > 0
    );
    if (
      question?.inputType === InputType.SELECTLIST &&
      question.keywordList.length === 0
    ) {
      showMessage("Please enter at least one option.", "warning");
      return;
    }

    onSave(question);
    sessionStorage.removeItem(StorageType.previousQuestionSurvey);
  };

  useEffect(() => {
    setQuestion(selected);
    if (selected.title) {
      sessionStorage.setItem(
        StorageType.previousQuestionSurvey,
        JSON.stringify(selected)
      );
    }
  }, [selected]);
  return (
    <Box maxHeight='70vh'>
      <Stack direction={"column"} spacing={1.5} mt={1}>
        <Textarea
          autoFocus
          value={question?.title}
          onChange={(e) => {
            if (!question) return;
            setQuestion({ ...question, title: e.target.value });
          }}
          minRows={4}
        />
        <Select
          color="primary"
          variant="plain"
          sx={{ width: 175 }}
          indicator={<KeyboardArrowDownRounded fontSize="small" />}
          value={question?.inputType}
          onChange={(e, newValue) => {
            if (!newValue || !question) return;
            if (newValue === InputType.TEXTFIELD) {
              setQuestion({
                ...question,
                inputType: newValue,
                keywordList: [],
              });
            } else {
              setQuestion({ ...question, inputType: newValue });
            }
          }}
        >

          <Option value={InputType.SELECTLIST}>Multiple Choice</Option>
          <Option value={InputType.TEXTFIELD}>Text Response</Option>
        </Select>
        <Box overflow={"auto"} maxHeight={"35vh"} sx={{ "&::-webkit-scrollbar": { display: "inline" } }}>
          <Stack direction={"column"} spacing={2}>
            {question?.inputType === InputType.TEXTFIELD ? (
              <Typography level="body-sm">
                The user will be asked to type in a response to this question.
              </Typography>
            ) : (
              Array.from({ length: 5 }, (_, index) => (
                <FormControl>
                  <FormLabel>Option {index + 1}</FormLabel>
                  <Input
                    value={question?.keywordList[index]}
                    onChange={(e) => handleChangeOption(index, e.target.value)}
                    size="md"
                  />
                </FormControl>
              ))
            )}
          </Stack>
        </Box>
      </Stack>

      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={!selected.title ? "end" : "space-between"}
        mt={4}
      >
        {selected.title && (
          <Box>
            <PlainButton
              variant="plain"
              sx={{
                paddingInline: 1,
              }}
              onClick={() => onDelete(selected)}
            >
              Delete Question
            </PlainButton>
          </Box>
        )}

        <Stack direction={"row"} gap={2}>
          <PlainButton variant="plain" onClick={onCancel}>
            Cancel
          </PlainButton>
          <Button variant="solid" onClick={handleOnSave}>
            Save
          </Button>
        </Stack>
      </Stack>

    </Box>
  );
};

export default QuestionSurveyDialog;

interface ItemProps {
  title: string;
  showAdd: boolean;
  onClick: Function;
}
const QuestionItem = ({ title, showAdd, onClick }: ItemProps) => {
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: 6,
        padding: 2,
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"start"}
      >
        <Typography level="title-md" sx={{ maxWidth: "80%" }}>
          {title}
        </Typography>
        {showAdd ? (
          <Typography
            level="body-md"
            textColor={"text.secondary"}
            textAlign={"right"}
          >
            Add
          </Typography>
        ) : (
          <KeyboardArrowDownRounded />
        )}
      </Stack>
    </Box>
  );
};
