import { Box, Typography, styled } from "@mui/joy";
import { SurvayDisplay } from "../../constants/interfaces";
import { PlainButton } from "../button/CustomButton";
import moment from "moment";
import { CustomButtton } from "../../custom-styles";

interface Props {
  survey: SurvayDisplay;
  refresh?: Function;
}

function CreatorSurveyItemList({ survey, refresh }: Props) {
  const handleClick = () => {
    window.open(survey.url, '_blank');
  };

  const StyledTable = styled('table')({
    width: '95%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
  });

  const StyledTd = styled('td')({
    padding: '8px',
    textAlign: 'left',
    wordWrap: 'break-word',
  });

  const nameColumnStyle = {
    width: '40%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
 const completedDateColumnStyle = { width: '30%' };
  const durationColumnStyle = { width: '20%' };
  const buttonColumnStyle = { width: '10%' };

  return (
    <Box className="unmoderated-box-shadow">
      <StyledTable>
        <tbody>
          <tr>
            <StyledTd style={nameColumnStyle}>
              <Typography level="title-lg">
                {survey.name || "No Name"}
              </Typography>
            </StyledTd>
            <StyledTd style={completedDateColumnStyle}>
              <Typography level="body-md">
                {survey.createdAt
                  ? moment(survey.createdAt).format("MMM DD, YYYY hh:mm A")
                  : "No Completion Date"}
              </Typography>
            </StyledTd>
            <StyledTd style={durationColumnStyle}>
              <Typography level="body-md">
                {survey.expectResponse 
                  ? survey.received +" / "+ survey.expectResponse +" received"
                  : "-"}
              </Typography>
            </StyledTd>
            <StyledTd style={buttonColumnStyle}>
              <CustomButtton onClick={handleClick}>
                View
              </CustomButtton>
            </StyledTd>
          </tr>
        </tbody>
      </StyledTable>
    </Box>
  );
}

export default CreatorSurveyItemList;