import { Circle, SearchRounded } from "@mui/icons-material";
import {
    Avatar,
    Input,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    ListItemDecorator,
    Stack,
    Typography
} from "@mui/joy";
import { Box } from "@mui/system";
import {
    collection,
    doc,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { placeholder } from "../../../constants/AppImages";
import { RoleType } from "../../../constants/enum";
import { Chat } from "../../../constants/interfaces";
import { primaryPurple } from "../../../joy-styles";
import { useAuth } from "../../../providers/AuthProvider";
import { firebaseDB } from "../../../providers/FirebaseProvider";

interface Props {
  onClick: (user: Chat) => void;
}

function CreatorMessages(props: Props) {
  const { onClick } = props;
  const { role, user } = useAuth();

  const [chats, setChats] = useState<Chat[]>([]);
  const [inbox, setInbox] = useState<Chat[]>([]);
  const [refHeight, setRefHeight] = useState<number>(0);
  const topHeight = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState("");

  const getCurrentId = () => {
    return `${role}_${user?.id}`;
  };

  const handleClickUser = (user: Chat) => {
    onClick(user);
    updateReadMessage(user);
  };

  const updateReadMessage = async (user: Chat) => {
    const receiverId = `${RoleType.lightster}_${user.user.id}`;
    await updateDoc(doc(firebaseDB, `${getCurrentId()}/${receiverId}`), {
      read: true,
    });
  };
  const handleSearch = (value: string) => {
    if (!value) {
      setInbox(chats);
    } else {
      const subArr = inbox.filter((str) =>
        str.user.displayName.toLowerCase().includes(value.toLowerCase())
      );
      setInbox(subArr);
    }
  };
  useEffect(() => {
    const q = query(
      collection(firebaseDB, `${getCurrentId()}`),
      where("isActive","==", true),
      // orderBy("timeStampServer", "desc"),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list: Chat[] = [];
      querySnapshot.forEach((doc) => list.push(doc.data() as Chat));
      list.sort((a, b) => {
        const aMillis = a.timeStampServer ? a.timeStampServer.toMillis() : 0;
        const bMillis = b.timeStampServer ? b.timeStampServer.toMillis() : 0;
        return bMillis - aMillis;
    });

      // list.reverse();
      setInbox(list);
      setChats(list);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setRefHeight(topHeight.current?.clientHeight ?? 0);
  }, [topHeight]);

  return (
    <Stack direction={"column"} height={"calc(100vh - 64px)"}>
      <Stack direction={"column"} gap={2} ref={topHeight}>
        <Typography level="h3">Messages</Typography>
        <Input
          fullWidth
          placeholder="Search for a message..."
          startDecorator={
            <Box
              display={"flex"}
              width={40}
              alignItems="center"
              justifyContent="center"
            >
              <SearchRounded sx={{ color: "#4A5568" }} />
            </Box>
          }
          variant="soft"
          color="neutral"
          sx={{
            fontSize: 14,
            fontWeight: 700,
            bgcolor: "#7676801F",
          }}
          value={search}
          onChange={(e) => {
            const { value } = e.target;
            setSearch(value);
            handleSearch(value);
          }}
        />
      </Stack>
      <Box
        className="chat-list"
        sx={{
          height: `calc(100vh - ${refHeight}px)`,
        }}
      >
        {inbox.length > 0 ? (
          <List
            color="neutral"
            aria-labelledby="ellipsis-list-demo"
            sx={{ "--ListItemDecorator-size": "56px" }}
          >
            {inbox.map((chat, i) => (
              <ListItem key={`${chat.id}_${i}`}>
                <ListItemButton
                  color="primary"
                  sx={{ paddingInline: 1 }}
                  onClick={() => handleClickUser(chat)}
                >
                  <ListItemDecorator>
                    <Avatar
                      src={chat.user?.imageProfile ?? placeholder}
                      sx={{ width: 40, height: 40, bgcolor: "white" }}
                    >
                      <img src={placeholder} width={40} height={40} />
                    </Avatar>
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      fontWeight={chat.read ? 500 : 700}
                    >
                      {chat.user?.displayName}
                    </Typography>
                    <Typography
                      level="body-sm"
                      noWrap
                      fontWeight={chat.read ? 500 : 700}
                    >
                      {chat.lastAction}
                    </Typography>
                  </ListItemContent>
                  <Circle
                    sx={{
                      fontSize: 8,
                      color: chat.read ? "transparent" : primaryPurple,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography level="body-md" sx={{ color: "#4A5568" }}>
            You don’t have any messages.
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

export default CreatorMessages;
