import { CreditCard } from "@mui/icons-material";
import { CircularProgress, Divider, Typography } from "@mui/joy";
import { Box, Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import {
    CheckmarkCircle,
    CloseCircle,
    InformationCircle,
    ThumbsDownOutline,
    ThumbsUpOutline,
} from "react-ionicons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import { RoleType, VoteResult } from "../../constants/enum";
import { Session } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";

export default function PaymentSummary() {
  const location = useLocation();
  const sessionId = location.state?.sessionId;
  const totalDuration = location.state?.totalDuration;
  const navigate = useNavigate();
  const { role, user } = useAuth();
  const { sessionService, handleToggle } = useApi();
  const [session, setSession] = useState<Session>();
  const [vote, setVote] = useState(false);
  const [loading, setLoading] = useState(false);

  const onPositiveVote = async () => {
    setVote(true);
    await sessionService.sessionRate(sessionId, VoteResult.positive);
  };
  const onNegativeVote = async () => {
    setVote(true);
    await sessionService.sessionRate(sessionId, VoteResult.negative);
  };
  const onNoVote = async () => {
    if (session?.status !== "completed") {
      return;
    }
    if (vote) {
      return;
    }
    await sessionService.sessionRate(sessionId, VoteResult.neutral);
  };

  const getSession = async () => {
    const res = await sessionService.getSession(sessionId);
    if (!res) return;
    handleReloadSession(res);
  };

  const handleEndSession = async () => {
    const res = await sessionService.endSession(sessionId, totalDuration ?? 0);
    if (!res) {
      return;
    }

    getSession();
  };
  const handleReloadSession = (response: Session) => {
    if (response.status === "inProgress") {
      handleEndSession();
    } else if (response.status === "noShow") {
      setSession(response);
      setLoading(false);
    } else if (
      role === "creator" &&
      !response?.paymentResult?.successPaymentData
    ) {
      getSession();
    } else if (role === "lightster" && response.status === "completed") {
      setSession(response);
      setLoading(false);
    } else {
      setSession(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSession();
    return () => {};
  }, [sessionId]);

  const isExcess = () => {
    return session?.paymentResult.successPaymentData.appliedUnit != 0;
  };

  const getHomePath = () => {
    return role === RoleType.lightster
      ? pagesName.lightster.home
      : pagesName.creator.home;
  };
  if (!sessionId) {
    return <Navigate to={getHomePath()} replace />;
  }

  if (!session || loading)
    return (
      <>
        <Box
          display={"flex"}
          height={"100vh"}
          width={"100vw"}
          alignItems="center"
          justifyContent={"center"}
        >
          <CircularProgress color="primary" />
        </Box>
      </>
    );
  return (
    <Container
      maxWidth="md"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        pt: 3,
      }}
    >
      <Box>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography level="h2" fontWeight={800}>
            Summary
          </Typography>
          <CloseCircle
            style={{
              cursor: "pointer",
            }}
            width={"32px"}
            height={"32px"}
            onClick={() => navigate(getHomePath(), { replace: true })}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={3}
          p={4}
          bgcolor="rgba(247, 250, 252, 1)"
          borderRadius={10}
        >
          <Typography level="h3" fontWeight={500}>
            Call with{" "}
            {role === "lightster"
              ? session.creator.displayname
              : session.lightster.displayname}
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            {moment(session.endCall).format("MMM D, YYYY hh:mm A")}
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            Duration: {session.quantity} minutes
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            Reference: {session.refNumber}
          </Typography>
          {session.creator.callType === "card" && role === "creator" && (
            <Box display={"flex"} flexDirection="row">
              <CreditCard color="secondary" />
              <Typography level="title-md">
                *****{session.paymentResult.successPaymentData.cardNumber}
              </Typography>
            </Box>
          )}

          {role === "lightster" &&
            getLightsterSummary(session.paymentResult?.transferSuccessData)}
          {session.creator.callType === "card" && role === "creator" && (
            <Box>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Typography level="title-lg" fontWeight={400} mt={3}>
                  {isExcess() ? "Reward:" : "Session:"}
                </Typography>
                <Typography level="title-lg" fontWeight={400} mt={3}>
                  $
                  {(
                    session.paymentResult.successPaymentData.subtotal / 100
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Typography level="title-lg" fontWeight={400}>
                  Tax:
                </Typography>
                <Typography level="title-lg" fontWeight={400}>
                  $
                  {(session.paymentResult.successPaymentData.tax / 100).toFixed(
                    2
                  )}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Typography level="h3" fontWeight={500}>
                  Total:
                </Typography>
                <Typography level="h3" fontWeight={500}>
                  $
                  {(
                    session.paymentResult.successPaymentData.total / 100
                  ).toFixed(2)}
                </Typography>
              </Box>
              {isExcess() && (
                <Box>
                  <Divider
                    sx={{ mt: 3, borderBottomWidth: 1, color: "black" }}
                  />
                  <Box
                    display={"flex"}
                    flexDirection="row"
                    justifyContent={"space-between"}
                    mt={3}
                  >
                    <Typography level="title-lg" fontWeight={400}>
                      Credits applied:
                    </Typography>
                    <Typography level="title-lg" fontWeight={400}>
                      {session.paymentResult.successPaymentData.appliedUnit}{" "}
                      credits
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="row"
                    justifyContent={"space-between"}
                  >
                    <Typography level="title-lg" fontWeight={400}>
                      Credits remaining:
                    </Typography>
                    <Typography level="title-lg" fontWeight={400}>
                      {session.paymentResult.successPaymentData.remainingUnit}{" "}
                      credits
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {session.creator.callType === "subscription" && role === "creator" ? (
            <Box>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Typography
                  level="title-lg"
                  mt={3}
                  sx={{ fontSize: 18, fontWeight: 700 }}
                >
                  Total:
                </Typography>
                <Typography
                  level="title-lg"
                  mt={3}
                  sx={{ fontSize: 18, fontWeight: 700 }}
                >
                  {session.paymentResult?.successPaymentData?.appliedUnit ?? 0}{" "}
                  credits
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Typography
                  level="title-lg"
                  sx={{ fontSize: 14, fontWeight: 500 }}
                >
                  Balance:
                </Typography>
                <Typography
                  level="title-lg"
                  sx={{ fontSize: 14, fontWeight: 500 }}
                >
                  {session.paymentResult.successPaymentData?.remainingUnit ?? 0}{" "}
                  credits
                </Typography>
              </Box>
              {session.paymentResult.successPaymentData?.remainingUnit &&
              session.paymentResult.successPaymentData.remainingUnit < 0 ? (
                <Box display={"flex"} flexDirection="column">
                  <Divider
                    sx={{ mt: 3, borderBottomWidth: 1, color: "black" }}
                  />
                  <Box
                    display={"flex"}
                    flexDirection="row"
                    mt={3}
                    alignItems="center"
                  >
                    <InformationCircle
                      color={"#00000"}
                      height="30px"
                      width="30px"
                    />
                    <Box width={10} />
                    <Typography level="body-xs">
                      {" "}
                      Additional credits used will appear on your next bill at
                      the exclusive rate of [rate] on your next bill.
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems="center"
        mt={"15%"}
      >
        {vote ? (
          <Box p={2} alignItems="center" justifyContent="center">
            <Box
              display={"flex"}
              flexDirection={"row"}
              sx={{
                background: "#F7FAFC",
                boxShadow: " 0px 4px 4px rgb(0 0 0 / 25%)",
                borderRadius: " 8px",
                width: "400px   ",
                height: "150px",
                marginBottom: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckmarkCircle width="24px" height="24px" color="black" />
              <Box width="5px" />
              <Typography level="body-md" fontWeight={400}>
                Thank you for rating!
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography level="body-md" fontWeight={400} textAlign="center">
              How was your experience with this Lightster?
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent="center  "
              mt={5}
              mb={5}
            >
              <ThumbsUpOutline
                style={{
                  cursor: "pointer",
                }}
                width={"35px"}
                height={"35px"}
                onClick={onPositiveVote}
              />
              <Box width={40} />
              <ThumbsDownOutline
                style={{
                  cursor: "pointer",
                }}
                width={"35px"}
                height={"35px"}
                onClick={onNegativeVote}
              />
            </Box>
          </Box>
        )}
        <Typography level="body-xs">
          E-mail support@lightster.co for support.
        </Typography>
      </Box>
    </Container>
  );
}

const getLightsterSummary = (transferSuccessData: any) => {
  const { amount, reward, boots } = transferSuccessData;
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
      >
        <Typography level="title-lg" fontWeight={400} mt={4}>
          Session reward
        </Typography>
        <Typography level="title-lg" fontWeight={400} mt={4}>
          ${reward.toFixed(2)}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
      >
        <Typography level="title-lg" fontWeight={400}>
          10% boost
        </Typography>
        <Typography level="title-lg" fontWeight={400}>
          ${boots.toFixed(2)}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
      >
        <Typography level="h3" fontWeight={500}>
          You've earned
        </Typography>
        <Typography level="h3" fontWeight={500}>
          ${amount.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};
