import {
    Box,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from "@mui/joy";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import PickerWithButtonField from "../../../ButtonDatePicker";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  timezone?: number;
  defaultValue: string;
  open: boolean;
  onClose: Function;
}
export const ProposeNewTimeDialog = ({
  timezone,
  defaultValue,
  open,
  onClose,
}: Props) => {
  const [slot, setSlot] = useState<string| undefined>(undefined);

  useEffect(() => {
    setSlot(defaultValue);
  }, []);

  return (
    <Modal open={open} >
      <ModalDialog
        aria-labelledby="layout-modal-title-reanme"
        aria-describedby="layout-modal-description-reanme"
        size="lg"
        sx={{ overflow: "auto" }}
      >
      
        <Typography
          id="layout-modal-title-reanme"
          level="h2"
          sx={{ fontSize: 24 }}
        >
          Propose a new time
        </Typography>
        <Typography level="body-lg" sx={{ color: "black" }}>
          Set your preferred date and time.
        </Typography>
        <Stack direction={"column"} spacing={2} alignItems="inherit" mt={2}>
            <Stack direction="row" alignItems={"center"} gap={1}>
              <Box width={"100%"}>
                <PickerWithButtonField
                  slot={0}
                  timezone={timezone}
                  defaultValue={dayjs(slot)}
                  value={dayjs(slot)}
                  onChange={(value: Dayjs | null) => {
                    setSlot(value?.toISOString());
                  }}
                />
              </Box>
            </Stack>
            <Stack style={{marginTop: 32}} direction={"row"} justifyContent="end" gap={2}>
                  <PlainButton variant="plain" onClick={()=>onClose()}>
                    Cancel
                  </PlainButton>
                  <SolidButton variant="solid" onClick={()=>onClose(slot)}>
                    Submit
                  </SolidButton>
                </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
