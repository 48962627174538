import { useEffect, useState } from "react";
import LocationField from "../../../components/criteria/LocationField";
import { CriteriaType } from "../../../constants/enum";
import { Criteria } from "../../../constants/interfaces";

import { Close } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Chip,
    CircularProgress,
    Grid,
    Stack
} from "@mui/joy";
import industry from "../../../assets/jsons/industry.json";
import jobtitle from "../../../assets/jsons/jobtitle.json";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import CustomTextfield from "../../../components/criteria/CustomTextfield";
import SliderField from "../../../components/criteria/SliderField";
import { useApi } from "../../../providers/ApiProvider";

interface Props {
  criteria?: Criteria;
  communityId?: string;
  onClose?: Function;
  onSave?: (value: Criteria) => void;
  onDelete?: (value: Criteria) => void;
}

function EditCriteria(props: Props) {
  const { criteria, communityId, onClose, onSave, onDelete } = props;

  const { showMessage, communityService, handleToggle } = useApi();

  const [selected, setSelected] = useState<Criteria | null>();
  const [answers, setAnswers] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const getCriteriaList = (criteria: Criteria) => {
    if (criteria.key === "jobtitle") {
      return jobtitle.data;
    }
    if (criteria.key === "industry") {
      return industry.data;
    }
    return criteria.keywordList;
  };

  const handleOnSave = () => {
    if (answers.length === 0) {
      showMessage("Select at least one option.", "warning");
      return;
    }
    if (selected) {
      selected.answers = answers.length == 0 ? null : answers;
      if (!onSave) return;
      onSave(selected);
    }
  };

  useEffect(() => {
    if (criteria) {
      setSelected(criteria);
      setAnswers(criteria.answers ?? []);
    }
  }, [criteria]);

  if (loading) {
    return (
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          minHeight: 200,
          width: "100%",
        }}
      >
        <CircularProgress color="primary" />
      </Stack>
    );
  }
  return (
    <Grid container>
      <Grid xs>
        {selected && selected.criteriaType === CriteriaType.location && (
          <LocationField
            isUpdate={true}
            selected={answers}
            onSaved={(value: any) => {
              selected.answers = value.length == 0 ? null : value;
              if (!onSave) return;
              onSave(selected);
            }}
            onCancel={() => {
              if (!onClose) return;
              onClose(false);
            }}
            onRemoved={() => {
              if (selected.profileId && onDelete) {
                onDelete(selected);
              }
            }}
          />
        )}
        {selected && selected.criteriaType === CriteriaType.multipleSelect && (
          <Box>
            <Autocomplete
              multiple
              size="lg"
              placeholder="Select"
              options={getCriteriaList(selected) ?? []}
              autoFocus
              value={answers}
              onChange={(e, value) => {
                setAnswers(value);
              }}
              renderTags={(tags, getTagProps) =>
                tags.map((item, index) => (
                  <Chip
                    size="sm"
                    variant="soft"
                    color="primary"
                    endDecorator={<Close fontSize="small" />}
                    {...getTagProps({ index })}
                  >
                    {item}
                  </Chip>
                ))
              }
            />
          </Box>
        )}
        {selected && selected.criteriaType === CriteriaType.customTextField && (
          <CustomTextfield
            defaultValue={answers[0]}
            onChange={(value: string) => setAnswers([value])}
          />
        )}
        {selected && selected.criteriaType === CriteriaType.range && (
          <SliderField
            maximum={selected.maximum}
            minimum={selected.minimum}
            onChange={(value: Array<number>) => {
              setAnswers([`${value[0]}`, `${value[1]}`]);
            }}
          />
        )}
        {selected && selected.criteriaType != CriteriaType.location && (
          <Stack
            direction={{ md: "column", lg: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="space-between"
            alignItems={"center"}
            mt={6}
          >
            <PlainButton
              variant="plain"
              color="primary"
              onClick={() => {
                if (selected.profileId && onDelete) {
                  onDelete(selected);
                }
              }}
            >
              Remove Criteria
            </PlainButton>
            <Stack
              direction={{ md: "column", lg: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              alignItems="center"
            >
              <PlainButton
                variant="plain"
                onClick={() => {
                  if (!onClose) return;
                  onClose();
                }}
              >
                Cancel
              </PlainButton>
              <SolidButton variant="solid" onClick={handleOnSave}>
                Save
              </SolidButton>
            </Stack>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

export default EditCriteria;
