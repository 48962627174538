import {
    Autocomplete,
    Box,
    FormControl,
    FormLabel,
    Modal,
    ModalDialog,
    Stack,
    Typography
} from "@mui/joy";
import React from "react";
import locations from "../../../assets/jsons/locations.json";
import { CountryProps, StateProps } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { OutlinedButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
}

function LocationDialog(props: Props) {
  const { open, onClose, onSave } = props;

  const { showMessage } = useApi();
  const [country, setCountry] = React.useState<CountryProps | null>(null);
  const [state, setState] = React.useState<StateProps | null>(null);
  const [city, setCity] = React.useState<string | null | undefined>(null);

  const handleOnSave = (e: any) => {
    e.preventDefault();
    let countryText = "";
    if (!country) {
      return showMessage("Please select your country!", "warning");
    }
    if (country && country.states.length > 0 && !state) {
      return showMessage(
        "Please select your state/province and city!",
        "warning"
      );
    }
    if (
      country &&
      country.states.length > 0 &&
      state &&
      state.cities.length > 0 &&
      !city
    ) {
      return showMessage("Please select your city!", "warning");
    }

    if (
      country &&
      country.states.length > 0 &&
      state &&
      state.cities.length > 0
    ) {
      countryText = [city, state?.name, country?.name].join(", ");
    } else if (
      country &&
      country.states.length > 0 &&
      state &&
      state.cities.length === 0
    ) {
      countryText = [state?.name, country?.name].join(", ");
    } else if (country && country.states.length === 0) {
      countryText = country?.name;
    }

    onSave(countryText);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="nested-modal-title"
        aria-describedby="nested-modal-description"
        size="lg"
      >
        <form noValidate onSubmit={handleOnSave}>
          <Typography id="nested-modal-title" level="h3" fontSize={24}>
            Location
          </Typography>

          <Stack direction="column" spacing={2}>
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Autocomplete
                autoFocus
                size="lg"
                placeholder="Country"
                value={country}
                onChange={(e: any, newValue: CountryProps | null) => {
                  if (!newValue) {
                    setState(null);
                    setCity(null);
                  }
                  setCountry(newValue);
                }}
                options={locations as Array<CountryProps>}
                getOptionLabel={(option: CountryProps) => option.name}
              />
            </FormControl>
            {country && (
              <FormControl>
                <FormLabel>State/Province</FormLabel>
                <Autocomplete
                  autoFocus
                  size="lg"
                  options={country.states}
                  getOptionLabel={(option) => option.name}
                  value={state}
                  onChange={(e: any, newValue: StateProps | null) => {
                    if (!newValue) {
                      setCity(null);
                    }
                    setState(newValue);
                  }}
                />
              </FormControl>
            )}
            {state && (
              <FormControl>
                <FormLabel>City</FormLabel>
                <Autocomplete
                  autoFocus
                  size="lg"
                  options={state.cities}
                  getOptionLabel={(option: any) => option}
                  value={city}
                  onChange={(e: any, newValue: any) => setCity(newValue)}
                />
              </FormControl>
            )}
          </Stack>

          <Box
            sx={{
              mt: 6,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <SolidButton
              variant="solid"
              color="primary"
              type="submit"
              onClick={handleOnSave}
            >
              Save
            </SolidButton>
            <OutlinedButton
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              Cancel
            </OutlinedButton>
          </Box>
        </form>
      </ModalDialog>
    </Modal>
  );
}

export default LocationDialog;
