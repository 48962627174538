import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Option,
  Select,
  Stack,
  Textarea,
  Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { pagesName } from "../../../App";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import { CreateTimeSlotsDialog } from "../../../components/dialog/schedules/CreateTimeSlotsDialog";
import { placeholder } from "../../../constants/AppImages";
import { StorageType } from "../../../constants/enum";
import {
  CreateBookingParams,
  ViewProfile,
} from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props { }

function CreateScheduleLightster(props: Props) {
  const { } = props;
  const { lightsterId } = useParams();
  const { showMessage, profileService, userService,sessionService } = useApi();
  const navigate = useNavigate();
  const [lightster, setLightster] = useState<ViewProfile>();
  const [timezone, setTimezone] = useState<number>();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState({
    communiies: false,
    users: false,
    timeSlot: false,
  });
  const [data, setData] = useState<CreateBookingParams>({
    scheduleMode: "schedule",
    expectedLength: 30,
    expectedLightster: 1,
    proposedTime: [],
    topic: "",
    shareScreen: null,
    lightsterId: "",
    pastUsers: true,
    status: "",
  });

  const [errors, setErrors] = useState({
    expectedLength: false,
    expectedLightster: false,
    proposedTime: false,
    topic: false,
    shareScreen: false,
  });

  const getLightsterProfile = async (id: string) => {
    const res = await profileService.getViewProfileLightster({
      lightsterId: id,
    });
    if (!res) return;
    setLightster(res);
  };

  const getLightsterTimezone = async () => {
    if (!lightsterId) return;
    const res = await userService.creatorgetLightsterTimezone(lightsterId);
    if (!res) return;
    setTimezone(res.timezone);

  }

  const checkEmptyField = () => {
    let contained = false;

    if (data.expectedLength == null) {
      contained = true;
      errors.expectedLength = true;
    }
    if (data.proposedTime.length === 0) {
      contained = true;
      errors.proposedTime = true;
    }
    if (data.shareScreen == null) {
      contained = true;
      errors.shareScreen = true;
    }
    if (!data.topic) {
      contained = true;
      errors.topic = true;
    }
    setErrors(errors);
    return contained;
  };
  const handleOnClickNext = () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }

    localStorage.setItem(StorageType.schedule, JSON.stringify(data));
    navigate(pagesName.creator.schedulePayment, {
      state: { booking: data },
    });
  };
  const handleOnClickUpdate = async () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    setLoading(true);
    localStorage.setItem(StorageType.schedule, JSON.stringify(data));
    
    const res = await sessionService.updateQuickBooking(data);

    if (res) {
      setTimeout(() => {
        setLoading(false);
        showMessage(
          "Invitation successfully updated, these changes will not affect users who have already confirmed.",
          "success"
        );
        localStorage.removeItem(StorageType.schedule);
        navigate(-1);
      }, 5000);
    } else {
      setLoading(false);
    }


  };

  useEffect(() => {
    if (lightsterId) {
      getLightsterProfile(lightsterId);
      getLightsterTimezone();
      setData({ ...data, lightsterId: lightsterId });
    }


    const schedule = localStorage.getItem(StorageType.schedule);
    if (schedule) {
      setData(JSON.parse(schedule));
    }
  }, []);

  return (
    <Box className="home" overflow="auto">
       {loading ? (
          <Box
            className="box-column"
            sx={{
              //minHeight: 300,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size="md" />
          </Box>
        ) : (
      <Stack direction={"column"} spacing={3}>
        <Box>
          <Typography level="h2" fontSize={28}>
            Schedule a live conversation
          </Typography>
          <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
            Use this feature to schedule a live conversation with users.
            Sessions can be joined on web or mobile, and comes with
            screen-sharing, recording, and transcription.
          </Typography>
        </Box>
        <Box overflow={"auto"} sx={{ "&::-webkit-scrollbar": {display: "none"}}}>
        <Box>
          <Typography level="title-lg" sx={{ color: "black", mb: 2 }}>
            Session Details
          </Typography>
          <Stack direction={"row"} gap={2} mb={3}>
            <Avatar
              src={lightster?.imageProfile ?? placeholder}
              sx={{ width: 56, height: 56, bgcolor: "white" }}
            >
              <img src={placeholder} style={{ width: 56, height: 56 }} />
            </Avatar>
            <Stack direction={"column"}>
              <Typography level="title-lg">{lightster?.displayName}</Typography>
              <Typography level="body-md" textColor={"text.secondary"}>
                {lightster?.subDisplayName}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Select
              size="lg"
              color={errors.expectedLength ? "danger" : "neutral"}
              startDecorator={
                <Typography level="title-sm">
                  How long do you expect the session to be?
                </Typography>
              }
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              sx={{
                ".MuiSelect-startDecorator": {
                  maxWidth: "60%",
                },
              }}
              slotProps={{
                button: {
                  style: { justifyContent: "end", fontSize: 16 },
                },
              }}
              placeholder="Select"
              value={data.expectedLength}
              onChange={(e, newValue) => {
                if (newValue && errors.expectedLength) {
                  setErrors({ ...errors, expectedLength: false });
                }
                setData({
                  ...data,
                  expectedLength: newValue ?? 0,
                });
              }}
            >
              {[60, 45, 30, 15].map((map) => (
                <Option key={map} value={map} sx={{ fontSize: 14 }}>
                  {map} minutes
                </Option>
              ))}
            </Select>
            <Select
              size="lg"
              color={errors.proposedTime ? "danger" : "neutral"}
              startDecorator={
                <Typography level="title-sm">
                  What are your preferred dates and times for the interview?
                </Typography>
              }
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              slotProps={{
                button: {
                  style: { justifyContent: "end", fontSize: 16 },
                  onClick: () => {
                    if (
                      !data.expectedLightster ||
                      data?.expectedLightster === "0"
                    ) {
                      showMessage(
                        "Please enter number of users to invite.",
                        "warning"
                      );
                      return;
                    }
                    setOpen({ ...open, timeSlot: true });
                  },
                },
              }}
              placeholder="Select"
              value={data.proposedTime.length}
              listboxOpen={false}
            >
              <Option value={data.proposedTime.length}>
                {data.proposedTime.length}
              </Option>
            </Select>
            <Select
              size="lg"
              color={errors.shareScreen ? "danger" : "neutral"}
              startDecorator={
                <Box>
                  <Typography level="title-sm">
                    Do you want to request that users join on their desktop?
                  </Typography>
                  <Typography level="body-sm">
                    Select “yes” if you are going to be sharing your screen.
                  </Typography>
                </Box>
              }
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              sx={{
                ".MuiSelect-startDecorator": {
                  maxWidth: "60%",
                  paddingBlock: "8px",
                },
              }}
              slotProps={{
                button: {
                  style: { justifyContent: "end", fontSize: 16 },
                },
              }}
              placeholder="Select"
              value={data.shareScreen}
              onChange={(e, newValue) => {
                if (newValue && errors.shareScreen) {
                  setErrors({ ...errors, shareScreen: false });
                }
                setData({ ...data, shareScreen: newValue });
              }}
            >
              <Option sx={{ fontSize: 14 }} value={true}>
                Yes
              </Option>
              <Option sx={{ fontSize: 14 }} value={false}>
                No
              </Option>
            </Select>
          </Stack>
        </Box>
        <Box>
          <Typography level="title-lg" sx={{ color: "black", mt:2}}>
            What should the user know about the session?
          </Typography>
          <Typography level="body-sm" sx={{ color: "black", mb: 1 }}>
            Please let the user know how best to prepare for the session, or
            what to expect.
          </Typography>
          <Textarea
            size="lg"
            minRows={4}
            error={errors.topic}
            value={data.topic}
            onChange={(e) => {
              const { value } = e.target;
              if (value && errors.topic) {
                setErrors({ ...errors, topic: false });
              }
              setData({ ...data, topic: value });
            }}
          />
        </Box>
        </Box>
        <Box
          className="box-row"
          sx={{ justifyContent: "end", gap: 2, mt: "48px !important" }}
        >
          <PlainButton
            variant="plain"
            onClick={() => {
              localStorage.removeItem(StorageType.schedule);
              navigate(-1);
            }}
          >
            Cancel
          </PlainButton>
          {
            data.status === 'inProgress' ?
              <SolidButton variant="solid" onClick={handleOnClickUpdate}>
                Save
              </SolidButton>
              :
              <SolidButton variant="solid" onClick={handleOnClickNext}>
                Next
              </SolidButton>
          }
        </Box>
      </Stack>)}

      {open.timeSlot && data.expectedLightster && (
        <CreateTimeSlotsDialog
          timezone={timezone}
          users={
            typeof data.expectedLightster === "string"
              ? parseInt(data.expectedLightster)
              : data.expectedLightster
          }
          defaultValue={data.proposedTime}
          open={open.timeSlot}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.proposedTime) {
              setErrors({ ...errors, proposedTime: false });
            }
            setData({ ...data, proposedTime: value });
            setOpen({ ...open, timeSlot: false });
          }}
        />
      )}
    </Box>
  );
}

export default CreateScheduleLightster;
