import { EastSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Stack,
  Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import imgFinances from "../../assets/images/finances.png";
import BackgroundRegister from "../../components/BackgroundRegister";
import { SolidButton } from "../../components/button/CustomButton";
import { RoleType, StorageType } from "../../constants/enum";
import { primaryPurple } from "../../mui-styles";
import { useApi } from "../../providers/ApiProvider";
import google from "../../assets/images/google.png";
import { useFirebaseAuth } from "../../providers/FirebaseProvider";
import { SignSSO, useAuth } from "../../providers/AuthProvider";


interface Props { }

function SignupEmail(props: Props) {
  const { } = props;

  const { authService, handleToggle, showMessage } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [accept, setAccept] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [utm, setUtm] = useState("");
  const { googleSignIn, facebookSignIn, appleSignIn } = useFirebaseAuth();

  const [data, setData] = useState({
    email: "",
    password: "",
    token: "",
    utm: "",
    aff: "",
    platform: "web",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });


  function handleOnChange(event: any) {
    const { value, name } = event.target;
    if (name == "password") {
      if (errors.password && value) {
        setErrors({ ...errors, password: false });
      }
    }
    if (name === "email") {
      if (errors.email && value) {
        setErrors({ ...errors, email: false });
      }
    }
    setData({ ...data, [name]: value });
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!data.email || !data.password) {
      setErrors({
        ...errors,
        email: data.email ? false : true,
        password: data.password ? false : true,
      });
      return showMessage("Please enter Email and Password", "warning");
    }

    if (!accept) {
      return showMessage(
        "Please agree to the Privacy Policy and Terms of Use.",
        "warning"
      );
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(data.email)) {
      setErrors({ ...errors, email: true });
      return showMessage("Please enter a valid email address", "error");
    }

    const token = await grecaptcha.enterprise.execute('6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9', { action: 'SIGNUP' });
    if (token) {
      data.token = token;
    }

    handleToggle(true);
    const res = await authService.signupEmail(data);
    if (!res) {
      handleToggle(false);
      return;
    }
    setTimeout(function () {
      handleToggle(false);
      navigate(pagesName.verifyPhoneNumber);
    }, 1000);
  };

  const handleSignInSSO = async (provider: any) => {
    const token = await grecaptcha.enterprise.execute('6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9', { action: 'LOGIN' });


    if (provider === "google") {
      const result = await googleSignIn();
      if (result) {
        const email = result.user?.email ?? result.user.providerData[0].email;
        const imageUrl = result.user.photoURL;
        const fullName = result.user.displayName;
        const dataSignIn: SignSSO = {
          vanityName: null,
          email: email,
          picture: imageUrl,
          fullName: fullName,
          provider: "google",
          token: token,
          platform: "web",
        };

        await auth.onSignInSSO(dataSignIn);
      }
    }
    if (provider === "facebook") {
      const result = await facebookSignIn();
      if (result) {
        const email = result.user?.email ?? result.user.providerData[0].email;
        const imageUrl = result.user.photoURL;
        const fullName = result.user.displayName;

        const dataSignIn: SignSSO = {
          vanityName: null,
          email: email,
          picture: imageUrl,
          fullName: fullName,
          provider: "facebook",
          token: token,
          platform: "web",
        };
        await auth.onSignInSSO(dataSignIn);
      }
    }

    if (provider === "apple") {
      const result = await appleSignIn();
      if (result) {
        const email = result.user?.email ?? result.user.providerData[0].email;
        const imageUrl = result.user.photoURL;
        const fullName = result.user.displayName;

        const dataSignIn: SignSSO = {
          vanityName: null,
          email: email,
          picture: imageUrl,
          fullName: fullName,
          provider: "apple",
          token: token,
          platform: "web",
        };
        await auth.onSignInSSO(dataSignIn);
      }
    }
  };

  useEffect(() => {
    document.title = "Lightster | Create an account";
    const myParam = new URLSearchParams(location.search).get("role");
    const utmParam = new URLSearchParams(location.search).get("utm_source");
    const affParam = new URLSearchParams(location.search).get("aff") ?? "";
    setUtm(utmParam || "");

    if (myParam && myParam === RoleType.lightster) {
      localStorage.setItem(StorageType.role, RoleType.lightster);
    }

    if (myParam && myParam === RoleType.creator) {
      localStorage.setItem(StorageType.role, RoleType.creator);
    }
    if (utmParam) {
      localStorage.setItem(StorageType.utm, utmParam);
      setData({ ...data, utm: utmParam, aff: affParam });
    }
  }, []);
  return (
    <form noValidate onSubmit={handleSubmit} autoComplete="off">
      <BackgroundRegister>
        <Stack
          height={"100%"}
          direction={"column"}
          justifyContent="center"
          alignItems={"start"}
          mt={"0vh"} //3
          mb={"0vh"} //10
        >
          <Box height={140} >
            <img
              src={imgFinances}
              alt="img-register-finances"
              height="100%"
              style={{ objectFit: "contain" }}
            />
          </Box>

          <Stack spacing={2}>
            <Stack direction="column" spacing={0.5}>
              <Typography level="h2" fontSize={25} >
                Welcome to Lightster!
              </Typography>
              <Typography level="body-md">
                To get started, please enter your e-mail below. You will use
                these to login to Lightster.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <FormControl>
                <FormLabel>E-mail address</FormLabel>
                <Input
                  autoComplete="off"
                  autoFocus
                  fullWidth
                  type="email"
                  name="email"
                  size="lg"
                  error={errors.email}
                  value={data.email}
                  onChange={handleOnChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  fullWidth
                  size="lg"
                  type={visibility ? "text" : "password"}
                  name="password"
                  error={errors.password}
                  value={data.password}
                  onChange={handleOnChange}
                  endDecorator={
                    <IconButton onClick={() => setVisibility((pre) => !pre)}>
                      {visibility ? (
                        <i className="bi bi-eye"></i>
                      ) : (
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </IconButton>
                  }
                />
              </FormControl>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems="center">
              <i
                className="bi bi-check2-circle"
                style={{
                  fontSize: 24,
                  cursor: "pointer",
                  color: accept ? primaryPurple : "grey",
                }}
                onClick={() => setAccept((preData) => !preData)}
              ></i>
              <Typography
                level="body-md"
                sx={{ color: accept ? primaryPurple : "grey" }}
              >
                I have read and agreed to the{" "}
                <Link
                  href="https://www.lightster.co/privacy-policy"
                  sx={{ color: accept ? primaryPurple : "grey" }}
                >
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  href="https://www.lightster.co/terms-of-service"
                  sx={{ color: accept ? primaryPurple : "grey" }}
                >
                  Terms of Use
                </Link>
                .
              </Typography>
            </Stack>
            <SolidButton
              variant="solid"
              size="lg"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ maxWidth: 128 }}
              onClick={handleSubmit}
              type="submit"
            >
              Next
            </SolidButton>

          </Stack>
          <Typography
            level="body-md"
            sx={{
              width: "100%",
              textAlign: "center",
            }}
            pb={1}
            pt={0}
            marginTop={"24px"}
          >
            Other options
          </Typography>
          <Stack direction="column" width="100%" spacing={1.5}>
            <Button
              variant="solid"
              size="lg"
              fullWidth
              sx={{
                color: "black",
                background: "#FFF",
                boxShadow:
                  "0px 1px 1px 0px rgba(0, 0, 0, 0.17), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)",
              }}
              startDecorator={
                <img src={google} alt="google-icon" width={22} />
              }
              onClick={() => handleSignInSSO("google")}
            >
              Sign in with Google
            </Button>

            <Button
              variant="solid"
              size="lg"
              fullWidth
              sx={{
                background: "#1877F2",
              }}
              startDecorator={<i className="bi bi-facebook"></i>}
              onClick={() => handleSignInSSO("facebook")}
            >
              Continue with Facebook
            </Button>
            <Button
              variant="solid"
              size="lg"
              fullWidth
              sx={{ background: (theme) => theme.palette.neutral[900] }}
              startDecorator={<i className="bi bi-apple"></i>}
              onClick={() => handleSignInSSO("apple")}
            >
              Sign in with Apple
            </Button>
          </Stack>
        </Stack>
      </BackgroundRegister>
    </form>
  );
}

export default SignupEmail;
