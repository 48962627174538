import { LinkedIn } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Stack,
    Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { ThumbsDownOutline, ThumbsUpOutline } from "react-ionicons";
import { useNavigate, useParams } from "react-router-dom";
import { pagesName } from "../../../App";
import ChatRoom from "../../../components/ChatRoom";
import { OutlinedButton } from "../../../components/button/CustomButton";
import { placeholder } from "../../../constants/AppImages";
import { StorageType } from "../../../constants/enum";
import { UserChat, ViewProfile } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props {}

function ViewLightsterProfile(props: Props) {
  const {} = props;
  const { userId, communityId } = useParams();
  const { profileService } = useApi();
  const navigate = useNavigate();
  const [data, setData] = useState<ViewProfile>();
  const [openChat, setOpenChat] = useState<UserChat | null>(null);

  const getLightsterProfile = async (id: string, commId?: string) => {
    let params: any = { lightsterId: id };
    if (commId) {
      params.communityId = commId;
    }
    const res = await profileService.getViewProfileLightster(params);
    if (!res) return;
    setData(res);
  };

  const handleClickCommunityLink = () => {
    navigate(pagesName.creator.home, {
      state: { commId: communityId },
      replace: true,
    });
  };

  const handleOpenChat = () => {
    if (!data) return;
    const userProfile: UserChat = {
      subDisplayName: data.subDisplayName ?? "",
      id: data.id,
      imageProfile: data.imageProfile ?? null,
      username: data.username ?? "",
      email: "",
      subCompanyDisplayName: "",
      displayName: data?.displayName ?? "",
    };

    setOpenChat(userProfile);
    localStorage.setItem(
      StorageType.viewProfileChatActive,
      JSON.stringify(userProfile)
    );
  };
  useEffect(() => {
    if (userId && communityId) {
      getLightsterProfile(userId, communityId);
    }
    if (userId && !communityId) {
      getLightsterProfile(userId);
    }
  }, []);

  useEffect(() => {
    const getLocal = localStorage.getItem(StorageType.viewProfileChatActive);
    if (getLocal) {
      setOpenChat(JSON.parse(getLocal));
    }
    if (data) {
      if (data?.communityName) {
        document.title = `Lightster - ${data?.communityName} - ${data?.displayName}`;
      } else{
        document.title = `Lightster - ${data?.displayName}`;
      }
    }
  }, [data]);

  if (!data) {
    return <></>;
  }
  return openChat ? (
    <ChatRoom
      chatVideoCall={false}
      userProfile={openChat}
      receiverId={data.id}
      onClose={() => {
        setOpenChat(null);
        localStorage.removeItem(StorageType.viewProfileChatActive);
      }}
    />
  ) : (
    <Box className="home" height={"calc(100vh - 132px)"} overflow="scroll">
      <Stack direction="column" gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 2,
              minHeight: 100,
              gap: 2,
            }}
          >
            <Avatar
              variant="plain"
              src={data.imageProfile ?? placeholder}
              sx={{ height: 100, width: 100, bgcolor: "transparent" }}
            >
              <img src={placeholder} height="100" width="100" />
            </Avatar>

            <Stack direction="column" justifyContent={"space-evenly"}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={handleClickCommunityLink}
              >
                <Typography
                  level="body-lg"
                  sx={{ textDecoration: "underline" }}
                >
                  {data.communityName ?? communityId}
                </Typography>
              </Box>
              <Typography level="h3" fontSize={28}>
                {data.displayName}
              </Typography>
              <Stack direction="row" gap={1}>
                <Box
                  className="box-row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography level="title-md">{data.positive}</Typography>
                  <Box height={20}>
                    <ThumbsUpOutline width="20px" height="20px" />
                  </Box>
                </Box>
                <Box
                  className="box-row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Box height={20}>
                    <ThumbsDownOutline width="20px" height="20px" />
                  </Box>
                  <Typography level="title-md">{data.negative}</Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              minHeight: 100,
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box>
              {data.isVerify && (
                <Chip variant="solid" sx={{ bgcolor: "#38A169" }}>
                  VERIFIED
                </Chip>
              )}
            </Box>
            <Box>
              {data.linkedIn && (
                <IconButton
                  onClick={() => {
                    window.open(data.linkedIn, "_blank");
                  }}
                >
                  <LinkedIn sx={{ color: "#2867B2", fontSize: 30 }} />
                </IconButton>
              )}
            </Box>
          </Box>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <OutlinedButton
            variant="outlined"
            color="primary"
            sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
            onClick={() => navigate(`${pagesName.creator.schedule}/${userId}`)}
          >
            Schedule a live conversation
          </OutlinedButton>
          <OutlinedButton
                variant="outlined"
                sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                onClick={() =>
                  navigate(`${pagesName.creator.unmoderated}/${userId}`)
                }
              >
                Start a test
              </OutlinedButton>
          {/* <OutlinedButton
            variant="outlined"
            sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
            onClick={() => navigate(pagesName.creator.survey)}
          >
            Send a survey
          </OutlinedButton> */}
          <OutlinedButton
            variant="outlined"
            color="primary"
            sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
            onClick={handleOpenChat}
          >
            Send a message
          </OutlinedButton>
        </Stack>
        <Stack>
          <Typography level="body-sm" fontStyle={"italic"}>
            "{data.subDisplayName}"
          </Typography>
        </Stack>
        {data.questions && (
          <Stack
            direction="column"
            gap={2}
            sx={{
              bgcolor: "#EFE7FA",
              borderRadius: "20px",
              p: 3,
            }}
          >
            <Typography level="title-lg">Your Criteria</Typography>
            {data.questions.map((criteria) => (
              <Stack direction={"row"} justifyContent="space-between">
                <Box flex={1}>
                  <Typography level="title-sm" sx={{ fontWeight: 400 }}>
                    {criteria.title}
                  </Typography>
                </Box>
                <Box display="flex" flex={2} justifyContent="end">
                  <Typography
                    level="title-sm"
                    sx={{ fontWeight: 500 }}
                    textAlign="end"
                  >
                    {criteria.answers.join(", ")}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        )}
        <Stack
          direction="column"
          gap={2}
          sx={{
            paddingInline: 3,
          }}
        >
          {data.criterias &&
            data.criterias.map((question) => (
              <Stack direction={"row"} justifyContent="space-between">
                <Box flex={1}>
                  <Typography level="title-sm" sx={{ fontWeight: 400 }}>
                    {question.title}
                  </Typography>
                </Box>
                <Box display="flex" flex={2} justifyContent="end">
                  <Typography
                    level="title-sm"
                    sx={{ fontWeight: 400 }}
                    textAlign={"end"}
                  >
                    {question.answers.join(", ")}
                  </Typography>
                </Box>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Box>
  );
}

export default ViewLightsterProfile;
