import { ModalClose, Stack } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import React from "react";

interface Props {
  open: boolean;
  onClose?: Function;
  title: String;
  closeButton?: boolean;
  subtitle: React.ReactElement;
}

function CustomDialog(props: Props) {
  const { open, onClose, title, closeButton, subtitle } = props;

  return (
    <Modal open={open} onClose={()=> onClose && onClose()}>
      <ModalDialog>
        {closeButton && <ModalClose />}
        <Stack direction={"column"} gap={3}>
          <Typography level="h2" fontSize={24}>
            {title}
          </Typography>

          {subtitle}
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default CustomDialog;
