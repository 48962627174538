import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Textarea,
  Typography,
  styled,
} from "@mui/joy";
import React, { useState } from "react";
import { QuestionDisplay } from "../../../pages/lightster/questions/Questions";
import { InputType, StorageType } from "../../../constants/enum";
import {
  primaryPurple,
  primaryPurpleDark,
  primaryPurpleHover,
} from "../../../joy-styles";
import { PlainButton, SolidButton } from "../../button/CustomButton";
import { useApi } from "../../../providers/ApiProvider";
interface LightsterAnswerQuestionDialogProps {
  open: boolean;
  onClose: Function;
  refresh: Function;
  question: QuestionDisplay;
}
export default function LightsterAnswerQuestionDialog(
  props: LightsterAnswerQuestionDialogProps
) {
  const { open, onClose, refresh, question } = props;
  const { questionService } = useApi();
  const [textAnswer, setTextAnswer] = useState("");

  const handleSendAnswer = async () => {
    const paylopad = {
      profiles: [
        {
          profileId: question.profileId,
          key: question.key,
          answers: [textAnswer],
        },
      ],
    };
    const res = await questionService.lightsterAnswerQuestion(paylopad);
    if (!res) return;

    localStorage.setItem(StorageType.user, JSON.stringify(res));

    onClose();
    refresh();
  };
  return (
    <Modal open={open} onClose={() => onClose()}>
      <ModalDialog
        aria-labelledby="layout-modal-title "
        aria-describedby="layout-modal-description "
        size="lg"
        sx={{ overflow: "auto" }}
      >
        <Typography id="layout-modal-title" level="h2" sx={{ fontSize: 24 }}>
          {question.title}
        </Typography>
        <Box overflow={"auto"} maxHeight={"35vh"} sx={{ "&::-webkit-scrollbar": {display: "inline"}}}>
          <Stack direction={"column"} spacing={2}>
            {question.inputType === InputType.SELECTLIST &&
              question.keywordList.map((option) => (
                <AnswerSelection
                  onClick={() => setTextAnswer(option)}
                  sx={{
                    borderWidth: textAnswer === option ? 2 : 1,
                    bgcolor: textAnswer === option ? "#F1E4FF" : "white",
                    borderColor: textAnswer === option ? primaryPurpleDark : "black",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Typography
                      level="body-sm"
                      width={"100%"}
                      fontWeight={textAnswer === option ? 700 : 500}
                      sx={{
                        ":hover": {
                          color:
                            textAnswer === option ? "black" : primaryPurpleDark,
                        },
                        color: textAnswer === option ? primaryPurpleDark : "black",
                      }}
                    >
                      {option}
                    </Typography>
                    <Typography
                      level="body-md"
                      sx={{
                        fontWeight: textAnswer === option ? 700 : 500,
                        color: textAnswer === option ? "#000" : primaryPurpleDark,
                      }}
                    >
                    </Typography>
                  </Stack>
                </AnswerSelection>
              ))}
            {question.inputType === InputType.TEXTFIELD && (
              <Stack direction={"column"} gap={2}>
                <FormControl>
                  <Textarea
                    autoFocus
                    minRows={4}
                    slotProps={{
                      textarea: {
                        readOnly: textAnswer === "Not applicable",
                        disabled: textAnswer === "Not applicable",
                      },
                    }}
                    value={textAnswer}
                    onChange={(e) => setTextAnswer(e.target.value)}
                  />
                </FormControl>
                <Stack direction={"row"} spacing={1} alignItems="center">
                  <i
                    className="bi bi-check2-circle"
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                      color:
                        textAnswer === "Not applicable" ? primaryPurple : "grey",
                    }}
                    onClick={() => {
                      if (textAnswer === "Not applicable") {
                        setTextAnswer("");
                        return;
                      }
                      setTextAnswer("Not applicable");
                    }}
                  ></i>
                  <Typography
                    level="title-sm"
                    sx={{
                      color:
                        textAnswer === "Not applicable" ? primaryPurple : "grey",
                    }}
                  >
                    Not applicable
                  </Typography>

                </Stack>

              </Stack>

            )}
          </Stack>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={{ sm: "space-between", md: "end" }}
          gap={2}
          mt={2}
        >
          <PlainButton variant="plain" onClick={() => onClose()}>
            Close
          </PlainButton>
          <SolidButton variant="solid" onClick={handleSendAnswer}>
            Send
          </SolidButton>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

const AnswerSelection = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  height: 48,
  borderRadius: 8,
  border: "1px solid #000",
  paddingInline: 24,
  cursor: "pointer",
  ":hover": {
    background: primaryPurpleHover,
    color: primaryPurpleDark,
    borderColor: `${primaryPurpleDark}`,
  },
}));
