import { EditSharp } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { Criteria, CriteriaParams, Setting } from "../../constants/interfaces";
import AddCriteria from "../../pages/creator/criteria/AddCriteria";
import EditCriteria from "../../pages/creator/criteria/EditCriteria";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import { OpenFieldName, usePlanDialog } from "../../providers/PlanPovider";
import { PlainButton, SolidButton } from "../button/CustomButton";

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  communityId: string;
}

function CommunitySettingDialog(props: Props) {
  const { open, onClose, communityId } = props;
  const { user } = useAuth();
  const { handleShowDialog, handleFlowUpgrade, showUpgradeToAddCustom } =
    usePlanDialog();
  const { showMessage } = useApi();
  const [value, setValue] = useState<string>("");
  const { communityService } = useApi();
  const [criteriaList, setCriteriaList] = useState<Array<Criteria>>([]);
  const [customSetting, setCustomSetting] = useState<Array<Criteria>>([]);
  const [setting, setSetting] = useState<Setting>();
  const [show, setShow] = useState({
    edit: false,
    add: false,
  });
  const [title, setTitle] = useState<string | null>(null);
  const [newCriteria, setNewCriteria] = useState<Criteria[]>([]);
  const [itemDelete, setItemDelete] = useState<string[]>([]);
  const [itemEdit, setItemEdit] = useState<Criteria[]>([]);
  const [criteria, setCriteria] = useState<Criteria | null>(null);
  const [loading, setLoading] = useState(false);

  const getCommunitySetting = async (commId: string) => {
    setLoading(true);
    const res: Setting = await communityService.getCommunitySetting(commId);
    setTimeout(() => {
      setLoading(false);
      if (!res) return;
      setSetting(res);
      setCriteriaList(res.criterias ?? []);
      setCustomSetting(res.customSettings ?? []);
    }, 1000);
  };

  const handleAddCriteria = () => {
    if (
      [...customSetting, ...newCriteria].length >= 3 &&
      user.memberPlan === "Free"
    ) {
      handleFlowUpgrade(true);
      handleShowDialog(OpenFieldName.UPGRADE_ADD_MORE, true);
      return;
    }
    setShow({ ...show, add: true });
  };

  const handelUpdateCriteria = async (commId: string) => {
    const profiles: Array<CriteriaParams> = [];
    newCriteria.forEach((element) => {
      const profileAns: CriteriaParams = {
        profileId: element?.profileId ?? "",
        answers: element.answers ?? [],
      };
      profiles.push(profileAns);
    });
    itemEdit.forEach((element) => {
      const profileAns: CriteriaParams = {
        profileId: element?.profileId ?? "",
        answers: element.answers ?? [],
      };
      profiles.push(profileAns);
    });

    const res = await communityService.updateCriteria(commId, profiles);
    if (!res) return;
    setNewCriteria([]);
  };

  const handleDeleteCriteria = async () => {
    itemDelete.forEach(async (element) => {
      await communityService.removeCriteria(communityId, element);
    });
  };
  const removeCriteria = async () => {
    await handleDeleteCriteria();
    setItemDelete([]);
  };

  const handleOnClose = async () => {
    if (
      newCriteria.length === 0 &&
      itemDelete.length === 0 &&
      itemEdit.length === 0
    ) {
      onClose(false);
      return;
    }
    setLoading(true);
    if (newCriteria.length > 0 || itemEdit.length > 0) {
      await handelUpdateCriteria(communityId);
    }

    if (itemDelete.length > 0) {
      await removeCriteria();
    }

    setTimeout(() => {
      onClose(true);
    }, 3000);
  };

  useEffect(() => {
    setValue(communityId);
  }, [communityId]);

  useEffect(() => {
    if (!value) return;
    getCommunitySetting(value);
  }, [value]);

  const getTitle = () => {
    if (show.edit) return criteria?.title;
    if (show.add && title)
      return title ?? "Add a new criteria or screening question";
    if (show.add && !title) return "Add a criteria";

    return "Update criteria";
  };
  const getAllCriteria = () => {
    return [...(criteriaList ?? []), ...(customSetting ?? []), ...newCriteria];
  };

  const isNewCriteria = (profileId: string) => {
    var contained = false;
    for (let i = 0; i < newCriteria.length; i++) {
      if (newCriteria[i].profileId === profileId) {
        contained = true;
        break;
      }
    }
    return contained;
  };

  const isEidCriteria = (profileId: string) => {
    var contained = false;
    for (let i = 0; i < itemEdit.length; i++) {
      if (itemEdit[i].profileId === profileId) {
        contained = true;
        break;
      }
    }
    return contained;
  };

  return (
    <Modal open={open}>
      <ModalDialog
        aria-labelledby="layout-modal-answer-question"
        size="lg"
        sx={{
          width: {
            xs: '480px',
            sm: '480px',
            md: '480px',
            lg: '640px',
            xl: '640px'
          },          //maxHeight: '120vh',
          top: '5vh',
          //height: '89vh'

        }}
      >

        <ModalClose onClick={handleOnClose} />
        <Typography
          id="layout-modal-answer-question"
          level="h2"
          fontSize={24}
          sx={{ mb: 0.5 }}
        >
          {getTitle()}
        </Typography>
        <Box
          sx={{
            maxHeight: '72vh',
            //overflowY: 'auto',  
          }}>
          {loading && (
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                minHeight: 200,
                width: "100%"
              }}
            >
              <CircularProgress color="primary" />
            </Stack>
          )}
          {!show.edit && !show.add && !loading && (

            <Stack direction={"column"} spacing={2.5}>
              <Box
                sx={{
                  // height:"50, purple box
                  maxHeight: '60vh',
                  //maxHeight:'90vh',
                  overflowY: 'auto',
                }}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    p: 2,
                    background: "#EFE7FA",
                    borderRadius: 8,
                    height: "auto"
                  }}
                >
                  {criteriaList.length === 0 &&
                    customSetting.length === 0 &&
                    newCriteria.length === 0 && (
                      <Typography level="body-md">
                        Choose from a variety of demographics, occupation, and
                        behaviours or create your own.
                      </Typography>
                    )}
                  {criteriaList.map((criteria, i) => (
                    <Stack key={i} direction="row" justifyContent="space-between">
                      <Stack>
                        <Typography level="title-md" textTransform="uppercase">
                          {criteria.answers?.join(", ")}
                        </Typography>
                        <Typography level="body-sm" textTransform="uppercase">
                          {criteria.title}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                  {customSetting.map((criteria, i) => (
                    <Stack
                      key={i}
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}

                    >
                      <Stack>
                        <Typography level="title-md" textTransform="uppercase">
                          {criteria.answers?.join(", ")}
                        </Typography>
                        <Typography level="body-sm" textTransform="uppercase">
                          {criteria.title}
                        </Typography>
                      </Stack>
                      <EditSharp
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setCriteria(criteria);
                          setShow({ ...show, edit: true });
                        }}
                      />
                    </Stack>
                  ))}
                  {newCriteria.map((criteria, i) => (
                    <Stack
                      key={i}
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Stack>
                        <Typography level="title-md" textTransform="uppercase">
                          {criteria.answers?.join(", ")}
                        </Typography>
                        <Typography level="body-sm" textTransform="uppercase">
                          {criteria.title}
                        </Typography>
                      </Stack>
                      <EditSharp
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setCriteria(criteria);
                          setShow({ ...show, edit: true });
                        }}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Box>


              <Stack direction={"column"} spacing={0.5}>
                <SolidButton variant="solid" onClick={handleAddCriteria} sx={{ mt: 200 }}>
                  {customSetting.length === 0 && criteriaList.length === 0
                    ? "Add a criteria"
                    : "Add another criteria"}
                </SolidButton>

                <PlainButton
                  variant="plain"
                  onClick={() => {
                    if (user.memberPlan === "Free") {
                      showUpgradeToAddCustom();
                      return;
                    }

                    setCriteria({ criteriaType: "custom", profileId: "" });
                    setShow({ ...show, add: true });
                  }}
                  sx={{

                    color: (theme) => theme.palette.primary.darkChannel,
                    fontWeight: 400,
                    ":hover": {
                      border: (theme) =>
                        `1px solid ${theme.palette.primary[500]}`,
                    },
                  }}
                >
                  Add a screening question
                </PlainButton>
              </Stack>
            </Stack>
          )}
          {show.edit && criteria && (
            <EditCriteria
              criteria={criteria}
              communityId={value ?? ""}
              onClose={() => {
                setCriteria(null);
                setShow({ ...show, edit: false });
              }}
              onDelete={(value: Criteria) => {
                const newValue: Criteria[] = customSetting.filter(
                  (f) => f.profileId !== value.profileId
                );

                const newValueCriteria: Criteria[] = newCriteria.filter(
                  (f) => f.profileId !== value.profileId
                );
                setShow({ ...show, edit: false });
                if (
                  !itemDelete.includes(value.profileId ?? "") &&
                  !isNewCriteria(value.profileId ?? "")
                ) {
                  setItemDelete([...itemDelete, value.profileId ?? ""]);
                }
                setCustomSetting(newValue);
                setNewCriteria(newValueCriteria);
              }}
              onSave={(value: Criteria) => {
                if (isNewCriteria(value.profileId ?? "")) {
                  const index = newCriteria.findIndex(
                    (ele) => ele.profileId === value.profileId
                  );
                  newCriteria[index] = value;
                  setNewCriteria(newCriteria);
                } else {
                  const index = customSetting.findIndex(
                    (ele) => ele.profileId === value.profileId
                  );
                  customSetting[index] = value;
                  setCustomSetting(customSetting);
                  if (isEidCriteria(value.profileId ?? "")) {
                    const index = itemEdit.findIndex(
                      (ele) => ele.profileId === value.profileId
                    );
                    itemEdit[index] = value;
                    setItemEdit(itemEdit);
                  } else {
                    setItemEdit([...itemEdit, value]);
                  }
                }

                setCriteria(null);
                setShow({ ...show, edit: false });
              }}
            />
          )}
          {show.add && (
            <AddCriteria
              showHeader={false}
              communityId={communityId}
              defaultCriteria={criteriaList}
              onCancel={() => {
                setShow({ ...show, add: false });
              }}
              onSave={(value: Criteria) => {
                if (
                  value.profileId &&
                  getAllCriteria()
                    .map((e) => e.profileId)
                    .includes(value.profileId)
                ) {
                  showMessage("Cannot add duplicate criteria", "warning");
                  return;
                }
                setNewCriteria([...newCriteria, value]);
                setShow({ ...show, add: false });
                setTitle("");
              }}
              goBack={() => setShow({ ...show, add: false })}
              onSelected={(title) => setTitle(title)}
              criteria={criteria}
              clearCustom={() => {
                setCriteria(null);
                setShow({ ...show, add: false });
              }}
            />
          )}

        </Box>
      </ModalDialog>
    </Modal>
  );
}

export default CommunitySettingDialog;
