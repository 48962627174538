import config from "../config";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

export default class PaymentServices extends BaseService {
  async getSubscriptions() {
    const res = await this.call("get", `${baseUrl}/creator/payment/cards`);
    return res.data;
  }

  async createSubscription(plan: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/payment/subscriptions`,
      {
        memberPlan: plan,
      }
    );
    return res.data;
  }

  async updateSubscription(plan: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/payment/subscriptions`,
      {
        memberPlan: plan,
      }
    );
    return res.data;
  }
  async getPaymentCallType(reward: number) {
    const res = await this.call("post", `${baseUrl}/payment/calltypes`, {
      reward: reward,
    });
    return res.data;
  }

  async setDefaultCard(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/payment/cards/default`,
      {
        newPaymentMethodId: id,
      }
    );
    return res.data;
  }
}
