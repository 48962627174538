import { async } from "@firebase/util";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertMessage, { useAlertMessage } from "../components/AlertMessage";
import jwt_decode from "jwt-decode";
import AuthServices from "../services/AuthServices";
import { AlertColor } from "@mui/material";
import { RoleType, StorageType } from "../constants/enum";
import { pagesName } from "../App";
import UserServices from "../services/UserService";
import UpgradeWithCustomContentDialog from "../components/dialog/plan/UpgradeWithCustomContentDialog";
import Smartlook from "smartlook-client";

export class SSOProvider {
  static GOOGLE = "google";
  static FACEBOOK = "facebook";
  static APPLE = "apple";
  static LINKEDIN = "linkedin";
}
export type SignSSO = {
  vanityName?: string | null;
  email: string;
  picture: string;
  fullName: string;
  provider: string;
  token: string | null | undefined;
  platform: string;
};

interface AuthContextType {
  api: AuthServices;
  token: string | null;
  user: any | null;
  role: string | null;
  onLogin: (value: any) => Promise<void>;
  onLogout: () => Promise<void>;
  onSignInSSO: (value: any) => Promise<void>;
  onShowMessage: (message: string, type: AlertColor) => void;
  onClientOpen: () => Promise<void>;
  handleCheckRegisterAccount: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const useAuth = () => {
  return React.useContext(AuthContext) as AuthContextType;
};

interface AuthProviderProps {
  children: JSX.Element;
}
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const { messageAlert, closeMessage, showMessage } = useAlertMessage();

  const handlleUnAuthorized = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleError = (e: any) => {
    showMessage(`${e?.message ?? e}`, "error");
  };

  const api = new AuthServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });

  const token = localStorage.getItem(StorageType.token);
  const user = JSON.parse(`${localStorage.getItem(StorageType.user)}`);
  const roleActive = localStorage.getItem(StorageType.role);

  const handleLogin = async (values: any) => {
    const res = await api.login(values);
    if (!res) {
      return;
    }

    handleCheckRegisterAccount();
  };

  const handleLogout = async () => {
    await api.logout();
    navigate("/");
  };

  const handleSignInSSO = async ({
    vanityName,
    email,
    picture,
    fullName,
    provider,
    token,
    platform,
  }: SignSSO) => {
    const payload: SignSSO = {
      vanityName: vanityName,
      email: email,
      picture: picture,
      fullName: fullName,
      provider: provider,
      token: token,
      platform: platform,
    };

    const res = await api.signupSSO(payload);
    if (!res) return;

    handleCheckRegisterAccount();
  };

  const handleCheckRegisterAccount = async () => {
    const userService = new UserServices({
      onError: handleError,
      onUnAuthorized: handlleUnAuthorized,
    });
    const user = await userService.getUser();
    if (!user) {
      return;
    }
    handleUpdateTimezone();
    Smartlook.identify(Math.floor(Math.random() * 9999), { email: user.email });
    const { isLightster, isCreator, phone, username } = user;
    if (isCreator && !isLightster) {
      navigate(pagesName.creator.home);
      localStorage.setItem(StorageType.role, RoleType.creator);
    } else if (isLightster && !isCreator) {
      navigate(pagesName.lightster.home);
      localStorage.setItem(StorageType.role, RoleType.lightster);
    } else if (!phone) {
      navigate(pagesName.verifyPhoneNumber);
    } else {
      navigate(pagesName.chooseRole);
    }
  };

  const handleDevice = async () => {
    const userService = new UserServices({
      onError: handleError,
      onUnAuthorized: handlleUnAuthorized,
    });
    const user = await userService.getUser();
    if (!user) {
      return;
    }
    handleUpdateTimezone();
    Smartlook.identify(Math.floor(Math.random() * 9999), { email: user.email });
  };

  const handleUpdateTimezone = async () => {
    const userService = new UserServices({
      onError: handleError,
      onUnAuthorized: handlleUnAuthorized,
    });
    const timezone = (new Date().getTimezoneOffset() * -1).toString();
    const payload = {
      timezone: timezone,
    };

    const res = await userService.updateTimezone(payload);
    if (!res) return;
  };
  const value: AuthContextType = {
    api: api,
    token: token,
    user: user,
    role: roleActive,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onSignInSSO: handleSignInSSO,
    onClientOpen: handleDevice,
    onShowMessage: showMessage,
    handleCheckRegisterAccount:handleCheckRegisterAccount,
  };

  return (
    <AuthContext.Provider value={value}>
      <AlertMessage
        messageAlert={messageAlert}
        autoHideDuration={null}
        onClose={closeMessage}
      />
      {children}
    </AuthContext.Provider>
  );
};
