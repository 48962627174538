import { CircularProgress, Typography } from "@mui/joy";
import { Box, Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { CloseCircle } from "react-ionicons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import { useAuth } from "../../providers/AuthProvider";

export default function UnmoderatedSummary() {
  const location = useLocation();
  const { unmoderated, totalDuration, displayname } = location.state;
  const navigate = useNavigate();
  const { role } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  if (!unmoderated) {
    return <Navigate to={pagesName.lightster.home} replace />;
  }

  if (loading)
    return (
      <>
        <Box
          display={"flex"}
          height={"100vh"}
          width={"100vw"}
          alignItems="center"
          justifyContent={"center"}
        >
          <CircularProgress color="primary" />
        </Box>
      </>
    );
  return (
    <Container
      maxWidth="md"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        pt: 3,
      }}
    >
      <Box>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography level="h2" fontWeight={800}>
            Summary
          </Typography>
          <CloseCircle
            style={{
              cursor: "pointer",
            }}
            width={"32px"}
            height={"32px"}
            onClick={() =>
              navigate(pagesName.lightster.home, { replace: true })
            }
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          mt={3}
          p={4}
          bgcolor="rgba(247, 250, 252, 1)"
          borderRadius={10}
        >
          <Typography level="h3" fontWeight={500}>
            {displayname}
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            {moment(new Date()).format("MMM D, YYYY hh:mm A")}
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            Duration: {totalDuration} minutes
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            Reference: {unmoderated.refNumber}
          </Typography>

          {role === "lightster" &&
            getLightsterSummary(unmoderated.paymentResult?.transferSuccessData)}
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems="center"
        mt={"15%"}
      >
        <Typography level="body-xs">
          E-mail support@lightster.co for support.
        </Typography>
      </Box>
    </Container>
  );
}

const getLightsterSummary = (transferSuccessData: any) => {
  const { amount, reward, boots } = transferSuccessData;
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
      >
        <Typography level="title-lg" fontWeight={400} mt={4}>
          Session reward
        </Typography>
        <Typography level="title-lg" fontWeight={400} mt={4}>
          ${reward.toFixed(2)}
        </Typography>
      </Box>
      {boots && (
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Typography level="title-lg" fontWeight={400}>
            10% boost
          </Typography>
          <Typography level="title-lg" fontWeight={400}>
            ${boots.toFixed(2)}
          </Typography>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={"space-between"}
      >
        <Typography level="h3" fontWeight={500}>
          You've earned
        </Typography>
        <Typography level="h3" fontWeight={500}>
          ${amount.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};
