import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { Box, Stack } from "@mui/system";
import { PlainButton, SolidButton } from "../../button/CustomButton";
import {  Unmoderated } from "../../../constants/interfaces";
import moment from "moment";
import { EastSharp } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  unmoderated: Unmoderated;
  onSelected: () => void;
}

function DetailBeforeUnmoderated(props: Props) {
  const { open, unmoderated, onClose, onSelected } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog aria-labelledby="title-plan-detail" size="lg">
        <Typography id="title-plan-detail" level="h3" sx={{ fontSize: 24 }}>
          <span style={{ fontWeight: "bold", color: "#7730E1" }}>
            {unmoderated.name} - ${unmoderated.reward}
          </span>
        </Typography>
        <ModalClose />
        <Stack
          spacing={2}
          border={"1px solid #7730E1"}
          borderRadius="8px"
          sx={{ paddingBlock: 3, marginTop: "8px" }}
        >
          <Box overflow={"auto"} maxHeight={"53vh"} >
          <Stack
            direction={"column"}
            spacing={0.1}
            justifyContent="center"
            alignItems="baseline"
            paddingInline={3}
          >
            <Stack direction={"column"} >
              <Typography level="title-md" sx={{ fontSize: 20, fontWeight: "bold" }}>
                Here's what you need to know
              </Typography>
              <Stack height={"24px"}></Stack>
              <Typography level="body-md" sx={{ fontSize: 16 }}>
              You have been selected for an unmoderated session. You will be asked to share your opinion, feedback, or ideas on a particular topic by following instructions on the screen.
              </Typography>
            </Stack>
            <Stack height={"24px"}></Stack>

            <Stack direction={"row"}>
              <Typography level="title-md" sx={{ fontSize: 16, fontWeight: "bold" }}>Reward:</Typography>
              <Box pl={1}>
                <Typography level="body-md" sx={{ fontSize: 16 }}>${unmoderated.reward} reward</Typography>
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Typography level="title-md" sx={{ fontSize: 16, fontWeight: "bold" }}>Format:</Typography>
              <Box pl={1}>
                <Typography level="body-md" sx={{ fontSize: 16 }}>
                Online recorded session with video & audio
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"column"}>
              <Typography level="title-md" sx={{ fontSize: 16, fontWeight: "bold" }}>How it works</Typography>
              <Box pl={3}>
                <Typography level="body-md" sx={{ display: "list-item", fontSize: 16 }}>
                Before you begin, you will be required to share your screen, and record your audio and video from your computer.
                </Typography>
                <Typography level="body-md" sx={{ display: "list-item", fontSize: 16 }}>
                You will be asked to respond to a series of questions by speaking out loud. 
                </Typography>
                <Typography level="body-md" sx={{ display: "list-item", fontSize: 16 }}>
                Some questions may require you to complete a particular task, such as navigating to a specific website or application.
                </Typography>
              </Box>
            </Stack>
            <Stack height={"24px"}></Stack>
            <Stack direction={"column"}>
              <Typography level="title-md" sx={{ fontSize: 16, fontWeight: "bold" }}>
                Message from {unmoderated.displayname}
              </Typography>
              <Typography level="body-md" sx={{ fontSize: 16 }}>“{unmoderated.topic}“</Typography>
            </Stack>
            <Stack height={"24px"}></Stack>
          </Stack>
          </Box>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between" spacing={2} sx={{ marginTop: "16px"}}>
          <PlainButton variant="plain" onClick={() => onClose(false)}>
            Decline this invitation
          </PlainButton>
          <SolidButton variant="solid" color="primary" onClick={onSelected} endDecorator={<EastSharp fontSize="small" />}>
            Let's start!
          </SolidButton>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default DetailBeforeUnmoderated;
