import { Avatar } from "@mui/joy";
import { useEffect, useRef } from "react";
import { placeholder } from "../../constants/AppImages";
import { PersonAvatar } from "../../constants/AppImages";

interface PlacehoderLayoutProps {
  imageUrl: string;
}
export const PlacehoderLayout = ({ imageUrl }: PlacehoderLayoutProps) => {
  return (
    <div className="media-player">
      <div style={{ width: 50, height: 50, alignItems: "center",  }}>
        {imageUrl ? (
          <Avatar color="primary" variant="soft" src={imageUrl} sx={{ width: 50, height: 50 }} />
        ) : (
          <Avatar
          color="primary" variant="soft"
            src={PersonAvatar}
            alt="image placeholder"
            sx={{ width: 50, height: 50 }}
          />
        )}
      </div>
    </div>
  );
};

interface MediaPlayerProps {
  videoTrack: any;
}
const MediaPlayer = ({ videoTrack }: MediaPlayerProps) => {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) return;
    videoTrack?.play(container.current);
    return () => {
      videoTrack?.stop();
    };
  }, [container, videoTrack]);

  return <div ref={container} className="media-player"></div>;
};
export default MediaPlayer;
