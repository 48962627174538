import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Textarea,
  Option,
  Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pagesName } from "../../../App";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import { StorageType } from "../../../constants/enum";
import { CreateUnmoderatedParams, Member } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import SelectCommunitiesDialog from "../../../components/dialog/schedules/SelectCommunitiesDialog";
import SelectUsersDialog from "../../../components/dialog/send-message/SelectUsersDialog";

interface Props { }

function CreateUnmoderatedLightster(props: Props) {
  const { } = props;
  const { lightsterId } = useParams();
  const { showMessage, sessionService } = useApi();
  const navigate = useNavigate();
  const [dup, setDup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState({
    communities: false,
    users: false,
  });

  const [data, setData] = useState<CreateUnmoderatedParams>({
    name: "",
    lightsterId: "",
    topic: "",
    expectedLightster: 1,
    pastUsers: true,
    steps: [],
    communityId: [] as string[],
    users: [] as Member[],
  });

  const [errors, setErrors] = useState({
    name: false,
    lightsterId: false,
    topic: false,
    communityId: false,
    users: false,
  });

  const handleSetErrors = (field: string, value: boolean) => {
    setErrors({ ...errors, [field]: value });
  };
  const checkEmptyField = () => {
    let contained = false;
    if (data.name == null) {
      contained = true;
      errors.name = true;
    }

    if (!data.topic) {
      contained = true;
      errors.topic = true;
    }
    if (dup && (!data.communityId || data.communityId.length === 0)) {
      errors.communityId = true;
      contained = true;
    }

    if (dup && (!data.users || data.users.length === 0)) {
      errors.users = true;
      contained = true;
    }

    setErrors(errors);
    return contained;
  };
  const handleOnClickNext = () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));
    navigate(pagesName.creator.unmoderatedSteps, {
      state: { booking: data },
    });
  };
  const handleOnClickUpdate = async () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    setLoading(true);
    localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));

    const res = await sessionService.updateUnmoderated(data);

    if (res) {
      setTimeout(() => {
        setLoading(false);
        showMessage(
          "Invitation successfully updated, these changes will not affect users who have already confirmed.",
          "success"
        );
        localStorage.removeItem(StorageType.unmoderated);
        navigate(-1);
      }, 5000);
    } else {
      setLoading(false);
    }


  };

  useEffect(() => {
    if (lightsterId) {
      if (lightsterId === "dup") {
        setDup(true);
        setData({ ...data, lightsterId: "dup" });
      } else {
        setData({ ...data, lightsterId: lightsterId });
      }
    }
    const schedule = localStorage.getItem(StorageType.unmoderated);
    if (schedule) {
      setData(JSON.parse(schedule));
    }
  }, []);


  return (
    <Box className="home" sx={{ height: "100%" }} overflow="auto">
      {loading ? (
        <Box
          className="box-column"
          sx={{
            //minHeight: 300,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="md" />
        </Box>
      ) : (
        <Stack direction={"column"} spacing={3}>
          <Box>
            <Typography level="h2" fontSize={28}>
              Start a test
            </Typography>
            <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
              Run an unmoderated test with users in this community. Unmoderated tests are recorded and sent to you after each test is completed.
            </Typography>
          </Box>
          <Box overflow={"auto"} maxHeight={"53vh"} sx={{ "&::-webkit-scrollbar": { display: "none" } }}>
            <Box>
              <Typography level="title-lg" sx={{ color: "black", mb: 2 }}>
                Test Details
              </Typography>
              <Stack direction={"column"} spacing={1}>
                <Input
                  color={errors.name ? "danger" : "neutral"}
                  size="lg"
                  placeholder="Enter"
                  startDecorator={
                    <Typography level="title-sm">Test name</Typography>
                  }
                  sx={{
                    ".MuiInput-startDecorator": {
                      maxWidth: "60%",
                    },
                  }}
                  slotProps={{
                    input: {
                      style: {
                        textAlign: "end",
                        fontSize: 16,
                      },
                    },
                  }}
                  error={errors.name}
                  value={data.name}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value && errors.name) {
                      setErrors({ ...errors, name: false });
                    }
                    setData({ ...data, name: value });
                  }}
                />

              </Stack>
            </Box>

            {dup ? (
              <Box>
                <Typography level="title-lg" sx={{ color: "black", mt:2 , mb: 2 }}>
                  Which users do you want to send to?
                </Typography>
                <Stack direction={"column"} spacing={2}>
                  <FormControl>

                    <Select
                      size="lg"
                      color={errors.communityId ? "danger" : "neutral"}
                      startDecorator={<Typography level="title-sm">Communities</Typography>}
                      indicator={<KeyboardArrowDownRounded fontSize="small" />}
                      slotProps={{
                        button: {
                          style: { justifyContent: "end", fontSize: 16 },
                          onClick: () => setOpen({ ...open, communities: true }),
                        },
                      }}
                      placeholder="Select"
                      listboxOpen={false}
                      value={data.communityId && data.communityId.length > 0 ? data.communityId.length : null}
                    >
                      {data.communityId && data.communityId.length > 0 && (
                        <Option value={data.communityId.length}>
                          {data.communityId.length}
                        </Option>
                      )}
                    </Select>
                  </FormControl><Select
                    size="lg"
                    color={errors.users ? "danger" : "neutral"}
                    startDecorator={<Typography level="title-sm">Select users</Typography>}
                    indicator={<KeyboardArrowDownRounded fontSize="small" />}
                    slotProps={{
                      button: {
                        style: { justifyContent: "end", fontSize: 16 },
                        onClick: () => {
                          if (!data.communityId || data.communityId.length === 0) {
                            showMessage("Please select a community.", "warning");
                            setErrors({ ...errors, communityId: true });
                            return;
                          }
                          setOpen({ ...open, users: true });
                        },
                      },
                    }}
                    placeholder="Select"
                    listboxOpen={false}
                    value={data.users && data.users.length > 0 ? data.users.length : null}
                  >
                    {data.users && data.users.length > 0 && (
                      <Option value={data.users.length}>{data.users.length}</Option>
                    )}
                  </Select>
                </Stack>
              </Box>
            ) : null}
            <Box>
              <Typography level="title-lg" sx={{ color: "black", mt: 2 }}>
                What should the user know about the test?
              </Typography>
              <Typography level="body-sm" sx={{ color: "black", mb: 1 }}>
                Please let the user know a bit about what to expect in the test.
              </Typography>
              <Textarea
                size="lg"
                minRows={4}
                error={errors.topic}
                value={data.topic}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value && errors.topic) {
                    setErrors({ ...errors, topic: false });
                  }
                  setData({ ...data, topic: value });
                }}
                sx={{ borderRadius: 8 }}
              />
            </Box>
          </Box>
          <Box
            className="box-row"
            sx={{ justifyContent: "end", gap: 2, mt: "48px !important" }}
          >
            <PlainButton
              variant="plain"
              onClick={() => {
                localStorage.removeItem(StorageType.unmoderated);
                navigate(-1);
              }}
            >
              Cancel
            </PlainButton>
            <SolidButton variant="solid" onClick={handleOnClickNext}>Next</SolidButton>
          </Box>
        </Stack>)}
      {open.communities && (
        <SelectCommunitiesDialog
          open={open.communities}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.communityId) {
              setErrors({ ...errors, communityId: false });
            }
            setData({ ...data, communityId: value });
            setOpen({ ...open, communities: false });
          }}
          defaultData={data?.communityId ?? []}
        />
      )}
      {open.users && (
        <SelectUsersDialog
          open={open.users}
          defaultMembers={data.users ?? []}
          communityIds={data.communityId ?? []}
          onSave={(value: Member[]) => {
            if (value.length > 0 && errors.users) {
              setErrors({ ...errors, users: false });
            }
            setData({ ...data, users: value });
            setOpen({ ...open, users: false });
          }}
          onClose={() => setOpen({ ...open, users: false })}
        />
      )}
    </Box>
  );
}

export default CreateUnmoderatedLightster;
