import { Modal, ModalDialog, Typography } from "@mui/joy";
import { Box, Stack } from "@mui/system";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function PlanDetailDialog(props: Props) {
  const { open, onClose, onSubmit } = props;
  const getTextWhatYouGet = () => {
    return [
      "Unlimited criteria to segment users",
      "Add your own screening questions",
      "No limit on direct messages to users",
      "Set your own rewards to users",
      "Save 20% per transaction",
    ];
  };

  const benefits = [
    "Specialized recruitment support from the team by e-mailing support@lightster.co",
    "Run sessions on either web or mobile",
    "AI-enabled voice transcriptions",
  ];

  return (
    <Modal open={open}>
      <ModalDialog aria-labelledby="title-plan-detail" size="lg">
        <Typography id="title-plan-detail" level="h3" sx={{ fontSize: 24 }}>
          Premium Plan
        </Typography>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"column"}>
            <Typography level="title-md">Plan Details</Typography>
            <Box pl={3}>
              {getTextWhatYouGet().map((item) => (
                <Typography
                  key={item}
                  level="body-md"
                  sx={{ display: "list-item" }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Stack>
          <Stack direction={"column"}>
            <Typography level="title-md">Price</Typography>
            <Box pl={3}>
              <Typography level="body-md" sx={{ display: "list-item" }}>
                $50 monthly
              </Typography>
            </Box>
          </Stack>
          <Stack direction={"column"}>
            <Typography level="title-md">Other Benefits</Typography>
            <Box pl={3}>
              {benefits.map((data) => (
                <Typography
                  key={data}
                  level="body-md"
                  sx={{ display: "list-item" }}
                >
                  {data}
                </Typography>
              ))}
            </Box>
          </Stack>
          <Stack direction={"row"} justifyContent="end" spacing={2}>
            <PlainButton variant="plain" onClick={onClose}>
              Go back
            </PlainButton>
            <SolidButton variant="solid" color="primary" onClick={onSubmit}>
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default PlanDetailDialog;
