import config from "../config";
import { ProfileParams } from "../constants/interfaces";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

const getRowActive = () => {
  return localStorage.getItem("row_active") ?? "creator";
};

export default class CommunityServices extends BaseService {
  async getMyCommunities() {
    const res = await this.call("get", `${baseUrl}/creator/my-communities`);
    return res.data;
  }

  async getCommunitiesRecommended() {
    const res = await this.call(
      "get",
      `${baseUrl}/creator/community-recommended`
    );
    return res.data;
  }

  async getCommunityMembers(communityId: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/creator/community/members`,
      {},
      { params: { communityId: communityId } }
    );

    return res.data;
  }
  async getCommunitySetting(communityId: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/creator/community/setting`,
      {},
      { params: { communityId: communityId } }
    );

    return res.data;
  }
  async createCommunity(name: string) {
    const res = await this.call("post", `${baseUrl}/creator/community`, {
      name: name,
      settingType: "dynamic",
    });

    return res.data;
  }

  async renameCommunity(communityId: string, name: string) {
    const res = await this.call("put", `${baseUrl}/creator/community/rename`, {
      communityId: communityId,
      name: name,
    });

    return res.data;
  }
  async deleteCommunity(communityId: string) {
    const res = await this.call(
      "delete",
      `${baseUrl}/creator/community/unfollow?communityId=${communityId}`
    );

    return res.data;
  }
  async updateCriteria(communityId: string, profiles: Array<ProfileParams>) {
    const res = await this.call(
      "put",
      `${baseUrl}/creator/community/criteria`,
      {
        communityId: communityId,
        profiles: profiles,
      }
    );

    return res.data;
  }

  async removeCriteria(communityId: string, profileId: string) {
    const res = await this.call(
      "put",
      `${baseUrl}/creator/remove/criteria/community`,
      {
        communityId: communityId,
        profileId: profileId,
      }
    );

    return res.data;
  }

  async refreshCommunity(communityId: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/refresh/community`,
      {
        communityId: communityId,
      }
    );

    return res.data;
  }
  async getCommunityListMembers(body: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/community/membersList`,
      body
    );

    return res.data;
  }
}
