import { CheckCircleRounded, EastRounded } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/joy";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { SolidButton } from "../../../components/button/CustomButton";
import { useAuth } from "../../../providers/AuthProvider";

interface Props {}

function CreatorPlanSuccess(props: Props) {
  const { user } = useAuth();
  const getTextWhatYouGet = () => {
    return [
      "Unlimited criteria to segment users",
      "Add your own screening questions",
      "No limit on direct messages to users",
      "Set your own rewards to users",
      "Save 20% per transaction",
    ];
  };

  const getTextDisplayName = () => {
    return user.displayName;
  };
  const handleNavigate = () => {
    window.location.replace(pagesName.creator.home);
  };

  return (
    <BackgroundRegister>
      <Stack direction={"column"} mt={"10vh"}>
        <CheckCircleRounded
          sx={{ color: "#48BB78", fontSize: "80px", mb: 3 }}
        />

        <Stack direction={"column"} spacing={3}>
          <Box>
            <Typography level="h2" fontSize={28}>
              That’s it!
            </Typography>
            <Typography level="body-lg" sx={{ mt: 1 }}>
              Welcome to Lightster, {getTextDisplayName()}! You are now ready to
              start connecting with users.
            </Typography>
            <Typography level="body-lg" sx={{ mt: 4, display: "inline-flex" }}>
              As a welcome gift, please enjoy
              <Typography level="title-lg" sx={{ fontWeight: 700 }}>
                &nbsp;7 days of premium&nbsp;
              </Typography>{" "}
              on us!
            </Typography>
            <Typography level="title-lg" sx={{ mt: 4 }}>
              What you get
            </Typography>
            <Box pl={3}>
              {getTextWhatYouGet().map((item) => (
                <Typography level="body-lg" sx={{ display: "list-item" }}>
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
        </Stack>
        <Box mt={6}>
          <SolidButton
            variant="solid"
            endDecorator={<EastRounded fontSize="small" />}
            sx={{ width: 180, textTransform: "inherit" }}
            onClick={handleNavigate}
          >
            Next
          </SolidButton>
        </Box>
      </Stack>
    </BackgroundRegister>
  );
}

export default CreatorPlanSuccess;
