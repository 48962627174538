import config from "../config";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

export default class NotificationServices extends BaseService {
  async sendNotification(body: any) {
    await this.call("post", `${baseUrl}/notification/chat`, body);
  }

  async gteNumberNotification(body: any) {
   const res = await this.call("post", `${baseUrl}/notification`, body);
   return res.data;
  }

}
