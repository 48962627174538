import { Avatar, Box, Button, Stack, Typography } from "@mui/joy";
import { useState } from "react";
import { pagesName } from "../../App";
import { placeholder } from "../../constants/AppImages";
import { SurvayDisplay } from "../../constants/interfaces";
import { PlainButton, SolidButton } from "../button/CustomButton";
import CustomDialog from "../dialog/CustomDialog";
import InfoDialog from "../dialog/InfoDialog";
import ResponseQuestionsurveyDialog from "../dialog/survey/ResponseQuestionsurveyDialog";

interface Props {
  survey: SurvayDisplay;
  refresh: Function;
}

function LightsterSurveyItem(props: Props) {
  const { survey, refresh } = props;
  const [open, setOpen] = useState({
    complete: false,
    ended: false,
    leavePage: false,
  });

  const handleCompleted = async () => {
    setOpen({ ...open, complete: true });
  };
  const handleclickViewProfile = () => {
    window.open(
      `${pagesName.lightster.viewProfile}/${survey.creatorId}`,
      "_blank"
    );
  };
  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} gap={2} p={2}>
        <Avatar
          src={survey.imageUrl ?? placeholder}
          sx={{ width: 56, height: 56, cursor: "pointer" }}
          onClick={handleclickViewProfile}
        />
        <Stack direction="column" gap={1} width="100%">
          <Stack direction="row" justifyContent={"space-between"}>
            <Box>
              <Typography
                level="title-lg"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {survey.name}
              </Typography>
            </Box>
            <Typography
              level="body-md"
              textAlign={"right"}
              sx={{ ml: 2, color: "#34C759" }}
            >
              ${survey.reward}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography level="body-md">{survey.companyName}</Typography>
            <Typography level="body-md">
              {survey.questions} questions
            </Typography>
          </Stack>
          <Box>
            <Button
              variant="solid"
              size="sm"
              sx={{ bgcolor: "black", borderRadius: 6, px: 2, minHeight: 40 }}
              onClick={handleCompleted}
            >
              Complete now
            </Button>
          </Box>
        </Stack>
      </Stack>
      <div style={{ width: 547, overflow: "hidden", textOverflow: "ellipsis" }}>
      {open.complete && (
        <ResponseQuestionsurveyDialog
          open={open.complete}
          surveyId={survey.id}
          surveyName={survey.name}
          onClose={(value: boolean | undefined) => {
            if (value) {
              setOpen({ ...open, leavePage: true });
            } else {
              setOpen({ ...open, complete: false });
            }
          }}
          onSave={() => {
            setOpen({ ...open, complete: false });
            if (refresh) {
              refresh();
            }
          }}
          surveyEnded={() => {
            setOpen({ ...open, ended: true, complete: false });
          }}
        />
      )}
      </div>
      {open.ended && (
        <InfoDialog
          open={open.ended}
          onClose={() => {
            setOpen({ ...open, ended: false });
            refresh();
          }}
          title={"The survey has ended"}
          subtitle={
            <Typography level="body-md">
              Thank you for your time, the survey has now closed and no longer
              accepting responses.
            </Typography>
          }
        />
      )}
      {open.leavePage && (
        <CustomDialog
          open={open.leavePage}
          title={"Are you sure?"}
          subtitle={
            <Stack direction={"column"} gap={3}>
              <Typography level="body-lg">
                Your responses have not been send yet.
              </Typography>
              <Stack direction={"row"} gap={2}>
                <SolidButton
                  variant="solid"
                  onClick={() =>
                    setOpen({ ...open, leavePage: false, complete: false })
                  }
                >
                  Yes
                </SolidButton>
                <PlainButton
                  variant="plain"
                  onClick={() => setOpen({ ...open, leavePage: false })}
                >
                  Stay on this page
                </PlainButton>
              </Stack>
            </Stack>
          }
        />
      )}
    </Box>
  );
}

export default LightsterSurveyItem;
