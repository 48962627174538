import {
    Box,
    Modal,
    ModalDialog,
    Typography
} from "@mui/joy";
import { PlainButton, SolidButton } from "../button/CustomButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
export const DeleteCommunityDialog = ({ open, onClose, onSubmit }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        size="lg"
        sx={{
          width: "35vw",
          top: "12vh",
          left: "50%",
          transform: "translateX(-50%)",
          maxHeight: "calc(100% - 24vh)",
        }}
      >
        <Typography
          id="layout-modal-title-delete"
          level="h2"
          sx={{ fontSize: 24 }}
        >
          Delete this community
        </Typography>
        <Typography
          id="layout-modal-description-delete"
          sx={{ mt: 4, color: "black" }}
        >
          Are you sure you want to delete this community?
        </Typography>
        <Box
          sx={{ display: "flex", gap: 1, mt: 3, justifyContent: "flex-end" }}
        >
          <PlainButton variant="plain" color="primary" onClick={onClose}>
            Cancel
          </PlainButton>
          <SolidButton variant="solid" color="primary" onClick={onSubmit}>
            Yes
          </SolidButton>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
