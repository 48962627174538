import { AddCircle, Close } from "@mui/icons-material";
import {
    Box,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from "@mui/joy";
import moment from "moment";

import dayjs, { Dayjs } from "dayjs";

import { useEffect, useState } from "react";
import PickerWithButtonField from "../../../ButtonDatePicker";
import { PlainButton } from "../../button/CustomButton";
interface Props {
  users: number;
  timezone?: number;
  defaultValue: string[];
  open: boolean;
  onClose: (value: string[]) => void;
}
export const CreateTimeSlotsDialog = ({
  users,
  timezone,
  defaultValue,
  open,
  onClose,
}: Props) => {
  const [slots, setSlots] = useState<string[]>([]);

  const handleStartDate = () => {
    const startSlot: string[] = [];

    for (let index = 0; index < users; index++) {
      var newDate = "";

      const date = moment(startSlot[index - 1] ?? Date())
        .add(30, "minutes")
        .toISOString();

      const min = moment(date).minutes();

      if (min > 0 && min <= 30) {
        newDate = moment(date).set({ minute: 30 }).toISOString();
      } else if (min > 30 && min <= 60) {
        newDate = moment(date).set({ minute: 60 }).toISOString();
      } else {
        newDate = moment(date).set({ minute: 0 }).toISOString();
      }
      startSlot.push(newDate);
    }

    setSlots(startSlot);
  };
  const handleDeleteSlot = (slot: string) => {
    const newValue = slots.filter((f) => f !== slot);
    setSlots(newValue);
  };
  const handleAddSlot = () => {
    var newDate = "";
    const date = moment(slots[slots.length - 1])
      .add(30, "minutes")
      .toISOString();
    const min = moment(date).minutes();
    if (min > 0 && min <= 30) {
      newDate = moment(date).set({ minute: 30 }).toISOString();
    } else if (min > 30 && min <= 60) {
      newDate = moment(date).set({ minute: 60 }).toISOString();
    } else {
      newDate = moment(date).set({ minute: 0 }).toISOString();
    }
    setSlots([...slots, newDate]);
  };
  const handleChangeDate = (index: number, value: string) => {
    const newValue = [...slots];
    newValue[index] = value;

    setSlots(newValue);
  };

  useEffect(() => {
    if (defaultValue.length > 0) {
      setSlots(defaultValue);
    } else {
      handleStartDate();
    }
  }, []);

  return (
    <Modal open={open} onClose={() => onClose(slots)} sx={{ maxHeight: "93vh" }}>
      <ModalDialog
        aria-labelledby="layout-modal-title-reanme"
        aria-describedby="layout-modal-description-reanme"
        size="lg"
        sx={{ display: 'flex', flexDirection: 'column' }} // Use flex layout to control the sizes
      >
        <ModalClose />
        <Typography
          id="layout-modal-title-reanme"
          level="h2"
          sx={{ fontSize: 24, marginBottom: 2 }} // Added margin for spacing
        >
          Time Slots
        </Typography>
        <Typography level="body-lg" sx={{ color: "black", marginBottom: 2 }}> 
          Set your preferred date and time for each slot.
        </Typography>
        {/* Scrollable Container Starts Here */}
        <Box sx={{ flex: 1, overflowY: 'auto', marginBottom: 1, }}>
          <Stack direction={"column"} spacing={2}>
            {slots.map((slot: string, i: number) => (
              <Stack key={i} direction="row" alignItems={"center"} gap={1}>
                <Box width={"90%"}>
                  <PickerWithButtonField
                    slot={i + 1}
                    timezone={timezone}
                    defaultValue={dayjs(slot)}
                    value={dayjs(slot)}
                    onChange={(value: Dayjs | null) => {
                      if (value) {
                        handleChangeDate(i, value.toISOString());
                      }
                    }}
                  />
                </Box>
                <Box width={44}>
                  {i + 1 > users && (
                    <IconButton onClick={() => handleDeleteSlot(slot)}>
                      <Close />
                    </IconButton>
                  )}
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
        {/* Scrollable Container Ends Here */}
        {/* Fixed Position Button Starts Here */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <PlainButton
            size="lg"
            variant="plain"
            sx={{ color: "black", paddingInline: 2, maxWidth: 220 }}
            startDecorator={<AddCircle />}
            onClick={handleAddSlot}
          >
            Add another slot
          </PlainButton>
        </Box>
        {/* Fixed Position Button Ends Here */}
      </ModalDialog>
    </Modal>
  );
  
  
};
