import imgLogoAppber from "../assets/images/logo.png";

import { Box, Container } from "@mui/joy";
import React from "react";

interface Props {
  children: string | JSX.Element | JSX.Element[];
}

function BackgroundRegister(props: Props) {
  const { children } = props;
  const [progress, setProgress] = React.useState(0);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100vw",
        background: "linear-gradient(194.01deg, #FFFFFF 9.6%, #EAF2F5 89.64%)",
        position: "relative",
        display: "flex",
      }}
    >
      <img
        src={imgLogoAppber}
        alt="lightster-logo-app-bar"
        height={"35px"}
        style={{ position: "fixed", top: 10, left: 20 }}
      />
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            position: "relative",
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Container>
    </div>
  );
}

export default BackgroundRegister;
