import config from "../config";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

export default class QuestionServices extends BaseService {
  async getNewQuestions() {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/waiting/questions`
    );
    return res.data;
  }

  async lightsterAnswerQuestion(payload: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/criteria`, payload
    );

    return res.data;
  }
   
}
