import { Box, Link, Stack } from "@mui/joy";
import { LogoApple, LogoGooglePlaystore } from "react-ionicons";
import { primaryPurple } from "../joy-styles";

interface Props {}

function Footer(props: Props) {
  const {} = props;
  const style = {};

  return (
    <footer>
      <Stack
        direction={"row"}
        sx={{
          padding: "8px 1rem",
          bgcolor: "white",
          borderRadius: 30,
          alignItems: "center",
          justifyContent: "space-around",
          listStyleType: "none",
        }}
      >
        <li>Don't have the app yet?</li>
        <li style={{ color: primaryPurple, cursor: "pointer" }}>
          <Link
            href="https://apps.apple.com/us/app/lightster/id1583518400"
            target="_blank"
            underline="none"
          >
            <Box height={18}>
              <LogoApple width="18px" height="18px" />
              &nbsp;Apple Store
            </Box>
          </Link>
        </li>
        <li style={{ color: primaryPurple, cursor: "pointer" }}>
          <Link
            href="https://play.google.com/store/apps/details?id=com.lightster.app&pcampaignid=web_share"
            target="_blank"
            underline="none"
          >
            <Box height={18}>
              <LogoGooglePlaystore width="18px" height="18px" />
              &nbsp;Google Play Store
            </Box>
          </Link>
        </li>
      </Stack>
    </footer>
  );
}

export default Footer;
