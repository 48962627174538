import { Avatar, Box, Button, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { pagesName } from "../../App";
import { Booking } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import AcceptedDialog from "../dialog/invitations/AcceptedDialog";
import ConfirmYourSpot from "../dialog/invitations/ConfirmYourSpot";
import GetYourSpotDialog from "../dialog/invitations/GetYourSpotDialog";
import DetailBeforeGetSpot from "../dialog/invitations/DetailBeforeGetSpot";

interface Props {
  showDefault?: boolean;
  book: Booking;
  refresh: () => void;
}

function LightsterBookingItem(props: Props) {
  const { book, refresh, showDefault } = props;
  const { sessionService } = useApi();

  const [open, setOpen] = useState({
    getSpot: false,
    confirmSpot: false,
    accepted: false,
    noMoreSpot: false,
    detail: false,
  });
  const [loading, setLoading] = useState(false);
  const [spot, setSpot] = useState<string[]>([]);
  const [select, setSelect] = useState("");

  const handleConfirmSpot = async (id: string) => {
    setLoading(true);
    const token = await grecaptcha.enterprise.execute('6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9', {action: 'transaction_confirmed'});
    const res = await sessionService.confirmBooking(id, select, token,'web');
    setLoading(false);
    if (!res) {
      setOpen({ ...open, confirmSpot: false });
      refresh();
      return;
    }
    setOpen({ ...open, confirmSpot: false, accepted: true });
  };

  const handledeclineBooking = async () => {
    const res = await sessionService.declineBooking(book.id);
    if (!res) return;
    refresh();
  };
  const handleComplateandExpiredBooking = async (bookingStatus:boolean) => {
    if (!bookingStatus) {
      const res = await sessionService.detailBooking(book.id);
      refresh();
      if (!res) return;
      refresh();
    }
  };

  const handleclickViewProfile = () => {
    window.open(
      `${pagesName.lightster.viewProfile}/${book.creatorId}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (showDefault) {
      setSpot(book.proposedTime);
      setOpen({ ...open, detail: true });
    }
  }, []);
  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} spacing={2} p={2}>
        <Avatar
          sx={{ width: 56, height: 56 }}
          src={book.imageProfile}
          onClick={handleclickViewProfile}
        />
        <Box width={"100%"}>
          <Box className="box-row" sx={{ justifyContent: "space-between" }}>
            <Typography
              level="title-lg"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                width: "calc(100% - 100px)",
              }}
            >
              {book.displayname}
            </Typography>
            <Typography level="title-md" textAlign="right">
              ${book.reward} reward
            </Typography>
          </Box>
          <Typography level="body-md">{book.companydisplayname}</Typography>
          <Typography level="body-md">{book.expectedLength} minutes</Typography>
          <Box className="box-row" sx={{ gap: 1, pt: 1 }}>
            <Button
              variant="solid"
              size="sm"
              sx={{
                borderRadius: 6,
                bgcolor: "black",
                fontSize: 12,
                paddingInline: 2,
                minHeight: 36,
              }}
              onClick={() => {
                setSpot(book.proposedTime);
                setOpen({ ...open, detail: true });

                handleComplateandExpiredBooking(book.userActive);
                
              }}
            >
              Get your spot
            </Button>
            <Button
              variant="plain"
              size="sm"
              color="primary"
              sx={{
                borderRadius: 6,
                fontSize: 12,
                paddingInline: 2,
                minHeight: 40,
              }}
              onClick={handledeclineBooking}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </Stack>
      {open.detail && spot.length > 0 && (
        <DetailBeforeGetSpot
          open={open.detail}
          onClose={(value) => {
            if (!value) handledeclineBooking();
            setOpen({ ...open, detail: false });
          }}
          book={book}
          onSelected={() => {
            setOpen({
              ...open,
              detail: false,
              getSpot: true,
            });
          }}
        />
      )}
      {open.getSpot && spot.length > 0 && (
        <GetYourSpotDialog
          open={open.getSpot}
          onClose={() => setOpen({ ...open, getSpot: false })}
          spot={spot}
          onSelected={(value) => {
            setSelect(value);
            setOpen({
              ...open,
              getSpot: false,
              confirmSpot: true,
            });
          }}
        />
      )}
      {open.confirmSpot && select && (
        <ConfirmYourSpot
          open={open.confirmSpot}
          loading={loading}
          date={select}
          onClose={() => {
            setOpen({
              ...open,
              getSpot: true,
              confirmSpot: false,
            });
            setSelect("");
          }}
          onConfirm={() => handleConfirmSpot(book.id)}
        />
      )}
      <AcceptedDialog
        open={open.accepted}
        onClose={() => {
          refresh();
          setOpen({ ...open, accepted: false });
        }}
      />
    </Box>
  );
}

export default LightsterBookingItem;
