import {
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography
} from "@mui/joy";
import { plan02 } from "../../../constants/AppImages";

interface Props {
  open: boolean;
  onClose: () => void;
}

function CancelPlanSuccessDialog(props: Props) {
  const { open, onClose } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog size="lg">
        <ModalClose />

        <Stack direction={"column"}>
          <Stack
            direction={"column"}
            justifyContent="center"
            alignItems={"center"}
            spacing={3}
          >
            <img src={plan02} width={134} />
            <Typography level="h3">Confirmed!</Typography>
            <Typography level="body-md" textAlign={"center"}>
              You are now on the{" "}
              <Typography level="title-md">Free Plan</Typography>. We hope you
              continue to enjoy the benefits of co-creation with qualified users
              on Lightster!
            </Typography>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default CancelPlanSuccessDialog;
