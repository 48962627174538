import { useEffect, useState } from "react";
import "../index.css";

import imgLogo from "../assets/images/logo.png";
import google from "../assets/images/google.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pagesName } from "../App";
import { useFirebaseAuth } from "../providers/FirebaseProvider";
import { SignSSO, useAuth } from "../providers/AuthProvider";

import { imageLogin } from "../constants/AppImages";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { EastSharp } from "@mui/icons-material";
import { SolidButton } from "../components/button/CustomButton";
import { StorageType } from "../constants/enum";
import { Hidden } from "@mui/material";

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

function Login() {
  const [eyeOpen, setEyeOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { googleSignIn, facebookSignIn, appleSignIn } = useFirebaseAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const auth = useAuth();

  const [data, setData] = useState({
    email: "",
    password: "",
    token: "",
    platform: "web",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  function handleOnChange(event: any) {
    const { value, name } = event.target;
    if (name == "password") {
      if (errors.password && value) {
        setErrors({ ...errors, password: false });
      }
    }

    if (name === "email") {
      if (errors.email && value) {
        setErrors({ ...errors, email: false });
      }
    }
    setData({ ...data, [name]: value });
  }

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!data.email || !data.password) {
      setErrors({
        email: !data.email,
        password: !data.password,
      });
      auth.onShowMessage("Please enter Email and Password", "warning");
      return;
    }

    try {
      const token = await grecaptcha.enterprise.execute(
        "6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9",
        { action: "LOGIN" }
      );
      data.token = token ?? "";
      console.log("Token received:", token);

      await auth.onLogin(data);
      console.log("Login successful");
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleSignInSSO = async (provider: any) => {
    const token = await grecaptcha.enterprise.execute(
      "6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9",
      { action: "LOGIN" }
    );

    if (provider === "google") {
      const result = await googleSignIn();
      if (result) {
        const email = result.user?.email ?? result.user.providerData[0].email;
        const imageUrl = result.user.photoURL;
        const fullName = result.user.displayName;
        const dataSignIn: SignSSO = {
          vanityName: null,
          email: email,
          picture: imageUrl,
          fullName: fullName,
          provider: "google",
          token: token,
          platform: "web",
        };

        await auth.onSignInSSO(dataSignIn);
      }
    }
    if (provider === "facebook") {
      const result = await facebookSignIn();
      if (result) {
        const email = result.user?.email ?? result.user.providerData[0].email;
        const imageUrl = result.user.photoURL;
        const fullName = result.user.displayName;

        const dataSignIn: SignSSO = {
          vanityName: null,
          email: email,
          picture: imageUrl,
          fullName: fullName,
          provider: "facebook",
          token: token,
          platform: "web",
        };
        await auth.onSignInSSO(dataSignIn);
      }
    }

    if (provider === "apple") {
      const result = await appleSignIn();
      if (result) {
        const email = result.user?.email ?? result.user.providerData[0].email;
        const imageUrl = result.user.photoURL;
        const fullName = result.user.displayName;

        const dataSignIn: SignSSO = {
          vanityName: null,
          email: email,
          picture: imageUrl,
          fullName: fullName,
          provider: "apple",
          token: token,
          platform: "web",
        };
        await auth.onSignInSSO(dataSignIn);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const token = searchParams.get("token");

    if (token) {
      localStorage.setItem(StorageType.token, token);
      auth.handleCheckRegisterAccount();

      setLoading(false);
      return;
    }
    setTimeout(() => {
      setLoading(false);
      if (auth.token && auth.user) {
        const { isLightster, isCreator, phone, username } = auth.user;
        if (isLightster || isCreator) {
          navigate(pagesName.chooseRole);
        } else if (!phone) {
          navigate(pagesName.verifyPhoneNumber);
        }
      }
    }, 1000);
  }, [auth.token]);

  return (
    <form method="post" onSubmit={handleOnSubmit} noValidate autoComplete="off">
      <div className="login">
        <Stack
          sx={{
            width: "100%",
            maxWidth: 1280,
            display: "flex",
            flexDirection: { sm: "column", md: "row" },
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 4,
            p: 4,
            scale: { xs: "90%" },
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              border: "1px solid #000000",
              bgcolor: "white",
              maxWidth: 500,
              width: "100%",
            }}
          >
            <Box
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: { sm: "center", md: "start" },
                alignItems: "start",
                textAlign: "left",
              }}
            >
              <img src={imgLogo} height={35} alt="lightster-logo" />
              <Typography level="h2" pt={2} pb={2}>
                Login to co-create
              </Typography>

              <Stack direction="column" width="100%" spacing={2}>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    autoFocus
                    fullWidth
                    type="email"
                    name="email"
                    size="lg"
                    autoComplete="off"
                    error={errors.email}
                    value={data.email}
                    onChange={handleOnChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    fullWidth
                    type={eyeOpen ? "text" : "password"}
                    name="password"
                    size="lg"
                    error={errors.password}
                    value={data.password}
                    onChange={handleOnChange}
                    endDecorator={
                      eyeOpen ? (
                        <IconButton
                          size="sm"
                          onClick={() => setEyeOpen((pre) => !pre)}
                        >
                          <i className="bi bi-eye"></i>
                        </IconButton>
                      ) : (
                        <IconButton
                          size="sm"
                          onClick={() => setEyeOpen((pre) => !pre)}
                        >
                          <i className="bi bi-eye-slash"></i>
                        </IconButton>
                      )
                    }
                  />
                </FormControl>
                <Typography
                  level="body-md"
                  textAlign={"right"}
                  width={"100%"}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate(pagesName.resetpassword)}
                >
                  Forgot your password?
                </Typography>
                <SolidButton
                  variant="solid"
                  size="lg"
                  fullWidth
                  endDecorator={<EastSharp fontSize="small" />}
                  type="submit"
                  formNoValidate={true}
                >
                  Sign In
                </SolidButton>
                <Typography
                  color="primary"
                  level="body-md"
                  sx={{
                    textDecoration: "underline",
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(pagesName.signupEmail)}
                >
                  New to Lightster? Join us
                </Typography>
              </Stack>

              <Typography
                level="body-md"
                sx={{
                  width: "100%",
                  textAlign: "center",
                }}
                pb={2}
                pt={2}
              >
                Other options
              </Typography>
              <Stack direction="column" width="100%" spacing={2}>
                <Button
                  variant="solid"
                  size="lg"
                  fullWidth
                  sx={{
                    color: "black",
                    background: "#FFF",
                    boxShadow:
                      "0px 1px 1px 0px rgba(0, 0, 0, 0.17), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)",
                  }}
                  startDecorator={
                    <img src={google} alt="google-icon" width={22} />
                  }
                  onClick={() => handleSignInSSO("google")}
                >
                  Sign in with Google
                </Button>

                <Button
                  variant="solid"
                  size="lg"
                  fullWidth
                  sx={{
                    background: "#1877F2",
                  }}
                  startDecorator={<i className="bi bi-facebook"></i>}
                  onClick={() => handleSignInSSO("facebook")}
                >
                  Continue with Facebook
                </Button>
                <Button
                  variant="solid"
                  size="lg"
                  fullWidth
                  sx={{ background: (theme) => theme.palette.neutral[900] }}
                  startDecorator={<i className="bi bi-apple"></i>}
                  onClick={() => handleSignInSSO("apple")}
                >
                  Sign in with Apple
                </Button>
              </Stack>
            </Box>
          </Box>
          <Hidden mdDown>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                maxWidth: "450px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={imageLogin} width={400} alt="communities-login" />
              <Typography level="h3" sx={{ mt: 2 }}>
                Communities
              </Typography>
              <Typography
                level="body-lg"
                sx={{
                  mt: 1,
                  textAlign: "center",
                }}
              >
                With our instant communities, co-creation is within arm’s reach.
                No more one-off projects or tests.
              </Typography>
            </Box>
          </Hidden>
        </Stack>
      </div>
    </form>
  );
}

export default Login;
