import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
    Avatar,
    AvatarGroup,
    Box,
    Dropdown,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    Typography
} from "@mui/joy";
import { useState } from "react";
import { placeholder } from "../../constants/AppImages";
import { mapStatusText } from "../../constants/constants";
import { Booking } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../button/CustomButton";
import CustomDialog from "../dialog/CustomDialog";
import { pagesName } from "../../App";
import { useNavigate } from "react-router-dom";
import { StorageType } from "../../constants/enum";
interface Props {
  booking: Booking;
  refresh?: () => void;
}

function CreatorQuickBookingItem(props: Props) {
  const { booking, refresh } = props;
  const { sessionService, showMessage } = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);


  const handleStopSchedule = async () => {
    const res = await sessionService.stopQuickBooking(booking.id);

    if (res && refresh) {
      refresh();
    }
  };

  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} spacing={2} p={2}>
        <AvatarGroup>
          <Avatar src={booking.imageProfile ?? placeholder} />
          <Avatar sx={{ border: "1px solid black", fontSize: 24 }}>+</Avatar>
        </AvatarGroup>
        <Stack direction={"column"} sx={{ width: "-webkit-fill-available" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="title-lg">
              {booking.expectedLightster}{" "}
              {booking.expectedLightster > 1 ? "users" : "user"}
            </Typography>
            <Typography level="body-md">
              {booking.scheduled}/{booking.expectedLightster} scheduled{" "}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="body-md">
              {booking.expectedLength} minutes
            </Typography>
            <Dropdown>
              <MenuButton
                size="sm"
                sx={{
                  paddingInline: 0,
                  display: "inherit",
                  color: (theme) => theme.palette.primary[500],
                }}
                endDecorator={
                  <KeyboardArrowDownRounded fontSize="medium" color="primary" />
                }
                variant="plain"
              >
                {mapStatusText(booking.status)}
              </MenuButton>
              <Menu placement="bottom-start" variant="outlined">
              <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => {

                    localStorage.setItem(StorageType.schedule, JSON.stringify(booking));
                    if (booking.type === "lightster") {
                      navigate(`${pagesName.creator.schedule}/${booking.lightsterId}`);

                    } else{
                    navigate(pagesName.creator.schedule, {
                      state: { booking, scheduled: booking.scheduled },
                    });
                  }
                  }
                  }
                >
                  Modify invitation
                </MenuItem>
                <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  Stop scheduling now
                </MenuItem>
              </Menu>
            </Dropdown>
          </Stack>
          <Typography level="body-md" sx={{ fontStyle: "italic" }}>
            {booking.communityList}
          </Typography>
        </Stack>
      </Stack>
      {open && (
        <CustomDialog
          open={open}
          title="Are you sure?"
          subtitle={
            <Stack direction={"column"} spacing={4}>
              <Typography level="body-lg">
                If you stop scheduling now, no more users will be scheduled.
              </Typography>

              <Stack
                direction={"row"}
                justifyContent={{ sm: "space-between", md: "end" }}
                spacing={2}
              >
                <PlainButton variant="plain" onClick={() => setOpen(false)}>
                  Go Back
                </PlainButton>
                <SolidButton
                  variant="solid"
                  onClick={() => {
                    setOpen(false);
                    handleStopSchedule();
                  }}
                >
                  Yes
                </SolidButton>
              </Stack>
            </Stack>
          }
        />
      )}
    </Box>
  );
}

export default CreatorQuickBookingItem;
