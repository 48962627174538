import { Button, styled } from "@mui/joy";

export const SolidButton = styled(Button)(({ theme }) => ({
  color: "white",
  background: theme.palette.primary.darkChannel,
  borderRadius: 30,
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.darkChannel,
  borderColor: theme.palette.primary.darkChannel,
  borderRadius: 30,
}));

export const PlainButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.darkChannel,
  borderRadius: 30,
}));
