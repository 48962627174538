import { EastRounded, EastSharp } from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    Input,
    ListItem,
    ListItemButton,
    Option,
    Select,
    Stack,
    Typography,
} from "@mui/joy";
import {
  logout,
} from "../../constants/AppImages";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import countryCode from "../../assets/jsons/country_codes.json";
import BackgroundRegister from "../../components/BackgroundRegister";
import { SolidButton } from "../../components/button/CustomButton";
import { RoleType, StorageType } from "../../constants/enum";
import { RequestOtpRarams, VerifyOtpRarams } from "../../constants/interfaces";
import "../../index.css";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";

interface CountryProps {
  name: string;
  dial_code: string;
  code: string;
}
const defaultCountry: CountryProps = {
  name: "",
  dial_code: "",
  code: "",
};
function VerifyPhoneNumber() {
  const navigate = useNavigate();
  const { authService, handleToggle, showMessage } = useApi();
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState<CountryProps>(defaultCountry);

  const [sendCode, setSendCode] = useState(false);
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState<any>(); //5 sec for countdown
  const { onLogout } = useAuth();

  const handleLogout = async () => {
    await onLogout();
    navigate("/");
  };

  const handleChange = async (e: any) => {
    setPhone(e.target.value);
  };
  const handleSenCode = async () => {
    if (!country) {
      showMessage("Please select your country!", "warning");
      return;
    }

    if (!phone) {
      showMessage("Please enter phone number!", "warning");
      return;
    }
    // const token = await grecaptcha.enterprise.execute('6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9', {action: 'OTP'});

    const params: RequestOtpRarams = {
      phone: phone,
      phoneCode: country.dial_code,
      phoneCountry: country.name,
      token: "",
    };
    const res = await authService.requestOtp(params);
    if (!res) {
      return;
    }
    setSendCode(true);
    setCounter(new Date().getTime() + 300000);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!code) {
      showMessage("Please enter OTP number!", "warning");
      return;
    }
    const params: VerifyOtpRarams = {
      phone: phone,
      phoneCode: country.dial_code,
      phoneCountry: country.name,
      code: code,
    };
    handleToggle(true);
    const res = await authService.verifyOty(params);
    if (!res) {
      handleToggle(false);
      return;
    }
    const role = localStorage.getItem(StorageType.role);
    setTimeout(() => {
      handleToggle(false);
      if (role && role === RoleType.lightster) {
        navigate(pagesName.lightster.aboutYou);
      } else if (role && role === RoleType.creator) {
        navigate(pagesName.creator.aboutYou);
      } else {
        navigate(pagesName.chooseRole);
      }
    }, 1000);
  };

  useEffect(() => {
    const f = countryCode.filter((ff) => ff.code === "US");
    setCountry(f[0] as CountryProps);

    const handleBeforeUnload = (event:any) => {
      localStorage.clear();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, []);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <BackgroundRegister>
        <Stack direction={"column"} mt={"10vh"}>
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                Phone verification
              </Typography>
              <Typography level="body-md" mt={1}>
                In order to keep the platform safe for everyone, please enter
                your phone number below.
              </Typography>
            </Box>
            <Box width={"100%"}>
              <Input
                fullWidth
                placeholder="Phone number"
                startDecorator={
                  <React.Fragment>
                    <Select
                      variant="plain"
                      value={country}
                      onChange={(_, value) => {
                        if (value) {
                          setCountry(value);
                        }
                      }}
                      slotProps={{
                        listbox: {
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        ml: -1.5,
                        "&:hover": { bgcolor: "transparent" },
                      }}
                    >
                      {countryCode.map((item) => (
                        <Option value={item} sx={{ width: 170 }}>
                          {item.name} ({item.dial_code})
                        </Option>
                      ))}
                    </Select>
                    <Divider orientation="vertical" />
                  </React.Fragment>
                }
                value={phone}
                onChange={handleChange}
              />
            </Box>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Button
                variant="soft"
                disabled={sendCode}
                endDecorator={<EastRounded fontSize="small" />}
                onClick={handleSenCode}
              >
                Send code
              </Button>

              {sendCode && (
                <Countdown
                  date={counter}
                  intervalDelay={1000}
                  renderer={({ hours, minutes, seconds }) => (
                    <Typography
                      fontStyle={"italic"}
                      fontSize={16}
                      fontWeight={400}
                    >
                      Code expires in {minutes}:{seconds}
                    </Typography>
                  )}
                  onComplete={() => {
                    setSendCode(false);
                    setCode("");
                  }}
                />
              )}
            </Stack>
            {sendCode && (
              <Box>
                <FormControl>
                  <FormLabel>Enter code</FormLabel>
                  <Input
                    autoFocus
                    fullWidth
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {sendCode && (
              <SolidButton
                variant="solid"
                size="lg"
                endDecorator={<EastSharp fontSize="small" />}
                sx={{ maxWidth: 128 }}
                onClick={handleSubmit}
                onSubmit={handleSubmit}
                type="submit"
              >
                Next
              </SolidButton>
            )}
          </Stack>
        </Stack>
        <Box
        position="absolute"
        top={"93vh"}
        right={"56vw"}
        p={2}
        >
        <ListItem sx={{ padding: 0 }}>
          <ListItemButton color="primary" onClick={handleLogout}>
            <Box>
              <Box display={"inline-flex"} gap={1}>
                <Typography level="body-sm" color="primary" noWrap>
                  Logout
                </Typography>
                <img width={17} src={logout} alt="icons-logout" />
              </Box>
            </Box>
          </ListItemButton>
        </ListItem>
        </Box>
      </BackgroundRegister>
    </form>
  );
}

export default VerifyPhoneNumber;
