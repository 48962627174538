import { Box, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { CreatorHomeStep } from "../../constants/enum";
import { Criteria } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import { OpenFieldName, usePlanDialog } from "../../providers/PlanPovider";
import CommunityDetail from "./community/CommunityDetail";
import CommunitySetting from "./community/CommunitySetting";
import CreateCommunity from "./community/CreateCommunity";
import AddCriteria from "./criteria/AddCriteria";
import EditCriteria from "./criteria/EditCriteria";
import { StorageType } from "../../constants/enum";

interface Props {}

function CreatorHome(props: Props) {
  const {} = props;
  const { user,onClientOpen } = useAuth();
  const { showMessage, userService } = useApi();
  const { handleShowDialog, handleFlowUpgrade } = usePlanDialog();
  const [communityId, setCommunityId] = useState<string | null>(null);
  const [step, setStep] = useState<string>();
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [basicWarning, setBasicWarning] = useState(false);
  const [basicActivated, setBasicActivated] = useState(false);
  const [noCommunity, setNoCommunity] = useState(false);
  const [edit, setEdit] = useState<Criteria>();
  const [criteria, setCriteria] = useState<Criteria>();

  const getCreatorHome = async () => {
    const res = await userService.getCreatorHome();
    if (!res) return;
    setBasicWarning(res.basicWarning);
    setBasicActivated(res.basicActivated);
  };

  useEffect(() => {
    if(window.parent !== window)
      { 
        window.parent.postMessage({
          type: 'auth-success',
          token: localStorage.getItem(StorageType.token),
        }, '*'); 
      } else{
        onClientOpen();
        getCreatorHome();
        setStep(CreatorHomeStep.COMMUNITYDETAIL);
      }
  }, []);
  useEffect(() => {
    if (basicActivated) {
      handleShowDialog(OpenFieldName.ACTIVATED, true);
    }
    if (basicWarning) {
      handleShowDialog(OpenFieldName.WARNING, true);
    }
  }, [basicActivated, basicWarning]);

  useEffect(() => {
    getCreatorHome();
  }, []);

  useEffect(() => {
    if (!step) return;
    if (
      [
        CreatorHomeStep.ADDCRITERIA,
        CreatorHomeStep.EDITCRITERIA,
        CreatorHomeStep.COMMUNITYSETTING,
        CreatorHomeStep.COMMUNITYNAME,
      ].includes(step as CreatorHomeStep)
    ) {
      document.title = `Lightster - Create a community`;
    }

    return () => {
      document.title = "Lightster";
    };
  }, [step]);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "calc(100vh - 64px)",
        }}
      >
        {step === CreatorHomeStep.COMMUNITYDETAIL && (
          <CommunityDetail
            communityId={communityId}
            startWithCriteria={(value) => {
              setNoCommunity(value);
              setStep(CreatorHomeStep.ADDCRITERIA);
            }}
          />
        )}
        {step === CreatorHomeStep.ADDCRITERIA && (
          <Box className="home" sx={{ overflow: "scroll" }}>
            <AddCriteria
              criteria={criteria}
              clearCustom={() => {
                setCriteria(undefined);
                setStep(CreatorHomeStep.COMMUNITYSETTING);
              }}
              showHeader={true}
              noCommunity={noCommunity}
              onSaveNewCommunity={(value: Criteria) => {
                if (
                  value.profileId &&
                  criterias.map((e) => e.profileId).includes(value.profileId)
                ) {
                  showMessage("Cannot add duplicate criteria", "warning");
                  return;
                }
                setCriteria(undefined);
                setCriterias([...criterias, value]);
                setStep(CreatorHomeStep.COMMUNITYSETTING);
              }}
              goBack={() => {
                if (criterias.length > 0) {
                  setStep(CreatorHomeStep.COMMUNITYSETTING);
                  return;
                }
                setStep(CreatorHomeStep.COMMUNITYDETAIL);
              }}
            />
          </Box>
        )}
        {step === CreatorHomeStep.COMMUNITYSETTING && (
          <CommunitySetting
            criterias={criterias}
            onSubmit={() => {
              if (criterias.length === 0) {
                showMessage("Please add criteria.", "warning");
                return;
              }
              setStep(CreatorHomeStep.COMMUNITYNAME);
            }}
            onAddCriteria={(value: Criteria | null) => {
              if (user.memberPlan === "Free" && criterias.length >= 3) {
                handleFlowUpgrade(true);
                handleShowDialog(OpenFieldName.UPGRADE_ADD_MORE, true);
                return;
              }
              if (value) {
                setCriteria(value);
              }
              setStep(CreatorHomeStep.ADDCRITERIA);
            }}
            onEdit={(value) => {
              setEdit(value);
              setStep(CreatorHomeStep.EDITCRITERIA);
            }}
            goBack={() => {
              setCriterias([]);
              setStep(CreatorHomeStep.COMMUNITYDETAIL);
            }}
          />
        )}
        {step === CreatorHomeStep.COMMUNITYNAME && (
          <CreateCommunity
            criterias={criterias}
            onSubmit={(value) => {
              setCriterias([]);
              setCommunityId(value);
              setStep(CreatorHomeStep.COMMUNITYDETAIL);
            }}
          />
        )}
        {step === CreatorHomeStep.EDITCRITERIA && (
          <Box className="home" sx={{ minHeight: "80vh" }}>
            <Stack gap={2}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box className="primary-circle">
                  <Typography level="h3" sx={{ color: "white" }}>
                    1
                  </Typography>
                </Box>
                <Typography level="h3">Set your user criteria</Typography>
              </Box>
              <Typography level="body-lg" fontWeight={500}>
                To get matched with users, set the criteria that defines your
                user segment.
              </Typography>
              <EditCriteria
                criteria={edit}
                onSave={(value) => {
                  for (let index = 0; index < criterias.length; index++) {
                    const element = criterias[index];
                    if (element.profileId === value.profileId) {
                      element.answers = value.answers;
                    }
                  }
                  setStep(CreatorHomeStep.COMMUNITYSETTING);
                }}
                onClose={() => setStep(CreatorHomeStep.COMMUNITYSETTING)}
                onDelete={(value: Criteria) => {
                  const newValue = criterias.filter(
                    (element) => element.profileId !== value.profileId
                  );
                  setCriterias(newValue);
                  setStep(CreatorHomeStep.COMMUNITYSETTING);
                }}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default CreatorHome;
