import { useEffect } from 'react';
import {
  Modal,
  ModalDialog,
  Stack,
  Typography
} from "@mui/joy";
import { SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: () => void;
}

function PlanSuccessDialog(props: Props) {
  const { open, onClose } = props;
  const utm = localStorage.getItem("utm");

  // Adjusted useEffect hook to manage the script addition and removal
  useEffect(() => {
    let script: HTMLScriptElement;
    if (utm === "softwareselect") {
      script = document.createElement("script");
      script.src = "https://trk.crozdesk.com/Tzyzy7vJPydNU2S5ZyC7";
      script.async = true;
      document.body.appendChild(script);
    }

    // Cleanup function to remove script when component unmounts or condition no longer met
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [utm]); // Dependency array to re-run effect if `utm` changes

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        size="lg"
      >
        <Typography id="modal-title" level="h3" sx={{ fontSize: 24 }}>
          Thank you!
        </Typography>
        <Typography id="modal-desc" level="body-md">
          You have subscribed to the{" "}
          <Typography level="title-md">Premium Plan</Typography>. A confirmation
          has been sent to your e-mail.
        </Typography>
        {utm == "gartner" &&
          <img
            src="https://ct.capterra.com/capterra_tracker.gif?vid=2316620&vkey=dda314c841b9172231a4adcff70733e2"
            alt="capterra"
            style={{ display: "none" }}
          />
        }
        {/* Removed direct function call from JSX */}
        <Stack direction="row-reverse">
          <SolidButton variant="solid" color="primary" onClick={onClose}>
            Done
          </SolidButton>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default PlanSuccessDialog;
