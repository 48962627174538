import { CheckCircleRounded, EastRounded } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/joy";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { SolidButton } from "../../../components/button/CustomButton";
import { useAuth } from "../../../providers/AuthProvider";

interface Props {}

function LightsterRegisterSuccess(props: Props) {
  const {} = props;
  const { user } = useAuth();

  const getTextDisplayName = () => {
    return user.displayName;
  };

  const handleNavigate = () => {
    window.location.replace(pagesName.lightster.home);
  };
  return (
    <BackgroundRegister>
      <Stack direction={"column"} mt={"10vh"}>
        <CheckCircleRounded
          sx={{ color: "#48BB78", fontSize: "80px", mb: 3 }}
        />

        <Stack direction={"column"} spacing={3}>
          <Box>
            <Typography level="h2" fontSize={28}>
              That’s it!
            </Typography>
            <Typography level="body-lg" mt={1}>
              Welcome to Lightster, {getTextDisplayName()}! You are now ready to
              start connecting with Creators.
            </Typography>
          </Box>
          <Typography level="body-lg">
            Watch your e-mail for notifications on your invitations and
            qualifying questions.
          </Typography>
        </Stack>
        <Box mt={6}>
          <SolidButton
            variant="solid"
            endDecorator={<EastRounded fontSize="small" />}
            sx={{ width: 180, textTransform: "inherit" }}
            onClick={handleNavigate}
          >
            Next
          </SolidButton>
        </Box>
      </Stack>
    </BackgroundRegister>
  );
}

export default LightsterRegisterSuccess;
