import { EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";

interface Props {}

function CreatorPlanDetail(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(pagesName.creator.planPayment, { state: state });
  };

  return (
    <BackgroundRegister>
      <form noValidate onSubmit={handleSubmit}>
        <Stack width={"100%"} direction={"column"}>
          <Box height={180}>
            <BorderLinearProgress determinate value={100} sx={{ mt: 10 }} />
          </Box>
          <Stack direction={"column"} spacing={4}>
            <Typography>Review your plan</Typography>
            <Box className="plan-review">
              <Stack spacing={3}>
                <Typography>
                  {state === "standard" ? "Co-create" : "Flex"}
                </Typography>
                <Box>
                  <Typography sx={{ fontSize: "16px " }}>
                    Plan Details
                  </Typography>
                  <Box pl={2}>
                    {(state === "basic"
                      ? flexPlan.detail
                      : standardPlan.detail
                    ).map((d) => (
                      <Typography display={"list-item"}>{d}</Typography>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px " }}>Price</Typography>
                  <Box pl={2}>
                    {(state === "basic"
                      ? flexPlan.price
                      : standardPlan.price
                    ).map((d) => (
                      <Typography display={"list-item"}>{d}</Typography>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px " }}>
                    Other Benefits:
                  </Typography>
                  <Box pl={2}>
                    {(state === "basic"
                      ? flexPlan.benefits
                      : standardPlan.benefits
                    ).map((d) => (
                      <Typography display={"list-item"}>{d}</Typography>
                    ))}
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={10}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ minWidth: 128 }}
              type="submit"
              autoFocus
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </form>
    </BackgroundRegister>
  );
}

const standardPlan = {
  detail: [
    "Free 90 minutes for live sessions (promo)",
    "5 live sessions included monthly",
    "Unlimited chat conversations",
    "Offer a product deal to users as a reward",
  ],
  price: [
    "$200 monthly, charged to your credit card on the 1st of every month",
  ],
  benefits: [
    "Unused minutes get carried over",
    "Additional live sessions are $1.25 per minute",
    "Sessions where you offer free access to your product as a reward don’t count towards the included live sessions",
  ],
};

const flexPlan = {
  detail: [
    "Unlimited chat conversations",
    "Offer your product as a reward for free",
  ],
  price: ["$50 monthly, charged to your credit card on the 1st of every month"],
  benefits: ["Sessions are only $1.50 per minute"],
};

export default CreatorPlanDetail;
