import {
    Box,
    Modal,
    ModalDialog,
    Stack,
    Typography
} from "@mui/joy";
import { plan01 } from "../../../constants/AppImages";
import { useAuth } from "../../../providers/AuthProvider";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  displayName: string;
}

function TrialEndedDialog(props: Props) {
  const { open, onCancel, onSubmit, displayName } = props;
  const { user } = useAuth();
  const getTextWhatYouGet = () => {
    return [
      "Unlimited criteria to segment users",
      "Add your own screening questions",
      "No limit on direct messages to users",
      "Set your own rewards to users",
      "Save 20% per transaction",
    ];
  };

  return (
    <Modal open={open}>
      <ModalDialog size="lg">
        <Stack direction="column" spacing={4}>
          <Stack direction={"column"} alignItems="center">
            <img src={plan01} alt="trial ended plan" width={83} />
            <Typography level="h3" fontWeight={700}>
              Premium Plan{" "}
            </Typography>
            <Typography level="h3" fontWeight={400}>
              trial period ended
            </Typography>
          </Stack>
          <Typography level="body-lg">
            {displayName}, we hope you enjoyed the benefits of the premium plan.
            Tap next to continue enjoying the benefits!
          </Typography>
          <Stack direction={"column"}>
            <Typography level="title-lg" fontWeight={700}>
              What you get for just $50 a month
            </Typography>
            <Box pl={3}>
              {getTextWhatYouGet().map((item) => (
                <Typography
                  key={item}
                  level="body-lg"
                  sx={{ display: "list-item" }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Stack>
          <Stack direction={"row"} justifyContent="end" spacing={2}>
            <PlainButton variant="plain" onClick={onCancel}>
              Cancel
            </PlainButton>
            <SolidButton variant="solid" onClick={onSubmit}>
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default TrialEndedDialog;
