import React, { useState } from "react";
import ActivatedPlanDialog from "../components/dialog/plan/ActivatedPlanDialog";
import CancelPlanDialog from "../components/dialog/plan/CancelPlanDialog";
import CancelPlanSuccessDialog from "../components/dialog/plan/CancelPlanSuccessDialog";
import PlanDetailDialog from "../components/dialog/plan/PlanDetailDialog";
import PlanPayment from "../components/dialog/plan/PlanPayment";
import PlanSuccessDialog from "../components/dialog/plan/PlanSuccessDialog";
import TrialEndedDialog from "../components/dialog/plan/TrialEndedDialog";
import UpgradeToViewUserDialog from "../components/dialog/plan/UpgradeToViewUserDialog";
import UpgradeWithCustomContentDialog from "../components/dialog/plan/UpgradeWithCustomContentDialog";
import { StorageType } from "../constants/enum";

export class OpenFieldName {
  static WARNING = "basicWarning";
  static ACTIVATED = "basicActivated";
  static UPGRADE_ADD_MORE = "upgradeToAddMore";
  static UPGRADE_CUSTTOM = "upgradeToAddCustom";
  static UPGRADE_VIEW_USER = "upgradeToViewUsers";
  static PLAN_DETAIL = "planDetail";
  static PLAN_PAYMENT = "planPayment";
  static PLAN_SUCCESS = "planSuccess";
  static CANCEL_PLAN = "cancelPlan";
  static CANCEL_SUCCESS = "cancelPlanSuccess";
}

interface PlanDialogContextType {
  handleFlowUpgrade: (value: boolean) => void;
  showUpgradeToAddMore: () => void;
  showUpgradeToAddCustom: () => void;
  handleShowDialog: (field: string, value: boolean) => void;
}
const PlanDialogContext = React.createContext<PlanDialogContextType | null>(
  null
);

export const usePlanDialog = () => {
  return React.useContext(PlanDialogContext) as PlanDialogContextType;
};

interface PlanDialogProviderProps {
  children: JSX.Element;
}
export const PlanDialogProvider = ({ children }: PlanDialogProviderProps) => {
  const user = JSON.parse(`${localStorage.getItem(StorageType.user)}`);
  const [flowUpgrade, setFlowUpgrade] = useState(false);

  const [open, setOpen] = useState({
    basicWarning: false,
    basicActivated: false,
    upgradeToAddMore: false,
    upgradeToAddCustom: false,
    upgradeToViewUsers: false,
    planDetail: false,
    planPayment: false,
    planSuccess: false,
    cancelPlan: false,
    cancelPlanSuccess: false,
  });

  const handleFlowUpgrade = (value: boolean) => {
    setFlowUpgrade(value);
  };
  const showUpgradeToAddMore = () => {
    setFlowUpgrade(true);
    setOpen({ ...open, upgradeToAddMore: true });
  };
  const showUpgradeToAddCustom = () => {
    setFlowUpgrade(true);
    setOpen({ ...open, upgradeToAddCustom: true });
  };

  const handleShowDialog = (field: string, value: boolean) => {
    setOpen({ ...open, [field]: value });
  };
  const value: PlanDialogContextType = {
    handleFlowUpgrade: handleFlowUpgrade,
    showUpgradeToAddMore: showUpgradeToAddMore,
    showUpgradeToAddCustom: showUpgradeToAddCustom,
    handleShowDialog: handleShowDialog,
  };

  return (
    <PlanDialogContext.Provider value={value}>
      {children}
      <TrialEndedDialog
        displayName={user?.displayName ?? ""}
        open={open.basicWarning}
        onCancel={() =>
          setOpen({ ...open, basicWarning: false, cancelPlan: true })
        }
        onSubmit={() =>
          setOpen({ ...open, basicWarning: false, planDetail: true })
        }
      />
      <ActivatedPlanDialog
        displayName={user?.displayName ?? ""}
        open={open.basicActivated}
        onClose={() => setOpen({ ...open, basicActivated: false })}
      />
      <UpgradeToViewUserDialog
        open={open.upgradeToViewUsers}
        onClose={() => setOpen({ ...open, upgradeToViewUsers: false })}
        onSubmit={() =>
          setOpen({ ...open, upgradeToViewUsers: false, planDetail: true })
        }
      />
      <PlanDetailDialog
        open={open.planDetail}
        onClose={() => {
          if (flowUpgrade) {
            setOpen({ ...open, planDetail: false });
            setFlowUpgrade(false);
          } else {
            setOpen({ ...open, basicWarning: true, planDetail: false });
          }
        }}
        onSubmit={() =>
          setOpen({ ...open, planDetail: false, planPayment: true })
        }
      />
      {open.planPayment && (
        <PlanPayment
          open={open.planPayment}
          onClose={() => {
            setOpen({ ...open, planDetail: true, planPayment: false });
          }}
          onSubmit={() =>
            setOpen({ ...open, planPayment: false, planSuccess: true })
          }
        />
      )}
      <PlanSuccessDialog
        open={open.planSuccess}
        onClose={() => {
          setOpen({ ...open, planSuccess: false });
          window.location.reload();
        }}
      />
      <CancelPlanDialog
        open={open.cancelPlan}
        goBack={() => {
          setOpen({ ...open, cancelPlan: false, basicWarning: true });
        }}
        onSubmit={() =>
          setOpen({ ...open, cancelPlan: false, cancelPlanSuccess: true })
        }
      />
      <CancelPlanSuccessDialog
        open={open.cancelPlanSuccess}
        onClose={() => setOpen({ ...open, cancelPlanSuccess: false })}
      />
      <UpgradeWithCustomContentDialog
        title={open.upgradeToAddCustom ? "Upgrade to add" : "Upgrade to add"}
        contentUpgrade={
          open.upgradeToAddCustom
            ? "You can add a custom criteria such as a screening question (multiple choice or text responses) on the"
            : "To add more criteria to this community, upgrade to the"
        }
        open={open.upgradeToAddCustom || open.upgradeToAddMore}
        onClose={() => {
          if (open.upgradeToAddCustom) {
            setOpen({ ...open, upgradeToAddCustom: false });
          } else {
            setOpen({ ...open, upgradeToAddMore: false });
          }
        }}
        onSubmit={() => {
          if (open.upgradeToAddCustom) {
            setOpen({ ...open, upgradeToAddCustom: false, planDetail: true });
          } else {
            setOpen({ ...open, upgradeToAddMore: false, planDetail: true });
          }
        }}
      />
    </PlanDialogContext.Provider>
  );
};
