import {
  AddCircle,
  ArrowDropDown
} from "@mui/icons-material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { TimerOutline } from "react-ionicons";

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dropdown,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import {
  OutlinedButton,
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import CommunitySettingDialog from "../../../components/dialog/CommunitySettingDialog";
import { DeleteCommunityDialog } from "../../../components/dialog/DeleteCommunityDialog";
import { RenameCommunityDialog } from "../../../components/dialog/RenameCommunityDialog";
import { placeholder } from "../../../constants/AppImages";
import {
  CommunityDisplay,
  Criteria,
  Member,
  Setting,
} from "../../../constants/interfaces";
import { primaryPurpleDark } from "../../../joy-styles";
import { useApi } from "../../../providers/ApiProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { OpenFieldName, usePlanDialog } from "../../../providers/PlanPovider";
import { StorageType } from "../../../constants/enum";

interface Props {
  communityId: string | null;
  startWithCriteria: (value: boolean) => void;
}
function createData(
  id: string,
  name: string,
  imamge: string,
  subDisplayName: string
) {
  return { id, name, imamge, subDisplayName };
}

function CommunityDetail(props: Props) {
  const { communityId, startWithCriteria } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { communityService, showMessage } = useApi();
  const { user } = useAuth();
  const { handleShowDialog, handleFlowUpgrade } = usePlanDialog();
  const [open, setOpen] = useState({
    rename: false,
    delete: false,
    update: false,
  });
  const [members, setMembers] = useState<Member[]>([]);
  const [setting, setSetting] = useState<Setting>();
  const [currentComm, setCurrentComm] = useState<string | null>(null);
  const [communityName, setCommunityName] = useState<string>("");
  const [communities, setCommunities] = useState<CommunityDisplay[]>([]);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const [bottonHeight, setButtonHeight] = useState(0);
  const topHeight = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [clickedRowIds, setClickedRowIds] = useState<string[]>([]);


  const getCommunityMembers = async (commId: string) => {
    const res = await communityService.getCommunityMembers(commId);
    if (!res) return;
    setMembers(res.data);
  };
  const getCommunitySetting = async (commId: string) => {
    const res = await communityService.getCommunitySetting(commId);
    if (!res) return;
    setSetting(() => res);
  };
  const getMyCommunity = async () => {
    setLoading(true);
    const res: CommunityDisplay[] = await communityService.getMyCommunities();
    if (!res) return;

    if (res.length === 0) {
      startWithCriteria(true);
      return;
    }
    setCommunities(res);
    const localCommu = JSON.parse(`${localStorage.getItem(StorageType.community)}`);
    if (communityId) {
      const data = res.find((f) => f.id == communityId);
      setCurrentComm(data?.id ?? "");
      setCommunityName(data?.name ?? "");
      localStorage.setItem(StorageType.community, JSON.stringify({ ...data }));
    } else if (location.state?.commId) {
      const data = res.find((f) => f.id == location.state?.commId);
      setCurrentComm(data?.id ?? "");
      setCommunityName(data?.name ?? "");
      localStorage.setItem(StorageType.community, JSON.stringify({ ...data }));
    }
    else if (currentComm) {
      const data = res.find((f) => f.id == currentComm);
      setCurrentComm(data?.id ?? "");
      setCommunityName(data?.name ?? "");
      localStorage.setItem(StorageType.community, JSON.stringify({ ...data }));
    } else if (localCommu) {
      const data = res.find((f) => f.id == localCommu.id);
      setCurrentComm(data?.id ?? "");
      setCommunityName(data?.name ?? "");
      localStorage.setItem(StorageType.community, JSON.stringify({ ...data }));
    }
    else {
      setCurrentComm(res[0].id);
      setCommunityName(res[0].name);
      localStorage.setItem(StorageType.community, JSON.stringify({ ...res[0] }));
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleRenameCommunity = async (value: string) => {
    if (!currentComm) return;
    setLoading(true);
    const res = await communityService.renameCommunity(currentComm, value);
    setTimeout(() => {
      setLoading(false);
      if (!res) return;
      showMessage("You've successfully renamed the community", "success");
      getMyCommunity();
    }, 1000);
  };

  const handleDeletCommunity = async () => {
    if (!currentComm) return;
    setLoading(true);
    const res = await communityService.deleteCommunity(currentComm);
    setLoading(false);
    showMessage("Delete community success!", "success");

    const newCommunities = communities.filter((f) => f.id !== currentComm);
    setCommunities(newCommunities);
    if (newCommunities.length > 0) {
      setCurrentComm(newCommunities[0].id);
      setCommunityName(newCommunities[0].name);
      localStorage.setItem(StorageType.community, JSON.stringify({ ...newCommunities[0] }));
      await getCommunitySetting(newCommunities[0].id);
      await getCommunityMembers(newCommunities[0].id);
    } else {
      startWithCriteria(true);
    }
  };

  const getAllCriteria = () => {
    return [...(setting?.criterias ?? []), ...(setting?.customSettings ?? [])];
  };
  const criteriaEmpty = () => {
    return (
      (setting?.criterias ?? []).length === 0 &&
      (setting?.customSettings ?? []).length === 0
    );
  };
  const isPublic = () => {
    return setting?.type === "public";
  };
  const isDynamic = () => {
    return setting?.type === "private" && setting.setting === "dynamic";
  };
  const isStatic = () => {
    return setting?.type === "private" && setting.setting === "static";
  };
  const isAllCommunity = () => {
    return setting?.type === "private" && setting.setting === "static" && setting.name === "All users";
  };
  const memberPlanFree = () => {
    return user.memberPlan === "Free";
  };

  const showUpdateViewUsers = () => {
    if (
      memberPlanFree() &&
      isPublic() &&
      (setting?.customSettings ?? []).length > 0
    ) {
      return true;
    } else if (
      memberPlanFree() &&
      isPublic() &&
      (setting?.customSettings ?? []).length === 0
    ) {
      return false;
    } else if (
      memberPlanFree() &&
      isDynamic() &&
      (setting?.customSettings ?? []).length > 3
    ) {
      return true;
    } else {
      return false;
    }
  };

  const refreshCommunity = async (id: string) => {
    await communityService.refreshCommunity(id);
  };
  const getStartCommunity = async (id: string) => {
    setLoading(true);
    await getCommunitySetting(id);
    await getCommunityMembers(id);
    await refreshCommunity(id);
    setLoading(false);
  };
  useEffect(() => {
    getMyCommunity();
    if (communityId || location.state?.commId) {
      setCurrentComm(communityId ?? location.state.commId);
    }
  }, []);

  useEffect(() => {
    if (!currentComm) return;
    getStartCommunity(currentComm);
    document.title = `Lightster - ${communities.find((ele) => ele.id === currentComm)?.name
      }`;
    setHeight(topHeight?.current?.offsetHeight ?? 0);
  }, [currentComm]);

  useEffect(() => {
    if (communities.length > 0) {
      document.title = `Lightster - ${communities.find((ele) => ele.id === currentComm)?.name
        }`;
    }

    return () => {
      document.title = "Lightster";
    };
  }, [communities]);

  useLayoutEffect(() => {
    function handleResize() {
      if (topHeight.current) {
        const height = topHeight.current.offsetHeight;
        setHeight(height);
      }
      if (buttonRef.current) {
        const btHeight = buttonRef.current.offsetHeight;
        setButtonHeight(btHeight);
      }
    }

    handleResize(); // initial call to get width and height of the element
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [topHeight, buttonRef]);

  const handleRowClick = (id: string) => {
    // Check if the row has already been clicked
    if (clickedRowIds.includes(id)) {
      // If yes, remove it from the array
      setClickedRowIds(clickedRowIds.filter(rowId => rowId !== id));
    } else {
      // If not, add it to the array
      setClickedRowIds([...clickedRowIds, id]);
    }
    // You may want to keep the navigation logic here as well
    window.open(`${pagesName.creator.viewProfile}/${id}/${currentComm}`);
  };


  return (
    <Box className="home" height="100%" overflow={"auto"}
      sx={{
        //height:"200vh" ,
        /*
        width:{
          xs:"auto",
        },
        boxSizing: {xs:'border-box',},
       
        overflow:{xs:"overflow"},
        */
      }}>
      {loading ? (
        <Stack
          direction={"column"}
          justifyContent="center"
          alignItems={"center"}
          //height={"150vh"}
          height={"100%"}
        >
          <CircularProgress size="md" />
        </Stack>
      ) : (
        <Box >
          <Box ref={topHeight}>
            <Stack width={"100%"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  sx={{
                    borderBottom: `3px solid ${primaryPurpleDark}`,
                    maxWidth: 400,
                  }}
                >
                  <Dropdown>
                    <MenuButton
                      className="menu-button"
                      variant="plain"
                      sx={{
                        padding: 0,
                        textAlign: "start",
                        justifyContent: "flex-start",
                        ":hover": {
                          background: "#FFF",
                        },
                      }}
                    >
                      <Stack direction={"row"} gap={1} alignItems="center">
                        <Typography
                          level="h3"
                          sx={{
                            textAlign: "start",
                            fontSize: 24,
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                          }}
                        >
                          {communities.find((f) => f.id === currentComm)?.name}
                        </Typography>
                        <ArrowDropDown />
                      </Stack>
                    </MenuButton>
                    <Menu placement="bottom-start" sx={{ maxWidth: 400, maxHeight: '50vh' }}>
                      {communities.map((community) => (
                        <MenuItem
                          key={community.id}
                          color="primary"
                          selected={community.id === currentComm}
                          onClick={() => {
                            setCommunityName(community.name);
                            setCurrentComm(community.id);
                            localStorage.setItem(StorageType.community, JSON.stringify({ ...community }));
                          }}
                        >
                          {community.name}
                        </MenuItem>
                      ))}
                      <MenuItem
                        color="primary"
                        onClick={() => startWithCriteria(false)}
                      >
                        <ListItemDecorator sx={{ paddingRight: "0.75rem" }}>
                          <AddCircle />
                        </ListItemDecorator>
                        Create
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </Box>
                <Stack direction="row" gap={2} alignItems="center">
                  {!isStatic() && (
                    <PlainButton
                      className="update-button"
                      size="sm"
                      variant="plain"
                      onClick={() => {
                        if (
                          memberPlanFree() &&
                          isPublic() &&
                          (setting?.customSettings ?? []).length === 0
                        ) {
                          handleFlowUpgrade(true);
                          handleShowDialog(
                            OpenFieldName.UPGRADE_ADD_MORE,
                            true
                          );
                          return;
                        }
                        setOpen({ ...open, update: true });
                      }}
                      sx={{
                        padding: "10px 16px !important",
                        ":disabled": {
                          color: (theme) => theme.palette.neutral[200],
                        },
                      }}
                      disabled={
                        showUpdateViewUsers() || setting?.setting === "product"
                      }
                    >
                      Update criteria
                    </PlainButton>
                  )}
                  {!isPublic() && (
                    <PlainButton
                      className="rename-button"
                      size="sm"
                      variant="plain"
                      onClick={() => setOpen({ ...open, rename: true })}
                      sx={{
                        padding: "10px 16px !important",
                        ":disabled": {
                          color: (theme) => theme.palette.neutral[200],
                        },
                      }}
                      disabled={setting?.setting === "product" || setting?.name === "All users"}
                    >
                      Rename
                    </PlainButton>
                  )}
                  <PlainButton
                    size="sm"
                    variant="plain"
                    onClick={() => setOpen({ ...open, delete: true })}
                    sx={{
                      padding: "10px 16px !important",
                      ":disabled": {
                        color: (theme) => theme.palette.neutral[200],
                      },
                    }}
                    disabled={setting?.setting === "product" || setting?.name === "All users"}
                  >
                    Delete
                  </PlainButton>
                </Stack>
              </Stack>
            </Stack>
            <Stack width={"100%"} mt={1}>
              <Stack spacing={1} direction="row" className="ellipsis">
                {
                  getAllCriteria().map((c: Criteria, i: number) => (
                    <Stack
                      key={c.profileId + i.toString()}
                      direction={"row"}
                      alignItems="center"
                      gap={1}
                      maxWidth={"100%"}
                    >
                      <Typography level="body-md" noWrap style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {" "}
                        <span style={{ textDecoration: 'underline' }}>{c.title}</span>: {c.answers?.join(", ")}
                      </Typography>
                      {i + 1 !== getAllCriteria().length && (
                        <Box
                          height={"16px"}
                          width="2px"
                          sx={{ bgcolor: "#CBD5E0" }}
                        />
                      )}
                    </Stack>
                  ))
                }
                {setting?.setting === "product" && (
                  <Typography level="body-md">
                    People who have expressed interest in your product based on
                    your company profile.
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Box>
          <Stack position={"relative"} width="100%">
            <Stack ref={buttonRef} direction={"row"} gap={1} mt={"8px"}>
              <OutlinedButton
                variant="outlined"
                sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                onClick={() =>
                  navigate(pagesName.creator.schedule, {
                    state: { communityId: currentComm },
                  })
                }
              >
                Schedule a live conversation
              </OutlinedButton>
              <OutlinedButton
                variant="outlined"
                sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                onClick={() =>
                  navigate(pagesName.creator.unmoderated, {
                    state: { communityId: currentComm },
                  })
                }
              >
                Start a test
              </OutlinedButton>
              <OutlinedButton
                variant="outlined"
                sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                onClick={() =>
                  navigate(pagesName.creator.survey, {
                    state: { communityId: currentComm },
                  })
                }
              >
                Send a survey
              </OutlinedButton>
              <OutlinedButton
                variant="outlined"
                sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                onClick={() =>
                  navigate(pagesName.creator.sendMessage, {
                    state: { communityId: currentComm },
                  })
                }
              >
                Send a message
              </OutlinedButton>
            </Stack>
            <Box
              className="table-users"
              sx={{
                //maxHeight: `calc(90vh - ${height + bottonHeight + 64}px)`,
                //minWidth: "600px", 
                //maxHeight: 'calc(90vh - 64px)',
                //maxHeight: '90vh',
                overflowY: 'auto',
                height: '100%',
                //display: 'flex',
                //flexDirection: 'column',
                //maxWidth: '100%'
              }}
            >
              {members.length !== 0 ? (
                <Table
                  borderAxis="none"
                  color="neutral"
                  size="lg"
                  variant="plain"
                >
                  <tbody>
                    {(setting?.type === "public" &&
                      (setting?.customSettings ?? []).length > 0
                      ? members.filter((e) => e.isBestMatch)
                      : members
                    ).map((row) => (
                      <tr
                        key={row.id}
                        onClick={() => handleRowClick(row.id)}
                      >
                        <td width={250}>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "transparent",
                                width: 30,
                                height: 30,
                              }}
                              src={row.imageProfile ?? placeholder}
                            >
                              <img
                                src={placeholder}
                                height="100%"
                                width="100%"
                              />
                            </Avatar>
                            <Typography level="title-sm" sx={{ fontWeight: row.new && !clickedRowIds.includes(row.id) ? 'bold' : 'normal' }}>
                              {row.displayName}
                            </Typography>
                          </Stack>
                        </td>
                        {row.matchList.slice(0, 3).map((match, i) => (
                          <td key={i} style={{ fontWeight: row.new && !clickedRowIds.includes(row.id) ? 'bold' : 'normal' }}>{match}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : criteriaEmpty() ? (!isStatic() ? (
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Box className="box-column" sx={{ mt: 20, gap: 4 }}>
                    <Typography level="h3">No criteria set</Typography>
                    <Typography level="body-md" sx={{ textAlign: "center" }}>
                      Add a criteria to see users that are relevant to your
                      product or project. Choose from a variety of demographics,
                      occupation, and behaviours or create your own custom
                      criteria.
                    </Typography>
                    <Button
                      variant="solid"
                      color="primary"
                      sx={{ width: 276 }}
                      onClick={() => setOpen({ ...open, update: true })}
                    >
                      Set criteria
                    </Button>
                  </Box>
                </Stack>)
                : (
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    {isAllCommunity() ? (
                      <Box className="box-column" sx={{ mt: 20, gap: 4 }}>
                        <Typography level="h3">You don't have any users yet</Typography>
                        <Typography level="body-md" sx={{ textAlign: "center" }}>
                        This list displays all users from all your communities. Create a community by clicking on the top left menu and adding your desired criteria.
                        </Typography>
                      </Box> ) : (
                      <Box className="box-column" sx={{ mt: 20, gap: 4 }}>
                        <Typography level="h3">No users added</Typography>
                        <Typography level="body-md" sx={{ textAlign: "center" }}>
                          You can choose specific users to add to this community from any of your communities. Adding users to this list can be done by visiting the mobile app and going to Community Settings.
                        </Typography>
                      </Box> )
                    }

                  </Stack>
                )
              ) : (
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Box maxWidth={620}>
                    <Box className="box-column" sx={{ mt: 20 }}>
                      <TimerOutline width={"70px"} height={"70px"} />
                      <Typography level="h3">Hang on tight...</Typography>
                    </Box>
                    <Typography
                      level="body-md"
                      sx={{ mt: 4, textAlign: "center" }}
                    >
                      There are no users that match your criteria yet, please
                      give it a few minutes! A match usually gets back within a
                      few minutes to a few hours.
                    </Typography>
                    <Typography
                      level="body-md"
                      sx={{ mt: 4, textAlign: "center" }}
                    >
                      If you’d like a faster response time, e-mail our team at
                      support@lightster.co for help!
                    </Typography>
                  </Box>
                </Stack>
              )}
              {showUpdateViewUsers() && (
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "#ffffffc7",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #000",
                      borderRadius: 12,
                      p: 4,
                      width: 480,
                      bgcolor: "#FFF",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <Typography level="h3">Upgrade to view users</Typography>
                    <Typography level="body-lg">
                      This community contains more than 3 criteria, which is
                      only available on the{" "}
                      <Typography level="title-lg">Premium Plan.</Typography>
                    </Typography>
                    <SolidButton
                      onClick={() => {
                        handleFlowUpgrade(true);
                        handleShowDialog(OpenFieldName.PLAN_DETAIL, true);
                      }}
                    >
                      Upgrade
                    </SolidButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      )}
      <DeleteCommunityDialog
        open={open.delete}
        onClose={() => {
          setOpen({ ...open, delete: false });
        }}
        onSubmit={() => {
          handleDeletCommunity();
          setOpen({ ...open, delete: false });
        }}
      />
      <RenameCommunityDialog
        value={communityName}
        open={open.rename}
        onClose={() => {
          setOpen({ ...open, rename: false });
        }}
        onSubmit={(value: string) => {
          handleRenameCommunity(value);
          setOpen({ ...open, rename: false });
        }}
      />
      {open.update && (
        <CommunitySettingDialog
          open={open.update}
          onClose={(value: boolean) => {
            if (value) {
              setTimeout(() => {
                if (!currentComm) return;
                getStartCommunity(currentComm);
                setOpen({ ...open, update: false });
              }, 3000);
            } else {
              setOpen({ ...open, update: false });
            }
          }}
          communityId={currentComm ?? ""}
        />
      )}


    </Box>
  );
}
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
export default CommunityDetail;
