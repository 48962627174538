import * as React from "react";
import dayjs, { Dayjs } from "dayjs";

import {
  useColorScheme as useMaterialColorScheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
} from "@mui/material/styles";
import {
  extendTheme as extendJoyTheme,
  useColorScheme,
  CssVarsProvider,
  THEME_ID,
} from "@mui/joy/styles";
import Input, { InputProps } from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
} from "@mui/x-date-pickers/DateField";
import {
  BaseSingleInputFieldProps,
  DateTimePicker,
  DateTimePickerProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers";
import theme from "./joy-styles";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
interface JoyFieldProps extends InputProps {
  label?: React.ReactNode;
  InputProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  formControlSx?: InputProps["sx"];
}

type JoyFieldComponent = ((
  props: JoyFieldProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & { propTypes?: any };

const JoyField = React.forwardRef(
  (props: JoyFieldProps, inputRef: React.Ref<HTMLInputElement>) => {
    const {
      disabled,
      id,
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      formControlSx,
      ...other
    } = props;

    return (
      <FormControl
        disabled={disabled}
        id={id}
        sx={[
          {
            flexGrow: 1,
          },
          ...(Array.isArray(formControlSx) ? formControlSx : [formControlSx]),
        ]}
        ref={containerRef}
      >
        <FormLabel>{label}</FormLabel>
        <Input
          disabled={disabled}
          slotProps={{ input: { ref: inputRef } }}
          startDecorator={startAdornment}
          endDecorator={endAdornment}
          {...other}
        />
      </FormControl>
    );
  }
) as JoyFieldComponent;

interface JoyDateFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {}

function JoyDateField(props: JoyDateFieldProps) {
  const {
    inputRef: externalInputRef,
    slots,
    slotProps,
    ...textFieldProps
  } = props;

  const response = useDateField<Dayjs, typeof textFieldProps>({
    props: textFieldProps,
    inputRef: externalInputRef,
  });

  return <JoyField {...response} />;
}

function JoyDatePicker(props: DatePickerProps<Dayjs>) {
  return (
    <DatePicker
      {...props}
      format="MM/YYYY"
      views={["year", "month"]}
      sx={{
        width: "100%",
        borderRadius: 30,
        ...props.sx,
      }}
      slots={{ field: JoyDateField, ...props.slots }}
      slotProps={{
        field: {
          formControlSx: {
            flexDirection: "row",
          },
        } as any,
      }}
    />
  );
}

function DateTimePickerJoy(props: DateTimePickerProps<Dayjs>) {
  return (
    <DateTimePicker
      {...props}
      format="lll"
      minDateTime={dayjs(new Date())}
      sx={{
        width: "100%",
        borderRadius: 30,
        paddingBlock: "16px",
        paddingInline: "12px",
      }}
      slots={{ field: JoyDateField, ...props.slots }}
      slotProps={{
        field: {
          formControlSx: {
            flexDirection: "row",
          },
        } as any,
      }}
    />
  );
}

interface DateTimePickerWithJoyFieldProps {
  hidden: boolean;
  value: Dayjs;
  defaultValue: Dayjs;
  onChange: (value: Dayjs | null) => void;
}

export function JoyDateTimePick(props: DateTimePickerProps<Dayjs>) {
  return (
    <MaterialCssVarsProvider>
      <CssVarsProvider theme={{ [THEME_ID]: theme }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={[
              "DatePicker",
              "DateRangePicker",
              "DateRangePicker",
              "DateTimePicker",
            ]}
          >
            <DateTimePickerJoy {...props} />
          </DemoContainer>
        </LocalizationProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default function PickerWithJoyField(props: DatePickerProps<Dayjs>) {
  return (
    <MaterialCssVarsProvider>
      <CssVarsProvider theme={{ [THEME_ID]: theme }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DatePicker", "DateRangePicker", "DateRangePicker"]}
          >
            <JoyDatePicker {...props} />
          </DemoContainer>
        </LocalizationProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
