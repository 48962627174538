import config from "../config";
import { StorageType } from "../constants/enum";
import { UploadImageParams } from "../constants/interfaces";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

export default class UserServices extends BaseService {
  async getUser() {
    const res = await this.call("get", `${baseUrl}/users/info`);
    if (res.data) {
      localStorage.setItem(StorageType.user, JSON.stringify(res.data));
      return res.data;
    }
  }

  async uploadImageProfile(params: UploadImageParams) {
    const res = await this.call(
      "post",
      `${baseUrl}/users/upload/profile/picture`,
      params
    );
    return res.data;
  }
  async getLightsterHome() {
    const res = await this.call("get", `${baseUrl}/lightster/home`);
    return res.data;
  }
  async getCreatorHome() {
    const res = await this.call("get", `${baseUrl}/creator/home`);
    return res.data;
  }
  async creatorCoCreate() {
    const res = await this.call("get", `${baseUrl}/creator/co-create`);
    return res.data;
  }
  async creatorInviteUpcoming() {
    const res = await this.call("get", `${baseUrl}/creator/sessions/upcoming`);
    return res.data;
  }
  async creatorgetLightsterTimezone(lightsterId: string) {
    const res = await this.call("get", `${baseUrl}/creator/timezone`, {}, { params: { lightsterId: lightsterId } });
    return res.data;
  }
  async updateTimezone (body: any){
    const res = await this.call("put", `${baseUrl}/users/device`, body);
    return res.data;
  }
  async updateTimezoneNonUser (body: any, userId: string){
    const res = await this.call("put", `${baseUrl}/users/device/${userId}`, body);
    return res.data;
  }
  async getUserTimezone (userId: string){
    const res = await this.call("get", `${baseUrl}/users/timezone?userId=${userId}`);
    return res.data;
  }
}
