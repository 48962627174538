import { Link, Stack, Typography } from "@mui/joy";
interface Props {
  guest: boolean;
}
export const WaitingCallLayout = ({ guest }: Props) => {
  return (
    <div
      style={{
        backgroundColor: "#F4F6F6 ",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        borderRadius: "var(--Radius-8x, 16px)"}}
    >
      <Stack direction={"column"} alignItems={"center"}>
        <Typography level="h3" textAlign={"center"}>
          Waiting for everyone to join...
        </Typography>
        {guest && (
          <Typography level="body-lg" mt={2}>
            If you are the host, login{" "}
            <Typography
              component={Link}
              href="https://app.lightster.co"
              fontWeight={700}
              sx={{ color: (theme) => theme.palette.primary[500] }}
            >
              here
            </Typography>{" "}
            to start the session.
          </Typography>
        )}
      </Stack>
    </div>
  );
};
