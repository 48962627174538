import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import { InputType, StorageType } from "../../../constants/enum";
import { CreateUnmoderatedParams } from "../../../constants/interfaces";
import {
  AddCircle,
  DeleteOutline,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import UnmoderatedQuestionDialog from "../../../components/dialog/unmodurated/UnmoderatedQuestionDialog";
import { pagesName } from "../../../App";
import { useApi } from "../../../providers/ApiProvider";

export interface QuestionUnmoderated {
  title: string;
  inputType: string;
  keywordList: string[];
}

function CreateSteps() {
  const navigate = useNavigate();
  const { showMessage } = useApi();
  const [loading, setLoading] = useState(false);
  const refScroll = useRef<null | HTMLDivElement>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedStepQues, setSelectedSetQues] =
    useState<QuestionUnmoderated | null>(null);
  const [indexData, setIndexData] = useState<any>({
    step: null,
    ques: null,
  });
  const [indexErrors, setIndexErrrors] = useState<any[]>([]);
  const [questionErrors, setQuestionErrors] = useState<any[]>([]);

  const [data, setData] = useState<CreateUnmoderatedParams>({
    name: "",
    communityId: [],
    topic: "",
    expectedLightster: "",
    pastUsers: null,
    steps: [],
  });

  const newQuestion: QuestionUnmoderated = {
    title: "",
    keywordList: ["", "", "", "", ""],
    inputType: "verbalResponse",
  };

  const handleAddQuestion = (index: number) => {
    let newQues = data.steps[index].questions as QuestionUnmoderated[];
    newQues.push(newQuestion);
    data.steps[index].questions = newQues;
    setData(data);

    setSelectedSetQues(newQuestion);
    setIndexData({ step: index, ques: data.steps[index].questions.length - 1 });
    setOpenDialog(true);
  };

  const handleUpdateQuestion = (value: QuestionUnmoderated) => {
    const stepNumber = data.steps[indexData.step].stepNumber;
    const find = questionErrors.find(
      (f) => f.step === stepNumber && f.questions.includes(indexData.ques)
    );
    if (find) {
      const newArray = find.questions.filter((e: any) => e !== indexData.ques);

      if (Array.isArray(newArray)) {
        questionErrors.forEach((data, i) => {
          if (data.step === stepNumber) {
            questionErrors[i].questions = newArray;
          }
        });
        setQuestionErrors(questionErrors);
      } else {
        const filter = questionErrors.filter((e) => e.step === stepNumber);
        setQuestionErrors(filter);
      }
    }

    data.steps[indexData.step].questions[indexData.ques] = value;
    setData(data);
    clearQues();
  };

  const deleteQues = () => {
    const questions = data.steps[indexData.step].questions;
    const fillter = (questions as QuestionUnmoderated[]).filter(
      (f) => f !== selectedStepQues
    );

    data.steps[indexData.step].questions = fillter;

    setData(data);
    clearQues();
  };

  const clearQues = () => {
    setIndexData({ step: null, ques: null });
    setSelectedSetQues(null);
    setOpenDialog(false);
  };

  const handleAddStep = () => {
    const stepNo = data.steps.length + 1;
    const newStep = {
      stepNumber: stepNo,
      detail: "",
      questions: [newQuestion] as QuestionUnmoderated[],
    };

    setData((pre) => {
      const newItems = [...pre.steps, newStep];
      return { ...pre, steps: newItems };
    });
  };
  const handleDeleteStep = (iStep: number) => {
    const deletedData = data.steps[iStep];
    const filter = data.steps.filter((f) => f !== deletedData);
    setData({ ...data, steps: filter });
  };
  const handleStepDetailChange = (value: any, iStep: number) => {
    if (value && indexErrors.includes(iStep)) {
      const filter = indexErrors.filter((f) => f != iStep);
      setIndexErrrors(filter);
    }

    setData((pre) => {
      const updatedItem = { ...data.steps[iStep], detail: value };
      const newItems = [...pre.steps];
      newItems[iStep] = updatedItem;
      return { ...pre, steps: newItems };
    });
  };

  const scrollToBottom = () => {
    refScroll.current?.scrollIntoView({ behavior: "smooth" });
  };
  const checkEmptyField = () => {
    let contained = false;
    const _indexErrors = [];

    for (let index = 0; index < data.steps.length; index++) {
      const element = data.steps[index];
      if (!element.detail.trim()) {
        contained = true;
        _indexErrors.push(index);
      }
    }

    if (_indexErrors.length > 0 && contained) {
      setIndexErrrors(_indexErrors);
    }

    if (!contained && _indexErrors.length > 0) {
      setIndexErrrors([]);
    }

    return contained;
  };

  const checkQuestionsEmpty = () => {
    let contained = false;
    const emptyData: any = [];

    if (Array.isArray(data.steps)) {
      data.steps.forEach((step, stepIndex) => {
        const stepData = { step: step.stepNumber, questions: [] as number[] };
        if (Array.isArray(step.questions)) {
          step.questions.forEach(
            (question: QuestionUnmoderated, questionIndex: number) => {
              if (question.title === "") {
                contained = true;
                stepData.questions.push(questionIndex);
              }
            }
          );
        }
        if (stepData.questions.length > 0) {
          emptyData.push(stepData);
        }
      });
    }
    setQuestionErrors(emptyData);

    return contained;
  };

  const handleOnClickNext = () => {
    if (checkEmptyField()) {
      checkQuestionsEmpty();
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    if (checkQuestionsEmpty()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));
    navigate(pagesName.creator.unmoderatedPayment, {
      state: { booking: data },
    });
  };

  const checkFieldError = (stepNo: number, questionIndex: number) => {
    if (Array.isArray(questionErrors)) {
      const find = questionErrors.find(
        (f) => f.step === stepNo && f.questions.includes(questionIndex)
      );
      return !!find;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data.steps]);

  useEffect(() => {
    const localData = JSON.parse(
      localStorage.getItem(StorageType.unmoderated) ?? ""
    );
    if (localData.steps.length > 0) {
      setData(localData);
    } else {
      setData({
        ...localData,
        steps: [
          {
            stepNumber: 1,
            detail: "",
            questions: [newQuestion],
          },
        ],
      });
    }
  }, []);

  return (
    <Box
      className="home"
      sx={{ height: "100%", overflow: "hidden", padding: 0 }}
      overflow="auto"
    >
      {loading ? (
        <Box
          className="box-column"
          sx={{
            minHeight: 300,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="md" />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "100%",
          }}
        >
          <Box sx={{ pr: 4, pl: 4, mt: 4 }}>
            <Typography level="h2" fontSize={28}>
              Test questions
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              pr: 4,
              pl: 4,
              mt: 3,
              overflowY: "auto",
            }}
          >
            {data.steps.map((step, iStep) => (
              <Box key={`test-questions-step-${iStep}`}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography level="title-lg" sx={{ color: "black" }}>
                    Step {iStep + 1}
                  </Typography>
                  {iStep !== 0 && (
                    <IconButton onClick={() => handleDeleteStep(iStep)}>
                      <DeleteOutline sx={{ color: "red" }} />
                    </IconButton>
                  )}
                </Stack>
                <Typography level="body-sm" sx={{ color: "black", mb: 1 }}>
                  <b>Instruction to user:</b> &nbsp;Please provide the task that
                  you would like the user to do.
                </Typography>
                <Stack direction={"column"} spacing={1}>
                  <Input
                    color={indexErrors.includes(iStep) ? "danger" : "neutral"}
                    size="lg"
                    placeholder="Example: Please click on this link: https://www.mytestwebsite.com..."
                    sx={{ borderRadius: 8 }}
                    slotProps={{
                      input: {
                        style: {
                          fontSize: 16,
                          borderRadius: 2,
                        },
                      },
                    }}
                    error={indexErrors.includes(iStep)}
                    value={step.detail}
                    onChange={(e) => {
                      const newdata = e.target.value;
                      handleStepDetailChange(newdata, iStep);
                    }}
                  />
                </Stack>
                <Stack direction={"column"} spacing={2} mt={2}>
                  {step.questions.map((quest: any, i: number) => (
                    <>
                      <Typography level="body-sm" fontWeight={700}>
                        Question {i + 1}
                      </Typography>
                      <QuestionItem
                        title={quest.title || "Add"}
                        onClick={() => {
                          setOpenDialog(true);
                          setSelectedSetQues(quest);
                          setIndexData({ step: iStep, ques: i });
                        }}
                        error={checkFieldError(step.stepNumber, i)}
                      />
                    </>
                  ))}
                  <Box>
                    <Button
                      variant="plain"
                      sx={{ color: "black", width: 212, paddingInline: 2 }}
                      startDecorator={<AddCircle sx={{ color: "black" }} />}
                      onClick={() => handleAddQuestion(iStep)}
                    >
                      Add another question
                    </Button>
                  </Box>
                  {step.stepNumber === data.steps.length && (
                    <Box pb={3} mt={"8px !important"}>
                      <Button
                        variant="plain"
                        sx={{
                          color: "black",
                          width: 136,
                          paddingInline: 2,
                          marginTop: "0px !important",
                        }}
                        startDecorator={<AddCircle sx={{ color: "black" }} />}
                        onClick={() => handleAddStep()}
                      >
                        Add a step
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Box>
            ))}
          </Box>
          <Box sx={{ pr: 4, pl: 4, mb: 3 }}>
            <Box className="box-row" sx={{ justifyContent: "end", gap: 2 }}>
              <PlainButton
                variant="plain"
                onClick={() => {
                  localStorage.setItem(
                    StorageType.unmoderated,
                    JSON.stringify(data)
                  );
                  navigate(-1);
                }}
              >
                Back
              </PlainButton>

              <SolidButton variant="solid" onClick={handleOnClickNext}>
                Next
              </SolidButton>
            </Box>
          </Box>
        </Box>
      )}
      {selectedStepQues && (
        <UnmoderatedQuestionDialog
          open={openDialog}
          defaultData={selectedStepQues}
          showDetete={indexData.ques > 0}
          onClose={(value: QuestionUnmoderated | null | string) => {
            if (value && typeof value !== "string") {
              handleUpdateQuestion(value as QuestionUnmoderated);
              return;
            }

            if (value && typeof value === "string" && value === "deleted") {
              deleteQues();
              return;
            }

            if (!value) {
              clearQues();
            }
          }}
        />
      )}
    </Box>
  );
}

export default CreateSteps;

interface ItemProps {
  title: string;
  onClick: Function;
  error?: boolean;
}
const QuestionItem = ({ title, onClick, error = false }: ItemProps) => {
  return (
    <Box
      sx={{
        border: !error
          ? "1px solid black"
          : "1px solid var(--joy-palette-danger-500)",
        borderRadius: 8,
        paddingInline: 2,
        paddingBlock: "9px",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"start"}
      >
        <Typography
          level="title-md"
          sx={{
            maxWidth: "80%",
            color: error ? "var(--joy-palette-danger-500)" : "black",
          }}
        >
          {title}
        </Typography>
        <KeyboardArrowDownRounded
          sx={{ color: error ? "var(--joy-palette-danger-500)" : "black" }}
        />
      </Stack>
    </Box>
  );
};
