import { EastRounded } from "@mui/icons-material";
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Typography
} from "@mui/joy";
import { useState } from "react";
import { SolidButton } from "../../../components/button/CustomButton";

import { Criteria, CriteriaParams } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props {
  criterias: Criteria[];
  onSubmit: (value: string) => void;
}

function CreateCommunity(props: Props) {
  const { criterias, onSubmit } = props;
  const { communityService, handleToggle } = useApi();
  const [name, setName] = useState("");

  const createCommmunity = async () => {
    handleToggle(true);
    const res = await communityService.createCommunity(name);
    if (!res) return;
    await updateCriteriaAns(res._id);
    setTimeout(() => {
      handleToggle(false);
      onSubmit(res._id);
    }, 3000);
  };

  const updateCriteriaAns = async (communityId: string) => {
    const profiles: Array<CriteriaParams> = [];
    for (let index = 0; index < criterias.length; index++) {
      const element = criterias[index];
      if (element.profileId == null) return;
      const profileAns: CriteriaParams = {
        profileId: element.profileId,
        answers: element.answers ?? [],
      };
      profiles.push(profileAns);
    }

    const res = await communityService.updateCriteria(communityId, profiles);
    if (!res) return;
  };

  return (
    <Box className="home" sx={{ height: "calc(100vh - 64px)" }}>
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box className="primary-circle">
            <Typography level="h3" sx={{ color: "white" }}>
              2
            </Typography>
          </Box>
          <Typography level="h3">Name this community</Typography>
        </Box>
        <Typography level="body-lg">
          What do you want to call this community? You’re the only one that will
          see it.
        </Typography>
      </Stack>
      <FormControl sx={{ mt: 4 }}>
        <FormLabel>Community Name</FormLabel>
        <Input
          size="lg"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <Box display={"flex"} flexDirection="row" justifyContent="end" mt={6}>
        <SolidButton
          endDecorator={<EastRounded fontSize="small" />}
          variant="solid"
          onClick={createCommmunity}
        >
          View users
        </SolidButton>
      </Box>
    </Box>
  );
}

export default CreateCommunity;
