import dcubeBlack from "../assets/icons/3dcube/3dcube-black.png";
import dcube from "../assets/icons/3dcube/3dcube.png";

import profileUserBlack from "../assets/icons/preple-2user/profile-2user-black.png";
import profileUser from "../assets/icons/preple-2user/profile-2user.png";

import bookBlack from "../assets/icons/book/book-black.png";
import book from "../assets/icons/book/book.png";

import folderOpenBlack from "../assets/icons/folder-open/folder-open-black.png";
import folderOpen from "../assets/icons/folder-open/folder-open.png";

import securityUserBlack from "../assets/icons/security-user/security-user-black.png";
import securityUser from "../assets/icons/security-user/security-user.png";

import editPrimary from "../assets/icons/edit-primary.png";
import editGrey from "../assets/icons/edit.png";
import logout from "../assets/icons/logout.png";
import premium from "../assets/icons/premium.png";
import guestLogin from "../assets/images/business-plan.png";
import imageLogin from "../assets/images/login-app-screen.png";
import appLogo from "../assets/images/logo.png";
import thumbtacks from "../assets/images/thumbtacks.png";
import placeholder from "../assets/images/user.png";


import ChatOff from "../assets/icons/ControlFooter-icons/ChatOff.svg";
import ChatOn from "../assets/icons/ControlFooter-icons/ChatOn.svg";
import MicOff from "../assets/icons/ControlFooter-icons/MicOff.svg";
import MicOn from "../assets/icons/ControlFooter-icons/MicOn.svg";
import RecordOff from "../assets/icons/ControlFooter-icons/RecordOff.svg";
import RecordOn from "../assets/icons/ControlFooter-icons/RecordOn.svg";
import ShareScreenOff from "../assets/icons/ControlFooter-icons/ShareScreenOff.svg";
import ShareScreenOn from "../assets/icons/ControlFooter-icons/ShareScreenOn.svg";
import SpeakerOff from "../assets/icons/ControlFooter-icons/SpeakerOff.svg";
import SpeakerOn from "../assets/icons/ControlFooter-icons/SpeakerOn.svg";
import VideoOff from "../assets/icons/ControlFooter-icons/VideoOff.svg";
import VideoOn from "../assets/icons/ControlFooter-icons/VideoOn.svg";
import EndSessionIcon from "../assets/icons/EndSessionIcon.svg";
import PersonAvatar from "../assets/icons/PersonAvatar.svg";

import guest01 from "../assets/images/guest-avatar/guest01.png";
import guest02 from "../assets/images/guest-avatar/guest02.png";
import guest03 from "../assets/images/guest-avatar/guest03.png";
import guest04 from "../assets/images/guest-avatar/guest04.png";
import guest05 from "../assets/images/guest-avatar/guest05.png";
import guest06 from "../assets/images/guest-avatar/guest06.png";
import guest07 from "../assets/images/guest-avatar/guest07.png";
import guest08 from "../assets/images/guest-avatar/guest08.png";
import guest09 from "../assets/images/guest-avatar/guest09.png";
import guest10 from "../assets/images/guest-avatar/guest10.png";

import plan01 from "../assets/images/plan01.png";
import plan02 from "../assets/images/plan02.png";
export const guestsList = [
  guest01,
  guest02,
  guest03,
  guest04,
  guest05,
  guest06,
  guest07,
  guest08,
  guest09,
  guest10,
];
export const imageMockupList = [
  "https://demos.pixinvent.com/materialize-nextjs-admin-template/demo-1/images/avatars/1.png",
  "https://demos.pixinvent.com/materialize-nextjs-admin-template/demo-1/images/avatars/8.png",
  "https://demos.pixinvent.com/materialize-nextjs-admin-template/demo-1/images/avatars/2.png",
];

export {
    appLogo, book,
    bookBlack, dcube,
    dcubeBlack, editGrey,
    editPrimary, folderOpen,
    folderOpenBlack, guest01,
    guest02,
    guest03,
    guest04,
    guest05,
    guest06,
    guest07,
    guest08,
    guest09,
    guest10, guestLogin, imageLogin, logout, placeholder, plan01,
    plan02, profileUser,
    profileUserBlack, securityUser,
    securityUserBlack, thumbtacks,
    premium
};

export{
  ChatOff, 
  ChatOn,
  MicOff,
  MicOn,
  RecordOff,
  RecordOn,
  ShareScreenOff,
  ShareScreenOn,
  SpeakerOff,
  SpeakerOn,
  VideoOff,
  VideoOn,
  EndSessionIcon,
  PersonAvatar
};
