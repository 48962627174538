import { EastSharp } from "@mui/icons-material";
import { Stack, Typography } from "@mui/joy";

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import BackgroundRegister from "../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../components/CustomProgress";
import { SolidButton } from "../../components/button/CustomButton";
import { RoleType, StorageType } from "../../constants/enum";
import { useAuth } from "../../providers/AuthProvider";

interface Props {}

function ChooseRole(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [role, setRole] = useState("");
  const { user, onShowMessage } = useAuth();

  const handleNavigate = () => {
    if (!role) {
      onShowMessage("Please choose the role.", "warning");
      return;
    }

    if (role === RoleType.lightster) {
      localStorage.setItem(StorageType.role, RoleType.lightster);
      if (state?.isRegister || !user?.isLightster) {
        return navigate(pagesName.lightster.aboutYou);
      }
      return navigate(pagesName.lightster.home);
    }
    if (role === RoleType.creator) {
      localStorage.setItem(StorageType.role, RoleType.creator);
      if (state?.isRegister || !user?.isCreator) {
        return navigate(pagesName.creator.aboutYou);
      }
      return navigate(pagesName.creator.home);
    }
  };

  return (
    <BackgroundRegister>
      <Stack direction={"column"} width={"100%"} mt="10vh">
        {state?.isRegister && (
          <BorderLinearProgress determinate value={40} sx={{ mb: 11 }} />
        )}
        <Stack direction={"column"} spacing={4}>
          <Stack direction="column" gap={1}>
            <Typography level="h2" sx={{ width: "100%", fontSize: 28 }}>
              {state?.isRegister
                ? "Which role do you want to sign-up as?"
                : "What are you looking to do today?"}
            </Typography>
            <Typography level="body-lg">
              {state?.isRegister
                ? "Choose how you want to co-create. You can add another role and switch between roles later!"
                : "Choose the role that you would like to continue as."}
            </Typography>
          </Stack>
          {optionRole(
            "User",
            "I want to share my input and get rewarded",
            role === RoleType.lightster,
            () => setRole(RoleType.lightster)
          )}
          {optionRole(
            "Creator",
            "I am looking to connect with my target users for input",
            role === RoleType.creator,
            () => setRole(RoleType.creator)
          )}

          <SolidButton
            variant="solid"
            endDecorator={<EastSharp fontSize="small" />}
            sx={{ maxWidth: 128, mt: "48px !important" }}
            onClick={handleNavigate}
          >
            Next
          </SolidButton>
        </Stack>
      </Stack>
    </BackgroundRegister>
  );
}

const optionRole = (
  role: string,
  description: string,
  active: boolean,
  onClickRole: Function
) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      height={48}
      px={3}
      alignItems={"center"}
      border={active ? "1px solid #7730E1" : "1px solid black"}
      borderRadius="50px"
      bgcolor={active ? "#7730E1" : "transparent"}
      onClick={() => onClickRole()}
      sx={{
        cursor: "pointer",
        fontSize: 14,
        color: active ? "white" : "black",
      }}
    >
      {role}
      {": "}
      {description}
    </Stack>
  );
};

export default ChooseRole;
