import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  Cancel,
  CheckCircle,
} from "@mui/icons-material";
import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng";
import {
  getBrowserName,
  isSupportBrowsers,
  StorageType,
} from "../constants/enum";
import { useAuth } from "../providers/AuthProvider";
import { useApi } from "../providers/ApiProvider";
import { pagesName } from "../App";
import {
  Box,
  CircularProgress,
  Grid,
  Select,
  Switch,
  Typography,
  Option,
} from "@mui/joy";
import { OutlinedButton, SolidButton } from "../components/button/CustomButton";
import { agoraAppId } from "../constants/agoraRtc";
import { createDeviceTracks } from "../components/video-call/Devices";

const RequstRermissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sessionId = location.state?.sessionId;
  const unmoderatedId = location.state?.unmoderatedId;

  const guestSession = location.state?.guestSession;
  const role = location.state?.role;

  const scheduleType = location.state?.type;
  const browser = getBrowserName();

  const { user, onShowMessage } = useAuth();
  const { sessionService, handleToggle } = useApi();

  const [loading, setLoading] = useState(false);
  const [camera, setCamera] = useState(false);
  const [microphone, setMicrophone] = useState(false);
  const [mic, setMic] = useState(false);
  const [cam, setCam] = useState(false);
  const [speaker, setSpeaker] = useState(false);

  const [microphoneList, setMicrophonelist] = useState<Array<any>>([]);
  const [cameraList, setCameraList] = useState<Array<any>>([]);
  const [speakerList, setSpeakerList] = useState<Array<any>>([]);

  const [selectedCamera, setSelectedCamera] = useState<any>();
  const [selectedMicrophone, setSelectedMicrophone] = useState<any>();
  const [selectedSpeaker, setSelectedSpeaker] = useState<any>();

  const videoRef = useRef<HTMLVideoElement>(null);
  const [volume, setVolume] = useState(0);
  const [internet, setInternet] = useState<boolean | null>(null);

  let tracks: any[] = [];
  const handleDefaultChecked = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handlePermissionMicrophone = async () => {
    if (!microphone) {
      AgoraRTC.getMicrophones()
        .then((devices) => {
          if (devices.length > 0) {
            setMicrophone(true);
            setMic(true);
            setMicrophonelist(devices);
            setSelectedMicrophone(devices[0]);
            handleMicrophoneChange(devices[0].deviceId);
          } else {
            onShowMessage("Please add a microphone device.", "warning");
          }
        })
        .catch((e) => {
          console.log("get devices error!", e);
        });
    }
  };

  const handlePermissionCamera = async () => {
    if (!camera) {
      AgoraRTC.getCameras()
        .then((devices) => {
          if (devices.length > 0) {
            setCamera(true);
            setCam(true);
            setCameraList(devices);
            setSelectedCamera(devices[0]);
            handleCameraChange(devices[0].deviceId);
          } else {
            onShowMessage("Please add a camera device.", "warning");
          }
        })
        .catch((e) => {
          console.log("get devices error!", e);
        });
    }
  };

  const handlePermissionPlayback = () => {
    AgoraRTC.getPlaybackDevices().then((devices) => {
      if (devices.length > 0) {
        setSpeaker(true);
        setSelectedSpeaker(devices[0]);
        setSpeakerList(devices);
        handleSpeakerChange(devices[0].deviceId);
      }
    });
  };

  const handleJoin = async () => {
    if (microphone && mic && camera) {
      handleToggle(true);

      if (sessionId) {
        const res = await sessionService.startSession(sessionId);
        handleToggle(false);
        if (!res) return;
        navigate(pagesName.videocall, {
          state: { sessionId: sessionId },
          replace: true,
        });
      }
      if (unmoderatedId) {
        const res = await sessionService.lightsterStartUnmoderated(
          unmoderatedId
        );
        handleToggle(false);
        if (!res) return;
        navigate(pagesName.unmoderated, {
          state: {
            unmoderatedId: unmoderatedId,
            agoraUID: res.agoraUID,
            rtcToken: res.rtcToken,
            refNumber: res.refNumber,
          },
          replace: true,
        });
      }
    } else {
      onShowMessage(
        'To use this feature, we need access to your microphone. Please visit Settings on your browser, and set Microphone permissions to "Allow" for Lightster',
        "error"
      );
    }
  };
  const handleJoinAsGuest = async () => {
    if (microphone && mic) {
      if (!guestSession) return;

      if (role === "lightster") {
        navigate(pagesName.guestJoinLightster, {
          state: { session: guestSession },
        });
      } else {
        navigate(pagesName.guestJoin, {
          state: { session: guestSession },
        });
      }
    } else {
      onShowMessage(
        'To use this feature, we need access to your microphone. Please visit Settings on your browser, and set Microphone permissions to "Allow" for Lightster',
        "error"
      );
    }
  };

  const handleMicrophoneChange = async (deviceId: string) => {
    localStorage.setItem(StorageType.audio, deviceId);

    // Ensure previous stream tracks are stopped to avoid leaks.
    if (window.currentStream) {
      window.currentStream.getTracks().forEach((track) => track.stop());
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      });
      // Store the stream globally for cleanup.
      window.currentStream = stream;

      const audioContext = new (window.AudioContext || window.AudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

      microphone.connect(analyser);
      analyser.connect(scriptProcessor);
      scriptProcessor.connect(audioContext.destination);
      scriptProcessor.onaudioprocess = function (event) {
        const input = event.inputBuffer.getChannelData(0);
        let sum = 0.0;
        for (let i = 0; i < input.length; ++i) {
          sum += input[i] * input[i];
        }
        const volume = Math.sqrt(sum / input.length);
        // Update volume state or however you wish to display this.
        setVolume(volume);
      };
    } catch (error) {
      console.error("Error setting up microphone", error);
      // Handle error (e.g., user didn't grant permission)
    }
  };

  const handleTerminateStreams = async (action: "cancel" | "next") => {
    setLoading(true);

    if (window.currentStream) {
      window.currentStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setTimeout(() => {
      setLoading(false);
      if (action === "cancel") {
        setInternet(false);
        navigate(-1);
      }
      if (action === "next" && unmoderatedId) {
        handleJoin();
      }
      if (action === "next" && sessionId) {
        handleJoin();
      }
    }, 3000);
  };

  const handleSpeakerChange = async (deviceId: string) => {
    localStorage.setItem(StorageType.speaker, deviceId);
  };
  const handleCameraChange = async (deviceId: string) => {
    localStorage.setItem(StorageType.video, deviceId);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: deviceId ? { exact: deviceId } : undefined },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      } else {
        setTimeout(() => handleCameraChange(deviceId), 1000);
      }
    } catch (error) {
      console.error("Error accessing the camera", error);
      // Handle the error appropriately
    }
  };
  const handleCheckNetworkQuality = async (uId: string) => {
    const res = await sessionService.lightsterStartUnmoderated(uId);
    if (!res) return;

    const networkClient: IAgoraRTCClient = AgoraRTC.createClient({
      mode: "rtc",
      codec: "h264",
    });
    tracks = await createDeviceTracks();

    networkClient.on("user-published", async (user, mediaType) => {
      await networkClient.subscribe(user, mediaType);
      if (mediaType === "video") {
      }
      if (mediaType === "audio") {
        user.audioTrack?.play();
      }
    });

    networkClient.on("network-quality", async (quality) => {
      if (
        quality.uplinkNetworkQuality > 3 ||
        quality.downlinkNetworkQuality > 3
      ) {
        tracks.forEach((track) => track?.close());
        setInternet(false);
        await networkClient.leave();
      } else {
        tracks.forEach((track) => track?.close());
        setInternet(true);
        await networkClient.leave();
      }
    });

    await networkClient.join(
      agoraAppId,
      res.refNumber,
      res.rtcToken,
      parseInt(res.agoraUID)
    );
    if (tracks[1]) {
      await networkClient.publish(tracks);
    } else {
      await networkClient.publish(tracks[0]);
    }
  };

  const getIconForCheckInternet = () => {
    if (internet == null) {
      return <CircularProgress size="sm" />;
    }

    if (internet == true) {
      return <CheckCircle color="success" />;
    }

    if (internet == false) {
      return <Cancel color="error" />;
    }
  };
  useEffect(() => {
    handleDefaultChecked();
    handlePermissionMicrophone();
    handlePermissionPlayback();
    handlePermissionCamera();
    if (unmoderatedId) {
      handleCheckNetworkQuality(unmoderatedId);
    }

    return () => {
      tracks.forEach((track) => track?.close());
    };
  }, []);

  if (loading)
    return (
      <>
        <Box
          display={"flex"}
          height={"100vh"}
          width={"100vw"}
          alignItems="center"
          justifyContent={"center"}
        >
          <CircularProgress color="primary" />
        </Box>
      </>
    );

  return (
    <div className="bg" style={{ position: "relative", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "calc(100vh - 40px)",
        }}
      >
        <div className="max-width">
          <Grid container height="100%" alignContent={"center"}>
            <Grid
              padding={6}
              justifyContent={"center"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // backgroundColor: "white",
                height: "auto",
                minHeight: "50vh",
                width: "100%",
                // borderRadius: "10px",
                // border: "1px solid black",
              }}
            >
              <Typography level="h2" textAlign={"center"} fontWeight={700}>
                To join the call, we need access to your video & audio devices.
              </Typography>

              <Typography
                level="title-lg"
                textAlign={"center"}
                fontWeight={400}
              >
                You can still turn on/off your camera or mute/unmute yourself
                during the call.
              </Typography>

              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <Box display={"flex"} flexDirection="column" marginTop={5}>
                  <Box
                    sx={{
                      p: 4,
                      minWidth: 550,
                      border: "1px solid black",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection="row"
                      justifyContent={"space-between"}
                    >
                      <Typography level="title-lg">Microphone</Typography>
                      <Switch
                        checked={microphone}
                        onChange={handlePermissionMicrophone}
                      />
                    </Box>
                    {microphone ? (
                      <Box mt={1}>
                        <Select
                          sx={{ paddingInline: 3 }}
                          placeholder="Microphone"
                          value={selectedMicrophone}
                          onChange={(e, value) => {
                            setSelectedMicrophone(value);
                          }}
                        >
                          {microphoneList.map((data, i) => (
                            <Option key={`microphone-option-${i}`} value={data}>
                              {data.label}
                            </Option>
                          ))}
                        </Select>
                        <div style={{ marginTop: "20px" }}>
                          <Box
                            sx={{
                              height: "20px",
                              width: "100%",
                              backgroundColor: "#ddd",
                            }}
                          >
                            <Box
                              sx={{
                                height: "100%",
                                width: `${volume * 100}%`, // Assuming volume is a value between 0 and 1
                                backgroundColor: volume > 0.8 ? "red" : "green",
                                transition: "width 0.2s ease",
                              }}
                            />
                          </Box>
                        </div>
                      </Box>
                    ) : null}

                    {isSupportBrowsers() ? (
                      <Box>
                        <br />
                        <Box
                          display={"flex"}
                          flexDirection="row"
                          justifyContent={"space-between"}
                        >
                          <Typography level="title-lg">Speakers</Typography>
                          <Switch
                            checked={speaker}
                            onChange={handlePermissionPlayback}
                          />
                        </Box>
                        {speaker && (
                          <Select
                            sx={{ mt: 1, paddingInline: 3 }}
                            placeholder="Speakers"
                            value={selectedSpeaker}
                            onChange={(e, value) => {
                              setSelectedSpeaker(value);
                            }}
                          >
                            {speakerList.map((data) => (
                              <Option value={data}>{data.label}</Option>
                            ))}
                          </Select>
                        )}
                      </Box>
                    ) : null}
                    <br />
                    <Box
                      display={"flex"}
                      flexDirection="row"
                      justifyContent={"space-between"}
                    >
                      <Typography level="title-lg">Camera</Typography>
                      <Switch
                        checked={camera}
                        onChange={handlePermissionCamera}
                      />
                    </Box>
                    {camera ? (
                      <Box mt={1}>
                        <Select
                          sx={{ paddingInline: 3 }}
                          placeholder="Camera"
                          value={selectedCamera}
                          onChange={(e, value) => {
                            setSelectedCamera(value);
                          }}
                        >
                          {cameraList.map((data) => (
                            <Option value={data}>{data.label}</Option>
                          ))}
                        </Select>

                        <video
                          ref={videoRef}
                          autoPlay
                          playsInline
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            paddingTop: "16px",
                          }}
                        />
                      </Box>
                    ) : null}
                    {unmoderatedId && (
                      <Box
                        mt={4}
                        display={"flex"}
                        flexDirection="row"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography level="title-lg">
                          Internet connection
                        </Typography>
                        {getIconForCheckInternet()}
                      </Box>
                    )}
                    {unmoderatedId && internet !== null && !internet && (
                      <Typography level="body-sm" sx={{ color: "red" }}>
                        A faster connection is required, please try again.
                      </Typography>
                    )}
                  </Box>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      padding: "10px",
                      backgroundColor:
                        "#fff" /* Optional: for better visibility */,
                      borderTop:
                        "1px solid #ccc" /* Optional: to visually separate the button bar from content */,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0 auto",
                        maxWidth: "550px" /* Assuming your content width */,
                      }}
                    >
                      <OutlinedButton
                        variant="outlined"
                        color="primary"
                        startDecorator={<ArrowBackRounded />}
                        sx={{
                          height: "40px",
                          width: "120px",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleTerminateStreams("cancel");
                        }}
                      >
                        Cancel
                      </OutlinedButton>
                      {guestSession ? (
                        <SolidButton
                          variant="solid"
                          endDecorator={<ArrowForwardRounded />}
                          sx={{
                            height: "40px",
                            width: "auto",
                            textTransform: "capitalize",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          color="primary"
                          onClick={handleJoinAsGuest}
                        >
                          Join as Guest
                        </SolidButton>
                      ) : (
                        <SolidButton
                          variant="solid"
                          endDecorator={<ArrowForwardRounded />}
                          sx={{
                            height: "40px",
                            width: "120px",
                            textTransform: "capitalize",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          color="primary"
                          disabled={
                            unmoderatedId && (internet == null || !internet)
                          }
                          onClick={() => handleTerminateStreams("next")}
                        >
                          Join
                        </SolidButton>
                      )}
                    </div>
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RequstRermissions;
