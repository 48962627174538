import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { mapStatusText } from "../../constants/constants";
import { SurvayDisplay, Unmoderated } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import InfoDialog from "../dialog/InfoDialog";
import { placeholder } from "../../constants/AppImages";
import CustomDialog from "../dialog/CustomDialog";
import { PlainButton, SolidButton } from "../button/CustomButton";
import { StorageType } from "../../constants/enum";
import { pagesName } from "../../App";
import { useNavigate } from "react-router-dom";

interface Props {
  unmoderated: Unmoderated;
  refresh?: Function;
}

function CreatorUnmoderatedItem(props: Props) {
  const { unmoderated, refresh } = props;
  const { user } = useAuth();
  const { sessionService } = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    view: false,
    end: false,
  });

  const handleDuplicate = async (id: string) => {
    const data = await sessionService.detailUnmoderatedCreator(id);

    if (data.type === "lightster") {
      data.lightsterId = "dup"
      localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));
      navigate(`${pagesName.creator.unmoderated}/dup`);
    } else{
      localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));
      navigate(pagesName.creator.unmoderated);
    }
    return;

  };
  const handleEnd = async () => {
    await sessionService.stopUnmoderated(unmoderated.id);
    setOpen({ ...open, end: false });
  };
  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} spacing={2} p={2}>
        <AvatarGroup>
          <Avatar src={unmoderated.imageProfile ?? placeholder} />
          <Avatar sx={{ border: "1px solid black", fontSize: 24 }}>+</Avatar>
        </AvatarGroup>
        <Stack direction={"column"} sx={{ width: "-webkit-fill-available" }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="title-lg">
              {unmoderated.expectedLightster}{" "}
              {unmoderated.expectedLightster > 1 ? "users" : "user"}
            </Typography>
            <Typography level="body-md">
              {unmoderated.scheduled}/{unmoderated.expectedLightster} scheduled{" "}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="body-md">
              {unmoderated.name}
            </Typography>
            <Dropdown>
              <MenuButton
                size="sm"
                sx={{
                  paddingInline: 0,
                  display: "inherit",
                  color: (theme) => theme.palette.primary[500],
                }}
                endDecorator={
                  <KeyboardArrowDownRounded fontSize="medium" color="primary" />
                }
                variant="plain"
              >
                {mapStatusText(unmoderated.status)}
              </MenuButton>
              <Menu placement="bottom-start" variant="outlined">
              <MenuItem
                    color="primary"
                    sx={{
                      fontSize: 14,
                      color: "black",
                      justifyContent: "right",
                      ":hover": {                                                                                                   
                        color: (theme) => theme.palette.primary.darkChannel,
                        background: (theme) => theme.palette.primary.lightChannel,
                      },
                    }}
                    onClick={() => handleDuplicate(unmoderated.id)}
                  >
                    Duplicate
                  </MenuItem>
                {unmoderated.status === "completed" ? (
                  null ) : (
                  <MenuItem
                    color="primary"
                    sx={{
                      fontSize: 14,
                      justifyContent: "right",
                      color: "black",
                      ":hover": {
                        color: (theme) => theme.palette.primary.darkChannel,
                        background: (theme) => theme.palette.primary.lightChannel,
                      },
                    }}
                    onClick={() => setOpen({ ...open, end: true })}
                  >
                    End test
                  </MenuItem>
                )


                }
              </Menu>
            </Dropdown>
          </Stack>
          <Typography level="body-md" sx={{ fontStyle: "italic" }}>
            {unmoderated.communityList}
          </Typography>
        </Stack>
      </Stack>
      {open.end && (
        <CustomDialog
          open={open.end}
          title="Are you sure?"
          subtitle={
            <Stack direction={"column"} spacing={4}>
              <Typography level="body-lg">
                If you stop Unmodurated Testing now, no more users will be tested.
              </Typography>

              <Stack
                direction={"row"}
                justifyContent={{ sm: "space-between", md: "end" }}
                spacing={2}
              >
                <PlainButton variant="plain" onClick={() => setOpen({ ...open, end: false })}>
                  Go Back
                </PlainButton>
                <SolidButton
                  variant="solid"
                  onClick={() => {
                    handleEnd();
                  }}
                >
                  Yes
                </SolidButton>
              </Stack>
            </Stack>
          }
        />
      )}
    </Box>
  );
}

export default CreatorUnmoderatedItem;
