import { Alert, AlertColor } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

interface DefaultMessageAlertProps {
  message: string;
  open: boolean;
  type: AlertColor;
}

const defaultMessageAlert: DefaultMessageAlertProps = {
  message: "",
  open: false,
  type: "info",
};

export function useAlertMessage() {
  const [messageAlert, setMessageAlert] = React.useState(defaultMessageAlert);

  const closeMessage = () => {
    setMessageAlert({ ...messageAlert, open: false });
  };

  const showMessage = (message: string, type: AlertColor) => {
    setMessageAlert({ message, open: true, type });
  };

  return { messageAlert, closeMessage, showMessage };
}

interface AlertMessageProps {
  messageAlert: DefaultMessageAlertProps;
  autoHideDuration: number | null;
  onClose: () => void;
}
export default function AlertMessage({
  messageAlert = defaultMessageAlert,
  autoHideDuration = 3000,
  onClose,
}: AlertMessageProps) {
  const { open, type, message } = messageAlert;
  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={open}
    >
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}
