import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    Modal,
    ModalClose,
    ModalDialog,
    Typography
} from "@mui/joy";
import { ListItemAvatar } from "@mui/material";
import { useEffect, useState } from "react";
import { CommunityDisplay } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props {
  open: boolean;
  onClose: (value: string[]) => void;
  defaultData: string[];
}

function SelectCommunitiesDialog(props: Props) {
  const { open, onClose, defaultData } = props;
  const { communityService } = useApi();
  const [loading, setLoading] = useState(false);
  const [communities, setCommunities] = useState<CommunityDisplay[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const getCommunities = async () => {
    setLoading(true);
    const res = await communityService.getMyCommunities();
    if (res && (res as CommunityDisplay[]).length > 0) {
      setCommunities(res);
      setLoading(false);
    } else {
      getCommunitiesRecommended();
    }
  };
  const getCommunitiesRecommended = async () => {
    const res = await communityService.getCommunitiesRecommended();
    if (res && (res as CommunityDisplay[]).length > 0) {
      setCommunities(res);
    }
    setLoading(false);
  };

  const handleSelectCommunity = (id: string) => {
    if (selected.includes(id)) {
      const newValue = selected.filter((f) => f !== id);
      setSelected(newValue);
    } else {
      setSelected([...selected, id]);
    }
  };

  const getRandomBgColor = (type: string, setting: string) => {
    var pastels = [
      "#C1E5C0",
      "#C0DAE5",
      "#F39F9F",
      "#FDD9D9",
      "#FFC3D8",
      "#A0DCFF",
    ];
    if (type === "public") {
      return pastels[0];
    } else if (type == "private" && setting == "dynamic") {
      return pastels[1];
    } else {
      return pastels[2];
    }
  };

  useEffect(() => {
    getCommunities();
    if (defaultData) {
      setSelected(defaultData);
    }
  }, []);

  return (
    <Modal open={open} onClose={() => onClose(selected)} sx={{ height: '90vh'}}>
      <ModalDialog
        aria-labelledby="layout-modal-title-reanme"
        aria-describedby="layout-modal-description-reanme"
        size="lg"
        sx={{ width: { 
          xs: '480px',
            sm: '480px',
            md: '480px',
            lg: '640px',
            xl: '640px'
         } }}
        
      >
        <ModalClose />
        <Typography
          id="layout-modal-title-reanme"
          level="h2"
          sx={{ fontSize: 24 }}
        >
          Communities
        </Typography>
        {loading ? (
          <Box className="box-column">
            <CircularProgress size="md" />
          </Box>
        ) : (
          <Box sx={{ overflow: "auto" }}>
            <List>
              {communities.map((community) => (
                <ListItem
                  endAction={
                    <Checkbox
                      variant="outlined"
                      checked={selected.includes(community.id)}
                      onChange={() => handleSelectCommunity(community.id)}
                    />
                  }
                >
                  <ListItemButton
                    color="primary"
                    onClick={() => handleSelectCommunity(community.id)}
                  >
                    <ListItemAvatar sx={{ pr: 2 }}>
                      <Avatar
                        sx={{
                          "--Avatar-size": "50px",
                          bgcolor: getRandomBgColor(
                            community.communitiesType,
                            community.communitySettingType
                          ),
                          color: "white",
                        }}
                      >
                        {community.name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemContent>
                      <Typography
                        level="body-md"
                        fontWeight={selected.includes(community.id) ? 700 : 500}
                      >
                        {community.name}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
}

export default SelectCommunitiesDialog;
