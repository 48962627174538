import {
  KeyboardArrowDown,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { InputType } from "../../../constants/enum";
import { AnswerSurvay, QuestionSurvey } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: Function;
  onSave: Function;
  surveyName: string;
  surveyId: string;
  surveyEnded: Function;
  nonUser?: boolean;
  nonUserId?: string;
}

function parseTextForUrls(text: string) {
  const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  const parts = text.split(urlRegex);
  return parts.filter((part: any) => part); // Filter out empty strings
}

function ResponseQuestionsurveyDialog(props: Props) {
  const { open, surveyId, surveyName, onClose, onSave, surveyEnded, nonUser,nonUserId } = props;
  const { sessionService, showMessage } = useApi();
  const [questions, setQuestions] = useState<QuestionSurvey[]>([]);
  const [selected, setSelected] = useState<QuestionSurvey>();
  const [loading, setLoading] = useState(false);

  const renderTextWithLinks = (text: string) => {
    const parts = parseTextForUrls(text);
    return parts.map((part, index) => {
      if (part.match(/(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\b[-A-Z0-9+&@#/%=~_|.]*\.[A-Z]{2,4}\b(?:\/[-A-Z0-9+&@#/%=~_|]*)?)/i)) {
        const formattedPart = part.startsWith('http://') || part.startsWith('https://') ? part : `https://${part}`;
        return <a key={index} href={formattedPart} target="_blank" rel="noopener noreferrer">{part}</a>;
      } else {
        return part;
      }
    });
  };

    const checkAnswered = () => {
      let contained = false;
      for (let index = 0; index < questions.length; index++) {
        const element = questions[index];

        if (element.answer && element.answer?.trim().length !== 0) {
          contained = true;
          break;
        }
      }
      return contained;
    };
    const handleClickCancel = () => {
      if (checkAnswered()) {
        onClose(true);
      } else if(nonUser){
        onClose(true);
      }else {
        onClose();
      }
    };

    const handleSubmit = async () => {
      let params: AnswerSurvay[] = [];
      questions.forEach((ele) => {
        if (ele._id && ele.answer) {
          const map: AnswerSurvay = {
            questionId: ele._id,
            answer: ele.answer,
          };
          params.push(map);
        }
      });
      if (params.length !== questions.length) {
        showMessage(
          `Oops! You haven't answered all the questions.\nPlease answer all the questions before tapping on Submit.`,
          "warning"
        );
        return;
      }
      setLoading(true);
      if (nonUser) {
        await sessionService.lightsterAnswerSurveyNonUser(surveyId, nonUserId || "-", {
          questions: params,
        });
      } else{
      await sessionService.lightsterAnswerSurvey(surveyId, {
        questions: params,
      });
    }
      setTimeout(() => {
        setLoading(false);
        onSave();
      }, 1000);
    };

    useEffect(() => {
      const getSurveyDetail = async () => {
        const res = nonUser ? await sessionService.getLightsterSurveyDetailNonUser(surveyId,nonUserId || "-") : await sessionService.getLightsterSurveyDetail(surveyId);
        setQuestions(res.questions);
      };

      getSurveyDetail();
    }, []);

    useEffect(() => {
      const checkSurveyActive = async () => {
        const res = nonUser ? await sessionService.checkSurveyActiveNonUser(surveyId,nonUserId || "-") : await sessionService.checkSurveyActive(surveyId)
        if (!res || !res.isActive) {
          surveyEnded();
        }
      };
      var interval = setInterval(() => {
        if (!loading) {
          checkSurveyActive();
        }
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return (
      <Modal open={open}>
        <ModalDialog size="lg" sx={{ overflow: "auto" }}>
          <Typography level="h2" sx={{ fontSize: 24 }}>
            {surveyName}
          </Typography>
          {loading ? (
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems={"center"}
              minHeight={300}
            >
              <CircularProgress size="md" />
            </Stack>
          ) : (
            <Box>
              {selected ? (
                
                <Stack direction={"column"} gap={3} mt={3}>
                  {selected.inputType === InputType.TEXTFIELD ? (
                    <FormControl>
                      <div style={{ width: "90%", textWrap: "balance" }}>
                      <FormLabel>
                        {renderTextWithLinks(selected.title)}
                      </FormLabel>
                      </div>
                      <Textarea
                        minRows={3}
                        value={selected.answer}
                        onChange={(e) =>
                          setSelected({ ...selected, answer: e.target.value })
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl>
                      <div style={{ width: "98%", wordWrap: "normal", whiteSpace: "normal", overflow:"visible" }}>
                      <FormLabel style={{ wordBreak: "normal", whiteSpace: "normal", display: "block" }}>
                        {renderTextWithLinks(selected.title)}
                      </FormLabel>
                      </div>
                      <Select
                        placeholder={"Select"}
                        indicator={<KeyboardArrowDown />}
                        value={selected.answer}
                        onChange={(e, newValue) =>
                          setSelected({ ...selected, answer: newValue ?? "" })
                        }
                      >
                        {selected.keywordList.map((key) => (
                          <Option key={key} value={key}>
                            {key}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <Stack direction={"row"} justifyContent="end" gap={2}>
                    <PlainButton
                      variant="plain"
                      onClick={() => setSelected(undefined)}
                    >
                      Cancel
                    </PlainButton>
                    <SolidButton
                      variant="solid"
                      onClick={() => {
                        const index = questions.findIndex(
                          (f) => f._id === selected._id
                        );
                        questions[index].answer = selected.answer;
                        setQuestions(questions);
                        setSelected(undefined);
                      }}
                    >
                      Save
                    </SolidButton>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="column" gap={3} mt={3}>
                  <Box overflow={"auto"} maxHeight={"60vh"} sx={{ "&::-webkit-scrollbar": {display: "hidden"}}}>
                  <Stack direction="column" gap={2} >
                  
                    {questions.map((question) => (
                      <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", wordBreak: "normal", whiteSpace: "normal", display: "block" }}>
                      <QuestionItem
                        key={question._id}
                        title={question.title}
                        answer={question.answer ?? ""}
                        onClick={() => {
                          setSelected(question);
                        }}
                      />
                      </div>
                    ))} 
                    
                  </Stack>
                  </Box>
                  <Stack direction={"row"} justifyContent="end" gap={2}>
                    <PlainButton variant="plain" onClick={handleClickCancel}>
                      Cancel
                    </PlainButton>
                    <SolidButton variant="solid" onClick={handleSubmit}>
                      Submit
                    </SolidButton>
                  </Stack>
                </Stack>
                
              )}
            </Box>
          )}
        </ModalDialog>
      </Modal>
    );
  }

  export default ResponseQuestionsurveyDialog;

  interface ItemProps {
    title: string;
    answer: string;
    onClick: Function;
  }
  const QuestionItem = ({ title, answer, onClick }: ItemProps) => {
    return (
      <Box
        sx={{
          border: "1px solid black",
          borderRadius: 6,
          padding: 2,
          cursor: "pointer",
        }}
        onClick={() => onClick()}
      >
        {answer.length > 20 ? (
          <Stack direction={"column"} gap={1} alignItems={"start"}>
            <Stack width={"100%"} direction="row" justifyContent="space-between">
              <Typography level="title-md">{title}</Typography>
              <KeyboardArrowDownRounded color="primary" />
            </Stack>

            <Typography
              level="body-md"
              textAlign={"right"}
              sx={{ color: (theme) => theme.palette.primary[500] }}
            >
              {!answer ? "Answer" : answer}
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"start"}
          >
            <Typography level="title-md">{title}</Typography>
            <Typography
              level="body-md"
              textAlign={"right"}
              sx={{
                color: (theme) => theme.palette.primary[500],
                ml: 3,
                maxWidth: "80%",
              }}
            >
              {!answer ? "Answer" : answer}
            </Typography>
          </Stack>
        )}
      </Box>
    );
  };
