import { createTheme } from "@mui/material";
export const primaryPurple = "#7730E1";
export const primaryPurpleDark = "#4700B2";
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    mm: true;
    lg: true;
    xl: true;
  }
}
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      mm: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#7730E1",
      dark: "#4700B2",
      light: "#EFE7FA",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#7F889B",
      main: "#000000",
      dark: "#606A7C",
      contrastText: "#FFF",
    },
    success: {
      light: "#83E542",
      main: "#72E128",
      dark: "#64C623",
      contrastText: "#FFF",
    },
    error: {
      light: "#FF625F",
      main: "#FF4D49",
      dark: "#E04440",
      contrastText: "#FFF",
    },
    warning: {
      light: "#FDBE42",
      main: "#FDB528",
      dark: "#DF9F23",
      contrastText: "#FFF",
    },
    info: {
      light: "#40CDFA",
      main: "#26C6F9",
      dark: "#21AEDB",
      contrastText: "#FFF",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 48,
          fontSize: "18px",
          borderRadius: "50px",
          textTransform: "capitalize",
          px: 2,
        },
      },
      variants: [
        {
          props: { variant: "contained", disableElevation: true },
          style: {
            textTransform: "capitalize",
            color: "white",
            backgroundColor: "#4700B2",
            fontSize: "18px",
            borderRadius: "50px",
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          borderRadius: "30px",
          maxWidth: "100%",
          fontSize: "14px",
          fontWeight: 500,
        },
        root: {
          borderRadius: "30px",
          ".MuiOutlinedInput-root": {
            padding: "0px 13px",
            height: "48px",
            color: primaryPurple,
          },
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            order: "none",
          },
          "&.Mui-focused fieldset": {
            order: "none",
          },
        },
      },
      variants: [
        {
          props: {
            size: "medium",
          },
          style: {
            height: 48,
            borderRadius: "30px",
            maxWidth: "100%",
            fontSize: "14px",
            fontWeight: 500,
            color: "black",
          },
        },
      ],
    },
  },
});
