import { Box, Grid, IconButton, Stack, Typography, styled } from "@mui/joy";
import {
  UnmoderatedAnswer,
  UnmoderatedResult,
} from "../../constants/interfaces";
import moment from "moment";
import { CustomButtton } from "../../custom-styles";
import { ArrowDropDown } from "@mui/icons-material";
import { useState } from "react";
import { Hidden, useMediaQuery } from "@mui/material";
import { theme } from "../../mui-styles";

interface Props {
  unmoderated: UnmoderatedResult;
  refresh?: Function;
}

function CreatorUnmoderatedItemList({ unmoderated, refresh }: Props) {
  const isXlUp = useMediaQuery(theme.breakpoints.down("xl"));
  const colSpan = isXlUp ? 5 : 6;

  const handleClick = () => {
    window.open(unmoderated.url, "_blank");
  };

  const handleClickTranscription = () => {
    window.open(unmoderated.urlTranscription, "_blank");
  };

  const StyledTable = styled("table")({
    width: "95%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
  });

  const StyledTd = styled("td")({
    textAlign: "left",
    wordWrap: "break-word",
  });

  const StyledExpaneded = styled("div")({
    padding: "24px",
    borderRadius: 12,
    backgroundColor: "white",
    border: "1px solid #000",
  });
  const Item = styled(Grid)({
    padding: "6px 4px",
    fontSize: 12,
  });
  const StyledTrExpanded = styled("tr")({
    ":hover": {
      background: "none",
    },
  });
  const nameColumnStyle = { width: "20%" };
  const lightsterNameColumnStyle = { width: "20%" };
  const completedDateColumnStyle = { width: "30%" };
  const durationColumnStyle = { width: "20%" };
  const buttonColumnStyle = { width: "10%" };
  const [expanded, setExpanded] = useState<boolean>(false);

  const mapInputType = (type: string) => {
    switch (type) {
      case "verbalResponse":
        return "Verbal Response";
      case "ratingScale":
        return "Rating Scale (0 to 5)";
      case "selectList":
        return "Multiple Choice";
      case "textField":
        return "Text Response";
      default:
        return "Text Response";
    }
  };

  const showStepHeader = (answer: UnmoderatedAnswer, index: number) => {
    if (
      answer.step === unmoderated.answer[index].step &&
      answer.question === -1
    )
      return true;
    return false;
  };

  return (
    <Box className="unmoderated-box-shadow">
      <StyledTable>
        <tbody>
          <tr>
            <StyledTd style={nameColumnStyle}>
              <Stack direction={"row"} pl={2} gap={1} alignItems={"center"}>
                <IconButton
                  onClick={() => setExpanded((prev) => !prev)}
                  size="sm"
                  sx={{
                    ":hover": {
                      background: "transparent",
                    },
                  }}
                >
                  <ArrowDropDown
                    sx={{ color: "black", fontSize: "2em" }}
                    className={`fa-arrow-down ${expanded && "open"}`}
                  />
                </IconButton>
                <Typography level="title-lg">
                  {unmoderated.name || "No Name"}
                </Typography>
              </Stack>
            </StyledTd>
            <StyledTd style={lightsterNameColumnStyle}>
              <Typography level="body-md">
                {unmoderated.lightsterName || "No Lightster Name"}
              </Typography>
            </StyledTd>
            <Hidden xlDown>
              <StyledTd style={completedDateColumnStyle}>
                <Typography level="body-md">
                  {unmoderated.completedDate
                    ? moment(unmoderated.completedDate).format(
                        "MMM DD, YYYY hh:mm A"
                      )
                    : "No Completion Date"}
                </Typography>
              </StyledTd>
            </Hidden>
            <StyledTd style={durationColumnStyle}>
              <Typography level="body-md">
                {unmoderated.duration
                  ? moment(unmoderated.duration * 1000).format("mm:ss") +
                    " minutes"
                  : "No Duration"}
              </Typography>
            </StyledTd>
            <StyledTd style={buttonColumnStyle}>
              <CustomButtton
                onClick={handleClick}
                style={{ justifyContent: "end", paddingRight: "16px" }}
              >
                Recording
              </CustomButtton>
            </StyledTd>
            <StyledTd style={buttonColumnStyle}>
              <CustomButtton
                onClick={handleClickTranscription}
                style={{ justifyContent: "end", paddingRight: "16px" }}
              >
                Transcript
              </CustomButtton>
            </StyledTd>
          </tr>
          {expanded && (
            <StyledTrExpanded>
              <td colSpan={colSpan}>
                <StyledExpaneded
                  style={{
                    maxHeight: 262,
                    overflowY: "auto",
                    marginLeft: "56px",
                    marginRight: 16,
                  }}
                >
                  {unmoderated.answer.map((answer, index) => (
                    <div key={`unmoderated-answer-${index}`}>
                      {showStepHeader(answer, index) && (
                        <Grid
                          container
                          pb={"6px"}
                          pt={index === 0 ? 0 : "18px"}
                          paddingX={"4px"}
                        >
                          <Grid xs={10}>
                            <Typography
                              level="title-sm"
                              sx={{ fontWeight: 700, fontSize: 12 }}
                            >
                              Step {answer.step + 1} - {answer.detail}
                            </Typography>
                          </Grid>
                          <Grid xs={2}>
                            <Typography
                              level="title-sm"
                              sx={{
                                fontWeight: 700,
                                fontSize: 12,
                                textAlign: "right",
                              }}
                            >
                              {moment(answer.duration * 1000).format("mm:ss")}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {answer.question !== -1 && (
                        <Grid container>
                          <Item xs={3} className="text-overflow ">
                            {answer.detail}
                          </Item>
                          <Item xs={3} className="text-overflow ">
                            {mapInputType(answer.inputType)}
                          </Item>
                          <Item xs={4} style={{ lineBreak: "anywhere" }}>
                            {answer.inputType === "verbalResponse"
                              ? "See recording"
                              : answer.answer}
                          </Item>
                          <Item xs={2} sx={{ textAlign: "right" }}>
                            {moment(answer.duration * 1000).format("mm:ss")}
                          </Item>
                        </Grid>
                      )}
                    </div>
                  ))}
                </StyledExpaneded>
              </td>
            </StyledTrExpanded>
          )}
        </tbody>
      </StyledTable>
    </Box>
  );
}

export default CreatorUnmoderatedItemList;
