import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Box,
  Divider,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { mapStatusText } from "../../constants/constants";
import { SurvayDisplay } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import InfoDialog from "../dialog/InfoDialog";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import { StorageType } from "../../constants/enum";

interface Props {
  survey: SurvayDisplay;
  refresh?: Function;
}

function CreatorSurveyItem(props: Props) {
  const { survey, refresh } = props;
  const { user } = useAuth();
  const { sessionService } = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    view: false,
    end: false,
  });

  const handleViewReault = async (id: string) => {
    await sessionService.getSurveyResultEmail(id);

    setOpen({ ...open, view: true });
  };
  const handleEndSurvey = async (id: string) => {
    await sessionService.endSurvey(id);
    setOpen({ ...open, end: true });
  };
  const handleDuplicate = async (id: string) => {
    const data = await sessionService.detailSurvey(id);
    localStorage.setItem(StorageType.survry, JSON.stringify(data));

    navigate(pagesName.creator.survey);
    return;

  };
  return (
    <Box className="invite-box-shadow">
      <Stack direction="column" gap={2} p={2}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Box>
            <Typography
              level="title-lg"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {survey.name}
            </Typography>
          </Box>
          <Typography
            level="body-md"
            textAlign={"right"}
            sx={{ minWidth: 120 }}
          >
            {survey.received}/{survey.expectResponse} received
          </Typography>
        </Stack>
        <Typography level="body-md">
          {survey.questions} questions, {survey.expectResponse} responses
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography level="body-md">{survey.communityName}</Typography>

          <Dropdown>
            <MenuButton
              variant="plain"
              size="sm"
              color="primary"
              sx={{
                minWidth: 120,
                paddingInline: 1,
                justifyContent: "end",
                fontWeight: 500,
              }}
              endDecorator={
                <KeyboardArrowDownRounded
                  fontSize="small"
                  sx={{ color: "black" }}
                />
              }
            >
              {mapStatusText(survey.status)}
            </MenuButton>
            <Menu size="sm">
              <MenuItem
                color="primary"
                sx={{
                  fontSize: 14,
                  justifyContent: "right",
                  color: "black",
                  ":hover": {
                    color: (theme) => theme.palette.primary.darkChannel,
                    background: (theme) => theme.palette.primary.lightChannel,
                  },
                }}
                onClick={() => handleViewReault(survey.id)}
              >
                View results
              </MenuItem>
              <Divider />
              <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    color: "black",
                    justifyContent: "right",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => handleDuplicate(survey.id)}
                >
                  Duplicate
                </MenuItem>
              {survey.status === "completed" ? (
                null
              ) : (
                <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    color: "black",
                    justifyContent: "right",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => handleEndSurvey(survey.id)}
                >
                  End survey now
                </MenuItem>
              )}
            </Menu>
          </Dropdown>
        </Stack>
      </Stack>
      <InfoDialog
        open={open.view}
        onClose={() => {
          setOpen({ ...open, view: false });
        }}
        title={"The results have been e-mailed to you."}
        subtitle={
          <Typography level="body-md">
            We’ve e-mailed the survey results to your e-mail{" "}
            <Typography level="title-md" fontWeight={700}>
              {user.email}
            </Typography>
            .
          </Typography>
        }
      />
      <InfoDialog
        open={open.end}
        onClose={() => {
          setOpen({ ...open, end: false });
          if (refresh) {
            refresh();
          }
        }}
        title={"Confirmation"}
        subtitle={
          <Typography level="body-md">
            You have successfully ended the survey, and no more responses will
            be accepted.
          </Typography>
        }
      />
    </Box>
  );
}

export default CreatorSurveyItem;
