import { Box, Button, Grid, Tooltip, Typography } from "@mui/joy";
import { SetStateAction, useEffect } from "react";
import "../../index.css";
import MediaPlayer, { PlacehoderLayout } from "./MediaPlayer";
import { WaitingCallLayout } from "./WaitingCallLayout";
// import ChatRoom from "../../components/ChatRoom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { guestsList } from "../../constants/AppImages";
import { RoleType } from "../../constants/enum";
import { Session } from "../../constants/interfaces";
import { useAuth } from "../../providers/AuthProvider";
import ChatRoom from "../ChatRoom";
import { LiaThumbtackSolid } from "react-icons/lia";


interface VideoCallLayoutProps {
  start: boolean;
  pin: any;
  setPin: SetStateAction<any>;
  localTracks: any;
  remoteUsers: Array<any>;
  session: Session;
  guest: boolean;
  questLightster: boolean;
  shareScreenUid: string | undefined;
  chat?: {
    openChat: boolean;
    setOpenChat: (value: boolean) => void;
  };
}

export const VideoCallLayout = ({
  start,
  pin,
  setPin,
  localTracks,
  remoteUsers,
  session,
  guest,
  questLightster,
  shareScreenUid,
  chat,
}: VideoCallLayoutProps) => {
  const { role } = useAuth();

  const getReceiverId = () => {
    if (role === "creator") {
      return session.lightster.id;
    } else {
      return session.creator.id;
    }
  };
  const getImageProfile = (uid: number) => {
    if (uid === parseInt(session.lightster.agoraUID)) {
      return session.lightster?.imageProfile;
    } else if (uid === parseInt(session.creator.agoraUID)) {
      return session.creator?.imageProfile;
    } else {
      return guestsList[0];
    }
  };

  const getLocalImageProfile = () => {
    if (guest) {
      return guestsList[1];
    }
    return role === "creator"
      ? session?.creator?.imageProfile
      : session?.lightster?.imageProfile;
  };

  const getUserRole = (uid: number | undefined) => {
    if (uid === parseInt(session.creator.agoraUID)) {
      return session.creator.displayname;
    } else if (uid === parseInt(session.lightster.agoraUID)) {
      return session.lightster.displayname;
    } else if (guest && uid === parseInt(session.agoraUID)) {
      return "me";
    } else {
      return null;
    }
  };

  const getUid = () => {
    if (questLightster) {
      return parseInt(session.lightster.agoraUID);
    } else if (role === RoleType.lightster) {
      return parseInt(session.lightster.agoraUID);
    } else if (role === RoleType.creator) {
      return parseInt(session.creator.agoraUID);
    } else {
      return parseInt(session.agoraUID);
    }
  };

  const setDefaultPinUser = () => {
    if (!start) {
      setPin(null);
    } else if (shareScreenUid) {
      setPin(() => {
        return remoteUsers.find((f: any) => f.uid === parseInt(shareScreenUid));
      });
    } else if (role === "lightster") {
      setPin(() => {
        return remoteUsers.find(
          (f: any) => f.uid === parseInt(session.creator.agoraUID)
        );
      });
    } else if (questLightster) {
      setPin(() => {
        return remoteUsers.find(
          (f: any) => f.uid === parseInt(session.creator.agoraUID)
        );
      });
    } else {
      setPin(() => {
        return remoteUsers.find(
          (f: any) => f.uid === parseInt(session.lightster.agoraUID)
        );
      });
    }
  };

  useEffect(() => {
    setDefaultPinUser();
  }, [start, shareScreenUid, remoteUsers]);

  return (
    <Grid container height={"100%"} direction="row" marginLeft={0.5} width={"99.5%"} >
      {/* larger screen */}
      <Grid sx={{ height: { xs: '65%', sm: '77%', md: '75%', mm: '74%', lg: "100%" } }} xs={12} mm={12} lg={10}>
        <Grid height={"100%"} p={1} pb={0.5}>
          <Grid width={"100%"} id="share" height={"100%"} >
            {/* large screen */}
            {!start && !chat?.openChat ? (
              <WaitingCallLayout guest={guest} />
            ) : chat?.openChat ? (
              <ChatRoom
                chatVideoCall={true}
                receiverId={getReceiverId()}
                onClose={() => chat?.setOpenChat(false)}
              />
            ) : (
              <TagContainer
                name={!pin ? getUserRole(getUid()) : getUserRole(pin?.uid)}
                showIcon={false}
              >
                {!pin && localTracks.video ? (
                  localTracks?.video?.enabled ? (
                    <MediaPlayer videoTrack={localTracks?.video} />
                  ) : (
                    <PlacehoderLayout imageUrl={getLocalImageProfile()} />
                  )
                ) : pin?.videoTrack ? (
                  <MediaPlayer videoTrack={pin?.videoTrack} />
                ) : (
                  <PlacehoderLayout imageUrl={getImageProfile(pin?.uid)} />
                )}
              </TagContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} mm={12} lg={2} sx={{ height: { xs: '32%', sm: '24%', md: '25%', mm: '30%', lg: "30%" } }}>
        <Grid
          height={"100%"}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {pin || !start ? (
            <Grid sx={{ height: '100%', width: { lg: '100%', mm: "25%", md: "25%", sm: "30%", xs: "45%" } }} p={1} >
              {/* pin screen and session has not started */}
              <TagContainer
                name={getUserRole(getUid())}
                onClick={() => setPin(null)}
              >
                {localTracks.video && localTracks.video?.enabled ? (
                  <MediaPlayer videoTrack={localTracks?.video} />
                ) : (
                  <PlacehoderLayout imageUrl={getLocalImageProfile()} />
                )}
              </TagContainer>
            </Grid>
          ) : null}
          {remoteUsers.map((user: any, i: number) => {
            if (user !== pin) {
              if (user?.videoTrack) {
                return (
                  <Grid key={user.uid} sx={{ height: '100%', width: { lg: '100%', mm: "100%", md: "25%", sm: "50%", xs: "50%" } }} p={1}>
                    {/* small screen when user open camera */}
                    <TagContainer
                      name={getUserRole(user.uid)}
                      onClick={() => setPin(user)}
                    >
                      <MediaPlayer
                        key={user.uid}
                        videoTrack={user.videoTrack}
                      />
                    </TagContainer>
                  </Grid>
                );
              } else {
                return (
                  <Grid key={user.uid} sx={{ height: '100%', width: { lg: '100%', mm: "25%", md: "25%", sm: "30%", xs: "50%" } }} p={1}>
                    {/* small screen when user video off */}
                    <TagContainer
                      name={getUserRole(user.uid)}
                      onClick={() => setPin(user)}
                    >
                      <PlacehoderLayout
                        key={user.uid}
                        imageUrl={getImageProfile(user.uid)}
                      />
                    </TagContainer>
                  </Grid>
                );
              }
            } else return null;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
interface TagContainerProps {
  children: JSX.Element;
  name?: string | null;
  showIcon?: boolean;
  onClick?: () => void;
}
const TagContainer = ({
  children,
  name,
  showIcon,
  onClick,
}: TagContainerProps) => {
  const handle = useFullScreenHandle();

  return (
    <Box position={"relative"} height="100%" class-name="camera-off-box-outer">
      <FullScreen handle={handle} className="fullscreen-enabled">
        {children}
      </FullScreen>
      <Box position="absolute" className="camera-off-box" bottom={0} width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Box display="flex" bgcolor="transparent" pl={1} flex={1}>
            {name && (
              <Typography
                level="body-md"
                sx={{
                  color: "white",
                  fontSize: "13px",
                  maxWidth: "auto",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {name}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" justifyContent={"flex-end"}>
            {(showIcon ?? true) && (
              <Tooltip title="Set to main screen">
                <Box
                  display="flex"
                  bgcolor='rgba(0, 0, 0, 0.1)'
                  component={Button}
                  variant="solid"
                  onClick={onClick}
                  sx={{
                    '&:hover': {
                      bgcolor: 'rgba(0, 0, 0, 0.3)',
                    },
                  }}
                  p={1}
                >
                  <LiaThumbtackSolid size={'20px'} color="white" />
                </Box>
              </Tooltip>
            )}
            <Box
              display="flex"
              bgcolor='rgba(0, 0, 0, 0.1)'
              component={Button}
              variant="solid"
              onClick={handle.enter}
              sx={{
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
              p={1}
            >
              <Tooltip title="Full Screen">
                <FullscreenIcon sx={{ color: "white", fontSize: 23 }} />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};