import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";

import { EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import {
    Autocomplete,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Textarea,
    Typography
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import industry from "../../../assets/jsons/industry.json";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import { ProfileParams } from "../../../constants/interfaces";
import { primaryPurple } from "../../../mui-styles";
import { useApi } from "../../../providers/ApiProvider";

interface Props {}
const keyRequired = [
  "companyname",
  "companyindustry",
  "companywebsite",
  "productdescription",
  "companydontshowname",
  "productdescription",
];
function CreatorAboutProfile(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { profileService } = useApi();
  const [profile, setProfile] = useState(Array<ProfileParams>);
  const [errors, setErrors] = useState({
    companyname: false,
    companyindustry: false,
    companylocation: false,
    jobtitle: false,
    productdescription: false,
  });

  const { handleToggle, showMessage } = useApi();

  const getCompanyProfile = async () => {
    const res = await profileService.getCompany();
    if (!res) return;
    const list: Array<ProfileParams> = [];
    res.map((p: any) => {
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: p.answers,
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile(list);
  };

  const getProfileField = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data;
  };

  const handleOnChange = (field: string, value: any) => {
    const updatedObject = profile.map((p: ProfileParams) => {
      if (p.key === field) {
        const data: ProfileParams = {
          profileId: p.profileId,
          key: p.key,
          answers: value ? [value] : [],
          keywordList: p.keywordList,
        };
        return data;
      } else {
        return p;
      }
    });

    setProfile(updatedObject);
  };

  const handleError = (field: string, value: boolean) => {
    setErrors({ ...errors, [field]: value });
  };

  const textEmpty = (value: any) => {
    return ["", null, undefined].includes(value);
  };
  const handleSubmitError = async () => {
    const newErrors: any = {};
    for (let index = 0; index < profile.length; index++) {
      if (
        textEmpty(profile[index].answers[0]) &&
        [
          "companyname",
          "companyindustry",
          "companylocation",
          "jobtitle",
          "productdescription",
        ].includes(profile[index]?.key ?? "")
      ) {
        newErrors[`${profile[index].key}`] = true;
      }
    }
    setErrors((pre: any) => newErrors);
    return newErrors;
  };
  const getCreatorParams = () => {
    let profiles: Array<any> = [];

    (profile as Array<ProfileParams>).map((pro) => {
      if (pro.answers.length > 0) {
        profiles.push({
          profileId: pro.profileId,
          answers: pro.answers,
        });
      }
      if (pro.key === "companydontshowname") {
        profiles.push({
          profileId: pro.profileId,
          answers: pro.answers.length > 0 ? pro.answers : ["false"],
        });
      }
    });
    return { profiles: profiles };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const contained = await handleSubmitError();

    if (Object.values(contained).includes(true)) {
      showMessage("Please complete the information.", "error");
      return;
    }

    handleToggle(true);
    const res = await profileService.updateCreatotProfile(getCreatorParams());
    handleToggle(false);
    if (!res) return;
    navigate(pagesName.creator.productKeywords);
  };
  useEffect(() => {
    getCompanyProfile();

    const handleBeforeUnload = (event:any) => {
      localStorage.clear();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <BackgroundRegister>
        <Stack direction={"column"} mt={"10vh"}>
          <BorderLinearProgress determinate value={90} sx={{ mb: 11 }} />
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                Briefly describe your product
              </Typography>
              <Typography level="body-md" mt={1}>
                This helps users get more context about what you’re working on
                when you are connected.
              </Typography>
            </Box>
            <FormControl>
              <FormLabel>Company name</FormLabel>
              <Input
                autoFocus
                fullWidth
                error={errors.companyname}
                value={getProfileField("companyname")?.answers[0]}
                onChange={(e: any) => {
                  if (errors.companyname && e.target.value) {
                    handleError("companyname", false);
                  }
                  handleOnChange("companyname", e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Industry</FormLabel>
              <Autocomplete
                placeholder="Industry"
                options={industry.data}
                error={errors.companyindustry}
                value={getProfileField("companyindustry")?.answers[0] ?? ""}
                onChange={(_, value) => {
                  if (errors.companyindustry && value) {
                    handleError("companyindustry", false);
                  }
                  handleOnChange("companyindustry", value);
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Website</FormLabel>
              <Input
                fullWidth
                value={getProfileField("companywebsite")?.answers[0]}
                onChange={(e: any) =>
                  handleOnChange("companywebsite", e.target.value)
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Pitch</FormLabel>
              <Textarea
                minRows={4}
                error={errors.productdescription}
                value={getProfileField("productdescription")?.answers[0]}
                onChange={(e: any) => {
                  if (errors.productdescription && e.target.value) {
                    handleError("productdescription", false);
                  }
                  handleOnChange("productdescription", e.target.value);
                }}
              />
            </FormControl>

            <Stack direction={"row"} spacing={1} alignItems="center">
              <i
                className="bi bi-check2-circle"
                style={{
                  fontSize: 24,
                  cursor: "pointer",
                  color:
                    getProfileField("companydontshowname")?.answers[0] ===
                    "true"
                      ? primaryPurple
                      : "grey",
                }}
                onClick={() => {
                  const data =
                    getProfileField("companydontshowname")?.answers[0] ===
                    "true";
                  handleOnChange("companydontshowname", `${!data}`);
                }}
              ></i>
              <Typography
                level="title-sm"
                sx={{
                  color:
                    getProfileField("companydontshowname")?.answers[0] ===
                    "true"
                      ? primaryPurple
                      : "grey",
                }}
              >
                Don’t share my company name
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={10}
            mb={5}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ minWidth: 128 }}
              onClick={handleSubmit}
              type="submit"
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </BackgroundRegister>
    </form>
  );
}

export default CreatorAboutProfile;
