import React from "react";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBG1M5w6O6wZwBxncmGpTeNzXQpOGhYtKE",
  authDomain: "lightster-app.firebaseapp.com",
  projectId: "lightster-app",
  storageBucket: "lightster-app.appspot.com",
  messagingSenderId: "1012221501963",
  appId: "1:1012221501963:web:a94368c7cedff22554dd0e",
  measurementId: "G-M40Z1560X3",
};
const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
const analytics = getAnalytics(app);
export const firebaseDB = getFirestore(app);

interface FirebaseProviderProps {
  children: string | JSX.Element;
}
export interface FirebaseContextType {
  googleSignIn: Function;
  facebookSignIn: Function;
  appleSignIn: Function;
}

export const FirbaseContext = React.createContext<FirebaseContextType | null>(
  {} as FirebaseContextType
);

export const useFirebaseAuth = () => {
  return React.useContext(FirbaseContext) as FirebaseContextType;
};

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const auth = getAuth();

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    return await signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        return result;
      })
      .catch((error: any) => {
        console.error(error);
        console.error(error.code);
        console.error(error.message);
        console.error(error.customData.email);
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");
    return await signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        return result;
      })
      .catch((error: any) => {
        console.error(error);
        console.error(error.code);
        console.error(error.message);
        console.error(error.customData.email);
        const credential = FacebookAuthProvider.credentialFromError(error);
      });
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    return await signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential = OAuthProvider.credentialFromResult(result);
        return result;
      })
      .catch((error: any) => {
        console.error(error);
        console.error(error.code);
        console.error(error.message);
        console.error(error.customData.email);
        const credential = OAuthProvider.credentialFromError(error);
      });
  };

  const value: FirebaseContextType = {
    googleSignIn: signInWithGoogle,
    facebookSignIn: signInWithFacebook,
    appleSignIn: signInWithApple,
  };

  return (
    <FirbaseContext.Provider value={value}>{children}</FirbaseContext.Provider>
  );
};
