import {
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Stack,
  Typography
} from "@mui/joy";
import moment from "moment";

interface Props {
  open: boolean;
  onClose: () => void;
  spot: string[];
  onSelected: (value: any) => void;
  nonUser?: boolean;

}

function GetYourSpotDialog(props: Props) {
  const { open, spot, onClose, onSelected,nonUser } = props;


  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        size="lg"
        sx={{
          maxHeight: '85vh', // Limit the height to 90% of the viewport height
          overflow: 'auto' // Add scrolling to the content if it exceeds the max height
        }}
      >
        { !nonUser && (<ModalClose />)}
        <Typography id="layout-modal-title" level="h3" fontSize={24}>
          Get your spot
        </Typography>
        <Typography
          id="layout-modal-description"
          sx={{ color: "black", fontSize: 18 }}
        >
          Please select the time that works best for you below.
        </Typography>
        <Stack spacing={2}>
          {spot.map((sheet) => (
            <Sheet
              variant="outlined"
              color="neutral"
              sx={{
                p: 2,
                borderRadius: "8px",
                borderColor: "black",
                cursor: "pointer",
                ":hover": {
                  border: (theme) => `1px solid ${theme.palette.primary[500]}`,
                  background: (theme) => theme.palette.primary[50],
                  color: (theme) => theme.palette.primary[500],
                },
              }}
              onClick={() => onSelected(sheet)}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography level="body-md">
                  {moment(sheet).format("MMM DD, YYYY, hh:mm A")}
                </Typography>
                <Typography level="body-md" color="primary">
                  Select
                </Typography>
              </Stack>
            </Sheet>
          ))}
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default GetYourSpotDialog;
