import config from "../config";
import { StorageType } from "../constants/enum";
import {
  CreateBookingParams,
  CreateScheduleParams,
  CreateSurveyParams,
  CreateUnmoderatedParams,
} from "../constants/interfaces";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

const getRowActive = () => {
  return localStorage.getItem(StorageType.role);
};

export default class SessionServices extends BaseService {
  async getSchedules() {
    const res = await this.call(
      "get",
      `${baseUrl}/${getRowActive()}/sessions?status=future`
    );
    if (res.data) {
      return res.data;
    }
  }

  async getUpcomingSchedules() {
    const res = await this.call(
      "get",
      `${baseUrl}/${getRowActive()}/sessions/upcoming`
    );
    if (res.data) {
      return res.data;
    }
  }
  async getSession(id: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/${getRowActive()}/sessions/${id}`
    );
    if (res.data) {
      return res.data;
    }
  }

  async startSession(id: string) {
    const res = await this.call("post", `${baseUrl}/sessions/${id}/start`);
    if (res.data) {
      return res.data;
    }
  }

  async endSession(id: string, quantity: number) {
    const res = await this.call("post", `${baseUrl}/sessions/${id}/end`, {
      quantity: quantity,
    });
    if (res.data) {
      return res.data;
    }
  }

  async leaveSession(id: string) {
    const res = await this.call("post", `${baseUrl}/sessions/${id}/leave`);
    if (res.data) {
      return res.data;
    }
  }

  async confirmSession(id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/${getRowActive()}/sessions/${id}`,
      {
        type: "confirm",
      }
    );

    return res.data;
  }
  async declineSession(id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/${getRowActive()}/sessions/${id}`,
      {
        type: "decline",
      }
    );
    return res.data;
  }
  async startRecording(sessionId: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/sessions/${sessionId}/records/start`
    );
    return res.data;
  }
  async stopRecording(sessionId: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/sessions/${sessionId}/records/stop`
    );
    return res.data;
  }

  async updateRecording(sessionId: string, agoraUID: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/sessions/${sessionId}/records/update?agoraUID=${agoraUID}`
    );
    return res.data;
  }

  async sessionRate(sessionId: string, rate: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/sessions/${sessionId}/rate`,
      {
        voteResult: rate,
      }
    );
    return res.data;
  }
  async guestJoinSession(meetingId: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/sessions/${meetingId}/join`
    );
    if (res.data) {
      return res.data;
    }
  }
  async guestJoinSessionLightster(meetingId: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/sessions/${meetingId}/joinLightster`
    );
    if (res.data) {
      return res.data;
    }
  }
  async sharescreenJoinSession(meetingId: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/sessions/${meetingId}/join/sharescreen`
    );
    if (res.data) {
      return res.data;
    }
  }
  async detailBooking(id: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/sessions/v2/quickbooking/${id}`
    );

    return res.data;
  }

  async confirmBooking(
    id: string,
    proposedTime: string,
    token: string,
    platform: string
  ) {
    const res = await this.call(
      "patch",
      `${baseUrl}/lightster/sessions/v2/quickbooking/${id}`,
      {
        proposedTime: proposedTime,
        token: token,
        platform: platform,
      }
    );

    return res.data;
  }

  async declineBooking(id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/lightster/sessions/v2/quickbooking/${id}`,
      { type: "decline" }
    );

    return res.data;
  }

  async createQuickBooking(params: CreateBookingParams) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/sessions/v2/quickbooking`,
      params
    );

    return res.data;
  }
  async updateQuickBooking(params: CreateBookingParams) {
    const res = await this.call(
      "patch",
      `${baseUrl}/creator/sessions/v2/quickbooking/${params.id}`,
      params
    );

    return res.data;
  }
  async createSchedule(params: CreateScheduleParams) {
    const res = await this.call("post", `${baseUrl}/creator/sessions`, params);

    return res.data;
  }

  async stopQuickBooking(id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/creator/sessions/v2/quickbooking/${id}/end`
    );
    return res.data;
  }
  async checkSessionEnded(id: string) {
    const res = await this.call("post", `${baseUrl}/sessions/${id}/check`);
    return res.data;
  }
  async checkSessionEndedGuest(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/sessions/${id}/check/guest`
    );
    return res.data;
  }
  async checkSessionEndedGuestLightster(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/sessions/${id}/check/guestLightster`
    );
    return res.data;
  }

  async createSurvey(params: CreateSurveyParams) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/survey_new`,
      params
    );
    return res.data;
  }
  async checkSurveyActive(id: String) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/survey/${id}/isActive`
    );
    return res.data;
  }
  async checkSurveyActiveNonUser(id: String, userId: String) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/survey/${id}/isActive/${userId}`
    );
    return res.data;
  }
  async getLightsterSurveyDetail(id: String) {
    const res = await this.call("get", `${baseUrl}/lightster/survey/${id}`);
    return res.data;
  }
  async lightsterAnswerSurvey(id: String, params: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/survey/${id}`,
      params
    );
    return res.data;
  }
  async getLightsterSurveyDetailNonUser(id: String, userId: String) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/survey/${id}/${userId}`
    );
    return res.data;
  }
  async lightsterAnswerSurveyNonUser(id: String, userId: String, params: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/survey/${id}/${userId}`,
      params
    );
    return res.data;
  }
  async getSurveyResultEmail(id: String) {
    const res = await this.call("get", `${baseUrl}/creator/survey/${id}`);
    return res.data;
  }
  async endSurvey(id: String) {
    const res = await this.call("patch", `${baseUrl}/creator/survey/${id}/end`);
    return res.data;
  }
  async detailSurvey(id: String) {
    const res = await this.call("get", `${baseUrl}/creator/survey/${id}/detail`);
    return res.data;
  }
  async creatorChangeScheduld(payload: any, id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/creator/sessions/${id}`,
      payload
    );
    return res.data;
  }
  async lightsterChangeScheduld(payload: any, id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/lightster/sessions/${id}`,
      payload
    );
    return res.data;
  }
  async detailBookingNonUser(id: string, userId: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/sessions/v2/quickbooking/${id}/${userId}`
    );

    return res.data;
  }
  async confirmBookingNonUser(
    id: string,
    proposedTime: string,
    userId: string,
    token: string,
    platform: string
  ) {
    const res = await this.call(
      "patch",
      `${baseUrl}/lightster/sessions/v2/quickbooking/${id}/${userId}`,
      {
        proposedTime: proposedTime,
        token: token,
        platform: platform,
      }
    );

    return res.data;
  }

  async declineBookingNonUser(id: string, userId: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/lightster/sessions/v2/quickbooking/${id}/${userId}`,
      { type: "decline" }
    );

    return res.data;
  }
  async createUnmoderated(params: CreateUnmoderatedParams) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/sessions/unmoderated`,
      params
    );

    return res.data;
  }
  async updateUnmoderated(params: CreateUnmoderatedParams) {
    const res = await this.call(
      "patch",
      `${baseUrl}/creator/sessions/unmoderated/${params.id}`,
      params
    );

    return res.data;
  }

  async declineUnmoderated(id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/lightster/sessions/unmoderated/${id}`,
      { type: "decline" }
    );

    return res.data;
  }
  async stopUnmoderated(id: string) {
    const res = await this.call(
      "patch",
      `${baseUrl}/creator/sessions/unmoderated/${id}/end`
    );
    return res.data;
  }
  async detailUnmoderated(id: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/sessions/unmoderated/${id}`
    );

    return res.data;
  }
  async lightsterStartUnmoderated(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/start`
    );
    return res.data;
  }
  async lightsterStopUnmoderated(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/stop`
    );
    return res.data;
  }
  async shareScreenUnmoderated(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/sharescreen`
    );
    return res.data;
  }
  async startRecordUnmoderated(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/record`
    );
    return res.data;
  }
  async updateRecordUnmoderated(id: string, uid: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/record/update?agoraUID=${uid}`
    );
    return res.data;
  }
  async endUnmoderated(id: string, answer:any[]) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/end`,
      {
        answer: answer
      }
    );
    return res.data;
  }

  async leaveUnmoderated(id: string) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/leave`
    );
    return res.data;
  }
  async activeUnmoderated(id: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/sessions/unmoderated/${id}/active`
    );
    return res.data;
  }
  async viewUnmoderatedResultCreator() {
    const res = await this.call(
      "get",
      `${baseUrl}/creator/sessions/unmoderated/result`
    );
    return res.data;
  }
  async detailUnmoderatedCreator(id: String) {
    const res = await this.call("get", `${baseUrl}/creator/sessions/unmoderated/${id}`);
    return res.data;
  }
  async viewSessionResultCreator() {
    const res = await this.call(
      "get",
      `${baseUrl}/creator/sessions/result`
    );
    return res.data;
  }
  async viewSurveyResultCreator() {
    const res = await this.call("get", `${baseUrl}/creator/survey`);
    return res.data;
  }
}
