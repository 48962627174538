import config from "../config";
import { StorageType } from "../constants/enum";
import { RequestOtpRarams, VerifyOtpRarams } from "../constants/interfaces";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

export default class AuthServices extends BaseService {
  async login(payload: any) {
    const res = await this.call("post", `${baseUrl}/signup/login`, payload);
    if (!res.data) {
      return;
    }
    localStorage.setItem("lisgtster_share_screen_token", res.data.token);
    return res.data;
  }
  async signupSSO(payload: any) {
    const res = await this.call("post", `${baseUrl}/signup/sso`, payload);
    if (!res.data) {
      return;
    }
    localStorage.setItem("lisgtster_share_screen_token", res.data.token);
    return res.data;
  }
  async signupLinkedin() {
    const res = await this.call("get", `${baseUrl}/signup/linkedin`);
    if (!res.data) {
      return;
    }
    return res.data;
  }

  async logout() {
    const res = await this.call("post", `${baseUrl}/signup/logout`);
    localStorage.clear();
    return res.data;
  }

  async resetPassword(payload: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/signup/forgot/password`,
      payload
    );
    return res.data;
  }

  async verifyPassword(payload: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/signup/forgot/password/verify`,
      payload
    );
    return res.data;
  }
  async signupEmail(params: any) {
    const res = await this.call("post", `${baseUrl}/signup/email`, params);
    if (res.data) {
      localStorage.setItem(StorageType.token, res.data.token);
    }
    return res.data;
  }
  async requestOtp(params: RequestOtpRarams) {
    const res = await this.call("post", `${baseUrl}/signup/requestotp`, params);
    return res.data;
  }
  async verifyOty(params: VerifyOtpRarams) {
    const res = await this.call("post", `${baseUrl}/signup/verifyotp`, params);
    return res.data;
  }
}
