import {
  Box,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
  tabClasses
} from "@mui/joy";
import { useEffect, useState } from "react";
import CreatorQuickBookingItem from "../../../components/session/CreatorQuickBookingItem";
import CreatorSessionItem from "../../../components/session/CreatorSessionItem";
import CreatorSurveyItem from "../../../components/session/CreatorSurveyItem";
import { Booking, Session, SurvayDisplay, Unmoderated } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import CreatorUnmoderatedItem from "../../../components/session/CreatorUnmoderatedItem";

interface Props { }

function CreatorCoCreate(props: Props) {
  const { } = props;
  const { userService, showMessage } = useApi();
  const [tabIndex, setTabIndex] = useState<string | number | null>(0);
  const [upcoming, setUpcoming] = useState<Session[]>([]);
  const [invitations, setInvitations] = useState<Session[]>([]);
  const [booking, setBooking] = useState<Booking[]>([]);
  const [surveys, setSurveys] = useState<SurvayDisplay[]>([]);
  const [unmoderated, setUnmoderated] = useState<Unmoderated[]>([]);


  const getCreatorCoCreate = async () => {
    const res = await userService.creatorCoCreate();
    if (!res) return;
    setBooking(res.quickBooking);
    setUpcoming(res.upcomingSession);
    setInvitations(res.futureSession);
    setSurveys(res.survey);
    setUnmoderated(res.unmoderated);
  };

  const checkInvitationsEmpty = () => {
    return invitations.length === 0 && upcoming.length === 0 && booking.length === 0;
  };
  const checkUnmoderatedEmpty = () => {
    return unmoderated.length === 0;
  };

  useEffect(() => {
    getCreatorCoCreate();
    document.title = "Lightster - Invitations";
  }, []);

  useEffect(() => {
    var interval = setInterval(() => {
      getCreatorCoCreate();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
      }}
    >
      <Tabs
        aria-label="tabs"
        defaultValue={0}
        onChange={(e, value) => setTabIndex(value)}
        sx={{ bgcolor: "transparent", position: "sticky" }}
      >
        <TabList
          disableUnderline
          sx={{
            p: 0.5,
            gap: 1,
            borderRadius: "xl",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
              fontWeight: 600,
            },
            position: "inherit",
          }}
        >
          <Tab disableIndicator>Sessions ({invitations.length})</Tab>
          <Tab disableIndicator>
            Surveys (
            {surveys.filter((survey) => survey.status === "inProgress").length})
          </Tab>
          <Tab disableIndicator>Unmoderated Tests ({unmoderated.filter((unmoderated) => unmoderated.status === "inProgress").length})</Tab>
        </TabList>
      </Tabs>

      {tabIndex === 0 && (
        <Box height={"calc(100VH - 132px)"} overflow="auto">
          <Box>
            <Typography level="h3">Active</Typography>
            {checkInvitationsEmpty() && (
              <Typography level="body-md" py={2}>
                You don't have any sessions coming up.
              </Typography>
            )}
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              py={2}
            >
              {booking.map((book) => (
                <CreatorQuickBookingItem
                  booking={book}
                  refresh={() => getCreatorCoCreate()}
                />
              ))}
              {invitations.map((invite) => (
                <CreatorSessionItem
                  session={invite}
                  refresh={getCreatorCoCreate}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      )}

{tabIndex === 2 && (
        <Box height={"calc(100VH - 132px)"} overflow="auto">
          <Box>
            <Typography level="h3">Active</Typography>
            {unmoderated.filter((unmoderated) => unmoderated.status === "inProgress").length === 0 ? (
              <Typography level="body-md" py={2}>
                You don't have any active unmoderated tests.
              </Typography>
            ) : (
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              py={2}
            >
              {unmoderated.map((unmoderated) => {
                  if (unmoderated.status !== "completed") {
                    return <CreatorUnmoderatedItem unmoderated={unmoderated} refresh={getCreatorCoCreate} />;
                  }
                  return null;
                })}
            </Stack>
          )}
          </Box>
          <Box>
            <Typography level="h3">Completed</Typography>
            {unmoderated.filter((unmoderated) => unmoderated.status === "completed")
              .length === 0 ? (
              <Typography sx={{ py: 2 }}>
                You don't have any completed unmoderated tests.
              </Typography>
            ) : (
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                py={2}
              >
                {unmoderated.map((unmoderated) => {
                  if (unmoderated.status === "completed") {
                    return <CreatorUnmoderatedItem unmoderated={unmoderated} refresh={getCreatorCoCreate}/>;
                  }
                  return null;
                })}
              </Stack>
            )}
          </Box>
        </Box>
      )}
      
      
      {tabIndex === 1 && 
      (
        <Box height={"calc(100VH - 132px)"} overflow="auto">
          <Box>
            <Typography level="h3">Active</Typography>

            {surveys.filter((survey) => survey.status === "inProgress")
              .length === 0 ? (
              <Typography sx={{ py: 2 }}>
                You don't have any active surveys.
              </Typography>
            ) : (
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                py={2}
              >
                {surveys.map((survey) => {
                  if (survey.status === "inProgress") {
                    return (
                      <CreatorSurveyItem
                        survey={survey}
                        refresh={() => getCreatorCoCreate()}
                      />
                    );
                  }
                  return null;
                })}
              </Stack>
            )}
          </Box>
          <Box>
            <Typography level="h3">Completed</Typography>
            {surveys.filter((survey) => survey.status === "completed")
              .length === 0 ? (
              <Typography sx={{ py: 2 }}>
                You don't have any completed surveys.
              </Typography>
            ) : (
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                py={2}
              >
                {surveys.map((survey) => {
                  if (survey.status === "completed") {
                    return <CreatorSurveyItem survey={survey} />;
                  }
                  return null;
                })}
              </Stack>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CreatorCoCreate;
