import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Stack,
  Typography,
} from "@mui/joy";
import { ListItemAvatar } from "@mui/material";
import {
  DocumentTextOutline,
  HomeOutline,
  MegaphoneOutline,
  BulbOutline,
} from "react-ionicons";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import {
  appLogo,
  logout,
  placeholder,
  premium,
} from "../../constants/AppImages";
import { RoleType } from "../../constants/enum";
import { primaryPurpleDark } from "../../mui-styles";
import { useAuth } from "../../providers/AuthProvider";
import { OpenFieldName, usePlanDialog } from "../../providers/PlanPovider";
import { SolidButton } from "../button/CustomButton";
import { useEffect, useState } from "react";
import { useApi } from "../../providers/ApiProvider";
import { Session } from "../../constants/interfaces";
import moment from "moment";
import { useNotification } from "../../providers/NotificationProvider";

interface Props {
  onChangeMenu: () => void;
}

function MenuListItem(props: Props) {
  const { onChangeMenu } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, role, onLogout } = useAuth();
  const { handleShowDialog, handleFlowUpgrade } = usePlanDialog();
  const { userService } = useApi();
  const { badge, getNotification } = useNotification();

  const [upcoming, setUpcoming] = useState<Session[]>([]);
  const { sessionService, showMessage, handleToggle } = useApi();
  const [permissions, setPermissions] = useState({
    microphone: false,
    camera: false,
  });
  const [devices, setDevices] = useState({
    microphone: false,
    camera: false,
  });

  const checkDevices = async () => {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((stream) => {
        stream.forEach((device) => {
          if (device.kind === "audioinput") {
            if (device.label) {
              setPermissions({ ...permissions, microphone: true });
            }
            setDevices({ ...devices, microphone: true });
          }
          if (device.kind === "videoinput") {
            if (device.label) {
              setPermissions({ ...permissions, camera: true });
            }
            setDevices({ ...devices, camera: true });
          }
        });
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  };

  const handleJoin = async (session: Session) => {
    if (!inThirtyMinutes(session.request.proposedTime)) return;
    if (!permissions.microphone && !permissions.camera) {
      navigate(pagesName.requestPermissions, {
        state: { sessionId: session._id },
      });
      return;
    }

    handleToggle(true);
    const res = await sessionService.startSession(session._id);
    handleToggle(false);
    if (!res) {
      return;
    }

    navigate(pagesName.videocall, {
      state: { sessionId: session._id },
    });
  };

  const inThirtyMinutes = (dateTime: string) => {
    const olderDate = moment(new Date(dateTime)).subtract(30, "minutes");
    const isSameOrAfter = moment(Date.now()).isSameOrAfter(olderDate);

    return isSameOrAfter;
  };

  const isJoined = (session: Session) => {
    return session.creator.isJoined && session.lightster.isJoined;
  };

  const getCreatorCoCreate = async () => {
    const res = await userService.creatorInviteUpcoming();
    if (!res) return;
    setUpcoming(res);
  };

  const handleUpgrade = () => {
    handleFlowUpgrade(true);
    handleShowDialog(OpenFieldName.PLAN_DETAIL, true);
    return;
  };

  const handleNavidate = (path: string) => {
    onChangeMenu();
    if (
      [
        pagesName.lightster.home,
        pagesName.lightster.questions,
        pagesName.creator.home,
        pagesName.creator.coCreate,
        pagesName.creator.insights,
      ].includes(path)
    ) {
      navigate(path);
    }
  };

  const handleLogout = async () => {
    await onLogout();
    navigate("/");
  };

  const activeHome = (path: string) => {
    const split = path.split("/");
    if (split.includes("home")) return true;
    if (split.includes("view-profile")) return true;
    if (split.includes("schedule")) return true;
    return false;
  };
  const coCreatePath = () => {
    return role === RoleType.lightster ? "" : pagesName.creator.coCreate;
  };
  const insightsPath = () => {
    return role === RoleType.lightster ? "" : pagesName.creator.insights;
  };

  const getListMenu = () => {
    if (role === RoleType.lightster) {
      menus.push({
        title: "Questions",
        icon: (
          <MegaphoneOutline
            width={"24px"}
            height={"24px"}
            color={pathname.includes("questions") ? primaryPurpleDark : "black"}
          />
        ),
        path: pagesName.lightster.questions,
        active: pathname.includes("questions"),
        notifications: badge.question,
      });
      return menus;
    } else {
      const addCoCreate = {
        title: "Invitations",
        icon: (
          <DocumentTextOutline
            width={"24px"}
            height={"24px"}
            color={pathname === coCreatePath() ? primaryPurpleDark : "black"}
          />
        ),
        path: coCreatePath(),
        active: pathname === coCreatePath(),
        notifications: 0,
      };
      const addInsights = {
        title: "Transactions",
        icon: (
          <BulbOutline
            width={"24px"}
            height={"24px"}
            color={pathname === insightsPath() ? primaryPurpleDark : "black"}
          />
        ),
        path: insightsPath(),
        active: pathname === insightsPath(),
        notifications: 0,
      };

      menus.push(addCoCreate);
      menus.push(addInsights);


      return menus;
    }
  };
  const menus = [
    {
      title: "Home",
      icon: (
        <HomeOutline
          width={"24px"}
          height={"24px"}
          color={activeHome(pathname) ? primaryPurpleDark : "black"}
        />
      ),
      path:
        role === RoleType.lightster
          ? pagesName.lightster.home
          : pagesName.creator.home,
      active: activeHome(pathname),
      notifications: 0,
    },

    // {
    //   title: "Account",
    //   icon: (
    //     <PersonOutline
    //       width={"24px"}
    //       height={"24px"}
    //       color={pathname === pagesName.account ? primaryPurpleDark : "black"}
    //     />
    //   ),
    //   path: pagesName.account,
    //   active: pathname === pagesName.account,
    // },
  ];

  useEffect(() => {
    if (role === RoleType.creator) {
      getCreatorCoCreate();
      checkDevices();
    }

    if (role === RoleType.lightster) {
      getNotification();
    }
  }, []);


  return (
    <Stack direction={"column"} height={"calc(100vh - 64px)"}>
      <List>
        <ListItem sx={{ textAlign: "center", mb: 2, pY: 0 }}>
          <img src={appLogo} height="30px" />
        </ListItem>
        {getListMenu().map((menu) => (
          <div key={menu.path}>
            <ListItem>
              <ListItemButton
                color="primary"
                onClick={() => handleNavidate(menu.path)}
                sx={{
                  marginInline: 0,
                  paddingInline: 0,
                  color: menu.active ? primaryPurpleDark : "black",
                  borderBottom: `2px solid ${menu.active ? primaryPurpleDark : "transparent"
                    }`,
                }}
              >
                <ListItemDecorator>
                  <div style={{ marginTop: "6px" }}>
                    {menu.icon}
                  </div>
                </ListItemDecorator>
                {menu.title}
                {menu.notifications > 0 && (
                  <span
                    style={{
                      marginRight: 20,
                      color: "white",
                      background: primaryPurpleDark,
                      borderRadius: 50,
                      width: 24,
                      height: 24,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {menu.notifications}
                  </span>
                )}
              </ListItemButton>
            </ListItem>
          </div>
        ))}
        {upcoming.length > 0 && (
          <ListItem>
            <Typography sx={{ mt: 3 }} fontWeight={300}>
              UPCOMING
            </Typography>
          </ListItem>
        )}
        {role === RoleType.creator &&
          upcoming.map((invite, index) => (
            <ListItem key={index}>
              <ListItemButton color="primary">
                <ListItemDecorator>
                  <ListItemAvatar>
                    <Avatar
                      src={invite.lightster.imageProfile}
                      sx={{ width: 40, height: 40, bgcolor: "white" }}
                    >
                      <img src={placeholder} width={40} height={40} />
                    </Avatar>
                  </ListItemAvatar>
                  <div style={{ flex: 1, overflow: "hidden" }}>

                    <ListItemContent>
                      <div style={{ width: 150, overflow: "hidden", textOverflow: "ellipsis" }}>
                        <Typography level="body-xs" fontWeight={400}>
                          {invite.lightster.displayname}
                        </Typography>
                      </div>
                      <Typography fontSize={10} fontWeight={400}>

                        {moment(invite.request.proposedTime).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </Typography>
                    </ListItemContent>
                    {!isJoined(invite) && (
                      <Button
                        variant="solid"
                        size="sm"
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          minHeight: 24,
                          padding: "0px 10px",
                          ".MuiButton-root": {
                            width: 46,
                          },
                          bgcolor: inThirtyMinutes(invite.request.proposedTime)
                            ? "#4700B2"
                            : "#dcdcdc",
                        }}
                        onClick={() => handleJoin(invite)}
                      >
                        Join
                      </Button>
                    )}
                  </div>
                </ListItemDecorator>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <List sx={{ display: "contents", padding: 0 }}>
        {role === RoleType.creator && user.memberPlan == "Free" && (
          <ListItem sx={{ paddingInline: 0 }}>
            <ListItemButton color="primary" onClick={handleUpgrade}>
              <Box display={"inline-flex"} gap={1}>
                <img width={24} src={premium} alt="icons-premium" />
                <Typography level="body-lg">
                  <u> Upgrade</u>
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
        )}
        {role === RoleType.creator && (
          <ListItem sx={{ paddingInline: 0 }}>
            <Typography level="title-lg">{user.memberPlanName} Plan</Typography>
          </ListItem>
        )}
        <ListItem sx={{ padding: 0 }}>
          <ListItemAvatar>
            <Avatar src={user?.imageProfile ?? placeholder} />
          </ListItemAvatar>
          <Box>
            <Typography level="title-sm">{user?.displayName}</Typography>
            <ListItemButton color="primary" onClick={handleLogout}>
              <Box display={"inline-flex"} gap={1}>
                <Typography level="body-sm" color="primary" noWrap>
                  Logout
                </Typography>
                <img width={17} src={logout} alt="icons-logout" />
              </Box>
            </ListItemButton>
          </Box>
        </ListItem>
      </List>
    </Stack>
  );

}


export default MenuListItem;
