import { Box, Modal, ModalDialog, Typography } from "@mui/joy";
import moment from "moment";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  date: string;
  onConfirm: () => void;
}

function ConfirmYourSpot(props: Props) {
  const { open, loading, date, onClose, onConfirm } = props;

  return (
    <Modal open={open}>
      <ModalDialog
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        size="lg"
      >
        <Typography id="layout-modal-title" level="h3" fontSize={24}>
          Confirm your spot
        </Typography>
        <Typography
          id="layout-modal-description"
          sx={{ fontSize: 18, color: "black", mt: 1 }}
        >
          You’ve selected the{" "}
          <Typography level="title-sm">
            {moment(date).format("MMM DD, YYYY, hh:mm A")}
          </Typography>{" "}
          spot.
          <p />
          <p />
          Does this look right?
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", sm: "row-reverse" },
          }}
        >
          <SolidButton
            loading={loading}
            variant="solid"
            color="primary"
            onClick={() => {
              onConfirm();
            }}
          >
            Yes
          </SolidButton>
          <PlainButton variant="plain" color="primary" onClick={onClose}>
            Go back
          </PlainButton>
        </Box>
      </ModalDialog>
    </Modal>
  );
}

export default ConfirmYourSpot;
