import {
    Box,
    Modal,
    ModalDialog,
    Typography
} from "@mui/joy";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  title: string;
  contentUpgrade: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function UpgradeWithCustomContentDialog(props: Props) {
  const { title, contentUpgrade, open, onClose, onSubmit } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="variant-modal-title"
        aria-describedby="variant-modal-description"
        size="lg"
        sx={{
          maxHeight: "calc(100% - 24vh)",
          width: "35vw",
          top: "12vh",
          left: "50%",
          transform: "translateX(-50%)",
          padding: 4,
          "--ModalClose-inset": "24px",
        }}
      >
        <Typography
          id="variant-modal-title"
          level="h3"
          sx={{ fontSize: 24, color: "black" }}
        >
          {title}
        </Typography>
        <Typography
          id="variant-modal-description"
          level="body-md"
          sx={{ textAlign: "start", color: "black" }}
        >
          {contentUpgrade}{" "}
          <Typography level="title-md">Premium Plan</Typography>.
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", sm: "row-reverse" },
          }}
        >
          <SolidButton variant="solid" color="primary" onClick={onSubmit}>
            Upgrade
          </SolidButton>
          <PlainButton variant="plain" color="primary" onClick={onClose}>
            Go back
          </PlainButton>
        </Box>
      </ModalDialog>
    </Modal>
  );
}

export default UpgradeWithCustomContentDialog;
