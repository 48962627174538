import config from "../config";
import { StorageType } from "../constants/enum";
import { ProfileOptions, QuestionParams } from "../constants/interfaces";
import BaseService from "./BaseService";

const baseUrl = config.backendUrl;

export default class ProfileServices extends BaseService {
  async getAboutYou() {
    const res = await this.call("get", `${baseUrl}/profile/aboutYou`);
    return res.data;
  }

  async getCompany() {
    const res = await this.call("get", `${baseUrl}/profile/company`);
    return res.data;
  }

  async getYourProfile() {
    const res = await this.call("get", `${baseUrl}/profile/yourProfile`);
    return res.data;
  }

  async updateCreatotProfile(payload: any) {
    const res = await this.call("post", `${baseUrl}/creator/criteria`, payload);
    if (res.data) {
      localStorage.setItem(StorageType.user, JSON.stringify(res.data));
    }
    return res.data;
  }

  async updateLightProfile(payload: any) {
    const res = await this.call(
      "post",
      `${baseUrl}/lightster/criteria`,
      payload
    );
    if (res.data) {
      localStorage.setItem(StorageType.user, JSON.stringify(res.data));
    }

    return res.data;
  }

  async getSearchProfile(title: string) {
    const res = await this.call(
      "get",
      `${baseUrl}/profile/search/profiles?title=${title} `
    );
    return res.data;
  }
  async creaetCustomQuestion(body: QuestionParams) {
    const res = await this.call(
      "post",
      `${baseUrl}/creator/profile/custom`,
      body
    );
    return res.data;
  }

  async updateQuestion(qid: string, body: any) {
    const res = await this.call("put", `${baseUrl}/question?_id=${qid}`, body);
    return res.data;
  }
  async getViewProfileLightster(params: any) {
    const res = await this.call(
      "get",
      `${baseUrl}/creator/view/profile/lightster`,
      {},
      {
        params: params,
      }
    );
    return res.data;
  }

  async getViewProfileCreator(params: any) {
    const res = await this.call(
      "get",
      `${baseUrl}/lightster/view/profile/creator`,
      {},
      {
        params: params,
      }
    );
    return res.data;
  }
}
