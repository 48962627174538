import { CreditCard } from "@mui/icons-material";
import {
    Button,
    Modal,
    ModalDialog,
    Sheet,
    Typography
} from "@mui/joy";
import { Stack } from "@mui/system";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import config from "../../../config";
import { StorageType } from "../../../constants/enum";
import { useApi } from "../../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function PlanPayment(props: Props) {
  const { open, onClose, onSubmit } = props;
  const { paymentService, userService, showMessage } = useApi();
  const [creditCards, setCreditCards] = useState<Array<any>>([]);
  const [defaultCard, setDefaultCard] = useState<any>();
  const [selected, setSelected] = useState<any>();
  const [loading, setLoading] = useState(false);

  const getCreditCards = async () => {
    const res = await paymentService.getSubscriptions();
    if (!res) return;

    if (res.length === 0) {
      await addCreaditCard();
    } else {
      setCreditCards(res);
      const newValue = res.find((f: any) => f.default);
      setSelected(newValue);
      setDefaultCard(newValue);
    }
  };

  const addCreaditCard = async () => {
    const token = localStorage.getItem(StorageType.token);
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
        "X-LIGHTSTER-CLIENT": "WebAppShareScreen",
        "Access-Control-Expose-Headers": "Location",
        Accept: "*/*",
      },
    });
    instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    await instance
      .post(`${config.backendUrl}/creator/payment/cards`, { isWeb: true })
      .then(function (response: AxiosResponse) {
        var newWin = window.open(
          response.data.url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=50 ,left=50 ,width=500,height=600"
        );

        var timer = setInterval(function () {
          if (newWin?.closed) {
            clearInterval(timer);
            getCreditCards();
          }
        }, 1000);
      })
      .catch(function (error: AxiosResponse) {
        console.log(error);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (creditCards.length == 0) {
      return showMessage("Please add a credit card to proceed.", "warning");
    }
    if (selected !== defaultCard) {
      await handleSetDefaultCard(selected.id);
    }
    setLoading(true);
    const res = await paymentService.createSubscription("basic");
    if (!res) {
      setLoading(false);
      return;
    }
    getUserInfo();
  };

  const getUserInfo = async () => {
    await userService.getUser();
    setTimeout(() => {
      setLoading(false);
      onSubmit();
    }, 1000);
  };

  const handleSetDefaultCard = async (id: string) => {
    const res = await paymentService.setDefaultCard(id);
    if (!res) return;
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <Modal open={open}>
      <ModalDialog aria-labelledby="title-plan-detail" size="lg">
        <Typography id="title-plan-detail" level="h3" fontSize={24}>
          Payment
        </Typography>
        <Stack direction={"column"} spacing={2} sx={{ fontSize: 24 }}>
          <Button
            variant="solid"
            sx={{ borderRadius: 8 }}
            color="primary"
            onClick={addCreaditCard}
          >
            {"Add a new card"}
          </Button>
          {creditCards.map((data) => (
            <Sheet
              onClick={() => {
                setSelected(data);
              }}
              sx={{ p: 2, borderRadius: 8 }}
              color={data === selected ? "primary" : "neutral"}
              variant="outlined"
            >
              <Stack direction={"column"}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography
                    level="title-md"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {data.card.brand}****{data.card.last4}
                  </Typography>
                  <CreditCard />
                </Stack>
                <Typography level="body-sm">
                  {data.card.exp_month}/{data.card.exp_year}
                </Typography>
              </Stack>
            </Sheet>
          ))}
          <Stack direction={"row"} justifyContent="end" spacing={2}>
            <PlainButton variant="plain" onClick={onClose}>
              Go back
            </PlainButton>
            <SolidButton
              variant="solid"
              color="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default PlanPayment;
