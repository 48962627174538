import { LinkedIn } from "@mui/icons-material";
import { Avatar, Box, Chip, IconButton, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { ThumbsDownOutline, ThumbsUpOutline } from "react-ionicons";
import { useParams } from "react-router-dom";
import ChatRoom from "../../../components/ChatRoom";
import { OutlinedButton } from "../../../components/button/CustomButton";
import { placeholder } from "../../../constants/AppImages";
import { StorageType } from "../../../constants/enum";
import { UserChat, ViewProfile } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props {}

function ViewCreatorProfile(props: Props) {
  const {} = props;
  const { userId } = useParams();
  const { profileService } = useApi();
  const [data, setData] = useState<ViewProfile>();
  const [openChat, setOpenChat] = useState<UserChat | null>(null);

  const getCreatorProfile = async (id: string) => {
    let params: any = { creatorId: id };
    const res = await profileService.getViewProfileCreator(params);
    if (!res) return;
    setData(res);
  };

  const handleOpenChat = () => {
    if (!data) return;
    const userProfile: UserChat = {
      subDisplayName: data.subDisplayName,
      id: data.id,
      imageProfile: data.imageProfile,
      username: data.username,
      email: "",
      subCompanyDisplayName: data.subDisplayName,
      displayName: data?.displayName,
    };

    setOpenChat(userProfile);
    localStorage.setItem(
      StorageType.viewProfileChatActive,
      JSON.stringify(userProfile)
    );
  };

  useEffect(() => {
    if (userId) {
      getCreatorProfile(userId);
    }
  }, []);

  useEffect(() => {
    const getLocal = localStorage.getItem(StorageType.viewProfileChatActive);
    if (getLocal) {
      setOpenChat(JSON.parse(getLocal));
    }
    if (data) {
      document.title = `Lightster - ${data?.displayName}`;
    }
  }, [data]);

  if (!data) {
    return <></>;
  }
  return openChat ? (
    <ChatRoom
      chatVideoCall={false}
      userProfile={openChat}
      receiverId={data.id}
      onClose={() => {
        setOpenChat(null);
        localStorage.removeItem(StorageType.viewProfileChatActive);
      }}
    />
  ) : (
    <Box className="home" height={"calc(100vh - 132px)"} overflow="scroll">
      <Stack direction="column" gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 2,
              minHeight: 100,
              gap: 2,
            }}
          >
            <Avatar
              variant="plain"
              src={data.imageProfile ?? placeholder}
              sx={{ height: 100, width: 100, bgcolor: "transparent" }}
            >
              <img src={placeholder} height="100" width="100" />
            </Avatar>

            <Stack direction="column" justifyContent={"space-evenly"}>
              <Typography level="h3" fontSize={28}>
                {data.displayName}
              </Typography>
              <Stack direction="row" gap={1}>
                <Box
                  className="box-row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography level="title-md">{data.positive}</Typography>
                  <Box height={20}>
                    <ThumbsUpOutline width="20px" height="20px" />
                  </Box>
                </Box>
                <Box
                  className="box-row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Box height={20}>
                    <ThumbsDownOutline width="20px" height="20px" />
                  </Box>
                  <Typography level="title-md">{data.negative}</Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              minHeight: 100,
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Box>
              {data.isVerify && (
                <Chip variant="solid" sx={{ bgcolor: "#38A169" }}>
                  VERIFIED
                </Chip>
              )}
            </Box>
            <Box>
              {data.linkedIn && (
                <IconButton
                  onClick={() => {
                    window.open(data.linkedIn, "_blank");
                  }}
                >
                  <LinkedIn sx={{ color: "#2867B2", fontSize: 30 }} />
                </IconButton>
              )}
            </Box>
          </Box>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <OutlinedButton
            variant="outlined"
            color="primary"
            sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
            onClick={handleOpenChat}
          >
            Send a message
          </OutlinedButton>
        </Stack>
        <Stack>
          <Typography level="body-sm" fontStyle={"italic"}>
            "{data.subDisplayName}"
          </Typography>
        </Stack>
        <Stack
          direction="column"
          gap={2}
          sx={{
            paddingInline: 3,
          }}
        >
          {data.criterias &&
            data.criterias.map((question) => (
              <Stack direction={"row"} justifyContent="space-between">
                <Box flex={1}>
                  <Typography level="title-sm" sx={{ fontWeight: 400 }}>
                    {question.title}
                  </Typography>
                </Box>
                <Box display="flex" flex={2} justifyContent="end">
                  <Typography
                    level="title-sm"
                    sx={{ fontWeight: 400 }}
                    textAlign={"end"}
                  >
                    {question.answers.join(", ")}
                  </Typography>
                </Box>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Box>
  );
}

export default ViewCreatorProfile;
