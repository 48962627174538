import { useEffect, useState } from "react";
import parse from "html-react-parser";

import {
  FieldValue,
  addDoc,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { Photo } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Input,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import moment from "moment";
import { Call, CloseCircle, CloudDownload, Send } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../App";
import { placeholder } from "../constants/AppImages";
import { RoleType, StorageType } from "../constants/enum";
import { Message, UserChat } from "../constants/interfaces";
import "../index.css";
import { primaryPurple } from "../joy-styles";
import { useApi } from "../providers/ApiProvider";
import { useAuth } from "../providers/AuthProvider";
import { firebaseDB } from "../providers/FirebaseProvider";
import { OutlinedButton } from "./button/CustomButton";

const MessageType = {
  TEXT: "text",
  IMAGE: "image",
  LINK: "link",
  FILE: "file",
  BLOCK: "block",
  SESSION: "session",
  RECORDING: "recording",
};

interface ChatRoomProps {
  userProfile?: UserChat;
  receiverId: string;
  onClose: () => void;
  chatVideoCall: boolean;
}
export default function ChatRoom({
  userProfile,
  receiverId,
  onClose,
  chatVideoCall,
}: ChatRoomProps) {
  const { api, role, user } = useAuth();
  const { notiService, userService } = useApi();
  const [data, setData] = useState<Array<Message>>([]);
  const [docId, setDocId] = useState<Array<string>>([]);
  const [textInput, setTextInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const getUserInfo = async () => {
    await userService.getUser();
  };

  const getCurrentId = () => {
    if (!user?._id) {
      getUserInfo();
      const userInfo = JSON.parse(`${localStorage.getItem(StorageType.user)}`);
      return `${role}_${userInfo._id ?? user.id}`;
    }

    return `${role}_${user._id ?? user.id}`;
  };

  const getReceiverId = () => {
    if (role === "creator") {
      return `lightster_${receiverId}`;
    } else {
      return `creator_${receiverId}`;
    }
  };

  useEffect(() => {
    setLoadingPage(true);
    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);
    localStorage.removeItem(StorageType.homeChatActive);

    const q = query(
      collection(firebaseDB, `chats/${getCurrentId()}/${getReceiverId()}`),
      orderBy("TIMESTAMPSERVER", "desc"),
      limit(100)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages: Array<Message> = [];
      const listId: Array<string> = [];
      querySnapshot.forEach((doc) => {
        messages.push(doc.data() as Message);
        if (doc.data().READ === false) {
          listId.push(doc.id);
        }
      });
      setData(messages);
      setDocId(listId);
    });

    return () => {
      unsubscribe();
    };
  }, [receiverId]);

  const updateUnread = async (refDoc: string) => {
    // Update read messages.
    await updateDoc(
      doc(firebaseDB, `chats/${getCurrentId()}/${getReceiverId()}/${refDoc}`),
      {
        READ: true,
      }
    );
  };

  useEffect(() => {
    if (docId.length > 0) {
      docId.forEach((id) => {
        updateUnread(id);
      });
    }
  }, [docId]);

  const sendMessage = async (type: string, text: string, image: string) => {
    let textMsg = "";
    let lastAction = "";
    let imageUrl = "";

    /// Check message type
    switch (type) {
      case MessageType.TEXT:
        textMsg = text;
        lastAction = text;
        break;
      case MessageType.IMAGE:
        imageUrl = image;
        textMsg = "";
        lastAction = "picture";
        break;
      case MessageType.LINK:
        imageUrl = "";
        textMsg = text;
        lastAction = text;
        break;
      case MessageType.FILE:
        imageUrl = image;
        textMsg = text;
        lastAction = text;

        break;
    }
    const payload = {
      SENDER_ID: getCurrentId(),
      RECEIVER_ID: getReceiverId(),
      MESSAGE_TYPE: type,
      MESSAGE_TEXT: textMsg,
      MESSAGE_IMG_LINK: imageUrl,
      TIMESTAMP: new Date().toISOString(),
      TIMESTAMPSERVER: serverTimestamp(),
    };

    // Add a new message for current user.
    await addDoc(
      collection(firebaseDB, `chats/${getCurrentId()}/${getReceiverId()}`),
      { ...payload, READ: true }
    );

    // Add a new message for receiver user.
    await addDoc(
      collection(firebaseDB, `chats/${getReceiverId()}/${getCurrentId()}`),
      { ...payload, READ: false }
    );

    retryFunction(
      updateLastActionForCurrentUser(lastAction),
      retryCount,
      retryDelay
    );
    retryFunction(
      updateLastActionForReceiverUser(lastAction),
      retryCount,
      retryDelay
    );
    //Send notification
    sendNotification(type, lastAction);
  };

  const retryCount = 5; // Number of retries
  const retryDelay = 2000; // Delay between retries in milliseconds

  const retryFunction = async (
    fn: any,
    retries: number,
    delay: number
  ): Promise<any> => {
    try {
      const result = await fn();
      return result;
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retryFunction(fn, retries - 1, delay);
      } else {
        sendNotificationToLightsterTeam(error);
        throw error; // Re-throw the error after exhausting retries
      }
    }
  };

  const updateLastActionForCurrentUser = async (lastAction: string) => {
    try {
      const docRef = doc(firebaseDB, `${getCurrentId()}/${getReceiverId()}`);
      const docSnap = await getDoc(docRef);
      const dataRef = {
        isActive: true,
        keepChatting: true,
        lastAction: lastAction,
        user: userProfile,
        read: true,
        timeStamp: new Date().toISOString(),
        timeStampServer: serverTimestamp(),
      };
      // Update or Set new last action for current user.
      docSnap.exists() ? updateDoc(docRef, dataRef) : setDoc(docRef, dataRef);

      return docSnap;
    } catch (err) {
      let errorString: string;

      if (err instanceof Error) {
        errorString = err.message;
      } else {
        errorString = String(err);
      }

      console.log(errorString);
      throw new Error(errorString);
    }
  };

  const updateLastActionForReceiverUser = async (lastAction: string) => {
    const currentUserProfile: UserChat = {
      subDisplayName: user?.subDisplayName ?? null,
      id: user._id,
      imageProfile: user?.imageProfile ?? null,
      username: user.username,
      email: user.email,
      subCompanyDisplayName: user?.subCompanyDisplayName ?? null,
      displayName: user?.displayName,
    };

    try {
      const docRef = doc(firebaseDB, `${getReceiverId()}/${getCurrentId()}`);
      const docSnapReceive = await getDoc(docRef);
      const dataRef = {
        isActive: true,
        keepChatting: true,
        lastAction: lastAction,
        user: currentUserProfile,
        read: false,
        timeStamp: new Date().toISOString(),
        timeStampServer: serverTimestamp(),
      };
      // Update or Set new last action for receiver user.
      docSnapReceive.exists()
        ? updateDoc(docRef, dataRef)
        : setDoc(docRef, dataRef);

      return docSnapReceive;
    } catch (err) {
      console.log(err);
    }
  };
  const sendNotification = async (type: string, lastAction: string) => {
    const body = {
      senderId: getCurrentId(),
      receiverId: getReceiverId(),
      messages: lastAction,
      type: type,
      data: {
        data: {
          type: "message",
          receiverType: role === "lightster" ? "creator" : "lightster",
          senderId: getCurrentId(),
        },
      },
    };
    await notiService
      .sendNotification(body)
      .then(() => {
        console.log("send notificaton success.");
      })
      .catch((err) => {
        console.log("send notification error: ", err);
      });
  };

  const sendNotificationToLightsterTeam = async (error: any) => {
    // 61fb2ff3aa42ce504796f2f9.  -> pat
    // 65ec018b12be7e74b17f7185. -> top
    // msg:  Send msg fail from. xxx to yyyy

    const body = {
      senderId: "lightster_65ec018b12be7e74b17f7185",
      receiverId: "creator_61fb2ff3aa42ce504796f2f9",
      messages: `Send msg fail from. ${getCurrentId()} to ${getReceiverId()} error ${error()}`,
      type: "text",
      data: {
        data: {
          type: "message",
          receiverType: "creator",
          senderId: "65ec018b12be7e74b17f7185",
        },
      },
    };
    await notiService
      .sendNotification(body)
      .then(() => {
        console.log("send notificaton success.");
      })
      .catch((err) => {
        console.log("send notification error: ", err);
      });
  };

  const uploadImage = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("Upload image error.");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          sendMessage(MessageType.IMAGE, "", downloadURL);
          setLoading(false);
        });
      }
    );
  };

  const onSubmitSend = (e: any) => {
    e.preventDefault();
    if (!textInput.trim()) {
      console.log("on text");
      return;
    }
    sendMessage("text", textInput, "");
    setTextInput("");
  };

  const handleclickViewLightsterProfile = () => {
    window.open(
      `${pagesName.creator.viewProfile}/${userProfile?.id}`,
      "_blank"
    );
  };

  const handleclickViewCreatorProfile = () => {
    window.open(
      `${pagesName.lightster.viewProfile}/${userProfile?.id}`,
      "_blank"
    );
  };

  if (loadingPage) {
    return (
      <div className={`chat ${!chatVideoCall ? "shadow" : ""}`}>
        <Stack
          direction={"column"}
          justifyContent="center"
          alignItems={"center"}
          sx={{ width: "100%", height: "100%" }}
        >
          <CircularProgress size="md" />
        </Stack>
      </div>
    );
  }

  return (
    <div className={`chat ${!chatVideoCall ? "shadow" : ""}`}>
      <Box p={3} borderRadius="10px 10px 0px 0px">
        {userProfile ? (
          <Box
            className="box-row"
            sx={{
              gap: 2,
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Box className="box-column" sx={{ alignItems: "start", gap: 2 }}>
              <Box className="box-row" sx={{ gap: 2 }}>
                <Avatar
                  variant="plain"
                  src={userProfile?.imageProfile ?? placeholder}
                  sx={{
                    height: 50,
                    width: 50,
                    bgcolor: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    role === RoleType.lightster
                      ? handleclickViewCreatorProfile()
                      : handleclickViewLightsterProfile()
                  }
                />
                <Box className="box-column" sx={{ alignItems: "start" }}>
                  <Typography
                    level="h3"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "black",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      role === RoleType.lightster
                        ? handleclickViewCreatorProfile()
                        : handleclickViewLightsterProfile()
                    }
                  >
                    {userProfile?.displayName}
                  </Typography>
                  <Typography
                    level="title-md"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {role === RoleType.creator
                      ? userProfile.subDisplayName
                      : userProfile.subCompanyDisplayName}
                  </Typography>
                </Box>
              </Box>
              {role === RoleType.creator && (
                <Box className="box-row" sx={{ gap: 1 }}>
                  <OutlinedButton
                    variant="outlined"
                    color="primary"
                    sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                    onClick={() => {
                      navigate(`${pagesName.creator.schedule}/${receiverId}`);
                    }}
                  >
                    Schedule a live conversation
                  </OutlinedButton>
                  <OutlinedButton
                    variant="outlined"
                    sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                    onClick={() => navigate(`${pagesName.creator.unmoderated}/${receiverId}`)}
                  >
                    Start a test
                  </OutlinedButton>
                  {/* <OutlinedButton
                    variant="outlined"
                    sx={{ minHeight: 0, padding: "10px 24px", fontSize: 14 }}
                    onClick={() => navigate(pagesName.creator.survey)}
                  >
                    Send a survey
                  </OutlinedButton> */}
                </Box>
              )}
            </Box>
            <CloseCircle
              width={"40px"}
              height={"40px"}
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </Box>
        ) : (
          <Box className="box-row" sx={{ justifyContent: "end" }}>
            <CloseCircle
              width={"40px"}
              height={"40px"}
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </Box>
        )}
      </Box>
      <div className="messages">
        {loading && (
          <div className="message owner">
            <Box className="loading">
              <CircularProgressWithLabel value={progress} />
            </Box>
          </div>
        )}
        {data.map((m, i) => (
          <div key={i}>
            {i < data.length && (
              <p
                className="date"
                style={{
                  display: `${i < data.length + 1 &&
                      convertDate(
                        m?.TIMESTAMPSERVER != null
                          ? m.TIMESTAMPSERVER.toDate()
                          : new Date()
                      ) !==
                      convertDate(
                        data[i + 1]?.TIMESTAMPSERVER != null
                          ? data[i + 1]?.TIMESTAMPSERVER.toDate()
                          : new Date()
                      )
                      ? "flex"
                      : "none"
                    } `,
                }}
              >
                {convertDate(
                  m?.TIMESTAMPSERVER != null
                    ? m.TIMESTAMPSERVER.toDate()
                    : new Date()
                )}
              </p>
            )}
            <div
              className={`message ${m.SENDER_ID === getCurrentId() && "owner"}`}
            >
              {getMessage(m, m.SENDER_ID === getCurrentId())}

              <text className="time">
                {convertTime(
                  m?.TIMESTAMPSERVER != null
                    ? m.TIMESTAMPSERVER.toDate()
                    : new Date()
                )}
              </text>
            </div>
          </div>
        ))}
      </div>
      <form noValidate onSubmit={onSubmitSend}>
        <Stack direction={"row"} alignItems="center" gap={2} sx={{ p: 3 }}>
          <Textarea
            variant="plain"
            minRows={1}
            maxRows={10}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            style={{ width: "100%" }}
            sx={{
              borderRadius: 6,
              bgcolor: (theme) => theme.palette.neutral[100],
              border: "1px solid transparent",
            }}
          />
          <Stack direction={"row"} gap={2}>
            <input
              style={{ display: "none" }}
              id="input-file"
              type="file"
              onChange={uploadImage}
            />

            <IconButton
              htmlFor="input-file"
              component="label"
              sx={{ fontSize: 28 }}
              color="primary"
              aria-label="upload picture"
            >
              <Photo sx={{ color: "black" }} />
            </IconButton>
          </Stack>

          <IconButton type="submit" onClick={onSubmitSend}>
            <Send
              style={{ color: textInput ? "#8C30F5" : "#7676801F", marginTop: "6px" }}
            />
          </IconButton>
        </Stack>
      </form>
    </div>
  );
}

const getMessage = (msg: Message, isSender: boolean) => {
  if (msg.MESSAGE_TYPE === MessageType.TEXT) {
    return getMessageText(msg.MESSAGE_TEXT);
  } else if (msg.MESSAGE_TYPE === MessageType.SESSION) {
    return getMessageSession(msg, isSender);
  } else if (msg.MESSAGE_TYPE === MessageType.RECORDING) {
    return getMessageRecording(msg, isSender);
  } else if (msg.MESSAGE_TYPE === MessageType.IMAGE) {
    return getMessageImage(msg.MESSAGE_IMG_LINK, isSender);
  } else if (msg.MESSAGE_TYPE === MessageType.LINK) {
    return getMessageLink(msg.MESSAGE_TEXT);
  } else if (msg.MESSAGE_TYPE === MessageType.FILE) {
    return getMessageLink(msg.MESSAGE_TEXT);
  } else {
    return getMessageText(msg.MESSAGE_TEXT);
  }
};

const convertFullDateTime = (date: string) => {
  return moment(new Date(date)).format("MMM DD, yyyy hh:mm A");
};

const convertDate = (date: Date) => {
  return moment(new Date(date)).format("MMM DD, yyyy");
};

const convertTime = (date: Date) => {
  return moment(new Date(date)).format("hh:mm A");
};

const getMessageText = (text: string) => {
  var urlRegex =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  if (urlRegex.test(text)) {
    return getMessageLink(text);
  }
  return <div className="text">{text}</div>;
};

const getMessageSession = (msg: Message, isSender: boolean) => {
  return (
    <Box
      className="text"
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      {isSender ? (
        <Box pr={2}>
          <Call color={"#FFFFFF"} height="16px" width="16px" />
        </Box>
      ) : null}
      <Box display="flex" flexDirection="column">
        <Typography
          sx={{
            color: isSender ? "white" : primaryPurple,
          }}
        >
          {msg.MESSAGE_TEXT}
        </Typography>
        <Typography sx={{ color: isSender ? "white" : primaryPurple }}>
          {convertFullDateTime(msg.MESSAGE_PROPOSED_TIME)}
        </Typography>
        {msg.MESSAGE_QUANTITY ? (
          <Typography sx={{ color: isSender ? "white" : primaryPurple }}>
            {msg.MESSAGE_QUANTITY}{" "}
            {msg.MESSAGE_QUANTITY == 1 ? "minute" : "minutes"}
          </Typography>
        ) : null}
      </Box>
      {!isSender ? (
        <Box pl={2}>
          <Call color={"#7730E1"} height="16px" width="16px" />
        </Box>
      ) : null}
    </Box>
  );
};

const getMessageRecording = (msg: Message, isSender: boolean) => {
  return (
    <Box
      sx={{ background: primaryPurple, borderRadius: 10, p: 2 }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={() => window.open(msg.MESSAGE_TEXT, "_blabk")}
    >
      {isSender ? (
        <Box pr={2}>
          <CloudDownload color={"#FFFFFF"} height="16px" width="16px" />
        </Box>
      ) : null}
      <Box display="flex" flexDirection="column">
        <Typography sx={{ color: isSender ? "white" : primaryPurple }}>
          Session Recording
        </Typography>
        <Typography sx={{ color: isSender ? "white" : primaryPurple }}>
          {convertFullDateTime(msg.TIMESTAMPSERVER.toString())}
        </Typography>
      </Box>
      {!isSender ? (
        <Box pl={2}>
          <CloudDownload color={"#7730E1"} height="16px" width="16px" />
        </Box>
      ) : null}
    </Box>
  );
};

const getMessageImage = (imageUrl: string, isSender: boolean) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent={isSender ? "end" : "start"}
    >
      <img
        src={imageUrl}
        width={300}
        style={{ objectFit: "contain", borderRadius: 12 }}
      />
    </Box>
  );
};

// const splitInput = (input: string, regex: RegExp) => {
//   let parts = [];
//   let match;

//   // Use lastIndex to keep track of the last index after each match
//   let lastIndex = 0;

//   // Find matches and build the parts array
//   while ((match = regex.exec(input)) !== null) {
//     // Add text before the URL
//     parts.push(input.substring(lastIndex, match.index));

//     // Add the URL
//     parts.push(match[0]);

//     // Update lastIndex to the end of the current match
//     lastIndex = match.index + match[0].length;
//   }

//   // Add any remaining text after the last URL
//   if (lastIndex < input.length) {
//     parts.push(input.substring(lastIndex));
//   }

//   return parts;
// }

const replaceUrlsWithLinks = (input: string, regex: RegExp) => {
  return input.replace(regex, (match: any) => {
    const formattedMatch =
      match.startsWith("http://") || match.startsWith("https://")
        ? match
        : `https://${match}`;
    return `<a className="text link" target="_blank" href="${formattedMatch}">${match}</a>`;
  });
};

const getMessageLink = (link: string) => {
  var urlRegex =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=\(\)]*)?/gi;
  let ar = `<div className="text">`;
  ar += replaceUrlsWithLinks(link, urlRegex);
  ar += "</div>";
  return parse(ar);
};

interface ProgressProps {
  value: number;
}
function CircularProgressWithLabel(props: ProgressProps) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress determinate {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          className="text"
          level="body-xs"
          component="div"
          color="neutral"
          sx={{ display: "inherit" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
