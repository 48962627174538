import { Stack, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import InfoDialog from "../../components/dialog/InfoDialog";
import LightsterBookingItem from "../../components/session/LightsterBookingItem";
import LightsterSessionItem from "../../components/session/LightsterSessionItem";
import LightsterSurveyItem from "../../components/session/LightsterSurveyItem";
import {
  Booking,
  Session,
  SurvayDisplay,
  Unmoderated,
} from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import LightsterUnmoderatedItem from "../../components/session/LightsterUnmoderatedItem";

interface Props {}

function LightsterHome(props: Props) {
  const {} = props;
  const { userService } = useApi();
  const { onClientOpen } = useAuth();

  const state = useLocation().state;

  const [open, setOpen] = useState({
    quickbooking: false,
    unmoderated: false,
  });
  const [ready, setReady] = useState(false);

  const [upcoming, setUpcoming] = useState<Session[]>([]);
  const [invitations, setInvitations] = useState<Session[]>([]);
  const [booking, setBooking] = useState<Booking[]>([]);
  const [bookingId, setBookingId] = useState<string>();
  const [unmoderated, setUnmoderated] = useState<Unmoderated[]>([]);
  const [unmoderatedId, setUnmoderatedId] = useState<string>();
  const [surveys, setSurveys] = useState<SurvayDisplay[]>([]);

  const getLighsterHome = async () => {
    const res = await userService.getLightsterHome();
    if (!res) return;

    setInvitations(res.pendingSession);
    setUpcoming(res.upcomingSession);
    setBooking(res.quickBooking);
    setSurveys(res.survey);
    setUnmoderated(res.unmoderated);
    setReady(true);
  };

  const invitationsEmpty = () => {
    return (
      upcoming.length === 0 &&
      invitations.length === 0 &&
      booking.length === 0 &&
      surveys.length === 0 &&
      unmoderated.length === 0
    );
  };
  useEffect(() => {
    onClientOpen();
    getLighsterHome();
    if (state?.quickBookingId) {
      setBookingId(state.quickBookingId);
    }
    if (state?.unmoderatedId) {
      setUnmoderatedId(state.unmoderatedId);
    }
  }, []);

  useEffect(() => {
    if (bookingId && ready) {
      const bookIds: string[] = booking.map((e) => e.id);
      if (!bookIds.includes(bookingId)) {
        setOpen({ ...open, quickbooking: true });
      }
    }
  }, [bookingId, ready]);

  useEffect(() => {
    if (unmoderatedId && ready) {
      const bookIds: string[] = unmoderated.map((e) => e.id);
      if (!bookIds.includes(unmoderatedId)) {
        setOpen({ ...open, unmoderated: true });
      }
    }
  }, [unmoderatedId, ready]);

  useEffect(() => {
    var interval = setInterval(() => {
      getLighsterHome();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {open.quickbooking && (
        <InfoDialog
          open={open.quickbooking}
          title="Not available"
          subtitle={
            <Typography level="body-lg">
              We’re sorry but this invitation is no longer available.
            </Typography>
          }
          textButton="Close"
          onClose={() => {
            setBookingId("");
            setOpen({ ...open, quickbooking: false });
            window.history.replaceState({}, document.title);
          }}
        />
      )}
      {open.unmoderated && (
        <InfoDialog
          open={open.unmoderated}
          title="Not available"
          subtitle={
            <Typography level="body-lg">
              We’re sorry but this invitation is no longer available.
            </Typography>
          }
          textButton="Close"
          onClose={() => {
            setUnmoderatedId("");
            setOpen({ ...open, unmoderated: false });
            window.history.replaceState({}, document.title);
          }}
        />
      )}
      <Typography level="h3">Invitations</Typography>
      <Box height={"calc(100VH - 132px)"} overflow="auto">
        {invitationsEmpty() ? (
          <Typography key={"no-session"} level="body-md">
            You don’t have any upcoming sessions. Visit our mobile app to
            respond to questions, messages, surveys, or invitations to a
            session.
          </Typography>
        ) : (
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
          >
            {surveys
              .filter((survey) => survey.status === "inProgress")
              .map((survey) => (
                <LightsterSurveyItem
                  key={`survey-${survey.id}`}
                  survey={survey}
                  refresh={() => getLighsterHome()}
                />
              ))}
            {booking.map((book) => (
              <LightsterBookingItem
                key={book.id}
                showDefault={bookingId == book.id}
                book={book}
                refresh={getLighsterHome}
              />
            ))}
            {unmoderated.map((unmoderated) => (
              <LightsterUnmoderatedItem
                key={unmoderated.id}
                showDefault={unmoderatedId == unmoderated.id}
                unmoderated={unmoderated}
                refresh={getLighsterHome}
              />
            ))}
            {invitations.map((invite) => (
              <LightsterSessionItem
                key={invite._id}
                session={invite}
                refresh={getLighsterHome}
              />
            ))}
            {upcoming.map((invite) => (
              <LightsterSessionItem
                key={invite._id}
                session={invite}
                refresh={getLighsterHome}
              />
            ))}
          </Stack>
        )}
        {invitationsEmpty() ? (
          <Box height={"5vh"} width="600px" marginTop={"16px"}>
            <Footer />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default LightsterHome;
