import React, { SetStateAction, useEffect, useState } from "react";

import { MdOutlineContentCopy } from "react-icons/md";

import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Dropdown,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Radio,
  Typography,
} from "@mui/joy";
import AgoraRTC, {
  CustomAudioTrackInitConfig,
  IAgoraRTCClient,
  ScreenVideoTrackInitConfig
} from "agora-rtc-sdk-ng";
import { collection, onSnapshot, query } from "firebase/firestore";
import moment from "moment/moment";
import {
  agoraAppId,
  client,
  getCameraDevices,
  getMicrophoneDevices,
  getSpeakerDevices,
} from "../../constants/agoraRtc";

import { isSupportBrowsers } from "../../constants/enum";
import { Message, Session } from "../../constants/interfaces";
import { primaryPurpleHover, redHoverEndSession } from "../../joy-styles";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import { firebaseDB } from "../../providers/FirebaseProvider";
import { input, output, videoinput } from "./Devices";
import { Hidden } from "@mui/material";

import {
  ChatOff,
  ChatOn,
  MicOff,
  MicOn,
  RecordOff,
  RecordOn,
  ShareScreenOff,
  ShareScreenOn,
  SpeakerOff,
  VideoOff,
  VideoOn
} from "../../constants/AppImages";

interface DevicesProps {
  input: Array<any> | [];
  output: Array<any> | [];
  video: Array<any> | [];
}
interface ControlsProps {
  start: boolean;
  tracks: any;
  setTracks: (value: any) => void;
  session: Session;
  guest: boolean;
  questLightster: boolean;
  recordingTimer: number;
  trackState: any;
  setTrackState: (value: any) => void;
  setShareScreenUid: SetStateAction<any>;
  record?: {
    recording: boolean;
    setRecording: (value: boolean) => void;
  };
  ownAction?: {
    ownAction: boolean;
    setOwnAction: (value: boolean) => void;
  };
  chat?: {
    openChat: boolean;
    setOpenChat: (value: boolean) => void;
  };
}
export default function ControlsFooter({
  start,
  tracks,
  setTracks,
  session,
  guest,
  questLightster,
  trackState,
  setTrackState,
  setShareScreenUid,
  recordingTimer,
  record,
  ownAction,
  chat,
}: ControlsProps) {
  const { role, user } = useAuth();
  const { sessionService, showMessage } = useApi();

  const [unreadCount, setUnreadCount] = useState(0);
  const [devices, setDevices] = useState<DevicesProps>({
    input: [],
    output: [],
    video: [],
  });

  const [mediaDefault, setMediaDefault] = useState({
    input: "",
    output: "",
    video: "",
  });

  const [clientShareScreen, setClientShareScreen] =
    useState<IAgoraRTCClient | null>(null);

  const handleChat = () => {
    if (chat) {
      const { openChat, setOpenChat } = chat;
      setOpenChat(!openChat);
    }
  };
  const mute = async (type: string) => {
    if (type === "audio") {
      await tracks.audio.setEnabled(!trackState.audio);
      setTrackState((ps: any) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      if (!tracks.video) {
        return showMessage(
          "The camera can't be turned on, the device is not found.",
          "error"
        );
      }
      await tracks.video.setEnabled(!trackState.video);
      setTrackState((ps: any) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const handleShareScreen = async () => {
    if (!start) {
      showMessage(
        "You can share the screen once the call has started.",
        "warning"
      );
      return;
    }
    if (!trackState?.share) {
      const client2 = AgoraRTC.createClient({
        mode: "rtc",
        codec: "h264",
      });

      const config: ScreenVideoTrackInitConfig = {
        optimizationMode: "detail",
        systemAudio: "exclude",
        encoderConfig: "1080p_3",
      };
      await AgoraRTC.createScreenVideoTrack(config)
        .then(async (screenTrack: any) => {
          const res = await sessionService.sharescreenJoinSession(session.refNumber);

          await client2.join(
            agoraAppId,
            res.refNumber,
            res.rtcToken,
            parseInt(res.agoraUID)
          );

          await client2.publish(screenTrack);

          screenTrack.on("track-ended", async () => {
            screenTrack.stop();
            await client2.unpublish(screenTrack);

            setShareScreenUid(null);
            setTracks((pre: any) => {
              return { ...pre, share: null };
            });
            setTrackState((pre: any) => {
              return { ...pre, share: !pre.share };
            });
            await client2.leave();

            await updateRecording(session.lightster.agoraUID);
          });

          setShareScreenUid(res.agoraUID);
          setTrackState((pre: any) => {
            return { ...pre, share: !pre.share };
          });
          setTracks((pre: any) => {
            return { ...pre, share: screenTrack };
          });
          setClientShareScreen(client2);

          await updateRecording(res.agoraUID);
        })
        .catch((e) => {
          showMessage(
            "Screen-sharing permissions not enabled. Please go to your System Settings to enable screen-sharing for your browser.",
            "error"
          );
          console.log(e);
        });
    } else {
      tracks.share?.close();
      await clientShareScreen?.unpublish(tracks.share);

      setShareScreenUid(null);
      setTracks((pre: any) => {
        return { ...pre, share: null };
      });
      setTrackState((pre: any) => {
        return { ...pre, share: !pre.share };
      });
      await clientShareScreen?.leave();
      setClientShareScreen(null);
      await updateRecording(session.lightster.agoraUID);
    }
  };

  const updateRecording = async (uid: string) => {
    await sessionService.updateRecording(session._id, uid);
  };

  const handleDevicesChange = async (type: string, deviceId: string) => {
    if (type === "audio") {
      localStorage.setItem("audioDeviceId", deviceId);
      setMediaDefault({ ...mediaDefault, input: deviceId });
      const _stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: deviceId },
      });
      const _audio = _stream.getAudioTracks()[0];
      const config: CustomAudioTrackInitConfig = {
        mediaStreamTrack: _audio,
      };
      const _track = AgoraRTC.createCustomAudioTrack(config);

      await client.unpublish(tracks.audio);
      await client.publish(_track);

      if (client.remoteUsers.length > 0) {
        client.remoteUsers.map((m) =>
          m.audioTrack?.setPlaybackDevice(mediaDefault.output)
        );
      }
      setTracks({ ...tracks, audio: _track });
    } else if (type === "speaker") {
      if (client.remoteUsers.length > 0) {
        client.remoteUsers.map((m) => {
          m.audioTrack?.setPlaybackDevice(deviceId);
          localStorage.setItem("speakerDeviceId", deviceId);
          setMediaDefault({ ...mediaDefault, output: deviceId });
        });
      }
    } else if (type === "video") {
      localStorage.setItem("videoDeviceId", deviceId);
      setMediaDefault({ ...mediaDefault, video: deviceId });
      const _stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: deviceId },
      });
      const _video = _stream.getVideoTracks()[0];
      const _track = AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack: _video,
      });

      await client.unpublish(tracks.video);
      await client.publish(_track);
      setTracks({ ...tracks, video: _track });
    }
  };

  useEffect(() => {
    const getAllDevices = async () => {
      const mics: any = await getMicrophoneDevices();
      const speakers: any = await getSpeakerDevices();
      const videos: any = await getCameraDevices();

      setDevices({ ...devices, input: mics, output: speakers, video: videos });
      setMediaDefault((per) => {
        return {
          input: input ?? mics[0]?.deviceId,
          output: output ?? speakers[0]?.deviceId,
          video: videoinput ?? videos[0]?.deviceId,
        };
      });
    };

    getAllDevices();
  }, []);

  const getCurrentId = () => {
    return `${role}_${user?.id}`;
  };

  const getReceiverId = () => {
    if (role === "creator") {
      return `lightster_${receiverId()}`;
    } else {
      return `creator_${receiverId()}`;
    }
  };

  const receiverId = () => {
    if (role === "creator") {
      return session.lightster.id;
    } else {
      return session.creator.id;
    }
  };

  useEffect(() => {
    if (guest) {
      return;
    }
    const q = query(
      collection(firebaseDB, `chats/${getCurrentId()}/${getReceiverId()}`)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages: Array<Message> = [];
      querySnapshot.forEach((doc) => {
        messages.push(doc.data() as Message);
      });
      messages.sort(function (a, b) {
        const aTimestamp = a.TIMESTAMPSERVER ? a.TIMESTAMPSERVER.toMillis() : 0;
        const bTimestamp = b.TIMESTAMPSERVER ? b.TIMESTAMPSERVER.toMillis() : 0;
        return aTimestamp - bTimestamp;
      });

      const unread = messages.filter(
        (m) =>
          m.READ === false &&
          m.RECEIVER_ID === getCurrentId() &&
          m.TIMESTAMPSERVER &&
          m.TIMESTAMPSERVER.toMillis() === new Date().getTime()
      );
      setUnreadCount(unread.length);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Grid container direction="row">
      <Grid xs alignItems="center">
        <Box>
          <Box
            width={"50px"}
            height={"50px"}
            sx={{
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              bgcolor: trackState?.share ? "#7E37FF20" : "white",
              color: trackState?.share ? "#7E37FF" : "black",
              ":hover": {
                border: "none",
                background: `${primaryPurpleHover} !important`,
              },
            }}
          >
            {!trackState?.share ? (
              <img src={ShareScreenOff} alt="shareScreenOff"
                style={{ width: "28px", height: "28px", cursor: "pointer", fontSize: "small" }}
                onClick={handleShareScreen}
              />
            ) : (
              <img src={ShareScreenOn} alt="shareScreen"
                style={{ width: "28px", height: "28px", cursor: "pointer" }}
                onClick={handleShareScreen}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        xs={10}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        container
        // spacing={2}
        spacing={"16px"}
      >
        <Grid>
          <Box width={50} height={"auto"} sx={{
            borderRadius: 6,
            background: trackState?.audio ? `white` : `#FF3737`,
            color: trackState?.audio ? `black` : `#FFFFFF`,
            ":hover": {
              border: "none",
              background: `${primaryPurpleHover} !important`,
            },
          }} >
            <ContainerIcon
              icon={
                trackState?.audio ? (
                  <img src={MicOff} alt="MicOff"
                    onClick={() => mute("audio")}
                  />
                ) : (
                  <img src={MicOn} alt="MicOn"
                    onClick={() => mute("audio")}
                  />
                )
              }
              type="audio"
              items={devices.input}
              deviceId={mediaDefault.input}
              onChangeDevice={(deviceId: string) => {
                handleDevicesChange("audio", deviceId);
              }}
            />
          </Box>
        </Grid>
        {isSupportBrowsers() ? (
          <Grid>
            <Box width={50} sx={{
              borderRadius: 6,
              ":hover": {
                background: `1px solid ${redHoverEndSession}`,
              }
            }}>
              <ContainerIcon
                type={"speaker"}
                items={devices.output}
                deviceId={mediaDefault.output}
                icon={
                  <img src={SpeakerOff} alt="SpeakerOff"
                    style={{ color: "#00000" }}
                  />
                }
                onChangeDevice={(value: string) => {
                  handleDevicesChange("speaker", value);
                }}
                cursor={false}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid>
          <Box width={50} height={"auto"} sx={{
            borderRadius: 6,
            background: trackState.video ? `white` : `#FF3737`,
            color: trackState.video ? `black` : `#FFFFFF`,
            ":hover": {
              border: "none",
              background: `${primaryPurpleHover} !important`,
            },
          }}>
            <ContainerIcon
              icon={
                trackState.video ? (
                  <img src={VideoOff} alt="VideoOff"
                    onClick={() => mute("video")}
                  />
                ) : (
                  <img src={VideoOn} alt="VideoOn"
                    onClick={() => mute("video")}
                  />
                )
              }
              type="video"
              deviceId={mediaDefault.video}
              items={devices.video}
              onChangeDevice={(deviceId) => {
                handleDevicesChange("video", deviceId);
              }}
            />
          </Box>
        </Grid>
        {((guest && !questLightster) || role === "creator") && start ? (
          <Grid>
            <Box >
              <div style={{ textWrap: "balance" }}>
                <Button
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: !record?.recording ? "none" : "none",
                    background: !record?.recording ? "white" : "#FFE5E5",
                    padding: "8px",
                    border: "none",
                    borderRadius: 6,
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontSize: "14px !important",
                    fontWeight: 400,
                    ":hover": {
                      border: "#FFE5E5",
                      background: !record?.recording ? `${primaryPurpleHover} !important` : "#FFE5E5 !important",
                      color: "black"
                    },
                    ":onClick": {
                      background: "#FFE5E5",

                    },
                  }}
                  fullWidth
                  variant="outlined"
                  color="neutral"
                  startDecorator={
                    !record?.recording ? <img src={RecordOff} alt="RecordOff" /> : <img src={RecordOn} alt="RecordOn" />
                  }
                  onClick={() => {
                    if (!record?.recording) {
                      record?.setRecording(true);
                      ownAction?.setOwnAction(true);
                    } else {
                      record?.setRecording(false);
                      ownAction?.setOwnAction(true);
                    }
                  }}
                >
                  <Hidden lgDown>
                    {!record?.recording ? "Record Session" : `${moment(recordingTimer * 1000).format("mm:ss")} - Stop Recording`}
                  </Hidden>
                </Button>
              </div>
            </Box>
          </Grid>
        ) : null}

        {!guest && role === "creator" ? (
          <Hidden smDown>
            <Grid>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    "& .MuiButton-startIcon": { margin: "0px" }, minWidth: 0,

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "8px",
                    border: "none",
                    //border: `solid 1px transparent`, 
                    borderRadius: 6,
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontSize: "14px !important",
                    fontWeight: 400,
                    ":hover": {
                      border: "none",
                      background: `${primaryPurpleHover} !important`,
                    },
                  }}
                  size="lg"
                  color="neutral"
                  fullWidth
                  startDecorator={<MdOutlineContentCopy style={{ width: "18px", height: "18px", color: "black", cursor: "pointer", fontSize: "medium" }} />}
                  onClick={() => {
                    navigator.clipboard.writeText(session.meetingURL);
                    showMessage("Meeting ID Copied!", "success");
                  }}
                >
                  <Hidden lgDown>
                    Copy Meeting ID
                  </Hidden>
                </Button>
              </Box>
            </Grid>
          </Hidden>

        ) : null}
      </Grid>
      <Grid display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        xs
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            width: 50,
            height: 50,
            borderRadius: 6,
            bgcolor: chat?.openChat ? "#7E37FF20" : "white",
            ":hover": {
              border: "none",
              background: `${primaryPurpleHover} !important`,
            },

          }}
        >
          {chat?.openChat ? (
            <img src={ChatOn} alt="ChatOn"
              width="30px" height="30px"
              onClick={handleChat}
              style={{ cursor: "pointer" }}
            />) :
            (
              <img src={ChatOff} alt="ChatOff"
                width="30px" height="30px"
                onClick={handleChat}
                style={{ cursor: "pointer" }}
              />
            )}

          {unreadCount > 0 && (
            <Box
              sx={{
                width: 24,
                height: 24,
                color: "white",
                background: "#7730E1",
                borderRadius: "50%",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Typography
                fontSize={16}
                textAlign="center"
                sx={{ color: "#FFF" }}
              >
                {unreadCount}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
interface ContainerIconProps {
  type: string;
  icon: JSX.Element;
  items: any[];
  deviceId: string;
  onChangeDevice: (value: string) => void;
  cursor?: boolean;
}
export const ContainerIcon = ({
  type,
  icon,
  items,
  deviceId,
  onChangeDevice,
  cursor = true,
}: ContainerIconProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: 50,
        height: 50,
        //bgcolor: "#666CFF20",
        borderRadius: 50,
        textAlign: "center",
        cursor: (cursor ? 'pointer' : "not-allowed"),
      }}
    >
      {icon}
      {items.length > 0 && (
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{
              root: {
                variant: "solid",
                // color: "primary",
                sx: {
                  backgroundColor: "#000",
                  borderRadius: 50,
                  position: "absolute",
                  //positive value for top, negative for bottom
                  bottom: 0,
                  //positive for left, negative for right
                  right: 0,
                  "--IconButton-size": "18px",
                  padding: 0,
                },
              },
            }}
            sx={{ padding: 0 }}
          >
            <ExpandMoreRounded sx={{ color: "#FFF", fontSize: 16 }} />
          </MenuButton>
          <Menu placement="bottom-start">
            {items.map((t: any) => (
              <MenuItem
                key={t.deviceId}
                onClick={() => {
                  handleClose();
                  onChangeDevice(t.deviceId);
                }}
              >
                <Radio checked={t.deviceId === deviceId} sx={{ mr: 2 }} />
                {t.label}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      )}
    </Box>
  );
};
