import { Box, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { TextResponse } from "../../constants/enum";

interface Props {
  defaultValue?: string;
  onChange: (value: string) => void;
}

function CustomTextfield(props: Props) {
  const { defaultValue, onChange } = props;
  const [value, setValue] = useState("");

  const handleSelectOptions = (option: string) => {
    setValue(option);
    onChange(option);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      handleSelectOptions(TextResponse.optional);
    }
  }, []);
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Stack direction={"column"} spacing={1}>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => handleSelectOptions(TextResponse.optional)}
        >
          <i
            className="bi bi-check2-circle"
            style={{
              fontSize: 24,
              cursor: "pointer",
              color: value === TextResponse.optional ? "#000000" : "#EEEFF4",
            }}
            onClick={() => handleSelectOptions(TextResponse.optional)}
          ></i>
          <Typography level="title-lg">Optional</Typography>
        </Stack>

        <Typography level="body-md" sx={{ pl: 4 }}>
          This question does not impact who shows up under Your Criteria, but if
          they have a response you will see it on their profile.
        </Typography>
      </Stack>

      <Stack direction={"column"} spacing={1}>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => handleSelectOptions(TextResponse.required)}
        >
          <i
            className="bi bi-check2-circle"
            style={{
              fontSize: 24,
              cursor: "pointer",
              color: value === TextResponse.required ? "#000000" : "#EEEFF4",
            }}
            onClick={() => handleSelectOptions(TextResponse.required)}
          ></i>
          <Typography level="title-lg">Required</Typography>
        </Stack>
        <Typography level="body-md" sx={{ pl: 4 }}>
          Lightsters must have a response to this question to show up under Your
          Criteria.
        </Typography>
      </Stack>
    </Box>
  );
}

export default CustomTextfield;
