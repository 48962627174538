import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng";

export const client: IAgoraRTCClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "h264",
});
export const agoraAppId = "a59c4703691d4eb4a92fd8544ac1dec0";

export const getMicrophoneDevices = async () => {
  const devices = await AgoraRTC.getMicrophones()
    .then((stream) => {
      return stream;
    })
    .catch((e) => {
      console.error("get devices error!", e);
    });

  return devices;
};

export const getSpeakerDevices = async () => {
  const devices = await AgoraRTC.getPlaybackDevices()
    .then((stream) => {
      return stream;
    })
    .catch((e) => {
      console.error("get devices error!", e);
    });

  return devices;
};

export const getCameraDevices = async () => {
  const devices = await AgoraRTC.getCameras()
    .then((stream) => {
      return stream;
    })
    .catch((e) => {
      console.error("get devices error!", e);
    });

  return devices;
};
