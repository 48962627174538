import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import BackgroundRegister from "../components/BackgroundRegister";
import { useApi } from "../providers/ApiProvider";
import {
  Box,
  CircularProgress,
  Grid,
} from "@mui/joy";
import AcceptedDialog from "../components/dialog/invitations/AcceptedDialog";
import DeclinedDialog from "../components/dialog/invitations/DeclinedDialog";
import ConfirmYourSpot from "../components/dialog/invitations/ConfirmYourSpot";
import DetailBeforeGetSpot from "../components/dialog/invitations/DetailBeforeGetSpot";
import GetYourSpotDialog from "../components/dialog/invitations/GetYourSpotDialog";
import { Booking } from "../constants/interfaces";


function QuickbookingNonUser() {
  const params = new URLSearchParams(window.location.search);
  const quickBookingId = params.get("quickBookingId");
  const userId = params.get("id");
  const [ready, setReady] = useState(false);

  const [open, setOpen] = useState({
    getSpot: false,
    confirmSpot: false,
    accepted: false,
    noMoreSpot: false,
    detail: false,
    declined: false,
  });
  const navigate = useNavigate();
  const { sessionService, showMessage, userService } = useApi();
  const [spot, setSpot] = useState<string[]>([]);
  const [select, setSelect] = useState("");
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState<Booking>();

  useEffect(() => {
    getQuickBookingDetail();
    handleUpdateTimezone();
  }, []);


  const getQuickBookingDetail = async () => {
    if (quickBookingId && userId) {
      const res = await sessionService.detailBookingNonUser(quickBookingId, userId);
      console.log(res);
      if (!res) {
        return;
      };
      setBook({ ...res.creator, id: res._id.toString() });
      setSpot(res.creator.proposedTime);
      setOpen({ ...open, detail: true });
    }
  };

  const handleUpdateTimezone = async () => {
    if (userId) {
      const timezone = (new Date().getTimezoneOffset() * -1).toString();
      const payload = {
        timezone: timezone,
      };
      const res = await userService.updateTimezoneNonUser(payload, userId);
      if (!res) return;
    }
  };

  const handleConfirmSpot = async (id: string) => {
    if (userId) {
      setLoading(true);
      const token = await grecaptcha.enterprise.execute('6LcM4dEpAAAAAONOyGImTGbJ_Be5_xe5oVCdmNL9', {action: 'transaction_confirmed'});
      const res = await sessionService.confirmBookingNonUser(id, select, userId,token ,'web');
      setLoading(false);
      if (!res) {
        setOpen({ ...open, confirmSpot: false });
        return;
      }
      setOpen({ ...open, confirmSpot: false, accepted: true });
      // showMessage('Request sent! Check the status on the Invitations tab.', 'success');
    }
  };

  const handledeclineBooking = async () => {
    if (book && userId) {
      await sessionService.declineBookingNonUser(book.id, userId);
    }
    setOpen({ ...open, detail: false, declined: true });
    // showMessage('You have successfully declined the invitation.', 'success');
  };

  if (loading)
    return (
      <Box
        display={"flex"}
        height={"100vh"}
        width={"100vw"}
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <BackgroundRegister>
      <Box
        sx={{
          width: "100%",

          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container>

          {open.detail && spot.length > 0 && (
            <DetailBeforeGetSpot
              open={open.detail}
              nonUser={true}
              onClose={(value) => {
                if (!value) handledeclineBooking();
                setOpen({ ...open });
              }}
              book={book!}
              onSelected={() => {
                setOpen({
                  ...open,
                  detail: false,
                  getSpot: true,
                });
              }}
            />
          )}
          {open.getSpot && spot.length > 0 && (
            <GetYourSpotDialog
              open={open.getSpot}
              nonUser={true}
              onClose={() => {
                setOpen({ ...open });
              }}
              spot={spot}
              onSelected={(value) => {
                setSelect(value);
                setOpen({
                  ...open,
                  getSpot: false,
                  confirmSpot: true,
                });
              }}
            />
          )}
          {open.confirmSpot && select && (
            <ConfirmYourSpot
              open={open.confirmSpot}
              loading={loading}
              date={select}
              onClose={() => {
                setOpen({
                  ...open,
                  getSpot: true,
                  confirmSpot: false,
                });
                setSelect("");
              }}
              onConfirm={() => handleConfirmSpot(book?.id || "")}
            />
          )}
          {open.declined && (
            <DeclinedDialog
              open={open.declined}
              onClose={() => {
                setOpen({ ...open, declined: false });
                navigate("/");
              }}
            />
          )}
          <AcceptedDialog
            open={open.accepted}
            onClose={() => {
              setOpen({ ...open, accepted: false });
              navigate("/");
            }}
          />


        </Grid>
      </Box>
    </BackgroundRegister>
  );
};

export default QuickbookingNonUser;
