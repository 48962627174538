import { Edit, Info } from "@mui/icons-material";
import {
    Box,
    CircularProgress,
    Input,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import InfoDialog from "../../../components/dialog/InfoDialog";
import config from "../../../config";
import { StorageType } from "../../../constants/enum";
import {
    CallTypeReward,
    CreateSurveyParams,
    PaymentType,
} from "../../../constants/interfaces";
import { primaryPurple } from "../../../joy-styles";
import { useApi } from "../../../providers/ApiProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { red } from "@mui/material/colors";
interface Props {}

function SurveyPayment(props: Props) {
  const {} = props;
  const state = useLocation()?.state;

  const { paymentService, sessionService } = useApi();
  const navigate = useNavigate();
  const rewards = [10, 20, 30, 40, 50];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({
    fees: false,
    custom: false,
    confirm: false,
  });
  const [data, setData] = useState<CreateSurveyParams | null>(null);
  const [customReward, setCustomReward] = useState<number | null>(null);
  const [cards, setCards] = useState<PaymentType[]>([]);
  const [subscription, setSubscription] = useState<PaymentType>();
  const [card, setCard] = useState<PaymentType | null>();
  const [callType, setCallType] = useState<CallTypeReward>();
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const { showMessage } = useApi();
//added

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (data?.reward) {
      getPaymentReward(data?.reward);
    }
  }, [data?.reward]);

  const init = () => {
    const survey = state.survey;

    if (survey) {
      setData({ ...survey, reward: 30 });
    }
  };

  const getPaymentReward = async (reward: number) => {
    setLoading(true);
    const res = await paymentService.getPaymentCallType(reward);
    setCallType(res);
    const _cards = (res as CallTypeReward).paymentType.filter(
      (f) => f.type === "card"
    );
    const sub = (res as CallTypeReward).paymentType.filter(
      (f) => f.type === "subscription"
    );
    if (_cards.length > 0) {
      const defaultCard = _cards.find((f) => f.default || f.id === undefined);
      setCard(defaultCard);
      if (error) {
        setError(false);
      }
    }
    setCards(_cards);
    if (sub.length > 0) {
      setSubscription(sub[0]);
    }
    setLoading(false);
  };

  const handleCreateSurvey = async () => {
    if (!card || !card.id) { //modified
      setError(true);
      showMessage("Please select a payment method first.", "error");
      return; //added
    }
    if (!data) return;
    setLoading(true);
    data.callType = subscription ? subscription.type : card?.type;
    data.paymentMethodId = subscription ? subscription.id : card?.id;

    const res = await sessionService.createSurvey(data);
    if (!res) return;
    setTimeout(() => {
      setLoading(false);
      setDone(true);
      localStorage.removeItem(StorageType.survry);
      setOpen({ ...open, confirm: true });
    }, 1000);
  };

  const addCreaditCard = async () => {
    const token = localStorage.getItem(StorageType.token);
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
        "X-LIGHTSTER-CLIENT": "WebAppShareScreen",
        "Access-Control-Expose-Headers": "Location",
        Accept: "*/*",
      },
    });
    instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    await instance
      .post(`${config.backendUrl}/creator/payment/cards`, { isWeb: true })
      .then(function (response: AxiosResponse) {
        var newWin = window.open(
          response.data.url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=50 ,left=50 ,width=500,height=600"
        );

        var timer = setInterval(function () {
          if (newWin?.closed) {
            clearInterval(timer);
            if (data?.reward) {
              getPaymentReward(data.reward);
            }
          }
        }, 1000);
      })
      .catch(function (error: AxiosResponse) {
        console.log(error);
      });
  };

  if (!data) return <></>;

  return (
    <Box className="home" sx={{ height: "calc(100vh - 132px)" }}>
      {!done && (
        <Stack direction={"column"} spacing={4}>
          <Box className="box-column" sx={{ alignItems: "start", gap: 2 }}>
            <Box>
              <Typography level="h2" fontSize={28}>
                Rewards
              </Typography>
              <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
                Set the reward that you would like to offer in appreciation for
                their time and insights.
              </Typography>
            </Box>
            <Box className="box-row" sx={{ gap: 1 }}>
              {rewards.map((reward) => (
                <Box
                  sx={(theme) => ({
                    paddingInline: 1,
                    paddingBlock: "4px",
                    minWidth: 30,
                    border: `1.5px solid ${
                      reward === data.reward
                        ? theme.palette.primary[500]
                        : "black"
                    }`,
                    bgcolor:
                      reward === data.reward
                        ? theme.palette.primary[500]
                        : "white",
                    color: reward === data.reward ? "white" : "black",
                    borderRadius: 20,
                    alignItems: "center",
                    fontSize: 14,
                    ":hover": {
                      border: `1.5px solid ${theme.palette.primary.darkChannel}`,
                      bgcolor: theme.palette.primary.lightChannel,
                      color: theme.palette.primary[500],
                    },
                  })}
                  onClick={function () {
                    setData({ ...data, reward: reward });
                    setCustomReward(null);
                  }}
                >
                  ${reward}
                </Box>
              ))}
              <Box>
                <Stack
                  direction={"row"}
                  gap={1}
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpen({ ...open, custom: true })}
                >
                  <Edit sx={{ fontSize: 18 }} />
                  <Typography
                    level="body-md"
                    sx={{
                      color: customReward ? primaryPurple : "black",
                      textDecoration: customReward ? "underline" : "none",
                    }}
                  >
                    {!customReward || customReward === 0
                      ? "Enter a custom amount"
                      : customReward}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
          {loading ? (
            <Box
              className="box-column"
              sx={{
                minHeight: 300,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size="md" />
            </Box>
          ) : (
            <Box>
              <Typography level="h2" fontSize={28}>
                Summary
              </Typography>
              {callType?.remainingCredit ?? 0 > 0 ? (
                <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
                  You have{" "}
                  <Typography level="title-lg">
                    ${callType?.remainingCredit} in credits
                  </Typography>
                  . We will use your credits first and charge any remaining
                  amount to your payment method.
                </Typography>
              ) : (
                <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
                  This is the amount that will be charged to your payment method
                  for each session.
                </Typography>
              )}
              {subscription ? (
                <Box
                  className="box-column"
                  alignItems={"start"}
                  sx={{
                    bgcolor: "#F7FAFC",
                    borderRadius: 10,
                    mt: 4,
                    p: 3,
                    gap: 1,
                  }}
                >
                  <Box
                    className="box-row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <Typography level="body-lg">Each session</Typography>
                    <Typography level="body-lg">
                      {data.reward} credits
                    </Typography>
                  </Box>
                  <Box
                    className="box-row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <Typography level="h3">Balance:</Typography>
                    <Typography level="h3">
                      {subscription?.remainingCredit} credits
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  className="box-column"
                  alignItems={"start"}
                  sx={{
                    bgcolor: "#F7FAFC",
                    borderRadius: 10,
                    mt: 4,
                    p: 3,
                    gap: 1,
                  }}
                >
                  <Box
                    className="box-row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <Typography level="body-lg">
                      ${data.reward} user reward per response x{" "}
                      {data.expectResponse}
                    </Typography>
                    {data.reward && data.expectResponse && (
                      <Typography level="body-lg">
                        $
                        {(data.reward * Number(data.expectResponse))?.toFixed(
                          2
                        )}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    className="box-row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <Box
                      className="box-row"
                      sx={{ gap: 1 }}
                      onClick={() => setOpen({ ...open, fees: true })}
                    >
                      <Typography level="body-lg" alignItems={"center"}>
                        Fees
                      </Typography>
                      <Info sx={{ color: "black" }} />
                    </Box>

                    {callType?.fee && (
                      <Typography level="body-lg">
                        $
                        {(callType.fee * Number(data.expectResponse)).toFixed(
                          2
                        )}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    className="box-row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <Typography level="h3">Total:</Typography>
                    {callType?.total && (
                      <Typography level="h3">
                        $
                        {(callType.total * Number(data.expectResponse)).toFixed(
                          2
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {!subscription && (
                <Box mt={4}>
                  <Select
                    color={error ? "danger" : "neutral"}
                    size="lg"
                    slotProps={{
                      button: {
                        style: { justifyContent: "end", fontSize: 16 },
                      },
                    }}
                    startDecorator={
                      <Typography level="title-md">Payment</Typography>
                    }
                    value={card}
                    onChange={(e, newValue) => {
                      setCard(newValue);
                      setError(false);
                    }}
                  >
                    {cards.map((c) => (
                      <Option
                        value={c}
                        onClick={() => {
                          if (!c.id) {
                            addCreaditCard();
                          }
                        }}
                      >
                        {!c.id
                          ? "Add a Card"
                          : `${c.brand?.toUpperCase()}****${c.last4}`}
                      </Option>
                    ))}
                  </Select>
                </Box>
              )}
              
              <Stack mt={6} direction={"row"} gap={2} justifyContent="end">
                <PlainButton variant="plain" onClick={() => navigate(-1)}>
                  Back
                </PlainButton>
                <SolidButton variant="solid" onClick={handleCreateSurvey}>
                  Send
                </SolidButton>
              </Stack>
            </Box>
          )}
        </Stack>
        
      )}
      {open && callType && (
        <FeesDialog
          open={open.fees}
          onClose={() => setOpen({ ...open, fees: false })}
          transactionFee={callType?.transactionFee}
          serviceFee={callType?.serviceFee}
          responses={Number(data.expectResponse)}
        />
      )}
      {open.custom && (
        <CustomReward
          leastReward={rewards[0]}
          open={open.custom}
          onClose={(newReward: number | null) => {
            setOpen({ ...open, custom: false });
            if (!newReward) return;
            if (rewards.includes(newReward)) {
              setData({ ...data, reward: newReward });
              return;
            }
            setCustomReward(newReward);
            setData({ ...data, reward: newReward });
          }}
        />
      )}
      {open.confirm && (
        <InfoDialog
          open={open.confirm}
          title="Confirmation"
          subtitle={
            <Stack direction={"column"} spacing={3}>
              <Typography level="body-lg">
                The survey has been sent to all community members that match
                your criteria.
              </Typography>
              <Typography level="body-lg">
                You can make changes and view results by visiting the{" "}
                <Typography level="title-lg">“Invitations”</Typography> tab.
              </Typography>
            </Stack>
          }
          onClose={() => {
            setOpen({ ...open, confirm: false });
            navigate(-2);
          }}
        />
      )}
    </Box>
  );
}

export default SurveyPayment;

interface CustomProps {
  leastReward: number;
  open: boolean;
  onClose: (value: number | null) => void;
}
const CustomReward = ({ open, onClose, leastReward }: CustomProps) => {
  const [custom, setCustom] = useState("");
  const { onShowMessage } = useAuth();

  return (
    <Modal open={open} onClose={() => onClose(null)}>
      <ModalDialog aria-labelledby="layout-modal-title" size="lg">
        <ModalClose />
        <Typography id="layout-modal-title" level="h2" sx={{ fontSize: 24 }}>
          Enter reward amount
        </Typography>
        <Box>
          <Input
            autoFocus
            size="lg"
            type={"number"}
            startDecorator={<Typography>$</Typography>}
            value={custom}
            onChange={(e) => {
              const { value } = e.target;
              setCustom(value);
            }}
          />
        </Box>

        <SolidButton
          variant="solid"
          sx={{ bgcolor: "black", mt: 4 }}
          onClick={() => {
            if (parseInt(custom ?? 0) < leastReward) {
              onShowMessage(
                `Please enter a minimum of $${leastReward}.`,
                "error"
              );

              return;
            }
            onClose(parseInt(custom ?? 0));
          }}
        >
          Done
        </SolidButton>
      </ModalDialog>
    </Modal>
  );
};
interface FeesProps {
  open: boolean;
  onClose: () => void;
  transactionFee: number;
  serviceFee: number;
  responses: number;
}
const FeesDialog = ({
  open,
  onClose,
  transactionFee,
  serviceFee,
  responses,
}: FeesProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog aria-labelledby="layout-modal-title" size="lg">
        <ModalClose />
        <Typography id="layout-modal-title" level="h2" sx={{ fontSize: 24 }}>
          Fees information
        </Typography>
        <Box>
          <Typography level="title-lg">
            Transaction Fee: ${(transactionFee * responses).toFixed(2)}
          </Typography>
          <Typography level="body-lg">
            These are fees that are charged to process each transaction.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography level="title-lg">
            Service Fee: ${(serviceFee * responses).toFixed(2)}
          </Typography>
          <Typography level="body-lg">
            This fee helps us operate & improve Lightster.
          </Typography>
        </Box>
      
      </ModalDialog>
    </Modal>
  );
};
