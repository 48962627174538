import { InfoOutlined, KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import SelectCommunitiesDialog from "../../../components/dialog/schedules/SelectCommunitiesDialog";
import { StorageType } from "../../../constants/enum";
import { CreateUnmoderatedParams } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props { }

function CreateUnmoderated(props: Props) {
  const { } = props;
  const state = useLocation().state;
  const { showMessage, sessionService } = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    communiies: false,
    users: false,
    timeSlot: false,
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<CreateUnmoderatedParams>({
    name: "",
    communityId: [],
    topic: "",
    expectedLightster: "",
    pastUsers: null,
    steps: [],
  });

  const [errors, setErrors] = useState({
    name: false,
    communityId: false,
    topic: false,
    expectedLightster: false,
    expectedLightsterSchedule: false,
    pastUsers: false,
  });

  const handleSetErrors = (field: string, value: boolean) => {
    setErrors({ ...errors, [field]: value });
  };
  const checkEmptyField = () => {
    let contained = false;
    if (
      data.expectedLightster &&
      typeof data.expectedLightster == "string" &&
      parseInt(data.expectedLightster) <= 0
    ) {
      contained = true;
      errors.expectedLightster = true;
    }
    if (
      data.expectedLightster &&
      typeof data.expectedLightster == "string" &&
      data.scheduled && 
      parseInt(data.expectedLightster) <= data.scheduled 
    ) {
      contained = true;
      errors.expectedLightster = true;
      errors.expectedLightsterSchedule = true;
    }
    if (!data.expectedLightster) {
      contained = true;
      errors.expectedLightster = true;
    }


    if (data.communityId?.length === 0) {
      contained = true;
      errors.communityId = true;
    }
    if (data.pastUsers == null) {
      contained = true;
      errors.pastUsers = true;
    }
    if (data.name == null) {
      contained = true;
      errors.name = true;
    }

    if (!data.topic) {
      contained = true;
      errors.topic = true;
    }

    setErrors(errors);
    return contained;
  };
  const handleOnClickNext = () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));
    navigate(pagesName.creator.unmoderatedSteps, {
      state: { booking: data },
    });
  };
  const handleOnClickUpdate = async () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    setLoading(true);
    localStorage.setItem(StorageType.unmoderated, JSON.stringify(data));

    const res = await sessionService.updateUnmoderated(data);

    if (res) {
      setTimeout(() => {
        setLoading(false);
        showMessage(
          "Invitation successfully updated, these changes will not affect users who have already confirmed.",
          "success"
        );
        localStorage.removeItem(StorageType.unmoderated);
        navigate(-1);
      }, 5000);
    } else {
      setLoading(false);
    }


  };

  useEffect(() => {
    if (state?.communityId) {
      setData({ ...data, communityId: [state?.communityId] });
    }
    const schedule = localStorage.getItem(StorageType.unmoderated);
    if (schedule) {
      setData(JSON.parse(schedule));
    }
  }, []);

  return (
    <Box className="home" sx={{ height: "100%" }} overflow="auto">
      {loading ? (
        <Box
          className="box-column"
          sx={{
            //minHeight: 300,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="md" />
        </Box>
      ) : (
        <Stack direction={"column"} spacing={3}>
          <Box>
            <Typography level="h2" fontSize={28}>
            Start a test
            </Typography>
            <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
            Run an unmoderated test with users in this community. Unmoderated tests are recorded and sent to you after each test is completed.
            </Typography>
          </Box>
          <Box overflow={"auto"} maxHeight={"53vh"} sx={{ "&::-webkit-scrollbar": {display: "none"}}}>
          <Box>
            <Typography level="title-lg" sx={{ color: "black", mb: 2 }}>
            Test Details
            </Typography>
            <Stack direction={"column"} spacing={1}>
            <Input
            color={errors.name ? "danger" : "neutral"}
            size="lg"
            placeholder="Enter"
            startDecorator={
              <Typography level="title-sm">Test name</Typography>
            }
            sx={{
              ".MuiInput-startDecorator": {
                maxWidth: "60%",
              },
            }}
            slotProps={{
              input: {
                style: {
                  textAlign: "end",
                  fontSize: 16,
                },
              },
            }}
            error={errors.name}
            value={data.name}
            onChange={(e) => {
              const { value } = e.target;
              if (value && errors.name) {
                setErrors({ ...errors, name: false });
              }
              setData({ ...data, name: value });
            }}
          />
              <Input
                size="lg"
                type={"number"}
                placeholder="Enter"
                startDecorator={
                  <Typography level="title-sm">
                    Number of users to test
                  </Typography>
                }
                sx={{
                  ".MuiSelect-startDecorator": {
                    maxWidth: "60%",
                    padding: "8px 8px",
                  },
                }}
                slotProps={{
                  input: {
                    style: {
                      textAlign: "end",
                      fontSize: 16,
                      borderRadius: 8,
                    },
                  },
                }}
                value={data?.expectedLightster}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value && errors.expectedLightster) {
                    handleSetErrors("expectedLightster", false);
                    handleSetErrors("expectedLightsterSchedule", false);
                  }
                  const re = /^[0-9\b]+$/;
                  if (value === "" || re.test(value)) {
                    setData({
                      ...data,
                      expectedLightster: value,
                    });
                  }
                }}
                error={errors.expectedLightster}
              />
              <Select
                size="lg"
                color={errors.communityId ? "danger" : "neutral"}
                startDecorator={
                  <Typography level="title-sm">
                    Which communities do you want to select users from?
                  </Typography>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                slotProps={{
                  button: {
                    style: { justifyContent: "end", fontSize: 16},
                    onClick: () => setOpen({ ...open, communiies: true }),
                  },
                }}
                placeholder="Select"
                listboxOpen={false}
                value={data.communityId?.length}
                defaultValue={data.communityId?.length}
              >
                <Option value={data.communityId?.length}>
                  {data.communityId?.length}
                </Option>
              </Select>
              <Select
                size="lg"
                color={errors.pastUsers ? "danger" : "neutral"}
                startDecorator={
                  <Typography level="title-sm">
                    Do you want to include users that you’ve connected with in the
                    past?
                  </Typography>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                sx={{
                  ".MuiSelect-startDecorator": {
                    maxWidth: "70%",
                    borderRadius: 8,
                  },
                }}
                slotProps={{
                  button: {
                  
                    style: { justifyContent: "end", fontSize: 16 },
                  },
                }}
                placeholder="Select"
                value={data.pastUsers}
                onChange={(e, newValue) => {
                  if (newValue && errors.pastUsers) {
                    setErrors({ ...errors, pastUsers: false });
                  }
                  setData({ ...data, pastUsers: newValue });
                }}
              >
                <Option sx={{ fontSize: 14 }} value={true}>
                  Yes
                </Option>
                <Option sx={{ fontSize: 14 }} value={false}>
                  No
                </Option>
              </Select>
            </Stack>
          </Box>
          <Box>
            <Typography level="title-lg" sx={{ color: "black", mt:2 }}>
            What should the user know about the test?
            </Typography>
            <Typography level="body-sm" sx={{ color: "black", mb: 1 }}>
            Please let the user know a bit about what to expect in the test.
            </Typography>
            <Textarea
              size="lg"
              minRows={4}
              error={errors.topic}
              value={data.topic}
              onChange={(e) => {
                const { value } = e.target;
                if (value && errors.topic) {
                  setErrors({ ...errors, topic: false });
                }
                setData({ ...data, topic: value });
              }}
              sx={{borderRadius: 8}}
            />
          </Box>
          </Box>
          <Box
            className="box-row"
            sx={{ justifyContent: "end", gap: 2, mt: "48px !important" }}
          >
            <PlainButton
              variant="plain"
              onClick={() => {
                localStorage.removeItem(StorageType.unmoderated);
                navigate(-1);
              }}
            >
              Cancel
            </PlainButton>
            <SolidButton variant="solid" onClick={handleOnClickNext}>Next</SolidButton>
          </Box>
        </Stack>)}
      {open.communiies && (
        <SelectCommunitiesDialog
          open={open.communiies}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.communityId) {
              setErrors({ ...errors, communityId: false });
            }
            setData({ ...data, communityId: value });
            setOpen({ ...open, communiies: false });
          }}
          defaultData={data?.communityId ?? []}
        />
      )}
    </Box>
  );
}

export default CreateUnmoderated;
