import { Box, Typography, styled } from "@mui/joy";
import { SessionResult } from "../../constants/interfaces";
import { PlainButton } from "../button/CustomButton";
import moment from "moment";
import { CustomButtton } from "../../custom-styles";

interface Props {
  session: SessionResult;
  refresh?: Function;
}

function CreatorSessionItemList({ session, refresh }: Props) {

  const handleClick = () => {
    window.open(session.url, '_blank');
  };
  const handleClickTranscription = () => {
    window.open(session.urlTranscription, '_blank');
  };

  const StyledTable = styled('table')({
    width: '95%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
  });

  const StyledTd = styled('td')({
    padding: '8px',
    textAlign: 'left',
    wordWrap: 'break-word',
  });

  const lightsterNameColumnStyle = { width: '30%' };
  const completedDateColumnStyle = { width: '20%' };
  const durationColumnStyle = { width: '10%' };
  const buttonColumnStyle = { width: '10%' };

  return (
    <Box className="unmoderated-box-shadow">
      <StyledTable>
        <tbody>
          <tr>
            <StyledTd style={lightsterNameColumnStyle}>
              <Typography level="title-md">
                {session.lightsterName || "No Lightster Name"}
              </Typography>
            </StyledTd>
            <StyledTd style={completedDateColumnStyle}>
              <Typography level="body-md">
                {session.completedDate
                  ? moment(session.completedDate).format("MMM DD, YYYY hh:mm A")
                  : "No Completion Date"}
              </Typography>
            </StyledTd>
            <StyledTd style={durationColumnStyle}>
              <CustomButtton onClick={handleClick}>
                Recording
              </CustomButtton>
            </StyledTd>
            <StyledTd style={buttonColumnStyle}>
              <CustomButtton onClick={handleClickTranscription}>
                Transcript
              </CustomButtton>
            </StyledTd>
          </tr>
        </tbody>
      </StyledTable>
    </Box>
  );
}

export default CreatorSessionItemList;