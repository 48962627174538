import { Close, EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import {
    Autocomplete,
    Chip,
    FormControl,
    FormLabel,
    Stack,
    Typography
} from "@mui/joy";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import { ProfileParams } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props {}
const keyRequired = ["interests"];
function CreatorProductKeywords(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const [profile, setProfile] = useState<Array<ProfileParams>>([]);
  const { profileService, handleToggle } = useApi();
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCompanyProfile();
  }, []);
  const getCompanyProfile = async () => {
    const res = await profileService.getCompany();
    if (!res) return;
    const list: Array<ProfileParams> = [];
    res.map((p: any) => {
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: [],
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile(list);
  };

  const handleOnChange = (field: string, value: any, type: string) => {
    const updatedObject = profile.map((p: ProfileParams) => {
      if (p.key === field) {
        let newAnsers: string[] = [];
        if (type === "delete") {
          newAnsers = p.answers.filter((f) => f != value);
        }
        if (type === "add") {
          newAnsers = p.answers;
          newAnsers.push(value);
        }
        const data: ProfileParams = {
          profileId: p.profileId,
          key: p.key,
          answers: newAnsers,
          keywordList: p.keywordList,
        };
        return data;
      } else {
        return p;
      }
    });

    setProfile(updatedObject);
  };

  const getKeywordList = (key: string) => {
    const data = profile.find((f: ProfileParams) => f.key === key);
    return data?.keywordList;
  };

  const getProfileAnswers = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data?.answers ?? [];
  };

  const getCreatorParams = () => {
    let profiles: Array<any> = [];

    (profile as Array<ProfileParams>).map((pro) => {
      if (pro.answers.length > 0) {
        profiles.push({
          profileId: pro.profileId,
          answers: pro.answers,
        });
      }
    });

    if (profiles.length === 0) {
      return null;
    } else {
      return { profiles: profiles };
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    handleToggle(true);
    const data = getCreatorParams();
    if (data) {
      const res = await profileService.updateCreatotProfile(data);
      if (!res) return;
    }
    handleToggle(false);
    navigate(pagesName.creator.planSuccess);
  };

  return (
    <BackgroundRegister>
      <form noValidate onSubmit={handleSubmit}>
        <Stack direction={"column"} mt={"10vh"}>
          <BorderLinearProgress determinate value={80} sx={{ mb: 11 }} />
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                Product keywords
              </Typography>
              <Typography level="title-md" mt={1}>
                Adding product keywords help us match you with users who are
                interested in the relevant topics.
              </Typography>
            </Box>
            <Box>
              <FormControl>
                <FormLabel>Keywords</FormLabel>
                <Autocomplete
                  multiple
                  onChange={(_, value: string[]) => {
                    if (value) {
                      handleOnChange(
                        "interests",
                        value[value.length - 1],
                        "add"
                      );
                    }
                  }}
                  options={getKeywordList("interests") ?? []}
                  autoFocus
                  renderTags={(tags, getTagProps) =>
                    tags.map((item, index) => (
                      <Chip
                        variant="solid"
                        color="primary"
                        endDecorator={<Close fontSize="small" />}
                        {...getTagProps({ index })}
                      >
                        {item}
                      </Chip>
                    ))
                  }
                />
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={6}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ minWidth: 128 }}
              onClick={handleSubmit}
              type="submit"
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </form>
    </BackgroundRegister>
  );
}

export default CreatorProductKeywords;
