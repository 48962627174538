import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Input,
    Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { EyeOffOutline, EyeOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";

import BackgroundRegister from "../../components/BackgroundRegister";
import {
    OutlinedButton,
    SolidButton,
} from "../../components/button/CustomButton";
import { useAuth } from "../../providers/AuthProvider";

export const ResetPassword = () => {
  const navigate = useNavigate();
  let params = new URLSearchParams(document.location.search);
  let ref = params.get("ref");
  const { api, onShowMessage } = useAuth();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ref) {
      setStep(2);
    }
  }, []);

  const sentEmail = async (email: string) => {
    if (!email) {
      return;
    }
    setLoading(true);
    const res = await api.resetPassword({ email: email });
    setLoading(false);
    if (!res) {
      return;
    }
    setStep(1);
  };

  const handleResetPassword = async (newPass: string, confirmPass: string) => {
    if (newPass != confirmPass) {
      return onShowMessage("Password is incorrect!", "error");
    }
    setLoading(true);
    const res = await api.verifyPassword({
      code: ref,
      newpassword: confirmPass,
    });
    setLoading(false);
    if (!res) {
      return;
    }
    setStep(3);
  };
  if (loading)
    return (
      <Box
        display={"flex"}
        height={"100vh"}
        width={"100vw"}
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <BackgroundRegister>
      <div style={{ marginTop: "10vh" }}>
        {step === 0 && (
          <EnterEmail
            onCancel={() => navigate(-1)}
            onSubmit={(email) => sentEmail(email)}
          />
        )}
        {step === 1 && <EmailSent onClickDone={() => navigate(-1)} />}
        {step === 2 && (
          <ResetPasswordStep
            onCancel={() => navigate(-1)}
            onSubmit={(newPass, confirmPass) =>
              handleResetPassword(newPass, confirmPass)
            }
          />
        )}
        {step === 3 && (
          <ResetPasswordSuccess
            onClickDone={() => navigate("/", { replace: true })}
          />
        )}
      </div>
    </BackgroundRegister>
  );
};

interface EnterEmailProps {
  onCancel: () => void;
  onSubmit: (email: string) => void;
}
const EnterEmail = ({ onCancel, onSubmit }: EnterEmailProps) => {
  const [email, setEmail] = useState("");
  return (
    <>
      <Box mb={1}>
        <Typography level="h2">Password reset</Typography>
      </Box>

      <Typography
        level="body-lg"
        textAlign={"center"}
        fontSize={18}
        fontWeight={400}
      >
        To reset your password, please enter your e-mail below.
      </Typography>

      <Box mt={6} maxWidth={500}>
        <Grid container>
          <Grid xs={12}>
            <Input
              name="email"
              type={"email"}
              size="lg"
              placeholder="Enter your e-mail address"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid xs={12}>
            <Box
              pt={6}
              width={"100%"}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <OutlinedButton
                variant="outlined"
                color="primary"
                sx={{
                  width: 120,
                  textTransform: "capitalize",
                }}
                onClick={onCancel}
              >
                Cancel
              </OutlinedButton>
              <SolidButton
                variant="solid"
                color="primary"
                sx={{
                  width: 120,
                  textTransform: "capitalize",
                  fontWeight: 700,
                }}
                onClick={() => onSubmit(email)}
              >
                Submit
              </SolidButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

interface EmailSentProps {
  onClickDone: () => void;
}
const EmailSent = ({ onClickDone }: EmailSentProps) => {
  return (
    <div>
      <Box mb={1}>
        <Typography level="h2" textAlign={"center"}>
          Reset e-mail sent!
        </Typography>
      </Box>

      <Typography level="title-lg" textAlign={"center"}>
        Instructions on how to reset your password have been sent to the e-mail
        you provided.
      </Typography>
      <Box mt={6} display="flex" flexDirection="row" justifyContent="center">
        <Button
          variant="solid"
          color="primary"
          sx={{
            width: 120,
            textTransform: "capitalize",
          }}
          onClick={onClickDone}
        >
          Done
        </Button>
      </Box>
    </div>
  );
};

interface ResetPasswordStepProps {
  onCancel: () => void;
  onSubmit: (newPass: string, confirmPass: string) => void;
}
const ResetPasswordStep = ({ onCancel, onSubmit }: ResetPasswordStepProps) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState({ nPass: false, cPass: false });

  return (
    <>
      {" "}
      <Box mb={1}>
        <Typography
          level="h2"
          textAlign={"center"}
          fontSize={28}
          fontWeight={700}
        >
          Password reset
        </Typography>
      </Box>
      <Typography
        level="title-lg"
        textAlign={"center"}
        fontSize={18}
        fontWeight={400}
      >
        Please enter your new password below.
      </Typography>
      <Box mt={6} maxWidth={400}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Input
              size="lg"
              type={open.nPass ? "text" : "password"}
              placeholder="New password"
              fullWidth
              endDecorator={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setOpen({ ...open, nPass: !open.nPass })}
                >
                  {!open.nPass ? (
                    <EyeOffOutline width="16px" height="16px" />
                  ) : (
                    <EyeOutline width="16px" height="16px" />
                  )}
                </IconButton>
              }
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>
          <Grid xs={12}>
            <Input
              size="lg"
              name="confirmpassword"
              type={open.cPass ? "text" : "password"}
              placeholder="Confirm new password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              endDecorator={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setOpen({ ...open, cPass: !open.cPass })}
                >
                  {!open.cPass ? (
                    <EyeOffOutline width="16px" height="16px" />
                  ) : (
                    <EyeOutline width="16px" height="16px" />
                  )}
                </IconButton>
              }
            />
          </Grid>
          <Grid xs={12}>
            <Box
              pt={6}
              width={"100%"}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                color="neutral"
                sx={{
                  width: 120,
                  textTransform: "capitalize",
                }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                color="primary"
                sx={{
                  width: 120,
                  textTransform: "capitalize",
                  fontWeight: 700,
                }}
                onClick={() => onSubmit(newPassword, confirmPassword)}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
interface ResetPasswordSuccessProp {
  onClickDone: () => void;
}
const ResetPasswordSuccess = ({ onClickDone }: ResetPasswordSuccessProp) => {
  return (
    <div>
      <Box mb={1}>
        <Typography level="h2" textAlign={"center"}>
          Success!
        </Typography>
      </Box>

      <Typography level="title-lg" textAlign={"center"}>
        Your password has been changed and you can now login with your new
        password.
      </Typography>
      <Box mt={6} display="flex" flexDirection="row" justifyContent="center">
        <Button
          variant="solid"
          color="primary"
          sx={{
            width: 120,
            textTransform: "capitalize",
          }}
          onClick={onClickDone}
        >
          Done
        </Button>
      </Box>
    </div>
  );
};
