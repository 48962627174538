import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { InputType, StorageType } from "../../../constants/enum";
import { useApi } from "../../../providers/ApiProvider";
import { PlainButton } from "../../button/CustomButton";
import { QuestionUnmoderated } from "../../../pages/creator/unmoderated/CreateSteps";
import { CustomButtton } from "../../../custom-styles";


interface Props {
  open: boolean;
  onClose: (value: QuestionUnmoderated | null | string) => void;
  defaultData: QuestionUnmoderated | null;
  showDetete?: boolean;
}

function UnmoderatedQuestionDialog(props: Props) {
  const { open, onClose, defaultData, showDetete } = props;
  const [selected, setSelected] = useState<QuestionUnmoderated>();

  useEffect(() => {
    if (defaultData) setSelected(defaultData);
  }, []);

  return (
    <Modal open={open}>
      <ModalDialog
        aria-labelledby="layout-modal-title "
        aria-describedby="layout-modal-description "
        size="lg"
        sx={{ 
          width: {
            xs: '480px',
            sm: '480px',
            md: '480px',
            lg: '640px',
            xl: '640px'
          },  
          overflow: "auto" }}
      >
        <Typography id="layout-modal-title" level="h2" sx={{ fontSize: 24, mt: 0 }}>
          Add a Question
        </Typography>

        {selected && (
          <QuestionSelected
            selected={selected}
            onDelete={(value) => {
              onClose("deleted");
            }}
            onSave={(value) => {
              onClose(value);
            }}
            onCancel={() => {
              onClose(null);
            }}
            showDetete={!!selected.title || showDetete === true}
          />
        )}
      </ModalDialog>
    </Modal>
  );
}

interface QuestionSelectedProps {
  selected: QuestionUnmoderated;
  onCancel: () => void;
  onSave: (value: QuestionUnmoderated) => void;
  onDelete: (value: QuestionUnmoderated) => void;
  showDetete?: boolean;
}
const QuestionSelected = ({
  selected,
  onCancel,
  onSave,
  onDelete,
  showDetete,
}: QuestionSelectedProps) => {
  const { showMessage } = useApi();
  const [question, setQuestion] = useState<QuestionUnmoderated | null>(null);

  const handleChangeOption = (index: number, value: string) => {
    if (!question) return;
    question.keywordList[index] = value;
    setQuestion({ ...question, keywordList: question.keywordList });
  };

  const handleOnSave = () => {
    if (!question) return;
    if (question.title.trim().length === 0) {
      showMessage("Please enter a question.", "warning");
      return;
    }
    question.keywordList = question.keywordList.filter(
      (ele) => ele.trim().length > 0
    );
    if (
      question?.inputType === InputType.SELECTLIST &&
      question.keywordList.length === 0
    ) {
      showMessage("Please enter at least one option.", "warning");
      return;
    }

    onSave(question);
    sessionStorage.removeItem(StorageType.previousQuestionSurvey);
  };

  const getDescription = (inputType: string) => {
    if (inputType === "ratingScale") {
      return "The user will be asked to select one value on the scale of 0 to 5.";
    }

    if (inputType === "verbalResponse") {
      return "The user will be asked to respond to the question verbally.";
    }

    return "The user will be asked to type in a response to this question.";
  };
  useEffect(() => {
    setQuestion(selected);
    if (selected.title) {
      sessionStorage.setItem(
        StorageType.previousQuestionSurvey,
        JSON.stringify(selected)
      );
    }
  }, [selected]);
  return (
    <Box maxHeight="70vh" >

      <Stack direction={"column"} spacing={1.5} mt={1} >
        <Textarea
          autoFocus
          value={question?.title}
          onChange={(e) => {
            if (!question) return;
            setQuestion({ ...question, title: e.target.value });
          }}
          minRows={4}
        />

        <Select
          color="primary"
          variant="plain"
          sx={{ width: 216 }}
          indicator={<KeyboardArrowDownRounded fontSize="small" />}
          value={question?.inputType}
          onChange={(e, newValue) => {
            if (!newValue || !question) return;
            if (newValue === InputType.TEXTFIELD) {
              setQuestion({
                ...question,
                inputType: newValue,
                keywordList: [],
              });
            } else {
              setQuestion({ ...question, inputType: newValue });
            }
          }}
        >
          <Option key={"verbalResponse"} value={"verbalResponse"}>
            Verbal Response
          </Option>
          <Option key={"ratingScale"} value={"ratingScale"}>
            Rating Scale (0 to 5)
          </Option>
          <Option key={InputType.SELECTLIST} value={InputType.SELECTLIST}>
            Multiple Choice
          </Option>
          <Option key={InputType.TEXTFIELD} value={InputType.TEXTFIELD}>
            Text Response
          </Option>
        </Select>
        <Box overflow={"auto"} maxHeight={"37vh"} sx={{ "&::-webkit-scrollbar": { display: "inline" } }}>
          {question && (
            <Stack direction={"column"} spacing={1}>
              {question.inputType !== InputType.SELECTLIST ? (
                <Typography level="body-sm">
                  {getDescription(question.inputType)}
                </Typography>
              ) : (
                Array.from({ length: 5 }, (_, index) => (
                  <FormControl>
                    <FormLabel>Option {index + 1}</FormLabel>
                    <Input
                      // sx={{ borderRadius: 8, padding: "8px 16px" }}
                      value={question?.keywordList[index]}
                      onChange={(e) => handleChangeOption(index, e.target.value)}
                      // size="md"
                    />
                  </FormControl>

                ))
              )}

            </Stack>
          )}
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={!showDetete ? "end" : "space-between"}
        mt={4}
      >
        {showDetete && (
          <Box>
            <CustomButtton
              onClick={() => onDelete(selected)}
            >
              Delete Question
            </CustomButtton>
          </Box>
        )}
        <Stack direction={"row"} gap={2}>
          <PlainButton variant="plain" onClick={onCancel}>
            Cancel
          </PlainButton>
          <Button variant="solid" onClick={handleOnSave}>
            Save
          </Button>
        </Stack>
      </Stack>

    </Box>
  );
};

export default UnmoderatedQuestionDialog;
