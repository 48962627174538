import {
    EastSharp,
    KeyboardReturnRounded,
    LinkedIn,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";

interface Props {}

function LightsterConnections(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  return (
    <BackgroundRegister>
      <Stack direction={"column"} mt={"10vh"}>
        <BorderLinearProgress determinate value={100} sx={{ mb: 11 }} />
        <Stack direction={"column"} spacing={3}>
          <Box>
            <Typography level="h2" fontSize={28}>
              Connect your profiles
            </Typography>
            <Typography level="title-md">
              Connecting your profiles helps Creators learn more about you and
              also ranks your profile more highly than others.
            </Typography>
          </Box>
          <Box
            width={260}
            display="flex"
            justifyContent="center"
            sx={{
              p: 2,
              borderRadius: "10px",
              backgroundColor: "#2867B2",
            }}
          >
            <Stack direction={"row"} spacing={1} alignItems="center">
              <LinkedIn sx={{ color: "white", fontSize: 24 }} />
              <Typography
                fontSize={20}
                fontWeight={700}
                sx={{ color: "white" }}
              >
                Connect with Linkedin
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          mt={6}
          mb={6}
        >
          <PlainButton
            variant="plain"
            endDecorator={<KeyboardReturnRounded fontSize="small" />}
            onClick={() => navigate(-1)}
          >
            Back
          </PlainButton>
          <SolidButton
            variant="solid"
            endDecorator={<EastSharp />}
            sx={{ minWidth: 128 }}
            onClick={() => navigate(pagesName.lightster.success)}
          >
            Next
          </SolidButton>
        </Stack>
      </Stack>
    </BackgroundRegister>
  );
}

export default LightsterConnections;
