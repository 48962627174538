import { Box, Grid } from "@mui/joy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RoleType, StorageType } from "../../constants/enum";
import { Chat } from "../../constants/interfaces";
import CreatorMessages from "../../pages/creator/message/CreatorMessages";
import LightsterMessages from "../../pages/lightster/message/LightsterMessage";
import { useAuth } from "../../providers/AuthProvider";
import ChatRoom from "../ChatRoom";
import MenuListItem from "./MenuListItem";
import { Hidden } from "@mui/material";


interface BaseMenuProps {
  children: JSX.Element;
}

function BaseMenu({ children }: BaseMenuProps) {
  const { role } = useAuth();
  const { pathname } = useLocation();
  const [chatActive, setChatActive] = useState<Chat | null>(null);

  const handleChatActive = (value: Chat | null) => {
    localStorage.setItem(StorageType.homeChatActive, JSON.stringify(value));
    setChatActive(value);
  };

  useEffect(() => {
    const getLocal = localStorage.getItem(StorageType.homeChatActive);
    const split = pathname.split("/");
    if (getLocal && split.includes("home")) {
      setChatActive(JSON.parse(getLocal));
    } else {
      setChatActive(null);
    }
  }, [pathname]);
  return (
    
    <div className="bg">
      <Box
        className="max-width"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        
        <Grid container gap={3}>
        <Hidden lgDown>
          <Grid width={260}
          sx={{
            width:{
              
            }
          }}>
            <MenuListItem onChangeMenu={() => setChatActive(null)} />
          </Grid>
          </Hidden>
          <Grid xs>
          
            <Box
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              {children}

              {chatActive && (
                
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  
                  <ChatRoom
                    chatVideoCall={false}
                    userProfile={chatActive.user}
                    receiverId={chatActive.user.id}
                    onClose={() => {
                      localStorage.removeItem(StorageType.homeChatActive);
                      setChatActive(null);
                    }}
                  />
                </Box>
              
              )}
               
            </Box>
            {/* <Box height={"5vh"}>
              <Footer />
            </Box> */}
          </Grid>
          <Hidden mdDown>
          <Grid width={300}>
            {role === RoleType.lightster ? (
              <LightsterMessages onClick={(user) => handleChatActive(user)} />
            ) : (
              <CreatorMessages onClick={(user) => handleChatActive(user)} />
            )}
          </Grid>
          </Hidden>
        </Grid>
        
      </Box>

      </div>
  );
}

export default BaseMenu;
