import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";

interface Props {
  open: boolean;
  onClose: () => void;
}

function DeclinedDialog(props: Props) {
  const { open, onClose } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        size="lg"
      >
        <ModalClose onClick={onClose} />
        <Typography id="layout-modal-title" level="h3" fontSize={24}>
          Declined
        </Typography>
        <Typography
          id="layout-modal-description"
          sx={{ color: "black", fontSize: 18 }}
        >
          You have successfully declined the invitation.
        </Typography>
      </ModalDialog>
    </Modal>
  );
}

export default DeclinedDialog;
