import { EastRounded, KeyboardReturnRounded } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/joy";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";

import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
interface Props {}

function CreatorChoosePlan(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const [plan, setPlan] = useState(0);
  const mapPlan = (value: number) => {
    const map = new Map<number, string>();
    map.set(0, "standard");
    map.set(1, "basic");
    map.set(2, "free");

    return map.get(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = mapPlan(plan);
    if (data === "free") {
      return navigate(pagesName.creator.planSuccess, { state: data });
    }

    return navigate(pagesName.creator.planDetail, { state: data });
  };
  return (
    <BackgroundRegister>
      <form noValidate onSubmit={handleSubmit}>
        <Stack direction={"column"}>
          <Box height={180}>
            <BorderLinearProgress determinate value={100} sx={{ mt: 10 }} />
          </Box>

          <Stack direction={"column"} spacing={4}>
            <Box>
              <Typography>Choose your plan</Typography>
              <Typography>
                Our plans are designed to fit how you build products, and how
                you want to connect with users.
              </Typography>
            </Box>
            <Box
              component={"button"}
              autoFocus
              sx={{
                backgroundColor: "transparent",
                textAlign: "left",
              }}
              className={plan === 0 ? "plan-active" : "plan-inactive"}
              onClick={() => setPlan(0)}
              onFocus={() => setPlan(0)}
            >
              <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography>Co-create</Typography>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"end"}
                  alignItems={"end"}
                >
                  <Typography>$200/month</Typography>
                  <Box className="plan-trial">
                    <Typography
                      sx={{ color: "white", fontWeight: 500, fontSize: 9 }}
                    >
                      90 FREE MINUTES
                    </Typography>
                  </Box>
                </Box>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography>
                  You want regular input and the best value.
                </Typography>
                <Box pl={2}>
                  <Typography display={"list-item"}>
                    5 live sessions included, $1.25 per minute after
                  </Typography>
                  <Typography display={"list-item"}>
                    Unlimited chat conversations
                  </Typography>
                  <Typography display={"list-item"}>
                    Offer a product deal to users as a reward
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box
              component={"button"}
              sx={{
                backgroundColor: "transparent",
                textAlign: "left",
              }}
              className={plan === 1 ? "plan-active" : "plan-inactive"}
              onClick={() => setPlan(1)}
              onFocus={() => setPlan(1)}
            >
              <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography>Flex</Typography>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"end"}
                  alignItems={"end"}
                >
                  <Typography>$50/month</Typography>
                  <Box className="plan-trial">
                    <Typography
                      level="body-sm"
                      sx={{ color: "white", fontWeight: 500, fontSize: 9 }}
                    >
                      7-DAY FREE TRIAL
                    </Typography>
                  </Box>
                </Box>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography>
                  Your needs vary and want maximum flexibility.
                </Typography>
                <Box pl={2}>
                  <Typography display={"list-item"}>
                    $45 per live session (includes user rewards), $1.50 per
                    minute after
                  </Typography>
                  <Typography display={"list-item"}>
                    10 chat conversations monthly
                  </Typography>
                  <Typography display={"list-item"}>
                    Offer a product deal to users as a reward
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box
              component={"button"}
              sx={{
                backgroundColor: "transparent",
                textAlign: "left",
              }}
              className={plan === 2 ? "plan-active" : "plan-inactive"}
              onClick={() => setPlan(2)}
              onFocus={() => setPlan(2)}
            >
              <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography>Starter</Typography>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography>
                  Good for teams that get occasional feedback.
                </Typography>
                <Box pl={2}>
                  <Typography display={"list-item"}>
                    $60 per live session, $2.00 per minute after
                  </Typography>
                  <Typography display={"list-item"}>
                    3 chat conversations monthly
                  </Typography>
                  <Typography display={"list-item"}>
                    Build unlimited communities
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              mt={10}
              mb={10}
            >
              <PlainButton
                variant="plain"
                endDecorator={<KeyboardReturnRounded fontSize="small" />}
                onClick={() => navigate(-1)}
              >
                Back
              </PlainButton>
              <SolidButton
                variant="solid"
                endDecorator={<EastRounded fontSize="small" />}
                sx={{ minWidth: 128 }}
                onClick={handleSubmit}
                type="submit"
              >
                Next
              </SolidButton>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </BackgroundRegister>
  );
}

export default CreatorChoosePlan;
