import { Box, Stack, Typography, styled } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { SolidButton } from "../../../components/button/CustomButton";
import { useApi } from "../../../providers/ApiProvider";
import { primaryPurpleHover } from "../../../joy-styles";
import { useAuth } from "../../../providers/AuthProvider";
import { StorageType } from "../../../constants/enum";
import LightsterAnswerQuestionDialog from "../../../components/dialog/question/LightsterAnswerQuestionDialog";

import CustomDialog from "../../../components/dialog/CustomDialog";
import { useNotification } from "../../../providers/NotificationProvider";
import lightsterLineGrey from "../../../assets/images/lightster_line_grey.png";

export interface QuestionDisplay {
  profileId: string;
  key: string;
  title: string;
  keywordList: string[];
  inputType: string;
}

export default function Questions() {
  const { questionService } = useApi();
  const { user } = useAuth();
  const { refresh } = useNotification();
  const [questionThisMonth, setQuestionThisMonth] = useState<number | null>(
    null
  );
  const [questions, setQuestions] = useState<QuestionDisplay[] | never[]>([]);
  const [open, setOpen] = useState({
    answer: false,
    activeBoost: false,
  });
  const [selected, setSelected] = useState<QuestionDisplay | null>(null);

  const getQuestions = async () => {
    const res = await questionService.getNewQuestions();
    if (!res) return;
    setQuestions(res.data);
  };

  const isBoost = () => (questionThisMonth ?? 0) >= 5;

  useEffect(() => {
    refresh();
    getQuestions();
    setQuestionThisMonth(user?.questionThisMonth);
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxHeight: "calc(100vh - 70px)",
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        "&::-webkit-scrollbar": {
          display: "none",
        }
        //scrollbarWidth: "none",
        //msOverflowStyle: "none",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography level="h3">Questions</Typography>
        <TagBoots
          sx={{
            backgroundColor: isBoost() ? "#38A169" : primaryPurpleHover,
            color: isBoost() ? "white" : "black",
          }}
        >
          {isBoost() ? (
            <span>
              Rewards boost <b>active</b>{" "}
            </span>
          ) : (
            <span>
              <b>{5 - (questionThisMonth ?? 0)} questions</b> from boost
            </span>
          )}
        </TagBoots>
      </Stack>
      <Typography level="body-md" fontWeight={300}>
        Get matched by answering these questions. Five responses gets you a 10%
        rewards boost.
      </Typography>
      <Stack direction={"column"} spacing={2}>
        {questions.length === 0 && <Stack
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 180px)"}
        >
          <img src={lightsterLineGrey} width={132} />
          <Typography level="body-sm" sx={{ fontStyle: "italic", mt: 3, color: '#969BAB' }}>
            You're all caught up! We will send you an e-mail when you are
            matched.
          </Typography>
        </Stack>}
        {questions.length > 0 &&
          questions.map((question) => {
            return (
              <QuestionCard
                onClick={() => {
                  setSelected(question);
                  setOpen({ ...open, answer: true });
                }}
              >
                <div style={{ width: 750, overflow: "hidden", textOverflow: "ellipsis" }}>
                  <Typography level="body-md" width={"100%"} noWrap overflow={'clip'}  >
                    {question.title}
                  </Typography>
                </div>
                <SolidButton
                  variant="solid"
                  size="sm"
                  sx={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "6px 12px",
                    minHeight: "auto",
                    maxHeight: "36px",
                    fontWeight: 500,
                  }}
                >
                  Answer
                </SolidButton>
              </QuestionCard>
            );
          })}
      </Stack>
      {open.answer && selected && (
        <LightsterAnswerQuestionDialog
          open={open.answer}
          onClose={() => {
            setOpen({ ...open, answer: false });
          }}
          refresh={() => {
            getQuestions();
            refresh();
            const newData = JSON.parse(
              `${localStorage.getItem(StorageType.user)}`
            );
            setQuestionThisMonth(newData.questionThisMonth);
            if (newData.questionThisMonth === 5) {
              setOpen({ ...open, answer: false, activeBoost: true });
            }
          }}
          question={selected}
        />
      )}
      {open.activeBoost && (
        <CustomDialog
          open={open.activeBoost}
          onClose={() => setOpen({ ...open, activeBoost: false })}
          closeButton={true}
          title={"Rewards Boost Active"}
          subtitle={
            <Typography level="body-md">
              Hooray! You’ve responded to 5 questions this month, and have
              earned a 10% rewards boost which will be applied to all sessions
              you join for the month.
            </Typography>
          }
        />
      )}
    </Box>
  );
}

const TagBoots = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 12px",
  color: "white",
  backgroundColor: "#38A169",
  width: 232,
  maxHeight: 36,
  borderRadius: 20,
}));

const QuestionCard = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: "12px",
  border: "1px solid #000",
  background: "#FFF",
  boxShadow: "4px 4px 0px 0px #000",
  paddingInline: 32,
  gap: 16,
  width: "calc(100% - 75px)",
  height: '80px',
}));
