import "@fontsource-variable/inter";
import { Route, Routes } from "react-router-dom";
import Smartlook from "smartlook-client";
import Login from "./pages/Login";
import ChooseRole from "./pages/signup/ChooseRole";
import SignupEmail from "./pages/signup/SignupEmail";
import VerifyPhoneNumber from "./pages/signup/VerifyPhoneNumber";
import CreatorAboutProfile from "./pages/update-profle/creator/CreatorAboutProfile";
import CreatorAboutYou from "./pages/update-profle/creator/CreatorAboutYou";
import CreatorChoosePlan from "./pages/update-profle/creator/CreatorChoosePlan";
import CreatorPayment from "./pages/update-profle/creator/CreatorPayment";
import CreatorPlanDetail from "./pages/update-profle/creator/CreatorPlanDetail";
import CreatorPlanSuccess from "./pages/update-profle/creator/CreatorPlanSuccess";
import CreatorProductKeywords from "./pages/update-profle/creator/CreatorProductKeywords";
import LightsterAboutProfile from "./pages/update-profle/lightster/LightsterAboutProfile";
import LightsterAboutYou from "./pages/update-profle/lightster/LightsterAboutYou";
import LightsterConnections from "./pages/update-profle/lightster/LightsterConnections";
import LightsterRegisterSuccess from "./pages/update-profle/lightster/LightsterRegisterSuccess";
import LightsterYourInterests from "./pages/update-profle/lightster/LightsterYourInterests";

import { CssVarsProvider } from "@mui/joy/styles";
import { ProtectedRoute } from "./ProtectRoute";
import BaseMenu from "./components/menu/BaseMenu";
import theme from "./joy-styles";
import { GuestLogin } from "./pages/GuestLogin";
import { NotFound } from "./pages/NotFound";
import { QuickBookingRedirect } from "./pages/QuickBookingRedirect";
import RequstRermissions from "./pages/RequestPermissions";
import Account from "./pages/account/Account";
import CoCreate from "./pages/co-create/CoCreate";
import CreatorHome from "./pages/creator/CreatorHome";
import CreatorCoCreate from "./pages/creator/co-create/CreatorCoCreate";
import CreatorInsights from "./pages/creator/co-create/CreatorInsight";
import CreateSchedule from "./pages/creator/schedule/CreateSchedule";
import CreateScheduleLightster from "./pages/creator/schedule/CreateScheduleLightster";
import CreateSendMessage from "./pages/creator/send-message/CreateSendMessage";
import CreateSurvey from "./pages/creator/survey/CreateSurvey";
import SurveyPayment from "./pages/creator/survey/SurveyPayment";
import ViewLightsterProfile from "./pages/creator/view-profile/ViewLightsterProfile";
import LightsterHome from "./pages/lightster/LightsterHome";
import ViewCreatorProfile from "./pages/lightster/view-profile/ViewCreatorProfile";
import { ResetPassword } from "./pages/signup/ResetPassword";
import GuestVideoCall from "./pages/video-call/GuestVideoCall";
import MainVideoCall from "./pages/video-call/MainVideoCall";
import PaymentSummary from "./pages/video-call/PaymentSummary";
import { ApiProvider } from "./providers/ApiProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { FirebaseProvider } from "./providers/FirebaseProvider";
import { PlanDialogProvider } from "./providers/PlanPovider";
import { useEffect } from "react";
import Questions from "./pages/lightster/questions/Questions";
import { NotificationProvider } from "./providers/NotificationProvider";
import QuickbookingNonUser from "./pages/QuickBookingRedirectNonUser";
import SurveyNonUser from "./pages/SurveyRedirectNonUser";
import { GuestLoginLightster } from "./pages/GuestLoginLightster";
import GuestVideoCallLightster from "./pages/video-call/GuestVideoCallLightster";
import CreateUnmoderated from "./pages/creator/unmoderated/CreateUnmoderated";
import UnmoderatedPayment from "./pages/creator/unmoderated/UnmoderatedPayment";
import SchedulePayment from "./pages/creator/schedule/SchedulePayment";
import CreateSteps from "./pages/creator/unmoderated/CreateSteps";
import UnmoderatedTest from "./pages/video-call/UnmoderatedTest";
import UnmoderatedSummary from "./pages/video-call/UnmoderatedSummary";
import { UnmoderatedRedirect } from "./pages/UnmoderatedRedirect";
import CreateUnmoderatedLightster from "./pages/creator/unmoderated/CreateUnmoderatedLightster";

const pagesName = {
  signupEmail: "/signup/email",
  verifyPhoneNumber: "/signup/verify",
  resetpassword: "/resetpassword",
  chooseUsername: "/signup/choose-username",
  chooseRole: "/signup/choose-role",
  unmoderated: "/unmoderated",
  unmoderatedRedirect: "/unmoderatedRedirect",
  quickbooking: "/quickbooking",
  quickbookingNonUser: "/quickbookingnonuser",
  surveyNonUser: "/surveynonuser",
  lightster: {
    aboutYou: "/lightster/about-you",
    aboutProfile: "/lightster/about-profile",
    yourInterests: "/lightster/interests",
    connections: "/lightster/connections",
    success: "/lightster/success",
    home: "/lightster/home",
    viewProfile: "/lightster/view-profile",
    questions: "/lightster/questions",
  },
  creator: {
    aboutYou: "/creator/about-you",
    aboutProfile: "/creator/about-profile",
    productKeywords: "/creator/product-keywords",
    plan: "/creator/plan/select",
    planDetail: "/creator/plan/detail",
    planPayment: "/creator/plan/payment",
    planSuccess: "/creator/plan/success",
    home: "/creator/home",
    coCreate: "/creator/co-create",
    insights: "/creator/transactions",
    viewProfile: "/creator/view-profile",
    unmoderated: "/creator/unmoderated",
    unmoderatedSteps: "/creator/unmoderated/steps",
    unmoderatedPayment: "/creator/unmoderatedPayment",
    schedule: "/creator/schedule",
    schedulePayment: "/creator/schedule/payment",
    survey: "/creator/survey",
    surveyPayment: "/creator/survey/payment",
    sendMessage: "/creator/send-message",
  },
  coCreate: "/co-create",
  commnunities: "/communities",
  profile: "/profile",
  setting: "/setting",
  account: "/account",
  videocall: "/videocall",
  unmoderatedSummary: "/unmoderated/summary",
  guestJoin: "/videocall/guest",
  guestJoinLightster: "/videocall/guestLightster",
  requestPermissions: "/request/permissions",
  paymentSummary: "/payment/summary",
  question: "/question",
};

function App() {
  useEffect(() => {
    Smartlook.init("d84f068f912d2f1f352a3f5b1176c596ecbb1684", {});
  });
  return (
    <FirebaseProvider>
      <ApiProvider>
        <AuthProvider>
          <NotificationProvider>
            <CssVarsProvider theme={theme}>
              <PlanDialogProvider>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route
                    path={pagesName.signupEmail}
                    element={<SignupEmail />}
                  />
                  <Route
                    path={pagesName.verifyPhoneNumber}
                    element={
                      <ProtectedRoute>
                        <VerifyPhoneNumber />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.resetpassword}
                    element={<ResetPassword />}
                  />

                  <Route
                    path={pagesName.chooseRole}
                    element={
                      <ProtectedRoute>
                        <ChooseRole />
                      </ProtectedRoute>
                    }
                  />
                  {/* /lightster route/ */}
                  <Route
                    path={pagesName.lightster.aboutYou}
                    element={
                      <ProtectedRoute>
                        <LightsterAboutYou />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.lightster.aboutProfile}
                    element={
                      <ProtectedRoute>
                        <LightsterAboutProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.lightster.yourInterests}
                    element={
                      <ProtectedRoute>
                        <LightsterYourInterests />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.lightster.connections}
                    element={
                      <ProtectedRoute>
                        <LightsterConnections />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.lightster.success}
                    element={
                      <ProtectedRoute>
                        <LightsterRegisterSuccess />
                      </ProtectedRoute>
                    }
                  />
                  {/* /creator route/ */}
                  <Route
                    path={pagesName.creator.aboutYou}
                    element={
                      <ProtectedRoute>
                        <CreatorAboutYou />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.aboutProfile}
                    element={
                      <ProtectedRoute>
                        <CreatorAboutProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.productKeywords}
                    element={
                      <ProtectedRoute>
                        <CreatorProductKeywords />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.plan}
                    element={
                      <ProtectedRoute>
                        <CreatorChoosePlan />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.planDetail}
                    element={
                      <ProtectedRoute>
                        <CreatorPlanDetail />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.planPayment}
                    element={
                      <ProtectedRoute>
                        <CreatorPayment />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.planSuccess}
                    element={<CreatorPlanSuccess />}
                  />
                  <Route
                    path={pagesName.creator.home}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreatorHome />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.coCreate}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreatorCoCreate />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.creator.insights}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreatorInsights />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.viewProfile}/:userId/:communityId`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <ViewLightsterProfile />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.viewProfile}/:userId`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <ViewLightsterProfile />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.unmoderated}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateUnmoderated />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.unmoderated}/:lightsterId`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateUnmoderatedLightster />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.unmoderatedSteps}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateSteps />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.unmoderatedPayment}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <UnmoderatedPayment />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.schedule}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateSchedule />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.schedule}/:lightsterId`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateScheduleLightster />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.survey}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateSurvey />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.sendMessage}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <CreateSendMessage />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.surveyPayment}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <SurveyPayment />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.creator.schedulePayment}`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <SchedulePayment />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.lightster.home}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <LightsterHome />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.lightster.questions}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <Questions />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.question}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <Questions />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${pagesName.lightster.viewProfile}/:userId`}
                    element={
                      <ProtectedRoute>
                        <BaseMenu>
                          <ViewCreatorProfile />
                        </BaseMenu>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.videocall}
                    element={
                      <ProtectedRoute>
                        <MainVideoCall />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.unmoderated}
                    element={
                      <ProtectedRoute>
                        <UnmoderatedTest />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.unmoderatedSummary}
                    element={
                      <ProtectedRoute>
                        <UnmoderatedSummary />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.requestPermissions}
                    element={<RequstRermissions />}
                  />
                  <Route
                    path={pagesName.guestJoin}
                    element={<GuestVideoCall />}
                  />
                  <Route
                    path={pagesName.guestJoinLightster}
                    element={<GuestVideoCallLightster />}
                  />
                  <Route
                    path={pagesName.paymentSummary}
                    element={
                      <ProtectedRoute>
                        <PaymentSummary />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/room" element={<GuestLogin />} />
                  <Route path="/room/:meetingId" element={<GuestLogin />} />
                  <Route
                    path="/room_lightster"
                    element={<GuestLoginLightster />}
                  />
                  <Route
                    path="/room_lightster/:meetingId"
                    element={<GuestLoginLightster />}
                  />
                  <Route
                    path={pagesName.coCreate}
                    element={
                      <ProtectedRoute>
                        <CoCreate />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.account}
                    element={
                      <ProtectedRoute>
                        <Account />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.unmoderatedRedirect}
                    element={
                      <ProtectedRoute>
                        <UnmoderatedRedirect />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.quickbooking}
                    element={
                      <ProtectedRoute>
                        <QuickBookingRedirect />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={pagesName.quickbookingNonUser}
                    element={<QuickbookingNonUser />}
                  />
                  <Route
                    path={pagesName.surveyNonUser}
                    element={<SurveyNonUser />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </PlanDialogProvider>
            </CssVarsProvider>
          </NotificationProvider>
        </AuthProvider>
      </ApiProvider>
    </FirebaseProvider>
  );
}

export { pagesName };
export default App;
