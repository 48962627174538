import styled from "styled-components";

export const CustomButtton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #4700b2;
  font-weight: 600;
  min-height: 48px;
`;