import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import BackgroundRegister from "../components/BackgroundRegister";
import { useApi } from "../providers/ApiProvider";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/joy";
import { SurvayDisplay } from "../constants/interfaces";
import { SolidButton, PlainButton } from "../components/button/CustomButton";
import CustomDialog from "../components/dialog/CustomDialog";
import InfoDialog from "../components/dialog/InfoDialog";
import ResponseQuestionsurveyDialog from "../components/dialog/survey/ResponseQuestionsurveyDialog";


function SurveyNonUser() {
  const params = new URLSearchParams(window.location.search);
  const surveyId = params.get("surveyId");
  const userId = params.get("id");

  const [open, setOpen] = useState({
    complete: false,
    ended: false,
    leavePage: false,
    answered: false,
  });
  const navigate = useNavigate();
  const { sessionService, showMessage, userService } = useApi();
  const [loading, setLoading] = useState(false);
  const [survey, setSurvey]= useState<SurvayDisplay>();

  useEffect(() => {
    getSurveyDetail();
    handleUpdateTimezone();
  }, []);


  const getSurveyDetail = async () => {
    if (surveyId && userId) {
      const res = await sessionService.getLightsterSurveyDetailNonUser(surveyId, userId);
      console.log(res);
      if (!res) {
        return;
      };
      setSurvey({...res,id: res._id.toString()});
      setOpen({ ...open, complete: true });
    }
  };

  const handleUpdateTimezone = async () => {
    if (userId) {
      const timezone = (new Date().getTimezoneOffset() * -1).toString();
      const payload = {
        timezone: timezone,
      };
      const res = await userService.updateTimezoneNonUser(payload, userId);
      if (!res) return;
    }
  };

  if (loading)
    return (
      <Box
        display={"flex"}
        height={"100vh"}
        width={"100vw"}
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <BackgroundRegister>
      <Box
        sx={{
          width: "100%",

          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container>

        {open.complete && (
        <ResponseQuestionsurveyDialog
          open={open.complete}
          surveyId={survey?.id!}
          surveyName={survey?.name!}
          nonUser={true}
          nonUserId={userId||""}
          onClose={(value: boolean | undefined) => {
            if (value) {
              setOpen({ ...open, leavePage: true });
            } else {
              setOpen({ ...open, complete: false });
            }
          }}
          onSave={() => {
            setOpen({ ...open, complete: false, answered: true,  });
          }}
          surveyEnded={() => {
            setOpen({ ...open, ended: true, complete: false });
          }}
        />
      )}
      {open.ended && (
        <InfoDialog
          open={open.ended}
          onClose={() => {
            setOpen({ ...open, ended: false });
            navigate("/");
          }}
          title={"The survey has ended"}
          subtitle={
            <Typography level="body-md">
              Thank you for your time, the survey has now closed and no longer
              accepting responses.
            </Typography>
          }
        />
      )}
      {open.answered && (
        <InfoDialog
          open={open.answered}
          onClose={() => {
            setOpen({ ...open, answered: false });
            navigate("/");
          }}
          title={"The survey has been completed"}
          subtitle={
            <Typography level="body-md">
              Thank you for your response.
            </Typography>
          }
        />
      )}
      {open.leavePage && (
        <CustomDialog
          open={open.leavePage}
          title={"Are you sure?"}
          subtitle={
            <Stack direction={"column"} gap={3}>
              <Typography level="body-lg">
                Your responses have not been send yet.
              </Typography>
              <Stack direction={"row"} gap={2}>
                <SolidButton
                  variant="solid"
                  onClick={() => {
                    setOpen({ ...open, leavePage: false, complete: false });
                    navigate("/");
                  }
                  }
                >
                  Yes
                </SolidButton>
                <PlainButton
                  variant="plain"
                  onClick={() => setOpen({ ...open, leavePage: false })}
                >
                  Stay on this page
                </PlainButton>
              </Stack>
            </Stack>
          }
        />
      )}


        </Grid>
      </Box>
    </BackgroundRegister>
  );
};

export default SurveyNonUser;
