import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../providers/ApiProvider";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  ModalClose,
  ModalDialog,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import moment from "moment";
import { primaryPurpleHover } from "../../joy-styles";
import { Unmoderated } from "../../constants/type/unmoderated";
import { SolidButton } from "../../components/button/CustomButton";
import AgoraRTC, {
  IAgoraRTCClient,
  ScreenVideoTrackInitConfig,
} from "agora-rtc-sdk-ng";
import { agoraAppId, client } from "../../constants/agoraRtc";
import { LocalTracks } from "../../constants/interfaces";
import { createDeviceTracks } from "../../components/video-call/Devices";
import { pagesName } from "../../App";
import { useAuth } from "../../providers/AuthProvider";
import styled from "styled-components";
import { primaryPurple } from "../../mui-styles";
import { InputType } from "../../constants/enum";

interface Question {
  title: string;
  inputType: string;
  keywordList: string[];
  answer: string;
  _id: string;
}

interface Answer {
  duration: number;
  step: number;
  question: number;
  inputType: string;
  answer: string;
}

export default function UnmoderatedTest() {
  let navigate = useNavigate();
  const location = useLocation();
  const { unmoderatedId, rtcToken, agoraUID, refNumber } = location.state;
  const { user } = useAuth();
  const { sessionService, handleToggle, showMessage } = useApi();
  const [data, setData] = useState<Unmoderated>();
  const [isCall, setIsCall] = useState(false);
  const [start, setStart] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [answer, setAnswer] = useState<Answer[]>([]);

  const [connectionIssue, setConnectionIssue] = useState(false);
  const [localTracks, setLocalTracks] = useState<LocalTracks>({
    audio: null,
    video: null,
    share: null,
  });
  const [screenTrack, setScreenTrack] = useState<any>();
  const [shareScreenUid, setShareScreenUid] = useState<null | string>();
  const [clientShareScreen, setClientShareScreen] =
    useState<IAgoraRTCClient | null>(null);

  let tracks: any = [];

  const startRecording = async () => {
    await sessionService.startRecordUnmoderated(unmoderatedId);
  };

  const updateRecording = async (uid: string) => {
    await sessionService.updateRecordUnmoderated(unmoderatedId, uid);
  };

  const getUnmoderatedDetail = async (id: string) => {
    const res = await sessionService.detailUnmoderated(id);
    if (!res) return;
    setData(res);
  };

  const handleShareScreen = async () => {
    if (!isCall) {
      const client2 = AgoraRTC.createClient({
        mode: "rtc",
        codec: "h264",
      });

      const config: ScreenVideoTrackInitConfig = {
        optimizationMode: "detail",
        systemAudio: "exclude",
        encoderConfig: "1080p_3",
        displaySurface: "monitor",
        screenSourceType: "screen",
      };

      if (!data) return;
      await AgoraRTC.createScreenVideoTrack(config)
        .then(async (track: any) => {
          const res = await sessionService.shareScreenUnmoderated(
            unmoderatedId
          );

          await client2.join(
            agoraAppId,
            res.refNumber,
            res.rtcToken,
            parseInt(res.agoraUID)
          );

          await client2.publish(track);
          track.on("track-ended", async () => {
            track.stop();
            track?.close();
            await client2.unpublish(track);
            await client2.leave();
            leave();
          });

          setShareScreenUid(res.agoraUID);
          setScreenTrack(track);
          setClientShareScreen(client2);
          setIsCall(true);
          await startRecording();
          await updateRecording(res.agoraUID);
        })
        .catch((e) => {
          showMessage(
            "Screen-sharing permissions not enabled. Please go to your System Settings to enable screen-sharing for your browser.",
            "error"
          );
          setIsCall(false);
          setDuration(0);
        });
    } else {
      await closeShareScreen();
    }
  };

  const closeShareScreen = async (isEnd?: boolean) => {
    if (isEnd) {
      await updateRecording(agoraUID);
    }
    screenTrack?.close();
    await clientShareScreen?.unpublish(screenTrack);
    await clientShareScreen?.leave();
    setShareScreenUid(null);
    setScreenTrack(null);
    setIsCall(false);
  };

  const handleSetAnswer = (value: string) => {
    if (!data) return;
    if (!data) return;

    const newData = { ...data }; // Create a shallow copy of the data
    const currentStepData = { ...newData.creator.steps[currentStep] }; // Copy the current step
    const currentQuestionData = {
      ...currentStepData.questions[currentQuestion],
    }; // Copy the current question

    currentQuestionData.answer = value; // Update the answer
    currentStepData.questions = [
      ...currentStepData.questions.slice(0, currentQuestion),
      currentQuestionData,
      ...currentStepData.questions.slice(currentQuestion + 1),
    ]; // Update the questions array with the modified question
    newData.creator.steps = [
      ...newData.creator.steps.slice(0, currentStep),
      currentStepData,
      ...newData.creator.steps.slice(currentStep + 1),
    ]; // Update the steps array with the modified step

    setData(newData);
  };
  const handleStampAnswer = async () => {
    if (!data) return;
    const newAnswer = {
      duration: duration,
      step: currentStep,
      question: currentQuestion,
      inputType:
        currentQuestion === -1
          ? "steps"
          : data.creator.steps[currentStep].questions[currentQuestion]
            .inputType ?? "steps",
      answer:
        currentQuestion === -1
          ? ""
          : data.creator.steps[currentStep].questions[currentQuestion].answer ??
          "",
    };
    const currentAllAnswer = [...answer, newAnswer];
    setAnswer(currentAllAnswer);
    return currentAllAnswer;
  };

  const handleNextStep = async () => {
    if (!isCall) {
      setStart(true);
      return;
    }
    if (!data) return;

    const currentAllAnswer = await handleStampAnswer();

    if (
      currentStep === data.creator.steps.length - 1 &&
      currentQuestion === data.creator.steps[currentStep].questions.length - 1
    ) {
      await closeShareScreen(true);
      handleEndSession(currentAllAnswer ?? []);
      return;
    }
    const step = data.creator.steps[currentStep];

    if (currentQuestion < step.questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setCurrentStep(currentStep + 1);
      setCurrentQuestion(-1);
    }
  };
  const handleBackStep = () => {
    if (!isCall) {
      navigate(pagesName.lightster.home);
      return;
    }
    if (!data) return;

    handleStampAnswer();

    if (currentStep === 0 && currentQuestion === -1) {
      setOpen(true);
      return;
    }
    if (currentQuestion > -1) {
      setCurrentQuestion(currentQuestion - 1);
    } else {
      setCurrentStep(currentStep - 1);
      setCurrentQuestion(
        data.creator.steps[currentStep - 1].questions.length - 1
      );
    }
  };

  const getDataStep = () => {
    if (!data) return;
    const steps = data.creator.steps;

    let title = "";
    let detail = "";
    let question;

    if (currentQuestion < 0) {
      title = `Step ${steps[currentStep].stepNumber}`;
      detail = steps[currentStep].detail;
    } else {
      title = `Question ${currentQuestion + 1}`;
      detail = steps[currentStep].questions[currentQuestion].title;
      question = steps[currentStep].questions[currentQuestion];
    }

    let values = { title: title, detail: detail, question: question };

    return values;
  };

  const showCompleteButton = () => {
    if (!data) return false;
    return (
      currentStep === data.creator.steps.length - 1 &&
      currentQuestion ===
      data.creator.steps[data.creator.steps.length - 1].questions.length - 1
    );
  };

  const leave = async () => {
    tracks[0]?.close();
    tracks[1]?.close();
    localTracks?.audio?.close();
    localTracks?.video?.close();
    client.leave();
    await sessionService.leaveUnmoderated(unmoderatedId);
    navigate(pagesName.lightster.home);
  };

  const handleEndSession = async (currentAllAnswer: Answer[]) => {
    setLoading(true);
    const check = moment(duration * 1000).seconds();
    const totalDuration =
      check > 0
        ? moment(duration * 1000).minutes() + 1
        : moment(duration * 1000).minutes();
    const res = await sessionService.endUnmoderated(
      unmoderatedId,
      currentAllAnswer
    );
    tracks[0]?.close();
    tracks[1]?.close();
    localTracks?.audio?.close();
    localTracks?.video?.close();
    client?.leave();
    setLoading(false);

    navigate(pagesName.unmoderatedSummary, {
      state: {
        totalDuration: totalDuration,
        unmoderated: res,
        displayname: data?.name,
      },
      replace: true,
    });
  };

  useEffect(() => {
    if (screenTrack) {
      const sourceType = screenTrack._mediaStreamTrack.label;
      if (!sourceType.includes("screen")) {
        showMessage(
          "Please share your screen using the “Entire screen” option in order to proceed.",
          "error"
        );
        setIsCall(false);
        setDuration(0);
        closeShareScreen();
        return;
      }
    }
  }, [screenTrack]);

  useEffect(() => {
    if (unmoderatedId) {
      getUnmoderatedDetail(unmoderatedId);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      handleToggle(true);
      client.on("network-quality", (quality) => {
        if (
          quality.uplinkNetworkQuality === 6 ||
          quality.downlinkNetworkQuality === 6
        ) {
          setConnectionIssue(true);
          showMessage(
            "Connection lost. Please check your connection and refresh the browser.",
            "error"
          );
        } else if (
          quality.uplinkNetworkQuality > 3 ||
          quality.downlinkNetworkQuality > 3
        ) {
          setConnectionIssue(true);
          showMessage(
            "We’re having issues with your internet connection, please check your connection and refresh the browser.",
            "warning"
          );
        } else if (connectionIssue) {
          setConnectionIssue(false);
          showMessage("You’ve been re-connected!", "success");
        }
      });

      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", async (user, mediaType) => {
        await client.unsubscribe(user, mediaType);
      });

      tracks = await createDeviceTracks();
      setLocalTracks((pre) => {
        return { ...pre, audio: tracks[0], video: tracks[1] };
      });

      await client.join(agoraAppId, refNumber, rtcToken, parseInt(agoraUID));

      if (tracks[1]) {
        await client.publish(tracks);
        tracks[1].setEnabled(true);
      } else {
        await client.publish(tracks[0]);
      }
      handleToggle(false);
    };

    if (start) {
      init();
    }

    return () => {
      tracks[0]?.close();
      tracks[1]?.close();
    };
  }, [start]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isCall) {
        setDuration((prevCounter) => prevCounter + 1);
      }
    }, 1000);

    if (!isCall) clearInterval(interval);
    return () => clearInterval(interval);
  }, [isCall]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await sessionService.activeUnmoderated(unmoderatedId);
      if (!res) return;
      if (!res.isActive) {
        clearInterval(interval);
        tracks[0]?.close();
        tracks[1]?.close();
        client.leave();
        navigate(pagesName.lightster.home);
      }
    }, 1000 * 15);

    return () => clearInterval(interval);
  }, []);

  const disableButton = () => {
    if (!data) return false;
    if (currentQuestion < 0) return false;
    const current = data.creator.steps[currentStep].questions[currentQuestion];

    if (current.inputType === InputType.SELECTLIST && !current.answer)
      return true;
    if (current.inputType === InputType.TEXTFIELD && !current.answer)
      return true;
    if (current.inputType === InputType.RATING_SCALE && !current.answer)
      return true;

    return false;
  };
  const showButton = () => {
    if (start && !shareScreenUid) return false;
    return true;
  };

  if (!data) return <></>;

  if (loading) {
    return (
      <>
        <Box
          display={"flex"}
          height={"100vh"}
          width={"100vw"}
          alignItems="center"
          justifyContent={"center"}
        >
          <CircularProgress color="primary" />
        </Box>
      </>
    );
  }
  return (
    <Grid container>
      {shareScreenUid && (
        <Box
          width="100%"
          bgcolor={"#48BB78"}
          display="flex"
          justifyContent={"center"}
          maxHeight={30}
          alignItems="center"
        >
          <Typography sx={{ color: "white", fontWidth: 600 }}>
            This session is being recorded.
          </Typography>
        </Box>
      )}
      <DialogEndSession
        open={open}
        setOpen={setOpen}
        onConfirm={async () => {
          if (shareScreenUid) {
            await closeShareScreen();
          }
          leave();
        }}
      />
      <Grid xs={12} height={"10vh"}>
        <Stack
          direction={"row"}
          height={"100%"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box paddingInline={5}>
            <Typography level="h4">{data.name}</Typography>
            <Typography
              level="body-sm"
              sx={{
                color: "#474A57", fontWeight: 400,
                 whiteSpace: 'nowrap', 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '50vw'
              }}
            >
              {data.topic}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              paddingInline: 5,
              alignItems: "center",
            }}
          >
            <Typography
              level="h3"
              sx={{ color: "var(--joy-palette-primary-500)" }}
              fontWeight={400}
              pr={2}
            >
              {moment(duration * 1000).format("mm:ss") + " minutes"}
            </Typography>
            <Button
              className="leave-button"
              variant="solid"
              sx={{
                paddingInline: `24px !important`,
                ":hover": {
                  background: `${primaryPurpleHover} !important`,
                },
              }}
              onClick={async () => {
                setOpen(true);
              }}
            >
              End test
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid xs={12} sx={{ padding: "0px 42px 42px" }}>
        <Grid container direction={"row"}>
          <Grid
            xs
            height={"100%"}
            width={"100%"}
            sx={{
              border: "1px solid black",
              borderRadius: 8,
              padding: "5% 20% 42px",
              height: "calc(90vh - 42px)",
            }}
          >
            <Stack direction={"column"} gap={4} height={"100%"} width={"100%"}>
              <Stack height={"90%"} width={"100%"}>
                {!start ? (
                  <PreStartSession name={user.displayName} />
                ) : !isCall ? (
                  <StartSession
                    onClick={() => {
                      setIsCall(true);
                      handleShareScreen();
                    }}
                  />
                ) : (
                  <Step
                    question={getDataStep()?.question}
                    title={getDataStep()?.title ?? ""}
                    detail={getDataStep()?.detail ?? ""}
                    answer={getDataStep()?.question?.answer}
                    setAnswer={(value: string) => handleSetAnswer(value)}
                  />
                )}
              </Stack>

              {showButton() && (
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <CustomButtton onClick={handleBackStep}>
                    {(currentStep === 0 && currentQuestion === -1) || !start
                      ? "Cancel"
                      : "Back"}
                  </CustomButtton>
                  <SolidButton
                    variant="solid"
                    sx={{ maxHeight: 48 }}
                    onClick={handleNextStep}
                    disabled={disableButton()}
                  >
                    {showCompleteButton() ? "Complete" : "Next"}
                  </SolidButton>
                </Stack>
              )}
            </Stack>
          </Grid>
          {/* <Grid width={200}>
            <Box
              sx={{
                width: 200,
                height: 200,
                bgcolor: "var(--joy-palette-neutral-50)",
                borderRadius: 8,
              }}
            >
              <MediaPlayer videoTrack={localTracks.video} />
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
interface PreStartSessionProps {
  name: string;
}

const PreStartSession = ({ name }: PreStartSessionProps) => {
  return (
    <Stack direction={"column"} gap={5} width={"100%"}>
      <Typography fontSize={20}>
        Hello <b>{name}</b>
      </Typography>
      <Typography>
        Before we begin, there are a few things that you need to know:
        <br />
        <ul>
          <li>
            This is an unmoderated test, so you will be asked to do a series of
            steps, and respond to series of questions out loud
          </li>
          <li>
            Once you click “<b>Start</b>”, the recording for the session will
            begin, and everything displayed on this screen will be recorded
          </li>
          {/* <li>
            Before beginning, you will be asked to provide permissions to access
            your camera and microphone, please click on “<b>Allow</b>” to
            proceed
          </li> */}
          <li>
            Please ensure you are in a quiet environment before proceeding. For
            each question, please speak your thoughts as clearly and in as much
            detail as you can out loud
          </li>
        </ul>
        <br />
        If you have any questions, please e-mail support@lightster.co.
        <br />
        <br />
        Does this sound good to you?
      </Typography>
    </Stack>
  );
};

interface StartSessionProps {
  onClick: () => void;
}
const StartSession = ({ onClick }: StartSessionProps) => {
  return (
    <Stack direction={"column"} gap={5} width={"100%"}>
      <Typography fontSize={24}>
        <b>Great, we’re ready to begin!</b>
        <br />
        <br />
        Please click on the button below to start sharing your screen, be sure
        <br />
        to select the
        <b> “Entire screen” </b>option.
      </Typography>
      <Button
        variant="outlined"
        sx={{
          width: 230,
          borderColor: "black",
          fontSize: 18,
          fontWeight: 400,
          color: "black",
          ":hover": {
            borderColor: "var(--joy-palette-primary-900)",
          },
        }}
        onClick={onClick}
      >
        Share my screen
      </Button>
    </Stack>
  );
};
const linkify = (text: string) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return text.split(urlPattern).map((part, index) => {
    if (part.match(urlPattern)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
};

interface StepProps {
  question?: Question;
  title: string;
  detail: string;
  answer?: string;
  setAnswer: (v: string) => void;
}
const Step = ({ question, title, detail, answer, setAnswer }: StepProps) => {
  return (
    <Stack
      key={`step-${title}`}
      direction={"column"}
      width={"100%"}
      height={"100%"}
    >
      <Box>
        <Typography
          level="h4"
          sx={{ color: "#18191F", fontSize: "20px", mb: 5 }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontWeight={400}
          sx={{ color: "#18191F", fontSize: "16px", mb: 5 }}
        >
          {linkify(detail)}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto", pr: 2 }}>
        {question && question.inputType === InputType.TEXTFIELD && (
          <Textarea
            minRows={5}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        )}
        {question && (
          <Stack direction={"column"} gap={2}>
            {question.inputType === InputType.SELECTLIST &&
              question.keywordList.map((data) => (
                <Stack
                  key={data}
                  direction={"row"}
                  alignItems={"center"}
                  gap={2}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAnswer(data);
                  }}
                >
                  <i
                    className="bi bi-check2-circle"
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                      color: answer === data ? primaryPurple : "grey",
                    }}
                  ></i>
                  <Choice>{data}</Choice>
                </Stack>
              ))}
          </Stack>
        )}
        {question && question.inputType === InputType.RATING_SCALE && (
          <RadioGroup
            orientation="horizontal"
            sx={{ justifyContent: "space-evenly", width: "100%" }}
            defaultValue="outlined"
            name="radio-buttons-group"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          >
            <CustomRadio value="0" label="0" variant="outlined" />
            <CustomRadio value="1" label="1" variant="outlined" />
            <CustomRadio value="2" label="2" variant="outlined" />
            <CustomRadio value="3" label="3" variant="outlined" />
            <CustomRadio value="4" label="4" variant="outlined" />
            <CustomRadio value="5" label="5" variant="outlined" />
          </RadioGroup>
        )}
      </Box>
    </Stack>
  );
};

interface DialogEndSessionProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
}
function DialogEndSession({ open, setOpen, onConfirm }: DialogEndSessionProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        sx={{ maxWidth: 450 }}
        aria-labelledby="variant-modal-title-end-session"
        aria-describedby="variant-modal-description-end-session"
      >
        <ModalClose />
        <Typography
          id="variant-modal-title-end-session"
          level="h2"
          textColor="inherit"
        >
          {"End test"}
        </Typography>
        <Typography
          id="variant-modal-description-end-session"
          textColor="inherit"
        >
          You’re not finished just yet, are you sure you want to end the test?
          You may not receive the invitation again.
        </Typography>

        <Stack direction={"row"} gap={2} mt={2}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="neutral"
            fullWidth
            sx={{
              borderRadius: 6,
              border: "1px solid black",
              ":hover": {
                border: `1px solid ${primaryPurpleHover}`,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            autoFocus
            variant="solid"
            color="neutral"
            fullWidth
            sx={{
              borderRadius: 6,
              border: "1px solid black",
              bgcolor: "black",
              ":hover": {
                border: `1px solid ${primaryPurpleHover}`,
              },
            }}
          >
            OK
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
const CustomRadio = styled(Radio)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 24,
  width: 30,
  height: 30,
});

export const CustomButtton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #4700b2;
  font-weight: 600;
  min-height: 48px;
`;

export const Choice = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: 1px solid black;
  border-radius: 30px;
  padding: 8px 16px;
`;
