import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { pagesName } from "../App";
import BackgroundRegister from "../components/BackgroundRegister";
import { guestLogin } from "../constants/AppImages";
import { useApi } from "../providers/ApiProvider";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  Link,
  Typography,
} from "@mui/joy";
import { SolidButton } from "../components/button/CustomButton";

export const GuestLogin = () => {
  let { meetingId } = useParams();
  const navigate = useNavigate();
  const { sessionService, showMessage } = useApi();

  const [refNumber, setRefNumber] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (meetingId) {
      setRefNumber(meetingId);
    }
  }, []);

  const getMeetingDetail = async () => {
    setLoading(true);
    const res = await sessionService.guestJoinSession(refNumber);
    console.log(res);
    if (!res) {
      return navigate("/", { replace: true });
    }

    setLoading(false);
    navigate(pagesName.requestPermissions, {
      state: { guestSession: res },
    });
  };

  if (loading)
    return (
      <Box
        display={"flex"}
        height={"100vh"}
        width={"100vw"}
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <BackgroundRegister>
      <Box
        sx={{
          width: "100%",

          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container>
          <Grid xs={12} sx={{ textAlignLast: "center" }}>
            <img src={guestLogin} width="300px" />
          </Grid>
          <Grid xs={12} sx={{ textAlignLast: "center" }}>
            <Typography level="h2">Join as a guest</Typography>

            <Box
              mt={2}
              display={"flex"}
              flexDirection="row"
              justifyContent={"center"}
            >
              <Typography level="body-lg" fontSize={18}>
                If you are the host, please tap on the
              </Typography>
              <Typography level="title-lg" fontWeight={700} fontSize={18}>
                &nbsp;“Join”
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection="row" justifyContent={"center"}>
              <Typography level="body-lg" fontSize={18}>
                button after logging in&nbsp;
              </Typography>
              <Typography
                level="body-lg"
                color="primary"
                fontSize={18}
                fontWeight={700}
                component={Link}
                href="https://app.lightster.co"
                target="_blank"
                underline="none"
              >
                here
              </Typography>
              <Typography level="body-lg" fontSize={18}>
                .
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} mt={4}>
            <Input
              name="roomId"
              placeholder="Meeting ID"
              variant="outlined"
              color="neutral"
              fullWidth
              size="lg"
              value={refNumber}
              onChange={(e) => setRefNumber(e.target.value)}
            />
          </Grid>
          <Grid xs={12} mt={4}>
            <SolidButton
              sx={{
                p: 1,
                textTransform: "capitalize",
                backgroundColor: "black",
              }}
              variant="solid"
              fullWidth
              size="lg"
              onClick={() => getMeetingDetail()}
            >
              Join Session
            </SolidButton>
          </Grid>
        </Grid>
      </Box>
    </BackgroundRegister>
  );
};
