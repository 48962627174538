import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export default class BaseService {
  private axios: AxiosInstance;

  private callbacks: any;

  constructor(callbacks: any) {
    const token = localStorage.getItem("lisgtster_share_screen_token");
    this.axios = axios.create({});
    this.axios.defaults.headers.common[
      "X-LIGHTSTER-CLIENT"
    ] = `WebAppShareScreen`;
    if (token) {
      this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    this.callbacks = callbacks;
  }

  async call(
    method: string,
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    try {
      if (["get", "post", "put", "delete", "patch"].indexOf(method) === -1) {
        throw new Error(`Method "${method}" not supported.`);
      }

      return await this.axios({
        data,
        method,
        url,
        ...options,
      });
    } catch (e: any) {
      if (e.response && e.response.status === 401) {
        this.callbacks.onUnAuthorized(e);
        return { data: undefined } as AxiosResponse;
      }

      this.callbacks.onError(e.response.data);

      return { data: undefined } as AxiosResponse;
    }
  }
}
