export const mapStatusText = (value: string): string => {
  const status: { [key: string]: string } = {
    pending: "Pending",
    inProgress: "In Progress",
    changing: "Pending",
    confirmed: "Accepted",
    accepted: "Accepted",
    completed: "Completed"
  };

  return status[value] ?? value;
};
