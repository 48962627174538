import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: JSX.Element;
}
export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const token = localStorage.getItem("lisgtster_share_screen_token");

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children;
};
