import {
  Autocomplete,
  Box,
  Chip,
  ChipDelete,
  Stack,
  Typography
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import locations from "../../assets/jsons/locations.json";
import { CountryProps, StateProps } from "../../constants/interfaces";
import { PlainButton, SolidButton } from "../button/CustomButton";

interface Props {
  selected?: string[];
  onSaved: (value: any) => void;
  onRemoved?: () => void;
  onCancel: () => void;
  isUpdate?: boolean;
}

function LocationField(props: Props) {
  const { onSaved, onCancel, onRemoved, selected, isUpdate } = props;
  const [country, setCountry] = React.useState<CountryProps | null>(null);
  const [state, setState] = React.useState<StateProps | null>(null);
  const [city, setCity] = React.useState<string | null | undefined>(null);
  const [selectList, setSelectList] = useState<Array<string>>([]);

  const contained = (value: string) => {
    var contained = false;
    for (let i = 0; i < selectList.length; i++) {
      const split = selectList[i].split(", ");
      console.log(split);
      if (split.includes(value)) {
        contained = true;
        break;
      }
    }
    return contained;
  };
  const handleCountryChange = (value: string) => {
    if (contained(value)) return;
    setSelectList([...selectList, value]);
  };

  const handleStageChange = (countryName: string, stateName: string) => {
    const newValue = [stateName, countryName].join(", ");
    if (contained(stateName)) return;
    if (countryName === country?.name) {
      const newData = selectList.filter((f) => f != countryName);
      setSelectList([...newData, newValue]);
    }
  };

  const handleCityChange = (
    countryName: string,
    stateName: string,
    cityName: string
  ) => {
    const newValue = [cityName, stateName, countryName].join(", ");
    if (contained(cityName)) return;
    if (stateName === state?.name) {
      const newData = selectList.filter(
        (f) => f != [stateName, countryName].join(", ")
      );
      setSelectList([...newData, newValue]);
    } else if (countryName === country?.name) {
      const newData = selectList.filter((f) => f != countryName);
      setSelectList([...newData, newValue]);
    }
  };

  useEffect(() => {
    if (selected) {
      setSelectList(selected);
    }
  }, []);

  return (
    <Box width="100%" height="100%">
      <Stack direction={"column"} spacing={4}>
        <Stack direction="column" spacing={2}>
          {selectList.length > 0 && (
            <Stack
              spacing={{ xs: 1 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              mb={1}
            >
              {selectList.map((location: any, index: number) => (
                <Chip
                  variant="soft"
                  color="primary"
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        const removed = selectList.filter(
                          (f) => f !== location
                        );
                        setSelectList(removed);
                      }}
                    />
                  }
                >
                  {location}
                </Chip>
              ))}
            </Stack>
          )}
          <Typography level="title-sm">Country</Typography>
          <Autocomplete
            value={country}
            onChange={(e: any, newValue: CountryProps | null) => {
              if (!newValue) {
                setCountry(null);
                setState(null);
                setCity(null);
              } else {
                setCountry(newValue);
                setState(null);
                setCity(null);
                handleCountryChange(newValue.name);
              }
            }}
            size="lg"
            autoFocus
            options={locations as Array<CountryProps>}
            getOptionLabel={(option: CountryProps) => option.name}
          />
        </Stack>
        {country && (
          <Stack direction="column" spacing={2}>
            <Typography level="title-sm">State/Province (Optional)</Typography>
            <Autocomplete
              size="lg"
              autoFocus
              options={country.states}
              getOptionLabel={(option) => option.name}
              value={state}
              onChange={(e: any, newValue: StateProps | null) => {
                if (!newValue) {
                  setCity(null);
                  setState(null);
                } else {
                  setState(newValue);
                  setCity(null);
                  handleStageChange(country.name, newValue.name);
                }
              }}
            />
          </Stack>
        )}
        {state && (
          <Stack direction="column" spacing={2}>
            <Typography level="title-sm">City (Optional)</Typography>
            <Autocomplete
              size="lg"
              options={state.cities}
              getOptionLabel={(option: any) => option}
              value={city}
              onChange={(e: any, newValue: any) => {
                if (newValue) {
                  setCity(newValue);
                  handleCityChange(country?.name ?? "", state.name, newValue);
                }
              }}
              autoFocus
            />
          </Stack>
        )}
        <Stack
          sx={{ marginTop: "48px !important" }}
          direction="row"
          justifyContent={!onRemoved ? "end" : "space-between"}
          alignItems="center"
        >
          {onRemoved && (
            <PlainButton variant="plain" color="primary" onClick={onRemoved}>
              Remove criteria
            </PlainButton>
          )}
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <PlainButton variant="plain" onClick={onCancel}>
              Cancel
            </PlainButton>
            <SolidButton variant="solid" onClick={() => onSaved(selectList)}>
              {isUpdate ? "Save" : "Next"}
            </SolidButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default LocationField;
