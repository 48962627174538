import {
    Box,
    Modal,
    ModalDialog,
    Stack,
    Typography
} from "@mui/joy";
import { plan01 } from "../../../constants/AppImages";
import { SolidButton } from "../../button/CustomButton";

interface Props {
  displayName: string;
  open: boolean;
  onClose: () => void;
}

function ActivatedPlanDialog(props: Props) {
  const { open, onClose, displayName } = props;

  const getTextWhatYouGet = () => {
    return [
      "Unlimited criteria to segment users",
      "Add your own screening questions",
      "No limit on direct messages to users",
      "Set your own rewards to users",
      "Save 20% per transaction",
    ];
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog size="lg">
        <Stack direction="column" spacing={4}>
          <Stack direction={"column"} alignItems="center">
            <img src={plan01} alt="trial ended plan" width={83} />
            <Typography level="h3" fontWeight={700}>
              Premium Plan
            </Typography>
            <Typography level="h3" fontWeight={400}>
              activated for 7 days
            </Typography>
          </Stack>
          <Typography level="body-lg">
            {displayName}, as a welcome gift please enjoy 7 days of premium on
            us!
          </Typography>
          <Stack direction={"column"}>
            <Typography level="title-lg" fontWeight={700}>
              What you get
            </Typography>
            <Box pl={3}>
              {getTextWhatYouGet().map((item) => (
                <Typography
                  key={item}
                  level="body-lg"
                  sx={{ display: "list-item" }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Stack>
          <Stack direction={"row"} justifyContent="end" spacing={2}>
            <SolidButton variant="solid" fullWidth onClick={onClose}>
              Let’s go
            </SolidButton>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default ActivatedPlanDialog;
