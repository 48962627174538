import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
    Box,
    FormControl,
    Input,
    Option,
    Select,
    Stack,
    Textarea,
    Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import SelectCommunitiesDialog from "../../../components/dialog/schedules/SelectCommunitiesDialog";
import QuestionSurveyDialog from "../../../components/dialog/survey/QuestionSurveyDialog";
import { StorageType } from "../../../constants/enum";
import {
    CreateSurveyParams,
    QuestionSurvey,
} from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props {}

function CreateSurvey(props: Props) {
  const {} = props;
  const { state } = useLocation();
  const { showMessage } = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    communities: false,
    questions: false,
  });
  const [errors, setErrors] = useState({
    name: false,
    expectResponse: false,
    description: false,
    communityId: false,
    questions: false,
    pastUsers: false,
  });
  const [data, setData] = useState<CreateSurveyParams>({
    name: "",
    expectResponse: "",
    description: "",
    communityId: [],
    questions: [],
    pastUsers: null,
  });

  const checkEmptyField = () => {
    let contained = false;
    if (!data.name) {
      errors.name = true;
      contained = true;
    }
    if (data.communityId.length === 0) {
      errors.communityId = true;
      contained = true;
    }
    if (!data.description) {
      errors.description = true;
      contained = true;
    }
    if (data.questions.length === 0) {
      errors.questions = true;
      contained = true;
    }
    if (!data.expectResponse || Number(data.expectResponse) === 0) {
      errors.expectResponse = true;
      contained = true;
    }
    if (data.pastUsers == null) {
      contained = true;
      errors.pastUsers = true;
    }

    setErrors(errors);

    return contained;
  };
  const handleOnClickNext = () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    localStorage.setItem(StorageType.survry, JSON.stringify(data));
    navigate(pagesName.creator.surveyPayment, { state: { survey: data } });
  };

  useEffect(() => {
    if (state?.communityId) {
      setData({ ...data, communityId: [state.communityId] });
    }
    const defaultData = localStorage.getItem(StorageType.survry);
    if (defaultData) {
      setData(JSON.parse(defaultData));
    }
  }, []);
  return (
    <Box className="home">
      <Stack direction={"column"} spacing={3}>
        <Box>
          <Typography level="h2" fontSize={28}>
            Send a survey
          </Typography>
          <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
            You can use this feature to send a survey to the community members.
          </Typography>
        </Box>
        
        <Box overflow={"auto"} maxHeight={"55vh"} sx={{ "&::-webkit-scrollbar": {display: "none"}}}>
        <Stack direction={"column"} spacing={2}>
          <Input
            size="lg"
            placeholder="Enter"
            startDecorator={
              <Typography level="title-sm">Survey name</Typography>
            }
            sx={{
              ".MuiInput-startDecorator": {
                maxWidth: "60%",
              },
            }}
            slotProps={{
              input: {
                style: {
                  textAlign: "end",
                  fontSize: 16,
                },
              },
            }}
            error={errors.name}
            value={data.name}
            onChange={(e) => {
              const { value } = e.target;
              if (value && errors.name) {
                setErrors({ ...errors, name: false });
              }
              setData({ ...data, name: value });
            }}
          />
          <Select
            size="lg"
            color={errors.communityId ? "danger" : "neutral"}
            startDecorator={
              <Typography level="title-sm">Communities</Typography>
            }
            indicator={<KeyboardArrowDownRounded fontSize="small" />}
            slotProps={{
              button: {
                style: { justifyContent: "end", fontSize: 16 },
                onClick: () => setOpen({ ...open, communities: true }),
              },
            }}
            placeholder="Select"
            listboxOpen={false}
            value={data.communityId.length > 0 ? data.communityId.length : null}
          >
            {data.communityId.length > 0 && (
              <Option value={data.communityId.length}>
                {data.communityId.length}
              </Option>
            )}
          </Select>
          
          <FormControl>
            <Select
              size="lg"
              color={errors.questions ? "danger" : "neutral"}
              startDecorator={
                
                <Box>
                  <Typography level="title-sm">Survey questions</Typography>
                  <Typography level="body-sm">
                    You can add up to 10 questions per survey.
                  </Typography>
                </Box>
              }
              
              sx={{
                ".MuiSelect-startDecorator": {
                  maxWidth: "60%",
                  paddingBlock: "8px",
                },
              }}
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              slotProps={{
                button: {
                  id: "select-button",
                  "aria-labelledby": "select-label select-button",
                  style: { justifyContent: "end", fontSize: 16 },
                  onClick: () => setOpen({ ...open, questions: true }),
                },
              }}
              placeholder="Select"
              listboxOpen={false}
              value={data.questions.length > 0 ? data.questions.length : null}
            >
              {data.questions.length > 0 && (
                <Option value={data.questions.length}>
                  {data.questions.length}
                </Option>
              )}
            </Select>
          </FormControl>
           
          <Input
            placeholder="Enter"
            type={"number"}
            error={errors.expectResponse}
            value={data.expectResponse}
            onChange={(e) => {
              const { value } = e.target;
              if (value && errors.expectResponse) {
                setErrors({ ...errors, expectResponse: false });
              }
              const re = /^[0-9\b]+$/;
              if (value === "" || re.test(value)) {
                setData({ ...data, expectResponse: value });
              }
            }}
            sx={{
              ".MuiInput-startDecorator": {
                maxWidth: "60%",
                paddingBlock: 1,
                paddingInline: "4px",
              },
            }}
            slotProps={{
              input: {
                style: {
                  textAlign: "end",
                  fontSize: 16,
                },
              },
            }}
            startDecorator={
              <Box>
                <Typography level="title-sm">Number of responses</Typography>
                <Typography level="body-sm">
                  The survey will stop when the number of responses is reached.
                </Typography>
              </Box>
            }
          />
          
          <Select
              size="lg"
              color={errors.pastUsers ? "danger" : "neutral"}
              startDecorator={
                <Typography level="title-sm">
                  Include users who've responded to your surveys in the past?
                </Typography>
              }
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              sx={{
                ".MuiSelect-startDecorator": {
                  maxWidth: "70%",
                },
              }}
              slotProps={{
                button: {
                  style: { justifyContent: "end", fontSize: 16 },
                },
              }}
              placeholder="Select"
              value={data.pastUsers}
              onChange={(e, newValue) => {
                if (newValue && errors.pastUsers) {
                  setErrors({ ...errors, pastUsers: false });
                }
                setData({ ...data, pastUsers: newValue });
              }}
            >
              <Option sx={{ fontSize: 14 }} value={true}>
                Yes
              </Option>
              <Option sx={{ fontSize: 14 }} value={false}>
                No
              </Option>
            </Select>
          <Box>
            <Typography level="body-sm" sx={{ color: "black", mb: 1 }}>
              Please provide a short description about the survey so the users
              can understand its context.
            </Typography>

            <Textarea
              size="lg"
              minRows={4}
              error={errors.description}
              value={data.description}
              onChange={(e) => {
                const { value } = e.target;
                if (value && errors.description) {
                  setErrors({ ...errors, description: false });
                }
                setData({ ...data, description: value });
              }}
            />
          </Box>
        </Stack>
        </Box>
        
        <Box
          className="box-row"
          sx={{ justifyContent: "end", gap: 2, mt: "48px !important" }}
        >
          <PlainButton
            variant="plain"
            onClick={() => {
              navigate(-1);
              localStorage.removeItem(StorageType.survry);
            }}
          >
            Cancel
          </PlainButton>
          <SolidButton variant="solid" onClick={handleOnClickNext}>
            Next
          </SolidButton>
        </Box>
      </Stack>
      {open.communities && (
        <SelectCommunitiesDialog
          open={open.communities}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.communityId) {
              setErrors({ ...errors, communityId: false });
            }
            setData({ ...data, communityId: value });
            setOpen({ ...open, communities: false });
          }}
          defaultData={data?.communityId ?? []}
        />
      )}
      {open.questions && (
        <QuestionSurveyDialog
          open={open.questions}
          onClose={(value: QuestionSurvey[] | null) => {
            if (value) {
              if (value.length > 0 && errors.questions) {
                setErrors({ ...errors, questions: false });
              }
              setData({ ...data, questions: value });
            }

            setOpen({ ...open, questions: false });
          }}
          defaultData={data?.questions ?? []}
        />
      )}
    </Box>
  );
}

export default CreateSurvey;
