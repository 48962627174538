import {
  AutocompleteOption,
  CircularProgress,
  createFilterOptions,
  Input,
  ListItemDecorator,
  Option,
  Select,
  Stack,
  Textarea,
} from "@mui/joy";
import { useEffect, useState } from "react";
import LocationField from "../../../components/criteria/LocationField";

import { Add, Close, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Autocomplete, Box, Chip, Typography } from "@mui/joy";
import industry from "../../../assets/jsons/industry.json";
import jobtitle from "../../../assets/jsons/jobtitle.json";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import CustomTextfield from "../../../components/criteria/CustomTextfield";
import SliderField from "../../../components/criteria/SliderField";
import { CriteriaType, InputType } from "../../../constants/enum";
import { Criteria, QuestionParams } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { OpenFieldName, usePlanDialog } from "../../../providers/PlanPovider";

const filter = createFilterOptions<Criteria>();

interface Props {
  criteria?: Criteria | null;
  clearCustom?: () => void;
  communityId?: string;
  onSave?: (value: Criteria) => void;
  onCancel?: () => void;
  onSaveNewCommunity?: (value: Criteria) => void;
  defaultCriteria?: Criteria[];
  goBack?: () => void;
  showHeader?: boolean;
  onSelected?: (title: string | null) => void;
  noCommunity?: boolean;
}

function AddCriteria(props: Props) {
  const {
    criteria,
    clearCustom,
    communityId,
    onSave,
    onSaveNewCommunity,
    defaultCriteria,
    goBack,
    showHeader,
    onSelected,
    noCommunity,
  } = props;
  const { profileService, showMessage, communityService } = useApi();
  const { user } = useAuth();
  const { handleShowDialog, handleFlowUpgrade } = usePlanDialog();

  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<Array<Criteria>>([]);
  const [selected, setSelected] = useState<Criteria | null>(null);
  const [answers, setAnswers] = useState<string[]>([]);
  const [custom, setCustom] = useState([
    { option: "", selected: false },
    { option: "", selected: false },
    { option: "", selected: false },
    { option: "", selected: false },
    { option: "", selected: false },
    { option: "Not applicable", selected: false },
  ]);
  const [title, setTitle] = useState("");
  const [inputType, setInputType] = useState<string>(InputType.SELECTLIST);
  const [loading, setLoading] = useState(false);

  const searchCriterias = async () => {
    const res: Array<Criteria> = await profileService.getSearchProfile(search);
    if (res === null || res.length === 0) return;
    setOptions(res);
  };

  const getCriteriaList = (criteria: Criteria) => {
    if (criteria.key === "jobtitle") {
      return jobtitle.data;
    }
    if (criteria.key === "industry") {
      return industry.data;
    }
    return criteria.keywordList;
  };

  const handleOnSave = () => {
    if (
      defaultCriteria &&
      defaultCriteria.map((e) => e.profileId).includes(selected?.profileId)
    ) {
      showMessage("Value cannot be as same as default criteria.", "warning");
      return;
    }
    if (selected?.criteriaType === "custom" && !selected.profileId) {
      createCustomQuestion();
      return;
    }
    if (answers.length === 0) {
      showMessage("Select at least one option.", "warning");
      return;
    }

    if (selected) {
      selected.answers = answers.length == 0 ? null : answers;
      if (!selected.profileId) {
        selected.title = title;
      }

      if (communityId && onSave) {
        onSave(selected);
      } else {
        if (onSaveNewCommunity) {
          onSaveNewCommunity(selected);
        }
      }
    }
  };

  const createCustomQuestion = async () => {
    if (!title) {
      showMessage("Please enter a question.", "warning");
      return;
    }
    const keywordList: string[] = [];
    const newAnswers: string[] = [];
    if (inputType === InputType.TEXTFIELD) {
      keywordList.push("optional", "required");
      newAnswers.push(...answers);
    } else {
      custom.forEach(({ option }) => {
        if (option) {
          keywordList.push(option);
        }
      });
      custom.forEach(({ option, selected }) => {
        if (selected) {
          newAnswers.push(option);
        }
      });
    }

    if (keywordList.length === 0) {
      showMessage("Please start typing the option.", "warning");
      return;
    }
    if (newAnswers.length === 0) {
      showMessage("Please select the option.", "warning");
      return;
    }
    const payload: QuestionParams = {
      title: title,
      keywordList: keywordList,
      inputType: inputType,
    };

    const response: any = await profileService.creaetCustomQuestion(payload);
    if (!response) return;

    const newCriteria: Criteria = {
      key: response.key,
      profileType: response.profileType,
      title: response.title,
      keywordList: response.keywordList,
      criteriaType: response.criteriaType,
      profileId: response._id,
      answers: newAnswers,
    };
    if (onSaveNewCommunity) {
      onSaveNewCommunity(newCriteria);
    }
    if (onSave) {
      onSave(newCriteria);
    }
  };

  const handleCustomChange = (e: any, i: number) => {
    let newArr = [...custom];
    newArr[i].option = e.target.value;
    setCustom(newArr);
  };

  const handleCustomAnswersChange = (i: number) => {
    let newArr = [...custom];
    newArr[i].selected = !newArr[i].selected;
    setCustom(newArr);
  };

  const handleShowHeader = () => {
    if (showHeader && selected) {
      return selected.profileId !== "";
    } else if (showHeader != undefined) {
      return showHeader;
    } else {
      return false;
    }
  };

  const showCancelButton = () => {
    return !noCommunity || selected;
  };
  useEffect(() => {
    searchCriterias();
    if (criteria) {
      setSelected(criteria);
    }
  }, []);

  if (loading) {
    return (
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          minHeight: 200,
          width: "100%",
        }}
      >
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  return (
    <Stack gap={4}>
      {handleShowHeader() && (
        <Box display={"flex"} flexDirection="column">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box className="primary-circle">
              <Typography level="h3" sx={{ color: "white" }}>
                1
              </Typography>
            </Box>
            <Typography level="h3">Set your user criteria</Typography>
          </Box>
          <Typography level="body-lg" mt={2}>
            To get matched with users, set the criteria that defines your user
            segment.
          </Typography>
        </Box>
      )}
      {!selected && (
        <Autocomplete
          size="lg"
          placeholder="Start typing..."
          options={options}
          inputValue={search}
          onInputChange={(event, newInputValue) => {
            setSearch(newInputValue);
          }}
          value={null}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") return;
            if (!newValue?.profileId) {
              setTitle(search);
            }
            if (newValue?.profileId === "" && user.memberPlan === "Free") {
              handleFlowUpgrade(true);
              handleShowDialog(OpenFieldName.UPGRADE_CUSTTOM, true);
              return;
            }
            if (onSelected) {
              onSelected(newValue?.title ?? "");
            }
            setSelected(newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const isExisting = options.some(
              (option) => search === option.title
            );
            
            if (search !== "" && !isExisting) {
              const newCriteria: Criteria = {
                criteriaType: "custom",
                title: "Add a new criteria",
                profileId: "",
              };
              filtered.push(newCriteria);
            }

            return filtered;
          }}
          
          getOptionLabel={(option: any) => option.title}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          renderOption={(props, option) => (
            <AutocompleteOption
              {...props}
              key={`${option.profileId}_${option.key}`} 
            >
              {!option.profileId ? (
                <ListItemDecorator>
                  <Add />
                  {option.title}
                </ListItemDecorator>
              ) : (
                option.title
              )}
            </AutocompleteOption>
          )}
          slotProps={{ input: { maxLength: 130,textWrap:"balance" } }}
        />
      )}
      {selected && !communityId && selected.profileId != "" && (
        <Typography level={communityId ? "title-lg" : "h3"}>
          {selected?.title}
        </Typography>
      )}

      {selected && !selected.profileId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxHeight: '65vh', 
            overflowY: 'auto',
            mb: 2 
          }}
        >
          {!communityId && (
            <Typography level="h3">
              Add a new criteria or screening question
            </Typography>
          )}
          {!communityId && (
            <Typography level={!communityId ? "title-lg" : "title-md"}>
              Set a screening question to further screen users, and select the
              options that you would like to accept in to the community.
            </Typography>
          )}
          <Textarea
            variant="outlined"
            size="lg"
            placeholder="Enter a question..."
            autoFocus
            minRows={3}
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            slotProps={{ textarea: { maxLength: 130 } }}
          />

          <Stack direction={"column"} spacing={2}>
            <Select
              color="primary"
              variant="plain"
              sx={{ width: 175 }}
              size="sm"
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              value={inputType}
              onChange={(e, newValue) => setInputType(newValue ?? "")}
            >
              <Option value={InputType.SELECTLIST}>Multiple Choice</Option>
              <Option value={InputType.TEXTFIELD}>Text Response</Option>
            </Select>

            {inputType === InputType.SELECTLIST &&
              custom.map((item, i) => (
                <Box key={`input-type-select-list-${i}`}>
                 
                  <Stack direction="row" spacing={4}>
                    <i
                      className="bi bi-check2-circle"
                      style={{
                        fontSize: 32,
                        cursor: "pointer",
                        color: item.selected ? "black" : "#E2E8F0",
                      }}
                      onClick={() => {
                        if (!custom[i].option) {
                          showMessage(
                            `Please enter option ${
                              i + 1
                            } before selecting the option.`,
                            "warning"
                          );

                          return;
                        }
                        handleCustomAnswersChange(i);
                      }}
                    ></i>
                    <Input
                    fullWidth
                      variant="outlined"
                      placeholder={i == 5 ? item.option : `Option ${i + 1}`}
                      size="sm"
                      readOnly={i == 5}
                      value={item.option}
                      onChange={(e) => handleCustomChange(e, i)}
                      slotProps={{input:{sx:{paddingInline:2, paddingBlock:0}}}}
                    />
                  </Stack>
                </Box>
              ))}
            {inputType === InputType.TEXTFIELD && (
              <CustomTextfield
                onChange={(value: string) => setAnswers([value])}
              />
            )}
          </Stack>
        </Box>
      )}
      {selected && selected.criteriaType === CriteriaType.location && (
        <LocationField
          isUpdate={communityId != undefined}
          onSaved={(value) => {
            selected.answers = value.length == 0 ? [] : value;
            if (communityId && onSave) {
              onSave(selected);
            } else {
              if (onSaveNewCommunity) {
                onSaveNewCommunity(selected);
              }
            }
          }}
          onCancel={() => {
            setSearch("");
            setSelected(null);
            if (onSelected) {
              onSelected(null);
            }
          }}
        />
      )}
      {selected && selected.criteriaType === CriteriaType.multipleSelect && (
        <Box>
          <Autocomplete
            multiple
            size="lg"
            placeholder="Select"
            options={getCriteriaList(selected) ?? []}
            autoFocus
            value={answers}
            onChange={(e, value) => {
              setAnswers(value);
            }}
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => (
                <Chip
                  size="sm"
                  variant="soft"
                  color="primary"
                  endDecorator={<Close fontSize="small" />}
                  {...getTagProps({ index })}
                >
                  {item}
                </Chip>
              ))
            }
          />
        </Box>
      )}
      {selected && selected.criteriaType === CriteriaType.customTextField && (
        <CustomTextfield onChange={(value: string) => setAnswers([value])} />
      )}
      {selected && selected.criteriaType === CriteriaType.range && (
        <Box sx={{ paddingX: 3, paddingY: 3 }}>
          <SliderField
            maximum={selected.maximum}
            minimum={selected.minimum}
            onChange={(value: Array<number>) => {
              setAnswers([`${value[0]}`, `${value[1]}`]);
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          py: 2,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          mt: 'auto',
        }}
      >
      {selected?.criteriaType != CriteriaType.location && (
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={{ sm: "space-between", md: "end" }}
          spacing={2}
          sx={{ mt: "16px !important" }}
        >
          {showCancelButton() && (
            <PlainButton
              variant="plain"
              color="primary"
              onClick={() => {
                if (goBack && !selected) {
                  goBack();
                  return;
                }

                setSearch("");
                setAnswers([]);
                setSelected(null);
                if (onSelected) {
                  onSelected(null);
                }
                if (clearCustom && criteria) {
                  clearCustom();
                }
              }}
            >
              Cancel
            </PlainButton>
          )}
          <SolidButton
            variant="solid"
            color={!selected ? "neutral" : "primary"}
            disabled={!selected}
            onClick={handleOnSave}
          >
            Save
            {/* {communityId ? "Save" : "Next"} */}
          </SolidButton>
        </Stack>
      )}
      </Box>

    </Stack>
  );
}

export default AddCriteria;
