import { EditSharp } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";

import { Criteria } from "../../../constants/interfaces";
import { useAuth } from "../../../providers/AuthProvider";
import { usePlanDialog } from "../../../providers/PlanPovider";
interface Props {
  criterias: Criteria[];
  onSubmit: () => void;
  onAddCriteria: (value: Criteria | null) => void;
  onEdit: (value: Criteria) => void;
  goBack?: () => void;
}

function CommunitySetting(props: Props) {
  const { user } = useAuth();
  const { showUpgradeToAddCustom } = usePlanDialog();
  const { criterias, onSubmit, onAddCriteria, onEdit, goBack } = props;
  const [customSetting, setCustomSetting] = useState<Array<Criteria>>([]);

  useEffect(() => {
    setCustomSetting(criterias);
  }, []);

  return (
    <Box
      className="home"
      sx={{ height: "calc(100vh - 64px)", overflow: "auto" }}
    >
      <Stack direction={"column"} gap={2}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box className="primary-circle">
            <Typography level="h3" sx={{ color: "white" }}>
              1
            </Typography>
          </Box>
          <Typography level="h3">Set your user criteria</Typography>
        </Box>
        <Typography level="body-lg">
          To get matched with users, set the criteria that defines your user
          segment.
        </Typography>
      </Stack>
      <Stack mt={4}>
        <Stack
          direction={"column"}
          spacing={2}
          bgcolor="#EFE7FA"
          sx={{ borderRadius: "20px", p: 4 }}
        >
          {customSetting.map((criteria) => (
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack direction={"column"}>
                <Typography level="title-lg" textTransform="uppercase">
                  {criteria.answers?.join(", ")}
                </Typography>
                <Typography level="body-md" textTransform="uppercase">
                  {criteria.title}
                </Typography>
              </Stack>
              <Box sx={{ cursor: "pointer" }} onClick={() => onEdit(criteria)}>
                <EditSharp fontSize="small" />
              </Box>
            </Stack>
          ))}
          <Button
            variant="outlined"
            onClick={() => onAddCriteria(null)}
            sx={{
              ":hover": {
                backgroundColor: (theme) => theme.palette.primary[500],
                color: "#FFF",
                borderColor: "transparent",
              },
            }}
          >
            Add another criteria
          </Button>

          <PlainButton
            variant="plain"
            onClick={() => {
              if (user.memberPlan === "Free") {
                console.log("free");
                showUpgradeToAddCustom();
                return;
              }
              onAddCriteria({ criteriaType: "custom", profileId: "" });
            }}
            sx={{
              color: (theme) => theme.palette.primary.darkChannel,
              fontWeight: 400,
              ":hover": {
                border: (theme) => `1px solid ${theme.palette.primary[500]}`,
              },
            }}
          >
            Add a screening question
          </PlainButton>
        </Stack>

        <Box
          display={"flex"}
          flexDirection="row-reverse"
          gap={2}
          sx={{ mt: "48px !important" }}
        >
          <SolidButton variant="solid" onClick={() => onSubmit()}>
            Next
          </SolidButton>
          <PlainButton
            variant="plain"
            onClick={() => {
              if (!goBack) return;
              goBack();
            }}
          >
            Cancel
          </PlainButton>
        </Box>
      </Stack>
    </Box>
  );
}

export default CommunitySetting;
