import { Public } from "@mui/icons-material";
import { CssVarsProvider, THEME_ID, Typography } from "@mui/joy";
import Stack from "@mui/material/Stack";
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider
} from "@mui/material/styles";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UseDateFieldProps } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    BaseSingleInputFieldProps,
    DateValidationError,
    FieldSection,
} from "@mui/x-date-pickers/models";
import dayjs, { Dayjs } from "dayjs";

import moment from 'moment-timezone';
import momenttz from 'moment-timezone';
import * as React from "react";
import theme from "./joy-styles";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function BuildSlotField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    value,
    InputProps: { ref } = {},
  } = props;
  const split = label ? `${label}`.split(",") : null;

  let slot;
  let timezone;
  if (split) {
    slot = split[0];
    timezone = split[1] ? Number(split[1]) : null;
  }

  return (
    <Stack
      id={id}
      ref={ref}
      onClick={() => setOpen?.((prev) => !prev)}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={1}
      sx={{
        paddingInline: 2,
        border: (theme) => `1px solid black`,
        borderRadius: 2,
      }}
    >
      <Typography>{slot === "0"? "Date and time": `Slot ${slot}`}</Typography>
      <Stack
        direction={"column"}
        spacing={1}
        alignItems={"end"}
        sx={{ paddingBlock: 2 }}
      >
        <Typography
          level="body-md"
          sx={{ color: (theme) => theme.palette.primary[500] }}
        >
          {!value ? "" : value.format("lll")}
        </Typography>
        {timezone && value && (
          <Stack direction={"row"} gap={1} alignItems="center">
            <Public fontSize="small" />
            <Typography level="body-sm" sx={{ fontWeight: 400 }}>
              Their time:{" "}
              <Typography level="title-sm">
                {moment(
                  convertLocalToTimeZone(value.toISOString(), timezone)
                ).format("lll")}
              </Typography>
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function convertLocalToTimeZone(
  localDateTime: string,
  targetTimeZoneOffset: number
) {
  const localDate = new Date();
  const localOffset = localDate.getTimezoneOffset() * 60 * 1000;
  const targetOffset = targetTimeZoneOffset * 60 * 1000;
  const adjustedDate =
    new Date(localDateTime).getTime() + (targetOffset + localOffset);
  return adjustedDate;
}

function ButtonDatePicker(
  props: Omit<
    DateTimePickerProps<Dayjs>,
    "open" | "onOpen" | "onClose" | "timezone" | "slot"
  >,
  timezone: number
) {
  const [open, setOpen] = React.useState(false);
  const offset = timezone;
  const timezones = momenttz.tz.names();
  const newTimezone = timezones.find(tz => momenttz.tz(tz).utcOffset() === offset);

  const newProps = {
    ...props,
    timezone: newTimezone,
  };
  return (
    <DateTimePicker
      format="lll"
      minDateTime={dayjs(new Date())}
      slots={{ field: BuildSlotField, ...props.slots }}
      slotProps={{
        field: { setOpen } as any,
      }}
      {...newProps}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

interface PickerWithButtonFieldProps {
  timezone?: number;
  slot?: number;
  value: Dayjs | null;
  defaultValue: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
}

export default function PickerWithButtonField(
  props: PickerWithButtonFieldProps
) {
  return (
    <MaterialCssVarsProvider>
      <CssVarsProvider theme={{ [THEME_ID]: theme }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ButtonDatePicker
            label={[props.slot, props.timezone].join(",")}
            timezone={ props.timezone}
            {...props}
          />
        </LocalizationProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
