import { Close, EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import { ProfileParams } from "../../../constants/interfaces";

import {
    Autocomplete,
    Chip,
    FormControl,
    FormLabel,
    Stack,
    Typography
} from "@mui/joy";
import industry from "../../../assets/jsons/industry.json";
import jobTitle from "../../../assets/jsons/jobtitle.json";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import { useApi } from "../../../providers/ApiProvider";

interface Props { }
const keyRequired = [
  "employment",
  "stage",
  "industry",
  "companysize",
  "jobtitle",
];
function LightsterAboutProfile(props: Props) {
  const { } = props;
  const navigate = useNavigate();
  const { profileService, handleToggle, showMessage } = useApi();

  const [errors, setErrors] = useState({
    employment: false,
    industry: false,
    jobtitle: false,
  });

  const [profile, setProfile] = useState(Array<ProfileParams>);

  const handleOnChange = (field: string, value: any) => {
    const updatedObject = profile.map((p: ProfileParams) => {
      if (p.key === field) {
        const data: ProfileParams = {
          profileId: p.profileId,
          key: p.key,
          answers: Array.isArray(value) ? value : [value] ?? [],
          keywordList: p.keywordList,
        };
        return data;
      } else {
        return p;
      }
    });

    setProfile(updatedObject);
  };

  const getYourProfile = async () => {
    const res = await profileService.getYourProfile();
    if (!res) return;
    const list: Array<ProfileParams> = [];

    res.map((p: any) => {
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: p.answers,
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile(list);
  };

  const getKeywordList = (key: string) => {
    const data = profile.find((f: ProfileParams) => f.key === key);
    return data?.keywordList ?? [];
  };

  const getProfileField = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data;
  };

  const textEmpty = (value: any) => {
    return ["", null, undefined].includes(value);
  };
  const handleError = async () => {
    const newErrors: any = {};
    for (let index = 0; index < profile.length; index++) {
      if (
        textEmpty(profile[index].answers[0]) &&
        !["stage", "companysize"].includes(profile[index].key ?? "")
      ) {
        newErrors[`${profile[index].key}`] = true;
      }
    }
    setErrors((pre: any) => newErrors);
    return newErrors;
  };

  const getLightsterParams = () => {
    let profiles: Array<any> = [];

    profile.map((pro) => {
      if (pro.answers.length > 0) {
        profiles.push({
          profileId: pro.profileId,
          answers: pro.answers,
        });
      }
    });

    return { profiles: profiles };
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const contained = await handleError();

    if (Object.values(contained).includes(true)) {
      showMessage("Please complete the information.", "error");
      return;
    }

    handleToggle(true);
    const res = await profileService.updateLightProfile(getLightsterParams());
    handleToggle(false);
    if (!res) return;
    navigate(pagesName.lightster.yourInterests);
  };
  useEffect(() => {
    getYourProfile();
  }, []);

  return (
    <BackgroundRegister>
      <form noValidate onSubmit={handleSubmit}>
        <Stack direction={"column"} mt={"10vh"}>
          <BorderLinearProgress determinate value={80} sx={{ mb: 11 }} />
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                What do you do?
              </Typography>
              <Typography level="title-md" mt={1}>
                This helps Creators who are looking for people in specific
                occupations find you.
              </Typography>
            </Box>
            <FormControl>
              <FormLabel>Employment</FormLabel>
              <Autocomplete
                autoFocus
                multiple
                error={errors.employment}
                value={getProfileField("employment")?.answers ?? []}
                onChange={(_, value: string[]) => {
                  if (errors.employment && value) {
                    setErrors({ ...errors, employment: false });
                  }
                  handleOnChange("employment", value);
                }}
                options={getKeywordList("employment") ?? []}
                renderTags={(tags) =>
                  tags.map((item) => (
                    <Chip
                      variant="soft"
                      color="primary"
                      endDecorator={<Close fontSize="small" />}
                    >
                      {item}
                    </Chip>
                  ))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Stage</FormLabel>
              <Autocomplete
                value={getProfileField("stage")?.answers[0] ?? ""}
                onChange={(_, value: any) => handleOnChange("stage", value)}
                options={getKeywordList("stage") ?? []}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Industry</FormLabel>
              <Autocomplete
                error={errors.industry}
                value={getProfileField("industry")?.answers[0] ?? ""}
                onChange={(_, value: any) => {
                  if (errors.industry && value) {
                    setErrors({ ...errors, industry: false });
                  }
                  handleOnChange("industry", value);
                }}
                options={industry.data}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Job Title</FormLabel>
              <Autocomplete
                error={errors.jobtitle}
                value={getProfileField("jobtitle")?.answers[0] ?? ""}
                onChange={(_, value: any) => {
                  if (errors.jobtitle && value) {
                    setErrors({ ...errors, jobtitle: false });
                  }
                  handleOnChange("jobtitle", value);
                }}
                options={jobTitle.data}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Company Size</FormLabel>
              <Autocomplete
                value={getProfileField("companysize")?.answers[0] ?? ""}
                onChange={(_, value: any) =>
                  handleOnChange("companysize", value)
                }
                options={getKeywordList("companysize")}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={6}
            mb={6}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp />}
              sx={{ minWidth: 128 }}
              onClick={handleSubmit}
              type="submit"
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </form>
    </BackgroundRegister>
  );
}

export default LightsterAboutProfile;
