import { extendTheme } from "@mui/joy/styles";
import "@fontsource/poppins";
import { red } from "@mui/material/colors";
declare module "@mui/joy/styles" {
  // No custom tokens found, you can skip the theme augmentation.
}

export const primaryPurple = "#7730E1";
export const primaryPurpleDark = "#4700B2";
export const primaryPurpleHover = "#F1E4FF";
export const redHoverEndSession= red[300];
const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          "50": "#f0e7fb",
          "100": "#d8c5f6",
          "200": "#be9ef1",
          "300": "#a374ec",
          "400": "#8d53e7",
          "500": "#7730e1",
          "600": "#6c2bdb",
          "700": "#5c21d2",
          "800": "#4d1acc",
          "900": "#2f03c2",
          darkChannel: "#4700B2",
          lightChannel: "#F1E4FF",
        },
        neutral: {
          "50": "#f8fafc",
          "100": "#f1f5f9",
          "200": "#e2e8f0",
          "300": "#cbd5e1",
          "400": "#94a3b8",
          "500": "#64748b",
          "600": "#475569",
          "700": "#334155",
          "800": "#1e293b",
          "900": "#0f172a",
        },
        danger: {
          "50": "#fef2f2",
          "100": "#fee2e2",
          "200": "#fecaca",
          "300": "#fca5a5",
          "400": "#f87171",
          "500": "#ef4444",
          "600": "#dc2626",
          "700": "#b91c1c",
          "800": "#991b1b",
          "900": "#7f1d1d",
        },
        success: {
          "50": "#f0fdf4",
          "100": "#dcfce7",
          "200": "#bbf7d0",
          "300": "#86efac",
          "400": "#4ade80",
          "500": "#22c55e",
          "600": "#16a34a",
          "700": "#15803d",
          "800": "#166534",
          "900": "#14532d",
        },
        warning: {
          "50": "#fefce8",
          "100": "#fef9c3",
          "200": "#fef08a",
          "300": "#fde047",
          "400": "#facc15",
          "500": "#eab308",
          "600": "#ca8a04",
          "700": "#a16207",
          "800": "#854d0e",
          "900": "#713f12",
        },
      },
    },
  },
  components: {
    JoyTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          color: "black",
        },
      },
    },
    JoyButton: {
      defaultProps: {
        variant: "solid",
        color: "primary",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ":hover": {
            background: "#F1E4FF",
            color: theme.palette.primary.darkChannel,
          },
          borderRadius: 30,
          fontWeight: 600,
          padding: "0px 32px",
          minHeight: 46,
        }),
      },
    },



    JoyListItemButton: {
      defaultProps: {
        //variant: "solid",
        //color: "primary","#f9f9f9"
      },
      styleOverrides: {
        root: ({theme }) => ({
          ':hover': {
          //backgroundColor: 'white',
          background:'transparent !important',
          //color:'white',
            //color: theme.palette.primary.darkChannel,
          },
          ':active':{
            //backgroundColor: 'white',
            background:'transparent',
            //color:'white',
          }

          //borderRadius: 30,
          //fontWeight: 600,
          //padding: "0px 32px",
          //minHeight: 46,
        }),
      },
    },

    JoyInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: "white",
          borderRadius: 30,
          fontSize: 16,
          ":hover": {
            background: "white",
            //color: primaryPurple,
            border: `1px solid ${primaryPurple}`,
          },
          ".Mui-error": {
            border: `1px solid red`,
          },
          "--joy-palette-neutral-outlinedBorder": "black",
        }),
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: {
          background: "white",
          fontSize: 16,
          borderRadius: 30,
          ":hover": {
            background: "white",
            //color: primaryPurple,
            border: `1px solid ${primaryPurple}`,
          },
          "--joy-palette-neutral-outlinedBorder": "black",
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        root: {
          background: "white",
          borderRadius: 30,
          fontSize: 16,
          ":hover": {
            background: "white",
            //color: primaryPurple,
            border: `1px solid ${primaryPurple}`,
          },
          "--joy-palette-neutral-outlinedBorder": "black",
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          background: "white",
          borderRadius: 12,
          ":hover": {
            background: "white",
            //color: primaryPurple,
            border: `1px solid ${primaryPurple}`,
          },
          "--joy-palette-neutral-outlinedBorder": "black",
        },
      },
    },
    JoyFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
          margin: "0px 0px 8px",
          color: "black",
        },
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: {
          width: "40vw",
          top: "10vh",
          left: "50%",
          transform: "translateX(-50%)",
          padding: 32,
          "--ModalClose-inset": "24px",
          overflow: "auto",
        },
      },
    },
    JoyModalClose: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "white",
            //color: primaryPurpleDark,
          },
        },
      },
    },
  },
});

export default theme;
