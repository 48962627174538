import AgoraRTC, {
    CameraVideoTrackInitConfig,
    MicrophoneAudioTrackInitConfig,
} from "agora-rtc-sdk-ng";
import { StorageType } from "../../constants/enum";

export const createDeviceTracks = async () => {
  const audios = await AgoraRTC.getMicrophones();
  const videos = await AgoraRTC.getCameras();
  const input = localStorage.getItem(StorageType.audio);
  const videoinput: string | null = localStorage.getItem(StorageType.video);

  let audioTrack, videoTrack;
  if (audios.length > 0) {
    const contained = audios.find((f) => f.deviceId === input);
    const audioConfig: MicrophoneAudioTrackInitConfig = {
      microphoneId: contained?.deviceId,
    };
    audioTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);
  } else {
    const audioConfig: MicrophoneAudioTrackInitConfig = {
      microphoneId: audios[0]?.deviceId,
    };
    localStorage.setItem(StorageType.audio, audios[0].deviceId);
    audioTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);
  }

  if (videos.length !== 0) {
    const contained = videos.find((f) => f.deviceId === videoinput);
    if (contained) {
      const config: CameraVideoTrackInitConfig = {
        cameraId: contained?.deviceId,
      };
      videoTrack = await AgoraRTC.createCameraVideoTrack(config);
    } else {
      const config: CameraVideoTrackInitConfig = {
        cameraId: videos[0]?.deviceId,
      };
      localStorage.setItem(StorageType.video, videos[0].deviceId);
      videoTrack = await AgoraRTC.createCameraVideoTrack(config);
    }
  }

  return [audioTrack, videoTrack];
};

export const input: any = localStorage.getItem(StorageType.audio);
export const videoinput: any = localStorage.getItem(StorageType.video);
export const output: any = localStorage.getItem(StorageType.speaker);
