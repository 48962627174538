import { Typography } from "@mui/joy";
import { AlertColor, Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import AlertMessage, { useAlertMessage } from "../components/AlertMessage";
import InfoDialog from "../components/dialog/InfoDialog";
import AuthServices from "../services/AuthServices";
import CommunityServices from "../services/CommunityService";
import NotificationServices from "../services/NotificationService";
import PaymentServices from "../services/PaymentService";
import ProfileServices from "../services/ProfileService";
import SessionServices from "../services/SessionService";
import UserServices from "../services/UserService";
import QuestionServices from "../services/QuestionService";

interface ApiContextType {
  authService: AuthServices;
  profileService: ProfileServices;
  userService: UserServices;
  sessionService: SessionServices;
  communityService: CommunityServices;
  notiService: NotificationServices;
  paymentService: PaymentServices;
  questionService: QuestionServices;
  showMessage: (message: string, type: AlertColor) => void;
  handleToggle: (value: boolean) => void;
}

const ApiContext = React.createContext<ApiContextType | null>(null);

export const useApi = () => {
  return React.useContext(ApiContext) as ApiContextType;
};

interface ApiProviderProps {
  children: JSX.Element;
}
export const ApiProvider = ({ children }: ApiProviderProps) => {
  const navigate = useNavigate();
  const { messageAlert, closeMessage, showMessage } = useAlertMessage();
  const [open, setOpen] = React.useState({
    loading: false,
    noMoreSpot: false,
    notAvailable: false,
    noLonger: false,
  });

  const handleToggle = (value: boolean) => {
    setOpen({ ...open, loading: value });
  };

  const handlleUnAuthorized = () => {
    handleToggle(false);
    localStorage.clear();
    navigate("/");
  };

  const handleError = (e: any) => {
    handleToggle(false);
    if (e?.message === "Not available") {
      setOpen({ ...open, notAvailable: true });
    } else if (e?.message === "No more spots") {
      setOpen({ ...open, noMoreSpot: true });
    } else if (e?.message === "Slot no longer available") {
      setOpen({ ...open, noLonger: true });
    } else {
      showMessage(`${e?.message ?? e}`, "error");
    }
  };

  const authService = new AuthServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });
  const profileService = new ProfileServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });
  const userService = new UserServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });
  const sessionService = new SessionServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });
  const communityService = new CommunityServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });

  const notificationServices = new NotificationServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });

  const paymentService = new PaymentServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });

  const questionService = new QuestionServices({
    onError: handleError,
    onUnAuthorized: handlleUnAuthorized,
  });

  const value: ApiContextType = {
    authService: authService,
    profileService: profileService,
    userService: userService,
    sessionService: sessionService,
    communityService: communityService,
    notiService: notificationServices,
    paymentService: paymentService,
    questionService: questionService,
    showMessage: showMessage,
    handleToggle: handleToggle,
  };

  return (
    <ApiContext.Provider value={value}>
      {open.notAvailable && (
        <InfoDialog
          open={open.notAvailable}
          title={"Not available"}
          subtitle={
            <Typography level="body-lg">
              We’re sorry but this invitation is no longer available.
            </Typography>
          }
          textButton={"Close"}
          onClose={() => setOpen({ ...open, notAvailable: false })}
        />
      )}
      {open.noMoreSpot && (
        <InfoDialog
          open={open.noMoreSpot}
          title={"Not available"}
          subtitle={
            <Typography level="body-lg">
              We’re sorry but this invitation is no longer available.
            </Typography>
          }
          textButton={"Close"}
          onClose={() => setOpen({ ...open, noMoreSpot: false })}
        />
      )}
      {open.noLonger && (
        <InfoDialog
          open={open.noLonger}
          title={"Slot no longer available"}
          subtitle={
            <Typography level="body-lg">
              We’re sorry but this slot is no longer available. Please select
              another one.
            </Typography>
          }
          textButton={"Close"}
          onClose={() => setOpen({ ...open, noLonger: false })}
        />
      )}
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "white",
        }}
        open={open.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertMessage
        messageAlert={messageAlert}
        autoHideDuration={6000}
        onClose={closeMessage}
      />
      {children}
    </ApiContext.Provider>
  );
};
