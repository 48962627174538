import { Box, CircularProgress } from "@mui/joy";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { pagesName } from "../App";
import { RoleType, StorageType } from "../constants/enum";

export const QuickBookingRedirect = () => {
  const params = new URLSearchParams(window.location.search);
  const quickBookingId = params.get("quickBookingId");

  if (quickBookingId) {
    localStorage.setItem(StorageType.role, RoleType.lightster);
  }

  useEffect(() => {
    setTimeout(() => {
      if (!quickBookingId) {
        window.location.replace(window.location.origin);
      }
    }, 3000);
  }, []);

  if (quickBookingId) {
    return (
      <Navigate
        to={pagesName.lightster.home}
        state={{ quickBookingId: quickBookingId }}
        replace
      />
    );
  } else {
    return (
      <>
        <Box
          height={"100vh"}
          width={"100vw"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress size="md" />
        </Box>
      </>
    );
  }
};
