import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import moment from "moment/moment";
import { pagesName } from "../../App";
import { createDeviceTracks } from "../../components/video-call/Devices";
import { VideoCallLayout } from "../../components/video-call/VideoCallLayout";
import { agoraAppId, client } from "../../constants/agoraRtc";
import { RoleType } from "../../constants/enum";
import {
  ChatType,
  LocalTracks,
  OwnActionType,
  RecordType,
  Session,
} from "../../constants/interfaces";
import { primaryPurpleHover } from "../../joy-styles";
import { useApi } from "../../providers/ApiProvider";
import { useAuth } from "../../providers/AuthProvider";
import ControlsFooter from "../../components/video-call/ControlsFooter";
import ControlsHeader from "../../components/video-call/ControlsHeader";

export default function MainVideoCall() {
  let navigate = useNavigate();
  const location = useLocation();
  const { role } = useAuth();
  const { sessionService, handleToggle, showMessage } = useApi();

  const sessionId = location.state?.sessionId;

  const [session, setSession] = useState<Session>();
  const [start, setStart] = useState(false);
  const [recordingTimer, setrecordingTimer] = useState(0);
  const [counter, setCounter] = useState(0);
  const [dateStart, setDateStart] = useState<number>(0);
  const [connectionIssue, setConnectionIssue] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState<Array<IAgoraRTCRemoteUser>>(
    []
  );

  const [open, setOpen] = useState({
    end: false,
  });
  const [recording, setRecording] = useState(false);
  const [localTracks, setLocalTracks] = useState<LocalTracks>({
    audio: null,
    video: null,
    share: null,
  });
  const [trackState, setTrackState] = useState({
    video: false,
    audio: true,
    share: false,
  });
  const [shareScreenUid, setShareScreenUid] = useState();
  const [userOpenVideo, setUserOpenVideo] = useState<Array<any>>([]);
  let tracks: any = [];

  const [pin, setPin] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [joined, setJoined] = useState({ lightster: false, creator: false, guest: false });
  const [ownAction, setOwnAction] = useState(false);

  const startRecording = async (id: string) => {
    await sessionService.startRecording(id);
  };
  const stopRecording = async (id: string) => {
    await sessionService.stopRecording(id);
  };
  const handleRecording = async (value: boolean) => {
    setRecording(value);
    if (role === RoleType.creator) {
      if (value && session && !session.creator.recordId && start && ownAction) {
        startRecording(session._id);
        session.creator.recordId = "start";
        setOwnAction(false);
      }

      if (!value && session && start && ownAction) {
        stopRecording(session._id);
        session.creator.recordId = null;
        setOwnAction(false);
      }
    }
  };

  const handleUserjoined = (uid: any, sess: Session) => {
    const lightsterUID = sess.lightster.agoraUID;
    const creatorUID = sess.creator.agoraUID;
    if (uid === parseInt(lightsterUID)) {
      setJoined((pre) => {
        return { ...pre, lightster: !pre.lightster };
      });
    }
    else if (uid === parseInt(creatorUID)) {
      setJoined((pre) => {
        return { ...pre, creator: !pre.creator };
      });
    }
    else {
      setJoined((pre) => {
        return { ...pre, guest: true };
      });
    }
  };

  useEffect(() => {
    if (joined.lightster && (joined.creator || joined.guest)) {
      setStart(true);
      setDateStart(Date.now());
      counter > 0 ? setCounter(counter || 0) : setCounter(session?.duration || 0);
      if (session?.creator.recordId) {
        setRecording(true);
      }
    }
  }, [joined]);

  useEffect(() => {
    if (role === "lightster") {
      setJoined((pre) => {
        return { ...pre, lightster: !pre.lightster };
      });
    } else {
      setJoined((pre) => {
        return { ...pre, creator: !pre.creator };
      });
    }

    if (start && session) {
      const onPopState = () => {
        window.history.pushState(null, "", null);

        window.onpopstate = null;
        handleLeaveChannel();
      };
      window.addEventListener("popstate", onPopState);

      const exitConfirmation = (event: any) => {
        handleLeaveChannel();
      };
      window.addEventListener("beforeunload", exitConfirmation);

      return () => {
        window.removeEventListener("beforeunload", exitConfirmation);
        window.removeEventListener("popstate", onPopState);
      };
    }
  }, [start]);

  useEffect(() => {
    const getSession = async () => {
      if (!sessionId) return;
      const res = await sessionService.getSession(sessionId);
      if (!res) return;
      if (res.status === "completed") {
        navigate(pagesName.paymentSummary, {
          state: { sessionId: sessionId },
          replace: true,
        });
      }
      setSession(res);
    };

    if (!session) {
      getSession();
    }
  }, []);

  useEffect(() => {
    const init = async (sess: Session) => {
      handleToggle(true);

      client.on("network-quality", quality => {
        console.log("Network quality:", quality, connectionIssue);
        if (quality.uplinkNetworkQuality === 6 || quality.downlinkNetworkQuality === 6) {
          setConnectionIssue(true);
          showMessage("Connection lost. Please check your connection and refresh the browser.", "error");
        } else if (quality.uplinkNetworkQuality > 3 || quality.downlinkNetworkQuality > 3) {
          setConnectionIssue(true);
          showMessage("We’re having issues with your internet connection, please check your connection and refresh the browser.", "warning");
        } else if (connectionIssue) {
          setConnectionIssue(false);
          showMessage("You’ve been re-connected!", "success");
        }
      });
      // client.on("exception", function(evt) {
      //   console.log(evt.code, evt.msg, evt.uid);
      //   showMessage(evt.msg, "warning");
      // })

      client.on("user-joined", (user: IAgoraRTCRemoteUser) => {
        setRemoteUsers(() => client.remoteUsers);
        handleUserjoined(user?.uid, sess);
      });

      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        setRemoteUsers(() => client.remoteUsers);
        if (mediaType === "video") {
          setUserOpenVideo([...userOpenVideo, user.uid]);
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", async (user, mediaType) => {
        await client.unsubscribe(user, mediaType);
        setRemoteUsers(() => client.remoteUsers);
        if (mediaType === "video") {
          const list = userOpenVideo.filter((f) => f !== user.uid);
          setUserOpenVideo(list);
        }
      });

      tracks = await createDeviceTracks();
      setLocalTracks((pre) => {
        return { ...pre, audio: tracks[0], video: tracks[1] };
      });

      await client.join(
        agoraAppId,
        sess.refNumber,
        sess.rtcToken,
        parseInt(getUid())
      );

      if (tracks[1]) {
        await client.publish(tracks);
        tracks[1].setEnabled(false);
      } else {
        await client.publish(tracks[0]);
      }
      handleToggle(false);
    };
    if (session) {
      init(session);
    }

    return () => {
      tracks[0]?.close();
      tracks[1]?.close();
    };
  }, [session]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (start) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [start]);

  useEffect(() => {
    const checkSessionEnded = async () => {
      const res = await sessionService.checkSessionEnded(sessionId);
      if (!res) return;
      if (res.status !== "inProgress") {
        handleEndSession();
      }
      if (session) {
        if (res?.recordId) {
          session.creator.recordId = res.recordId;
          setRecording(true);
        } else {
          session.creator.recordId = null;
          setRecording(false);
        }
      }
    };

    if (!start) return;
    const interval2 = setInterval(() => {
      checkSessionEnded();
    }, 1000 * 10);
    return () => clearInterval(interval2);
  }, [start]);

  const getUid = () => {
    if (role === RoleType.lightster && session) {
      return session.lightster.agoraUID;
    }
    if (role === RoleType.creator && session) {
      return session.creator.agoraUID;
    }

    return "";
  };

  const leave = async () => {
    tracks[0]?.close();
    tracks[1]?.close();
    localTracks?.audio?.close();
    localTracks?.video?.close();
    localTracks?.share?.close();
    await client.leave();
  };

  const handleEndSession = async () => {
    handleToggle(true);
    setStart(false);
    await leave();
    const check = moment(counter * 1000).seconds();
    const totalDuration =
      check > 0
        ? moment(counter * 1000).minutes() + 1
        : moment(counter * 1000).minutes();
    await sessionService.endSession(sessionId, totalDuration ?? 0);
    handleToggle(false);
    navigate(pagesName.paymentSummary, {
      state: { sessionId: sessionId, totalDuration: totalDuration },
      replace: true,
    });
  };

  const handleLeaveChannel = async () => {
    handleToggle(true);
    await leave();
    await sessionService.leaveSession(sessionId);
    handleToggle(false);
    navigate(-1);
  };

  useEffect(() => {
    handleRecording(recording);
    const interval = setInterval(() => {
      if (recording) {
        setrecordingTimer((prevCounter) => prevCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [recording]);


  const chat: ChatType = {
    openChat: openChat,
    setOpenChat: setOpenChat,
  };

  const record: RecordType = {
    recording: recording,
    setRecording: setRecording,
  };
  const ownaction: OwnActionType = {
    ownAction: ownAction,
    setOwnAction: setOwnAction,
  };

  const getHomePath = () => {
    return role === RoleType.lightster
      ? pagesName.lightster.home
      : pagesName.creator.home;
  };
  if (!sessionId) {
    return <Navigate to={getHomePath()} replace />;
  }

  if (!session) {
    return <></>;
  }

  return (
    <div>
      <Grid container height={"100vh"}
        sx={{
          overflow: 'auto',
          scrollbarWidth: 'none', /* Firefox */
          '&::-webkit-scrollbar': {
            display: 'none', /* Chrome, Safari, Opera */
          },
        }} >
        {recording && (
          <Box
            width="100%"
            bgcolor={"#48BB78"}
            display="flex"
            justifyContent={"center"}
            maxHeight={30}
            alignItems="center"
          >
            <Typography sx={{ color: "white", fontWidth: 600 }}>
              This session is being recorded.
            </Typography>
          </Box>
        )}
        <DialogEndSession
          open={open.end}
          setOpen={(value) => setOpen({ ...open, end: value })}
          onConfirm={() => handleEndSession()}
        />
        <Grid xs={12} pl={2} pr={2} pt={1} alignSelf="center" >
          <ControlsHeader
            start={start}
            session={session}
            guest={false}
            questLightster={false}
            counter={counter}
            onClickEnd={() => {
              if (start) {
                setOpen({ ...open, end: true });
              } else {
                handleLeaveChannel();
              }
            }}
            showTimer={start}
          />
        </Grid>
        <Grid xs={12} height={"85vh"} >
          <Grid xs={12} height="100%" sx={{ borderRadius: "40%", }}>
            <VideoCallLayout
              start={start}
              pin={pin}
              setPin={setPin}
              localTracks={localTracks}
              remoteUsers={remoteUsers}
              session={session}
              guest={false}
              questLightster={false}
              shareScreenUid={shareScreenUid}
              chat={chat} />
          </Grid>
        </Grid>
        <Grid xs={12} pl={2} pr={2} alignItems="center">
          <ControlsFooter
            start={start}
            tracks={localTracks}
            setTracks={setLocalTracks}
            trackState={trackState}
            setTrackState={setTrackState}
            session={session}
            guest={false}
            questLightster={false}
            recordingTimer={recordingTimer}
            setShareScreenUid={setShareScreenUid}
            record={record}
            ownAction={ownaction}
            chat={chat}
          />
        </Grid>
      </Grid>
    </div>
  );
}

interface DialogEndSessionProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
}
function DialogEndSession({ open, setOpen, onConfirm }: DialogEndSessionProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        sx={{ maxWidth: 450 }}
        aria-labelledby="variant-modal-title-end-session"
        aria-describedby="variant-modal-description-end-session"
      >
        <ModalClose />
        <Typography
          id="variant-modal-title-end-session"
          level="h2"
          textColor="inherit"
        >
          {"End Session"}
        </Typography>
        <Typography
          id="variant-modal-description-end-session"
          textColor="inherit"
        >
          Are you sure you want to end this session? You will not be able to
          rejoin and a new invitation will have to be sent.
        </Typography>

        <Stack direction={"row"} gap={2} mt={2}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="neutral"
            fullWidth
            sx={{
              borderRadius: 6,
              border: "1px solid black",
              ":hover": {
                border: `1px solid ${primaryPurpleHover}`,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            autoFocus
            variant="solid"
            color="neutral"
            fullWidth
            sx={{
              borderRadius: 6,
              border: "1px solid black",
              bgcolor: "black",
              ":hover": {
                border: `1px solid ${primaryPurpleHover}`,
              },
            }}
          >
            OK
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
