import { useEffect, useState } from "react";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";

import { EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Typography,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import placeholder from "../../../assets/images/placeholder.png";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import {
    ProfileParams,
    UploadImageParams,
} from "../../../constants/interfaces";
import { primaryPurple } from "../../../mui-styles";
import { useApi } from "../../../providers/ApiProvider";

interface Props {}
const keyRequired = ["fullname", "lastnameinit"];
function CreatorAboutYou(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const { userService, profileService, handleToggle, showMessage } = useApi();
  const [profile, setProfile] = useState(Array<ProfileParams>);
  const [error, setError] = useState(false);
  const [imageUrl, setImageUrl] = useState<any | null>(null);
  const [imageFile, setImageFile] = useState<Blob>();

  const handleImageChange = (event: any) => {
    setImageUrl(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };
  const getBase64 = (fileImage: Blob, callback: (value: any) => void) => {
    const file = fileImage;
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file as Blob);
  };

  const updateImageProfile = async () => {
    getBase64(imageFile as Blob, async (value: any) => {
      const params: UploadImageParams = {
        base64: `${value}`,
        type: `${imageFile?.type}`,
      };
      await userService.uploadImageProfile(params);
    });
  };
  const getProfileField = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data;
  };

  const handleOnChange = (field: string, value: any) => {
    const updatedObject = profile.map((p: ProfileParams) => {
      if (p.key === field) {
        const data: ProfileParams = {
          profileId: p.profileId,
          key: p.key,
          answers: value ? [value] : [],
          keywordList: p.keywordList,
        };
        return data;
      } else {
        return p;
      }
    });

    setProfile(updatedObject);
  };

  const getCreatorParams = () => {
    let profiles: Array<any> = [];
    (profile as Array<ProfileParams>).map((pro) => {
      profiles.push({
        profileId: pro.profileId,
        answers: pro.answers,
      });
    });
    return { profiles: profiles };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!getProfileField("fullname")?.answers[0]) {
      setError(true);
      showMessage("Please enter your first and last name.", "error");
      return;
    }
    handleToggle(true);
    if (imageFile) {
      updateImageProfile();
    }

    const res = await profileService.updateCreatotProfile(getCreatorParams());
    if (!res) return;
    handleToggle(false);
    navigate(pagesName.creator.aboutProfile);
  };

  const getAboutYou = async () => {
    const res = await profileService.getAboutYou();
    if (!res) return;

    const list: Array<ProfileParams> = [];
    res.map((p: any) => {
      if (p.key === "picture url" && p.answers.length > 0) {
        setImageUrl(p.answers);
      }
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: p.answers,
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile(list);
  };
  useEffect(() => {
    getAboutYou();

    const handleBeforeUnload = (event:any) => {
      localStorage.clear();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <BackgroundRegister>
      <form noValidate onSubmit={handleSubmit}>
        <Stack direction={"column"} mt={"10vh"}>
          <BorderLinearProgress determinate value={60} sx={{ mb: 11 }} />
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                Tell us a bit about yourself
              </Typography>
              <Typography level="title-md" mt={1}>
                This is how you will show up on the platform when you connect
                with users.
              </Typography>
            </Box>

            <FormControl>
              <FormLabel>Upload a photo of yourself</FormLabel>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    height: 62,
                    width: 62,
                    borderRadius: "50px",
                  }}
                >
                  <img
                    src={imageUrl ?? placeholder}
                    style={{
                      height: 62,
                      width: 62,
                      borderRadius: "50px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="plain"
                    color="primary"
                    component="span"
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}
                  >
                    Choose file
                  </Button>
                </label>
              </Stack>
            </FormControl>

            <FormControl>
              <FormLabel>Your first and last name</FormLabel>
              <Input
                fullWidth
                error={error}
                value={getProfileField("fullname")?.answers[0]}
                onChange={(e) => {
                  if (error && e.target.value) {
                    setError(false);
                  }
                  handleOnChange("fullname", e.target.value);
                }}
              />
            </FormControl>

            <Stack direction={"row"} spacing={1} alignItems="center">
              <i
                className="bi bi-check2-circle"
                style={{
                  fontSize: 24,
                  cursor: "pointer",
                  color:
                    getProfileField("lastnameinit")?.answers[0] === "true"
                      ? primaryPurple
                      : "grey",
                }}
                onClick={() => {
                  const data =
                    getProfileField("lastnameinit")?.answers[0] === "true";

                  handleOnChange("lastnameinit", `${!data}`);
                }}
              ></i>
              <Typography
                level="title-sm"
                sx={{
                  color:
                    getProfileField("lastnameinit")?.answers[0] === "true"
                      ? primaryPurple
                      : "grey",
                }}
              >
                Only display my last name initial
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={10}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp fontSize="small" />}
              sx={{ minWidth: 128 }}
              onClick={handleSubmit}
              autoFocus
              type="submit"
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </form>
    </BackgroundRegister>
  );
}

export default CreatorAboutYou;
