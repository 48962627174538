import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalDialog,
    Typography
} from "@mui/joy";

import { useEffect, useState } from "react";
import { PlainButton, SolidButton } from "../button/CustomButton";
interface Props {
  value: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}
export const RenameCommunityDialog = ({
  value,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const [name, setName] = useState("");

  useEffect(() => {
    setName(value);
  }, [value]);
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="layout-modal-title-reanme"
        aria-describedby="layout-modal-description-reanme"
        size="lg"
        sx={{
          width: {
            xs: '480px',
            sm: '480px',
            md: '480px',
            lg: '640px',
            xl: '640px'
          },          //maxHeight: '120vh',
          top: '5vh',
          //height: '89vh'

        }}
      >
        <Typography
          id="layout-modal-title-reanme"
          level="h2"
          sx={{ fontSize: 24 }}
        >
          Rename this community
        </Typography>
        <FormControl sx={{ mt: 1 }}>
          <FormLabel>Community name</FormLabel>
          <Input
            placeholder="Type in here…"
            variant="outlined"
            color="primary"
            autoFocus
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>

        <Box
          sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 5.25 }}
        >
          <PlainButton variant="plain" color="primary" onClick={onClose}>
            Cancel
          </PlainButton>
          <SolidButton
            variant="solid"
            color="primary"
            onClick={() => onSubmit(name)}
          >
            Save
          </SolidButton>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
