import React, { useState } from "react";
import { useApi } from "./ApiProvider";
import { useAuth } from "./AuthProvider";
interface Badge{
    schedule: number;
    question: number;
    community: number;
}
interface NotificationContextType {
    badge: Badge;
    refresh: ()=>void;
    getNotification: ()=>void;
}

const NotificationContext = React.createContext<NotificationContextType | null>(
  null
);

export const useNotification = () => {
  return React.useContext(NotificationContext) as NotificationContextType;
};

interface NotificationProviderProps {
  children: JSX.Element;
}
export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const { notiService } = useApi();
  const { role } = useAuth();
  const [badge, setBadge] = useState({
    schedule: 0,
    question: 0,
    community: 0,
  });

  const getNotification = async () => {
    const res = await notiService.gteNumberNotification({ role: role });
    if(!res) return;
    setBadge(res);
  };

  const refresh = () => {
    getNotification();
  };

  const value: NotificationContextType = {
    badge: badge,
    refresh: refresh,
    getNotification: getNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
