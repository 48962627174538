import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { Box, Stack } from "@mui/system";
import { PlainButton, SolidButton } from "../../button/CustomButton";
import { Booking } from "../../../constants/interfaces";

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  book: Booking;
  onSelected: () => void;
  nonUser?: boolean;
}

function DetailBeforeGetSpot(props: Props) {
  const { open, book, onClose, onSelected, nonUser } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog aria-labelledby="title-plan-detail" size="lg">
        <Typography id="title-plan-detail" level="h3" sx={{ fontSize: 24 }}>
          Invitation From{" "}
          <span style={{ fontWeight: "bold", color: "#7730E1" }}>
            {book.displayname} - ${book.reward}
          </span>
        </Typography>
        { !nonUser && (<ModalClose />)}
        <Stack
          spacing={2}
          border={"1px solid #7730E1"}
          borderRadius="8px"
          sx={{ paddingBlock: 3, marginTop: "8px" }}
        >
          <Box overflow={"auto"} maxHeight={"53vh"} >
          <Stack
            direction={"column"}
            spacing={0.1}
            justifyContent="center"
            alignItems="baseline"
            paddingInline={3}
          >
            <Stack direction={"column"} >
              <Typography level="title-md" sx={{ fontSize: 20, fontWeight: "bold" }}>
                Here's what you need to know
              </Typography>
              <Stack height={"24px"}></Stack>
                <Typography level="body-md" sx={{ fontSize: 16}}>
                  You have been invited to a paid interview session, where you
                  will be asked for your opinion, feedback, or ideas on a
                  particular topic.
                </Typography>
            </Stack>
            <Stack height={"24px"}></Stack>
            <Stack direction={"row"}>
              <Typography level="title-md" sx={{ fontSize: 16, fontWeight: "bold" }}>Duration:</Typography>
              <Box pl={1}>
                <Typography level="body-md" sx={{ fontSize: 16}}>
                  {book.expectedLength} mins
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Typography level="title-md"  sx={{ fontSize: 16, fontWeight: "bold" }}>Reward:</Typography>
              <Box pl={1}>
                <Typography level="body-md" sx={{ fontSize: 16}}>${book.reward} reward</Typography>
              </Box>
            </Stack>
            <Stack direction={"row"}>
              <Typography level="title-md"  sx={{ fontSize: 16 , fontWeight: "bold"}}>Format:</Typography>
              <Box pl={1}>
                <Typography level="body-md" sx={{ fontSize: 16}}>
                  Online interview with video & audio
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"column"}>
              <Typography level="title-md"  sx={{ fontSize: 16 , fontWeight: "bold"}}>How to join</Typography>
              <Box pl={3}>
                <Typography level="body-md" sx={{ display: "list-item" ,fontSize: 16}}>
                  {book.shareScreen
                    ? "This session requires you to join on your computer by visiting https://app.lightster.co from your web browser"
                    : "You can join on your computer by visiting https://app.lightster.co from your web browser or on the Lightster mobile app"}
                </Typography>
              </Box>
            </Stack>
            <Stack height={"24px"}></Stack>
            <Stack direction={"column"}>
              <Typography level="title-md"  sx={{ fontSize: 16 , fontWeight: "bold"}}>
                Message from {book.displayname}
              </Typography>
                <Typography level="body-md" sx={{ fontSize: 16}}>“{book.topic}“</Typography>
            </Stack>
            <Stack height={"24px"}></Stack>
            <Stack direction={"column"}>
              <Typography level="title-md"  sx={{ fontSize: 16 , fontWeight: "bold"}}>Important information</Typography>
              <Box pl={3}>
                <Typography level="body-md" sx={{ display: "list-item", fontSize: 16 }}>
                  Please join at least 5 minutes before the start of your
                  selected time
                </Typography>
                <Typography level="body-md" sx={{ display: "list-item", fontSize: 16 }}>
                  We take no-shows seriously, if you do not attend the session
                  at the selected time you may not be selected for future
                  opportunities
                </Typography>
              </Box>
            </Stack>
          </Stack>
          </Box>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between" spacing={2} sx={{ marginTop: "16px"}}> 
          <PlainButton variant="plain" onClick={() => onClose(false)}>
            Decline this invitation
          </PlainButton>
          <SolidButton variant="solid" color="primary" onClick={onSelected}>
            View timeslots
          </SolidButton>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default DetailBeforeGetSpot;
