import { Box, Grid, Typography } from "@mui/joy";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { createDeviceTracks } from "../../components/video-call/Devices";
import { VideoCallLayout } from "../../components/video-call/VideoCallLayout";
import { agoraAppId, client } from "../../constants/agoraRtc";
import { LocalTracks, OwnActionType, RecordType } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import ControlsFooter from "../../components/video-call/ControlsFooter";
import ControlsHeader from "../../components/video-call/ControlsHeader";

export default function GuestVideoCall() {
  let navigate = useNavigate();
  const location = useLocation();
  const session = location.state?.session;
  const { sessionService, handleToggle, showMessage } = useApi();
  const [start, setStart] = useState(false);
  const [counter, setCounter] = useState(0);
  const [recordingTimer, setrecordingTimer] = useState(0);
  const [connectionIssue, setConnectionIssue] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState<Array<IAgoraRTCRemoteUser>>(
    []
  );

  const [localTracks, setLocalTracks] = useState<LocalTracks>({
    audio: null,
    video: null,
    share: null,
  });

  const [trackState, setTrackState] = useState({
    video: false,
    audio: true,
    share: false,
  });
  const [shareScreenUid, setShareScreenUid] = useState();
  const [userOpenVideo, setUserOpenVideo] = useState<any>([]);

  const [pin, setPin] = useState<any>(null);
  const [joined, setJoined] = useState({
    lightster: false,
    creator: false,
    guest: false,
  });
  let tracks: any = [];
  const [recording, setRecording] = useState(false);
  const [recordId, setRecordId] = useState();
  const [ownAction, setOwnAction] = useState(false);

  const handleUserjoined = (user: IAgoraRTCRemoteUser) => {
    const lightsterUID = session.lightster?.agoraUID;
    const creatorUID = session.creator?.agoraUID;
    if (user.uid === parseInt(lightsterUID)) {
      setJoined((pre) => {
        return { ...pre, lightster: !pre.lightster };
      });
    } else if (user.uid === parseInt(creatorUID)) {
      setJoined((pre) => {
        return { ...pre, creator: !pre.creator };
      });
      setPin(user);
    } else {
      setJoined((pre) => {
        return { ...pre, guest: !pre.guest };
      });
      if (!pin) {
        setPin(user);
      }
    }
  };

  useEffect(() => {
    if (joined.creator || joined.guest) {
      setStart(true);
      counter > 0 ? setCounter(counter || 0) : setCounter(session?.duration || 0);
      session.creator.recordId = recordId;
      if (session?.creator.recordId) {
        setRecording(true);
      }
    }
  }, [joined]);

  useEffect(() => {
    const init = async () => {
      handleToggle(true);

      client.on("network-quality", quality => {
        console.log("Network quality:", quality, connectionIssue);
        if (quality.uplinkNetworkQuality === 6 || quality.downlinkNetworkQuality === 6) {
          setConnectionIssue(true);
          showMessage("Connection lost. Please check your connection and refresh the browser.", "error");
        } else if (quality.uplinkNetworkQuality > 3 || quality.downlinkNetworkQuality > 3) {
          setConnectionIssue(true);
          showMessage("We’re having issues with your internet connection, please check your connection and refresh the browser.", "warning");
        } else if (connectionIssue) {
          setConnectionIssue(false);
          showMessage("You’ve been re-connected!", "success");
        }
      });
      // client.on("exception", function(evt) {
      //   console.log(evt.code, evt.msg, evt.uid);
      //   showMessage(evt.msg, "warning");
      // })

      client.on("user-joined", (user: IAgoraRTCRemoteUser) => {
        setRemoteUsers(client.remoteUsers);
        handleUserjoined(user);
      });
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        setRemoteUsers(client.remoteUsers);
        if (mediaType === "video") {
          setUserOpenVideo([...userOpenVideo, user.uid]);
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", async (user, mediaType) => {
        await client.unsubscribe(user, mediaType);
        setRemoteUsers(client.remoteUsers);
        if (mediaType === "video") {
          const list = userOpenVideo.filter((f: any) => f !== user.uid);
          setUserOpenVideo(list);
        }
      });

      tracks = await createDeviceTracks();
      setLocalTracks((pre) => {
        return { ...pre, audio: tracks[0], video: tracks[1] };
      });

      client.on("user-left", async (user) => {
        console.log("user-left", user);
        setRemoteUsers(client.remoteUsers);

        if (client.remoteUsers.length === 0) {
          tracks[0]?.close();
          tracks[1]?.close();
          await localTracks?.audio?.cloes();
          await localTracks?.video?.cloes();
          await localTracks?.share?.stop();
          await localTracks?.share?.cloes();
          await client.leave();
          navigate("/room_lightster", { replace: true });
        }
      });

      await client.join(
        agoraAppId,
        session.refNumber,
        session.rtcToken,
        parseInt(session.agoraUID)
      );

      if (tracks && tracks[1]) {
        await client.publish(tracks);
        tracks[1].setEnabled(false);
      } else {
        await client.publish(tracks[0]);
      }
      handleToggle(false);
    };

    if (session) {
      init();
    }
  }, [session]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (start) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [start]);

  useEffect(() => {
    const checkSessionEnded = async () => {
      const res = await sessionService.checkSessionEndedGuestLightster(session._id);
      if (!res) return;
      if (res.status !== "inProgress") {
        await leave();
      }
      if (res?.recordId) {
        session.creator.recordId = res.recordId;
        setRecording(true);
      }
      else {
        session.creator.recordId = null;
        setRecording(false);
      }
    };

    if (!start) return;
    const interval2 = setInterval(() => {
      checkSessionEnded();
    }, 1000 * 10);
    return () => clearInterval(interval2);
  }, [start]);

  const leave = async () => {
    handleToggle(true);
    tracks[0]?.close();
    tracks[1]?.close();
    localTracks?.audio?.close();
    localTracks?.video?.close();
    await client.leave();
    handleToggle(false);
    navigate("/room_lightster", { replace: true });
  };

  const record: RecordType = {
    recording: recording,
    setRecording: setRecording,
  };
  const ownaction: OwnActionType = {
    ownAction: ownAction,
    setOwnAction: setOwnAction,
  };

  if (!session) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <div>
        <Grid container height={"100vh"}
          sx={{
            overflow: 'auto',
            scrollbarWidth: 'none', /* Firefox */
            '&::-webkit-scrollbar': {
              display: 'none', /* Chrome, Safari, Opera */
            },
          }}>
          {recording && (
            <Box
              width="100%"
              bgcolor={"#48BB78"}
              display="flex"
              justifyContent={"center"}
              maxHeight={30}
              alignItems="center"
            >
              <Typography sx={{ color: "white", fontWidth: 600 }}>
                This session is being recorded.
              </Typography>
            </Box>
          )}

          <Grid xs={12} pl={2} pr={2} pt={1} alignSelf="center" >
            <ControlsHeader
              start={start}
              session={session}
              guest={true}
              questLightster={true}
              counter={counter}
              onClickEnd={leave}
              showTimer={start}
            />
          </Grid>
          <Grid xs={12} height={"85vh"} >
            <Grid xs={12} height="100%" sx={{ borderRadius: "40%", }}>
              <VideoCallLayout
                start={start}
                pin={pin}
                setPin={setPin}
                localTracks={localTracks}
                remoteUsers={remoteUsers}
                session={session}
                guest={true}
                questLightster={true}
                shareScreenUid={shareScreenUid}
              />
            </Grid>
          </Grid>
          <Grid xs={12} pl={2} pr={2} alignItems="center">
            <ControlsFooter
              start={start}
              tracks={localTracks}
              setTracks={setLocalTracks}
              trackState={trackState}
              setTrackState={setTrackState}
              session={session}
              guest={true}
              questLightster={true}
              recordingTimer={recordingTimer}
              setShareScreenUid={setShareScreenUid}
              record={record}
              ownAction={ownaction}
            />
          </Grid>
        </Grid>
    </div>
  );
}
