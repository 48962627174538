import {
    Avatar,
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    ListItemDecorator,
    Modal,
    ModalDialog,
    Stack,
    TabPanel,
    Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { Member } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../../button/CustomButton";

import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import { placeholder } from "../../../constants/AppImages";
interface Props {
  open: boolean;
  communityIds: string[];
  defaultMembers: Member[];
  onSave: Function;
  onClose: Function;
}

function SelectUsersDialog(props: Props) {
  const { open, communityIds, defaultMembers, onClose, onSave } = props;
  const { communityService } = useApi();
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [all, setAll] = useState<number>(0);
  const [bestMatch, setBestMatch] = useState<number>(0);
  const [favorite, setFavorite] = useState<number>(0);

  const [users, setUsers] = useState<Member[]>([]);

  const getUserIds = () => users.map((m) => m.id);

  const handleChangeUsers = (user: Member) => {
    const userIds = users.map((u) => u.id);
    if (userIds.includes(user.id)) {
      const newValue = users.filter((f) => f.id !== user.id);
      setUsers(newValue);
    } else {
      setUsers([...users, user]);
    }
  };

  const handleAllSelected = () => {
    if (selected) {
      setSelected(false);
      setUsers([]);
    } else {
      setSelected(true);

      setUsers(members);
    }
  };
  useEffect(() => {
    const getUserFromCommunities = async () => {
      setLoading(true);
      const body = { communityIds: communityIds };
      const res = await communityService.getCommunityListMembers(body);

      setTimeout(() => {
        setLoading(false);
        if (!res) return;

        setMembers(res.data);
        setAll(res.all);
        setBestMatch(res.bestMatch);
        setFavorite(res.favorite);
      }, 1000);
    };

    getUserFromCommunities();
    if (defaultMembers.length > 0) {
      setUsers(defaultMembers);
    }
  }, []);

  return (
    <Modal open={open}>
      <ModalDialog sx={{ width: { sm: "60vw", md: "40vw" } }}>
        <Stack direction={"column"} spacing={3}>
          <Stack direction={"row"} justifyContent="space-between">
            <Typography level="h2" fontSize={24}>
              Select users
            </Typography>
            <Typography
              level="body-md"
              sx={{
                color: (theme) => theme.palette.primary[500],
                cursor: "pointer",
              }}
              onClick={handleAllSelected}
            >
              {!selected ? "Select All" : "Clear All"}
            </Typography>
          </Stack>
          <Box>
            <Tabs
              aria-label="tabs"
              defaultValue={0}
              sx={{ bgcolor: "transparent" }}
            >
              <TabList
                disableUnderline
                sx={{
                  p: 0.5,
                  gap: 0.5,
                  justifyContent: "space-around",
                  borderRadius: "xl",
                  bgcolor: "background.level1",
                  [`& .${tabClasses.root}[aria-selected="true"]`]: {
                    boxShadow: "sm",
                    bgcolor: "background.surface",
                  },
                }}
              >
                <Tab disableIndicator>All ({all})</Tab>
                <Tab disableIndicator>Your Criteria ({bestMatch})</Tab>
                <Tab disableIndicator>Favourites ({favorite})</Tab>
              </TabList>
              {loading ? (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    bgcolor: "background.level1",
                    borderRadius: "xl",
                    mt: 2,
                    height: "40vh",
                    overflow: "auto",
                  }}
                >
                  <CircularProgress size="md" />
                </Stack>
              ) : (
                <Box>
                  <TabPanel
                    value={0}
                    sx={{
                      bgcolor: "background.level1",
                      borderRadius: "xl",
                      mt: 2,
                      height: "40vh",
                      overflow: "auto",
                    }}
                  >
                    <List sx={{ "--ListItemDecorator-size": "56px" }}>
                      {members.map((member) => (
                        <ListItem key={member.id}>
                          <ListItemButton
                            selected={getUserIds().includes(member.id)}
                            color="primary"
                            onClick={() => handleChangeUsers(member)}
                          >
                            <ListItemDecorator>
                              <Avatar src={member.imageProfile ?? placeholder}>
                                <img src={placeholder} width={40} />
                              </Avatar>
                            </ListItemDecorator>
                            <ListItemContent>
                              <Typography level="title-sm">
                                {member.displayName}
                              </Typography>
                              <Typography level="body-sm" noWrap>
                                {member.subDisplay}
                              </Typography>
                            </ListItemContent>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </TabPanel>
                  <TabPanel
                    value={1}
                    sx={{
                      bgcolor: "background.level1",
                      borderRadius: "xl",
                      mt: 2,
                      height: "40vh",
                      overflow: "auto",
                    }}
                  >
                    <List sx={{ "--ListItemDecorator-size": "56px" }}>
                      {members.map(
                        (member) =>
                          member.isBestMatch && (
                            <ListItem key={member.id}>
                              <ListItemButton
                                selected={getUserIds().includes(member.id)}
                                color="primary"
                                onClick={() => handleChangeUsers(member)}
                              >
                                <ListItemDecorator>
                                  <Avatar
                                    src={member.imageProfile ?? placeholder}
                                  >
                                    <img src={placeholder} width={40} />
                                  </Avatar>
                                </ListItemDecorator>
                                <ListItemContent>
                                  <Typography level="title-sm">
                                    {member.displayName}
                                  </Typography>
                                  <Typography level="body-sm" noWrap>
                                    {member.subDisplay}
                                  </Typography>
                                </ListItemContent>
                              </ListItemButton>
                            </ListItem>
                          )
                      )}
                    </List>
                  </TabPanel>
                  <TabPanel
                    value={2}
                    sx={{
                      bgcolor: "background.level1",
                      borderRadius: "xl",
                      mt: 2,
                      height: "40vh",
                      overflow: "auto",
                    }}
                  >
                    <List sx={{ "--ListItemDecorator-size": "56px" }}>
                      {members.map(
                        (member) =>
                          member.isFavorite && (
                            <ListItem key={member.id}>
                              <ListItemButton
                                selected={getUserIds().includes(member.id)}
                                color="primary"
                                onClick={() => handleChangeUsers(member)}
                              >
                                <ListItemDecorator>
                                  <Avatar
                                    src={member.imageProfile ?? placeholder}
                                  >
                                    <img src={placeholder} width={40} />
                                  </Avatar>
                                </ListItemDecorator>
                                <ListItemContent>
                                  <Typography level="title-sm">
                                    {member.displayName}
                                  </Typography>
                                  <Typography level="body-sm" noWrap>
                                    {member.subDisplay}
                                  </Typography>
                                </ListItemContent>
                              </ListItemButton>
                            </ListItem>
                          )
                      )}
                    </List>
                  </TabPanel>
                </Box>
              )}
            </Tabs>
          </Box>

          <Stack
            direction={"row"}
            justifyContent={{ sm: "space-between", md: "end" }}
            gap={2}
          >
            <PlainButton variant="plain" onClick={() => onClose()}>
              Cancel
            </PlainButton>
            <SolidButton
              variant="solid"
              color="primary"
              disabled={users.length == 0}
              onClick={() => {
                if (users.length === 0) {
                  return;
                }
                onSave(users);
              }}
              sx={{
                ":disabled": {
                  bgcolor: (theme) => theme.palette.neutral[200],
                },
              }}
            >
              Save
            </SolidButton>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default SelectUsersDialog;
