import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Dropdown,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    Typography,
} from "@mui/joy";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../App";
import { mapStatusText } from "../../constants/constants";
import { Session } from "../../constants/interfaces";
import { useApi } from "../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../button/CustomButton";
import CustomDialog from "../dialog/CustomDialog";
import { ProposeNewTimeDialog } from "../dialog/schedules/ProposeNewTimeDialog";
import DetailBeforeAccept from "../dialog/invitations/DetailBeforeAccept";

interface Props {
  session: Session;
  refresh: () => void;
}

function LightsterSessionItem(props: Props) {
  const { session, refresh } = props;
  const { sessionService, userService, showMessage, handleToggle } = useApi();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState({
    microphone: false,
    camera: false,
  });
  const [devices, setDevices] = useState({
    microphone: false,
    camera: false,
  });

  const [open, setOpen] = useState({
    proposeNewTime: false,
    decline: false,
    detail: false,
  });

  const [timezone, setTimezone] = useState<number>();
  const handleNewProposeTime =(newTime: string)=>{

    const payload = {type:'reschedule', proposedTime: newTime, expectedLength: session.request.expectedLength};
    const res = sessionService.lightsterChangeScheduld(payload, session._id);
    if(!res) return;
    showMessage('Request sent! Check the status on the Invitations tab.', 'success');
    setOpen({ ...open, proposeNewTime: false });
    refresh();

  }
  const getUserTimezone = async (userId: string) => {
    if (!userId) return;
    const res = await userService.getUserTimezone(userId);
    if (!res) return;
    setTimezone(parseInt(res.timezone));
  };


  const checkDevices = async () => {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((stream) => {
        stream.forEach((device) => {
          if (device.kind === "audioinput") {
            if (device.label) {
              setPermissions({ ...permissions, microphone: true });
            }
            setDevices({ ...devices, microphone: true });
          }
          if (device.kind === "videoinput") {
            if (device.label) {
              setPermissions({ ...permissions, camera: true });
            }
            setDevices({ ...devices, camera: true });
          }
        });
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  };

  const handleDecline = async () => {
    const res = await sessionService.declineSession(session._id);
    if (!res) {
      return;
    }
    showMessage(
      "Declined You have successfully declined the invitation.",
      "success"
    );
    refresh();
  };

  const handleAccept = async () => {
    const res = await sessionService.confirmSession(session._id);
    if (!res) {
      return;
    }
    showMessage("Invitation accepted!", "success");
    refresh();
  };

  const handleJoin = async () => {
    if (!inThirtyMinutes(session.request.proposedTime)) return;
      navigate(pagesName.requestPermissions, {
        state: { sessionId: session._id },
      });
      return;

    // handleToggle(true);
    // const res = await sessionService.startSession(session._id);
    // handleToggle(false);
    // if (!res) {
    //   return;
    // }

    // navigate(pagesName.videocall, {
    //   state: { sessionId: session._id },
    // });
  };

  const inThirtyMinutes = (dateTime: string) => {
    const olderDate = moment(new Date(dateTime)).subtract(30, "minutes");
    const isSameOrAfter = moment(Date.now()).isSameOrAfter(olderDate);

    return isSameOrAfter;
  };

  const isJoined = () => {
    return session.creator.isJoined && session.lightster.isJoined;
  };

  useEffect(() => {
    checkDevices();
    getUserTimezone(session.creator.id);
  }, []);

  const handleclickViewProfile = () => {
    window.open(
      `${pagesName.lightster.viewProfile}/${session.creator.id}`,
      "_blank"
    );
  };
  return (
    <Box className="invite-box-shadow">
      <Stack direction={"row"} spacing={2} p={2}>
        <Avatar
          sx={{ width: 56, height: 56, cursor: "pointer" }}
          src={session.creator.imageProfile}
          onClick={handleclickViewProfile}
        />
        <Box width={"100%"}>
          <Box className="box-row" sx={{ justifyContent: "space-between" }}>
            <Typography
              level="title-lg"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                width: "calc(100% - 100px)",
              }}
            >
              {session.creator.displayname}
            </Typography>
            <Typography level="title-md" textAlign="right">
              ${session.reward} reward
            </Typography>
          </Box>
          <Typography level="body-md">
            {moment(session.request.proposedTime).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="body-md">
              {session.request.expectedLength} minutes
            </Typography>
            <Dropdown>
              <MenuButton
                size="sm"
                sx={{
                  paddingInline: 0,
                  display: "inherit",
                  color: (theme) => theme.palette.primary[500],
                  ":hover": {
                    background: "none",
                  },
                }}
                endDecorator={
                  <KeyboardArrowDownRounded fontSize="medium" color="primary" />
                }
                variant="plain"
              >
                {mapStatusText(session.status)}
              </MenuButton>
              <Menu placement="bottom-end">
              {['pending', 'confirmed','changing'].includes(session.status) && <><MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => {
                    setOpen({ ...open, proposeNewTime: true });
                  }}
                >
                  Propose new time
                </MenuItem>
                <Divider />
                
                </> }
                <MenuItem
                  color="primary"
                  sx={{
                    fontSize: 14,
                    justifyContent: "center",
                    color: "black",
                    ":hover": {
                      color: (theme) => theme.palette.primary.darkChannel,
                      background: (theme) => theme.palette.primary.lightChannel,
                    },
                  }}
                  onClick={() => setOpen({...open, decline:true })}
                >
                  Decline session
                </MenuItem>
              </Menu>
            </Dropdown>
          </Stack>

          {session.status !== "pending"  && !( session.status === "changing" && session.request.requestBy == "creator" ) && !isJoined() && (
            <Box className="box-row" sx={{ gap: 1, pt: 1 }}>
              <Button
                variant="solid"
                size="sm"
                sx={(theme) => ({
                  borderRadius: 6,
                  fontSize: 12,
                  bgcolor: inThirtyMinutes(session.request.proposedTime)
                    ? "black"
                    : "#dcdcdc",
                  paddingInline: 2,
                  minHeight: 32,
                  ":hover": {
                    background: inThirtyMinutes(session.request.proposedTime)
                      ? theme.palette.primary.lightChannel
                      : "#dcdcdc",
                    color: inThirtyMinutes(session.request.proposedTime)
                      ? theme.palette.primary.darkChannel
                      : "white",
                  },
                })}
                onClick={handleJoin}
              >
                Join
              </Button>
            </Box>
          )}
          { (session.status === "pending"  || ( session.status === "changing" && session.request.requestBy == "creator" )) && (
            <Box className="box-row" sx={{ gap: 1, pt: 1 }}>
              <Button
                variant="solid"
                size="sm"
                sx={{
                  borderRadius: 6,
                  bgcolor: "black",
                  fontSize: 12,
                  paddingInline: 2,
                  minHeight: 32,
                }}
                onClick={() => {
                  setOpen({ ...open, detail: true });                  
                }}
                // onClick={handleAccept}
              >
                View
              </Button>
              <Button
                variant="plain"
                size="sm"
                color="primary"
                sx={{
                  borderRadius: 6,
                  fontSize: 12,
                  paddingInline: 2,
                  minHeight: 32,
                }}
                onClick={() => setOpen({...open, decline:true })}
              >
                Decline
              </Button>
            </Box>
          )}
          {isJoined() && (
            <Typography
              level="body-sm"
              color="success"
              sx={{ fontStyle: "italic" }}
            >
              You’ve joined on another device
            </Typography>
          )}
        </Box>
      </Stack>
      {open && (
        <CustomDialog
          open={open.decline}
          title="Are you sure you want to decline?"
          subtitle={
            <Stack direction={"column"} spacing={4}>
              <Typography level="body-lg">
                You are declining this invitation under 24 hours. You may be
                prevented from receiving further invitations.
              </Typography>

              <Stack
                direction={"row"}
                justifyContent={{ sm: "space-between", md: "end" }}
                spacing={2}
              >
                <PlainButton variant="plain" onClick={() => setOpen({...open, decline:false })}>
                  Go Back
                </PlainButton>
                <SolidButton
                  variant="solid"
                  onClick={() => {
                    setOpen({...open, decline:false })
                    handleDecline();
                  }}
                >
                  Yes
                </SolidButton>
              </Stack>
            </Stack>
          }
        />
      )}
       {open.proposeNewTime && (
        <ProposeNewTimeDialog
          open={open.proposeNewTime}
          timezone={timezone}
          defaultValue={session.request.proposedTime}
          onClose={(value: string | undefined) => {
            if(value){
              handleNewProposeTime(value);
              return;
            }
            setOpen({ ...open, proposeNewTime: false });
             
          }}
        />
      )}
      {open.detail && (
        <DetailBeforeAccept
          open={open.detail}
          onClose={(value) => {
            if (!value) handleDecline();
            setOpen({ ...open, detail: false });
          }}
          session={session}
          onSelected={() => {
            handleAccept();
            setOpen({
              ...open,
              detail: false,
            });
          }}
        />
      )}
    </Box>
  );
}

export default LightsterSessionItem;
