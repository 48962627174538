import { LinearProgress, styled } from "@mui/joy";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  color: "#48BB78",
  background: theme.palette.neutral[50],
}));

export { BorderLinearProgress };
