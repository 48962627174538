import { InfoOutlined, KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import { CreateTimeSlotsDialog } from "../../../components/dialog/schedules/CreateTimeSlotsDialog";
import SelectCommunitiesDialog from "../../../components/dialog/schedules/SelectCommunitiesDialog";
import { StorageType } from "../../../constants/enum";
import { CreateBookingParams } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props { }

function CreateSchedule(props: Props) {
  const { } = props;
  const state = useLocation().state;
  const { showMessage, sessionService } = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    communiies: false,
    users: false,
    timeSlot: false,
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<CreateBookingParams>({
    scheduleMode: "schedule",
    communityId: [],
    expectedLength: 30,
    proposedTime: [],
    topic: "",
    shareScreen: null,
    expectedLightster: "",
    pastUsers: null,
    status: ""
  });

  const [errors, setErrors] = useState({
    communityId: false,
    expectedLength: false,
    proposedTime: false,
    proposedTimeSchedule: false,
    topic: false,
    shareScreen: false,
    expectedLightster: false,
    expectedLightsterSchedule: false,
    pastUsers: false,
  });

  const handleSetErrors = (field: string, value: boolean) => {
    setErrors({ ...errors, [field]: value });
  };
  const checkEmptyField = () => {
    let contained = false;
    if (
      data.expectedLightster &&
      typeof data.expectedLightster == "string" &&
      parseInt(data.expectedLightster) <= 0
    ) {
      contained = true;
      errors.expectedLightster = true;
    }
    if (
      data.expectedLightster &&
      typeof data.expectedLightster == "string" &&
      data.scheduled && 
      parseInt(data.expectedLightster) <= data.scheduled 
    ) {
      contained = true;
      errors.expectedLightster = true;
      errors.expectedLightsterSchedule = true;
    }
    if (!data.expectedLightster) {
      contained = true;
      errors.expectedLightster = true;
    }


    if (data.communityId?.length === 0) {
      contained = true;
      errors.communityId = true;
    }
    if (data.pastUsers == null) {
      contained = true;
      errors.pastUsers = true;
    }
    if (data.expectedLength == null) {
      contained = true;
      errors.expectedLength = true;
    }
    if (data.proposedTime.length === 0) {
      contained = true;
      errors.proposedTime = true;
    }
    if (data.proposedTime.length < ( typeof data.expectedLightster === "string"
    ? parseInt(data.expectedLightster)
    : data.expectedLightster ) - ( data.scheduled ? data.scheduled : 0 ) ) {
      contained = true;
      errors.proposedTime = true;
      errors.proposedTimeSchedule = true;
    }


    if (data.shareScreen == null) {
      contained = true;
      errors.shareScreen = true;
    }
    if (!data.topic) {
      contained = true;
      errors.topic = true;
    }

    setErrors(errors);
    return contained;
  };
  const handleOnClickNext = () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    localStorage.setItem(StorageType.schedule, JSON.stringify(data));
    navigate(pagesName.creator.schedulePayment, {
      state: { booking: data },
    });
  };
  const handleOnClickUpdate = async () => {
    if (checkEmptyField()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    setLoading(true);
    localStorage.setItem(StorageType.schedule, JSON.stringify(data));

    const res = await sessionService.updateQuickBooking(data);

    if (res) {
      setTimeout(() => {
        setLoading(false);
        showMessage(
          "Invitation successfully updated, these changes will not affect users who have already confirmed.",
          "success"
        );
        localStorage.removeItem(StorageType.schedule);
        navigate(-1);
      }, 5000);
    } else {
      setLoading(false);
    }


  };

  useEffect(() => {
    if (state?.communityId) {
      setData({ ...data, communityId: [state?.communityId] });
    }
    const schedule = localStorage.getItem(StorageType.schedule);
    if (schedule) {
      setData(JSON.parse(schedule));
    }
  }, []);

  return (
    <Box className="home" sx={{ height: "100%" }} overflow="auto">
      {loading ? (
        <Box
          className="box-column"
          sx={{
            //minHeight: 300,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size="md" />
        </Box>
      ) : (
        <Stack direction={"column"} spacing={3}>
          <Box>
            <Typography level="h2" fontSize={28}>
              Schedule a live conversation
            </Typography>
            <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
              Use this feature to schedule a live conversation with users.
              Sessions can be joined on web or mobile, and comes with
              screen-sharing, recording, and transcription.
            </Typography>
          </Box>
          <Box overflow={"auto"}  sx={{ "&::-webkit-scrollbar": {display: "none"} }}>
          <Box>
            <Typography level="title-lg" sx={{ color: "black", mb: 2 }}>
              User Details
            </Typography>
            <Stack direction={"column"} spacing={1}>
              <Input
                size="lg"
                type={"number"}
                placeholder="Enter"
                startDecorator={
                  <Typography level="title-sm">
                    How many users do you want to schedule?
                  </Typography>
                }
                sx={{
                  ".MuiSelect-startDecorator": {
                    maxWidth: "60%",
                    padding: "8px 8px",
                  },
                }}
                slotProps={{
                  input: {
                    style: {
                      textAlign: "end",
                      fontSize: 16,
                    },
                  },
                }}
                value={data?.expectedLightster}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value && errors.expectedLightster) {
                    handleSetErrors("expectedLightster", false);
                    handleSetErrors("expectedLightsterSchedule", false);
                  }
                  const re = /^[0-9\b]+$/;
                  if (value === "" || re.test(value)) {
                    setData({
                      ...data,
                      expectedLightster: value,
                    });
                  }
                }}
                error={errors.expectedLightster}
              />
              {errors.expectedLightsterSchedule && (
                <FormHelperText sx={{ color: 'red'}}>
                  <InfoOutlined sx={{ color: 'red'}}/>
                  Please add more time slots.
                </FormHelperText>
              )}

              <Select
                size="lg"
                color={errors.communityId ? "danger" : "neutral"}
                startDecorator={
                  <Typography level="title-sm">
                    Which communities do you want to select users from?
                  </Typography>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                slotProps={{
                  button: {
                    style: { justifyContent: "end", fontSize: 16 },
                    onClick: () => setOpen({ ...open, communiies: true }),
                  },
                }}
                placeholder="Select"
                listboxOpen={false}
                value={data.communityId?.length}
                defaultValue={data.communityId?.length}
              >
                <Option value={data.communityId?.length}>
                  {data.communityId?.length}
                </Option>
              </Select>
              <Select
                size="lg"
                color={errors.pastUsers ? "danger" : "neutral"}
                startDecorator={
                  <Typography level="title-sm">
                    Do you want to include users that you’ve connected with in the
                    past?
                  </Typography>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                sx={{
                  ".MuiSelect-startDecorator": {
                    maxWidth: "70%",
                  },
                }}
                slotProps={{
                  button: {
                    style: { justifyContent: "end", fontSize: 16 },
                  },
                }}
                placeholder="Select"
                value={data.pastUsers}
                onChange={(e, newValue) => {
                  if (newValue && errors.pastUsers) {
                    setErrors({ ...errors, pastUsers: false });
                  }
                  setData({ ...data, pastUsers: newValue });
                }}
              >
                <Option sx={{ fontSize: 14 }} value={true}>
                  Yes
                </Option>
                <Option sx={{ fontSize: 14 }} value={false}>
                  No
                </Option>
              </Select>
            </Stack>
          </Box>
          <Box>
            <Typography level="title-lg" sx={{ color: "black", mb: 2, mt:2 }}>
              Session Details
            </Typography>
            <Stack direction={"column"} spacing={1}>
              <Select
                size="lg"
                color={errors.expectedLength ? "danger" : "neutral"}
                startDecorator={
                  <Typography level="title-sm">
                    How long do you expect the session to be?
                  </Typography>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                sx={{
                  ".MuiSelect-startDecorator": {
                    maxWidth: "60%",
                  },
                }}
                slotProps={{
                  button: {
                    style: { justifyContent: "end", fontSize: 16 },
                  },
                }}
                placeholder="Select"
                value={data.expectedLength}
                onChange={(e, newValue) => {
                  if (newValue && errors.expectedLength) {
                    setErrors({ ...errors, expectedLength: false });
                  }
                  setData({
                    ...data,
                    expectedLength: newValue ?? 0,
                  });
                }}
              >
                {[60, 45, 30, 15].map((map) => (
                  <Option key={map} value={map} sx={{ fontSize: 14 }}>
                    {map} minutes
                  </Option>
                ))}
              </Select>
              <Select
                size="lg"
                color={errors.proposedTime ? "danger" : "neutral"}
                startDecorator={
                  <Typography level="title-sm">
                    What are your preferred dates and times for the interview?
                  </Typography>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                slotProps={{
                  button: {
                    style: { justifyContent: "end", fontSize: 16 },
                    onClick: () => {
                      if (
                        !data.expectedLightster ||
                        data?.expectedLightster === "0"
                      ) {
                        showMessage(
                          "Please enter number of users to invite.",
                          "warning"
                        );
                        return;
                      }
                      setOpen({ ...open, timeSlot: true });
                    },
                  },
                }}
                placeholder="Select"
                value={data.proposedTime.length}
                listboxOpen={false}
              >
                <Option value={data.proposedTime.length}>
                  {data.proposedTime.length}
                </Option>
              </Select>
              {errors.proposedTimeSchedule && (
                <FormHelperText sx={{ color: 'red'}}>
                  <InfoOutlined sx={{ color: 'red'}}/>
                  The number cannot be lower than the number of confirmed users.
                </FormHelperText>
              )}
              <Select
                size="lg"
                color={errors.shareScreen ? "danger" : "neutral"}
                startDecorator={
                  <Box>
                    <Typography level="title-sm">
                      Do you want to request that users join on their desktop?
                    </Typography>
                    <Typography level="body-sm">
                      Select “yes” if you are going to be sharing your screen.
                    </Typography>
                  </Box>
                }
                indicator={<KeyboardArrowDownRounded fontSize="small" />}
                sx={{
                  ".MuiSelect-startDecorator": {
                    maxWidth: "60%",
                    paddingBlock: "8px",
                  },
                }}
                slotProps={{
                  button: {
                    style: { justifyContent: "end", fontSize: 16 },
                  },
                }}
                placeholder="Select"
                value={data.shareScreen}
                onChange={(e, newValue) => {
                  if (newValue && errors.shareScreen) {
                    setErrors({ ...errors, shareScreen: false });
                  }
                  setData({ ...data, shareScreen: newValue });
                }}
              >
                <Option sx={{ fontSize: 14 }} value={true}>
                  Yes
                </Option>
                <Option sx={{ fontSize: 14 }} value={false}>
                  No
                </Option>
              </Select>
            </Stack>
          </Box>
          <Box>
            <Typography level="title-lg" sx={{ color: "black", mt:2}}>
              What should the user know about the session?
            </Typography>
            <Typography level="body-sm" sx={{ color: "black", mb: 1 }}>
              Please let the user know how best to prepare for the session, or
              what to expect.
            </Typography>
            <Textarea
              size="lg"
              minRows={4}
              error={errors.topic}
              value={data.topic}
              onChange={(e) => {
                const { value } = e.target;
                if (value && errors.topic) {
                  setErrors({ ...errors, topic: false });
                }
                setData({ ...data, topic: value });
              }}
            />
          </Box>
          </Box>
          <Box
            className="box-row"
            sx={{ justifyContent: "end", gap: 2, mt: "22px !important" }}
          >
            <PlainButton
              variant="plain"
              onClick={() => {
                localStorage.removeItem(StorageType.schedule);
                navigate(-1);
              }}
            >
              Cancel
            </PlainButton>
            {
              data.status === 'inProgress' ?
                <SolidButton variant="solid" onClick={handleOnClickUpdate}>
                  Save
                </SolidButton>
                :
                <SolidButton variant="solid" onClick={handleOnClickNext}>
                  Next
                </SolidButton>
            }
          </Box>
        </Stack>)}
      {open.communiies && (
        <SelectCommunitiesDialog
          open={open.communiies}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.communityId) {
              setErrors({ ...errors, communityId: false });
            }
            setData({ ...data, communityId: value });
            setOpen({ ...open, communiies: false });
          }}
          defaultData={data?.communityId ?? []}
        />
      )}
      {open.timeSlot && data.expectedLightster && (
        <CreateTimeSlotsDialog
          users={
             ( typeof data.expectedLightster === "string"
              ? parseInt(data.expectedLightster)
              : data.expectedLightster ) - ( data.scheduled ? data.scheduled : 0 )
          }
          defaultValue={data.proposedTime}
          open={open.timeSlot}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.proposedTime) {
              setErrors({ ...errors, proposedTime: false, proposedTimeSchedule:false });
            }
            setData({ ...data, proposedTime: value });
            setOpen({ ...open, timeSlot: false });
          }}
        />
      )}
    </Box>
  );
}

export default CreateSchedule;
