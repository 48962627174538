export enum StorageType {
  token = "lisgtster_share_screen_token",
  user = "lightster_user_info",
  role = "lightster_row_active",
  audio = "audioDeviceId",
  video = "videoDeviceId",
  speaker = "speakerDeviceId",
  schedule = "schedule",
  viewProfileChatActive = "view_profile_chat_active",
  homeChatActive = "home_chat_active",
  survry = "survey",
  previousQuestionSurvey = "previous_question_survey",
  utm = "utm",
  community = "community",
  unmoderated = "unmoderated",
}

export enum RoleType {
  lightster = "lightster",
  creator = "creator",
}
export enum StatusType {
  pending = "pending",
  inProgress = "inProgress",
}

export enum VoteResult {
  positive = "positive",
  negative = "negative",
  neutral = "neutral",
}

export enum CriteriaType {
  multipleSelect = "multipleSelect",
  location = "location",
  range = "range",
  customTextField = "customTextField",
  keyword = "keyword",
  none = "none",
}

export enum InputType {
  NUMBER = "number",
  TEXT = "text",
  TEXTFIELD = "textfield",
  BOOLEAN = "boolean",
  EMAIL = "email",
  PHONE = "phone",
  LOCATION = "location",
  SELECTLIST = "selectList",
  URL = "url",
  CURRENCY = "currency",
  MULTIPLESELECT = "multipleSelectList",
  DATETIME = "datetime",
  DATE = "date",
  VERBAL_RESPONSE = "verbalResponse",
  RATING_SCALE = "ratingScale",
}
export enum TextResponse {
  optional = "optional",
  required = "required",
}

export enum CreatorHomeStep {
  ADDCRITERIA = "addCriteria",
  EDITCRITERIA = "editCriteria",
  CRITERIADETAIL = "criteriaDetail",
  COMMUNITYNAME = "communityName",
  COMMUNITYDETAIL = "communityDetail",
  COMMUNITYSETTING = "communitySetting",
}
export enum CommunityType {
  PRIVATE = "private",
  PUBLIC = "public",
}

export enum CommunitySetting {
  DYNAMIC = "dynamic",
  STATIC = "static",
}
export const getBrowserName = () => {
  let browserInfo = navigator.userAgent;
  let browser;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = "Opera";
  } else if (browserInfo.includes("Edg")) {
    browser = "Edge";
  } else if (browserInfo.includes("Chrome")) {
    browser = "Chrome";
  } else if (browserInfo.includes("Safari")) {
    browser = "Safari";
  } else if (browserInfo.includes("Firefox")) {
    browser = "Firefox";
  } else {
    browser = "unknown";
  }
  return browser;
};

export const isSupportBrowsers = () => {
  const browsers = ["Chrome", "Edge"];
  const browser = getBrowserName();
  return browsers.includes(browser);
};
