import {
  Box,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy";
import { useEffect, useState } from "react";
import {
  UnmoderatedResult,
  SurvayDisplay,
  SessionResult,
} from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import CreatorUnmoderatedItemList from "../../../components/session/CreatorUnmoderatedItemList";
import CreatorSessionItemList from "../../../components/session/CreatorSessionItemList";
import CreatorSurveyItemList from "../../../components/session/CreatorSurveyItemList";

interface Props {}

function CreatorInsights(props: Props) {
  const {} = props;
  const { showMessage, sessionService } = useApi();
  const [tabIndex, setTabIndex] = useState<string | number | null>(0);
  const [unmoderated, setUnmoderated] = useState<UnmoderatedResult[]>([]);
  const [session, setSession] = useState<SessionResult[]>([]);
  const [survey, setSurvey] = useState<SurvayDisplay[]>([]);

  const getCreatorUnmoderated = async () => {
    const res = await sessionService.viewUnmoderatedResultCreator();
    if (!res) return;
    setUnmoderated(res.unmoderated);
  };
  const getCreatorSession = async () => {
    const res = await sessionService.viewSessionResultCreator();
    if (!res) return;
    setSession(res.session);
  };
  const getCreatorSurvey = async () => {
    const res = await sessionService.viewSurveyResultCreator();
    if (!res) return;
    const sur = res.filter((e: any) => e.status === "completed");
    setSurvey(sur);
  };

  const checkInvitationsEmpty = () => {
    return unmoderated.length === 0;
  };
  const checkSessionEmpty = () => {
    return session.length === 0;
  };
  const checkSurveyEmpty = () => {
    return survey.length === 0;
  };

  useEffect(() => {
    getCreatorSession();
    getCreatorSurvey();
    getCreatorUnmoderated();
    document.title = "Lightster - Transactions";
  }, []);

  useEffect(() => {
    var interval = setInterval(() => {
      getCreatorSession();
      getCreatorSurvey();
      getCreatorUnmoderated();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // gap: 2,
      }}
    >
      <Tabs
        aria-label="tabs"
        defaultValue={0}
        onChange={(e, value) => setTabIndex(value)}
        sx={{ bgcolor: "transparent", position: "sticky" }}
      >
        <TabList
          disableUnderline
          sx={{
            p: 0.5,
            gap: 1,
            borderRadius: "xl",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
              fontWeight: 600,
            },
            position: "inherit",
          }}
        >
          <Tab disableIndicator>Sessions ({session.length})</Tab>
          <Tab disableIndicator>Surveys ({survey.length})</Tab>
          <Tab disableIndicator>Unmoderated Tests ({unmoderated.length})</Tab>
        </TabList>
      </Tabs>
      {tabIndex === 0 && (
        <Box height={"calc(100vh - 132px)"} overflow="auto">
          <Box>
            {checkSessionEmpty() && (
              <Typography level="body-md" py={2}>
                There are no session recordings yet.
              </Typography>
            )}
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              py={2}
            >
              {session.map((invite) => (
                <CreatorSessionItemList
                  session={invite}
                  refresh={getCreatorSession}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box height={"calc(100vh - 132px)"} overflow="auto">
          <Box>
            {checkSurveyEmpty() && (
              <Typography level="body-md" py={2}>
                You haven’t completed any surveys.
              </Typography>
            )}
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              py={2}
            >
              {survey.map((invite) => (
                <CreatorSurveyItemList
                  survey={invite}
                  refresh={getCreatorSurvey}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      )}
      {tabIndex === 2 && (
        <Box height={"calc(100vh - 132px)"} overflow="auto">
          <Box>
            {checkInvitationsEmpty() && (
              <Typography
                key={`unmoderated-emtpty-state-text`}
                level="body-md"
                py={2}
              >
                You haven’t completed any unmoderated tests.
              </Typography>
            )}
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              py={3}
            >
              {unmoderated.map((invite, i) => (
                <CreatorUnmoderatedItemList
                  key={`unmoderated-test-result-item-${i}`}
                  unmoderated={invite}
                  refresh={getCreatorUnmoderated}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CreatorInsights;
