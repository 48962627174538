import { redHoverEndSession } from "../../joy-styles";

import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/joy";
import moment from "moment/moment";
import { Session } from "../../constants/interfaces";
import { useAuth } from "../../providers/AuthProvider";
import { EndSessionIcon } from "../../constants/AppImages";
import { Hidden } from "@mui/material";
interface ControlsProps {
  start: boolean;
  session: Session;
  guest: boolean;
  questLightster: boolean;
  counter: number;
  onClickEnd: () => void;
  showTimer: boolean;
}
export default function ControlsHeader({
  start,
  session,
  guest,
  questLightster,
  counter,
  onClickEnd,
  showTimer,
}: ControlsProps) {
  const { role } = useAuth();

  const getTextEndSession = () => {
    if (guest) {
      return "Leave";
    } else {
      return start ? "End Session" : "Leave";
    }
  };

  return (
    <Grid container>
      <Grid xs={7} alignItems="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "5px"
          }}
        >
          <Box display={"flex"} flexDirection="row">
            <Avatar
              sx={{ width: 50, height: 50 }}
              src={
                role === "lightster" || questLightster
                  ? session?.creator?.imageProfile
                  : session?.lightster?.imageProfile
              }
            />

            <Box sx={{ pl: 2, mr: 2 }}>
              <Typography
                level="title-md"
                sx={{ color: "#000", fontWeight: 600 }}
              >
                {role === "lightster" || questLightster
                  ? session?.creator?.displayname
                  : session?.lightster?.displayname}
              </Typography>
              <Typography
                level="body-md"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  color: "#6D6D6D !important",
                  fontWeight: 400,
                  fontSize: "14px"
                }}
              >
                {role === "lightster" || questLightster
                  ? session?.creator?.companydisplayname
                  : session?.lightster?.subdisplayname}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid
        xs={5}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      //marginLeft={1}
      >
        <Stack width="100%" direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2} >
          {showTimer ? (
            <Box mt={5} marginRight={2}>
              <Typography level="h3" sx={{ color: "#6D6D6D" }} fontWeight={400} fontFamily={"Poppins"} fontSize={16} mt={1} height={"21px"}>
                {moment(counter * 1000).format("mm:ss") + " minutes"}
              </Typography>
            </Box>
          ) : null}


          <Box >
            <Button
              className="leave-button"
              variant="solid"

              sx={{
                //width: "140px",
                height: "36px",
                fontSize: "small",
                fontStyle: "normal",
                fontWeight: "300",
                padding: `16px 16px 17px 16px !important`,
                ":hover": {
                  background: `${redHoverEndSession} !important`,
                  color: "white"
                },
              }}
              onClick={() => onClickEnd()}
            >
              <Hidden lgUp>
                <img src={EndSessionIcon} alt="EndSessionIcon" />
              </Hidden>

              <Hidden lgDown>
                {getTextEndSession()}
              </Hidden>
            </Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}