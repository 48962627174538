import { Button, Modal, ModalDialog, Stack, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import React from "react";

interface Props {
  open: boolean;
  onClose: Function;
  title: string;
  textButton?: string;
  subtitle: React.ReactElement;
}

function InfoDialog(props: Props) {
  const { open, onClose, title, subtitle, textButton } = props;

  return (
    <Modal open={open}>
      <ModalDialog>
        <Stack direction={"column"} gap={3}>
          <Typography level="h2" fontSize={24}>
            {title}
          </Typography>
          {subtitle}
          <Box display={"flex"} flexDirection="row" justifyContent={"end"}>
            <Button onClick={() => onClose()}>{textButton ?? "OK"}</Button>
          </Box>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default InfoDialog;
