import { Box, Container, Grid, Stack } from "@mui/material";
import React from "react";
import MenuListItem from "../../components/menu/MenuListItem";
import CreatorMessages from "../creator/message/CreatorMessages";

interface Props {}

function CoCreate(props: Props) {
  const {} = props;

  return <div className="bg"></div>;
}

export default CoCreate;
