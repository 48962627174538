import {
    Box,
    Modal,
    ModalDialog,
    Stack,
    Typography
} from "@mui/joy";
import { useApi } from "../../../providers/ApiProvider";
import { PlainButton, SolidButton } from "../../button/CustomButton";

interface Props {
  open: boolean;
  goBack: () => void;
  onSubmit: () => void;
}

function CancelPlanDialog(props: Props) {
  const { open, goBack, onSubmit } = props;
  const { paymentService, handleToggle } = useApi();

  const benefits = [
    "Unlimited criteria to segment users",
    "Add your own screening questions",
    "No limit on direct messages to users",
    "Set your own rewards to users",
    "Save 20% per transaction",
  ];

  const handleSubmit = async () => {
    const res = await paymentService.updateSubscription("free");
    if (!res) return;
  };
  return (
    <Modal open={open}>
      <ModalDialog aria-labelledby="modal-title" size="lg">
        <Typography level="h3" id="modal-title" fontSize={24}>
          Are you sure?
        </Typography>
        <Stack direction={"column"} spacing={2}>
          <Box>
            <Typography level="title-md">
              If you cancel, you will loose these benefits:
            </Typography>
            <Box pl={2}>
              {benefits.map((item) => (
                <Typography
                  key={item}
                  level="body-md"
                  sx={{ display: "list-item" }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
          <Stack direction={"row-reverse"} sx={{ gap: 2 }}>
            <SolidButton variant="solid" color="primary" onClick={onSubmit}>
              Yes
            </SolidButton>
            <PlainButton variant="plain" color="primary" onClick={goBack}>
              Go back
            </PlainButton>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default CancelPlanDialog;
