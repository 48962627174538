import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
    Box,
    FormControl,
    FormLabel,
    Option,
    Select,
    Stack,
    Textarea,
    Typography,
} from "@mui/joy";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    serverTimestamp,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import {
    PlainButton,
    SolidButton,
} from "../../../components/button/CustomButton";
import SelectCommunitiesDialog from "../../../components/dialog/schedules/SelectCommunitiesDialog";
import SelectUsersDialog from "../../../components/dialog/send-message/SelectUsersDialog";
import { Member, UserChat } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { firebaseDB } from "../../../providers/FirebaseProvider";

interface Props {}

function CreateSendMessage(props: Props) {
  const {} = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { notiService, showMessage, handleToggle } = useApi();
  const { user } = useAuth();
  const [errors, setErrors] = useState({
    communityId: false,
    users: false,
    message: false,
  });
  const [open, setOpen] = useState({
    communities: false,
    users: false,
  });
  const [data, setData] = useState({
    communityId: [] as string[],
    users: [] as Member[],
    messageText: "",
  });

  const checkEmpty = () => {
    let empty = false;
    if (data.communityId.length === 0) {
      errors.communityId = true;
      empty = true;
    }

    if (data.users.length === 0) {
      errors.users = true;
      empty = true;
    }
    if (data.messageText.trim().length === 0) {
      errors.message = true;
      empty = true;
    }

    setErrors(() => errors);
    return empty;
  };
  const getCurrentId = () => {
    return `creator_${user._id}`;
  };

  const getReceiverId = (receiverId: string) => {
    return `lightster_${receiverId}`;
  };
  const handleSendMessage = async () => {
    if (checkEmpty()) {
      showMessage("Please fill out the information completely.", "error");
      return;
    }
    handleToggle(true);
    for (let index = 0; index < data.users.length; index++) {
      const element = data.users[index];

      const payload = {
        SENDER_ID: getCurrentId(),
        RECEIVER_ID: getReceiverId(element.id),
        MESSAGE_TYPE: "text",
        MESSAGE_TEXT: data.messageText,
        MESSAGE_IMG_LINK: "",
        TIMESTAMP: new Date().toISOString(),
        TIMESTAMPSERVER: serverTimestamp(),
      };

      // Add a new message for current user.
      await addDoc(
        collection(
          firebaseDB,
          `chats/${getCurrentId()}/${getReceiverId(element.id)}`
        ),
        { ...payload, READ: true }
      );

      // Add a new message for receiver user.
      await addDoc(
        collection(
          firebaseDB,
          `chats/${getReceiverId(element.id)}/${getCurrentId()}`
        ),
        { ...payload, READ: false }
      );

      const docRef = doc(
        firebaseDB,
        `${getCurrentId()}/${getReceiverId(element.id)}`
      );
      const docSnap = await getDoc(docRef);

      const docRefReceive = doc(
        firebaseDB,
        `${getReceiverId(element.id)}/${getCurrentId()}`
      );
      const docSnapReceive = await getDoc(docRefReceive);
      const currentUserProfile: UserChat = {
        subDisplayName: user?.subDisplayName ?? null,
        id: user._id,
        imageProfile: user?.imageProfile ?? null,
        username: user.username,
        email: user.email,
        subCompanyDisplayName: user?.subCompanyDisplayName ?? null,
        displayName: user?.displayName,
      };
      const userProfile: UserChat = {
        subDisplayName: element?.subDisplay ?? null,
        id: element.id,
        imageProfile: element?.imageProfile ?? null,
        username: null,
        email: null,
        subCompanyDisplayName: null,
        displayName: element?.displayName,
      };

      if (docSnap.exists()) {
        // Update last action for current user.
        await updateDoc(
          doc(firebaseDB, `${getCurrentId()}/${getReceiverId(element.id)}`),
          {
            isActive: true,
            keepChatting: true,
            lastAction: data.messageText,
            user: userProfile,
            read: true,
            timeStamp: new Date().toISOString(),
            timeStampServer: serverTimestamp(),
          }
        );
      } else {
        // Update last action for current user.
        await setDoc(
          doc(firebaseDB, `${getCurrentId()}/${getReceiverId(element.id)}`),
          {
            isActive: true,
            keepChatting: true,
            lastAction: data.messageText,
            user: userProfile,
            read: true,
            timeStamp: new Date().toISOString(),
            timeStampServer: serverTimestamp(),
          }
        );
      }
      if (docSnapReceive.exists()) {
        // Update last action for receiver user.
        await updateDoc(
          doc(firebaseDB, `${getReceiverId(element.id)}/${getCurrentId()}`),
          {
            isActive: true,
            keepChatting: true,
            lastAction: data.messageText,
            user: currentUserProfile,
            read: false,
            timeStamp: new Date().toISOString(),
            timeStampServer: serverTimestamp(),
          }
        );
      } else {
        // Update last action for receiver user.
        await setDoc(
          doc(firebaseDB, `${getReceiverId(element.id)}/${getCurrentId()}`),
          {
            isActive: true,
            keepChatting: true,
            lastAction: data.messageText,
            user: currentUserProfile,
            read: false,
            timeStamp: new Date().toISOString(),
            timeStampServer: serverTimestamp(),
          }
        );
      }

      //Send notification

      const body = {
        senderId: getCurrentId(),
        receiverId: getReceiverId(element.id),
        messages: data.messageText,
        type: "text",
        data: {
          data: {
            type: "message",
            receiverType: "lightster",
            senderId: getCurrentId(),
          },
        },
      };
      await notiService.sendNotification(body);
      handleToggle(false);
      navigate(pagesName.creator.home, { replace: true });
    }
  };

  useEffect(() => {
    if (state?.communityId) {
      setData({ ...data, communityId: [state.communityId] });
    }
  }, []);

  return (
    <Box className="home">
      <Stack direction={"column"} spacing={3}>
        <Box>
          <Typography level="h2" fontSize={28}>
            Send a message
          </Typography>
          <Typography level="body-lg" sx={{ mt: 2, color: "black" }}>
            You can use this feature to send a message to multiple users at
            once.
          </Typography>
        </Box>
        <Stack direction={"column"} spacing={2}>
          <FormControl>
            <FormLabel>Which users do you want to send to?</FormLabel>
            <Select
              size="lg"
              color={errors.communityId ? "danger" : "neutral"}
              startDecorator={
                <Typography level="title-sm">Communities</Typography>
              }
              indicator={<KeyboardArrowDownRounded fontSize="small" />}
              slotProps={{
                button: {
                  style: { justifyContent: "end", fontSize: 16 },
                  onClick: () => setOpen({ ...open, communities: true }),
                },
              }}
              placeholder="Select"
              listboxOpen={false}
              value={
                data.communityId.length > 0 ? data.communityId.length : null
              }
            >
              {data.communityId.length > 0 && (
                <Option value={data.communityId.length}>
                  {data.communityId.length}
                </Option>
              )}
            </Select>
          </FormControl>
          <Select
            size="lg"
            color={errors.users ? "danger" : "neutral"}
            startDecorator={
              <Typography level="title-sm">Select users</Typography>
            }
            indicator={<KeyboardArrowDownRounded fontSize="small" />}
            slotProps={{
              button: {
                style: { justifyContent: "end", fontSize: 16 },
                onClick: () => {
                  if (data.communityId.length === 0) {
                    showMessage("Please select a community.", "warning");
                    setErrors({ ...errors, communityId: true });
                    return;
                  }
                  setOpen({ ...open, users: true });
                },
              },
            }}
            placeholder="Select"
            listboxOpen={false}
            value={data.users.length > 0 ? data.users.length : null}
          >
            {data.users.length > 0 && (
              <Option value={data.users.length}>{data.users.length}</Option>
            )}
          </Select>
          <FormControl>
            <FormLabel>Type your message below</FormLabel>
            <Textarea
              minRows={4}
              value={data.messageText}
              error={errors.message}
              onChange={(e) => {
                const { value } = e.target;
                if (value.length > 0 && errors.message) {
                  setErrors({ ...errors, message: false });
                }
                setData({ ...data, messageText: value });
              }}
            />
          </FormControl>
        </Stack>
        <Box
          className="box-row"
          sx={{ justifyContent: "end", gap: 2, mt: "48px !important" }}
        >
          <PlainButton
            variant="plain"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </PlainButton>
          <SolidButton variant="solid" onClick={handleSendMessage}>
            Send
          </SolidButton>
        </Box>
      </Stack>
      {open.communities && (
        <SelectCommunitiesDialog
          open={open.communities}
          onClose={(value: string[]) => {
            if (value.length > 0 && errors.communityId) {
              setErrors({ ...errors, communityId: false });
            }
            setData({ ...data, communityId: value });
            setOpen({ ...open, communities: false });
          }}
          defaultData={data?.communityId ?? []}
        />
      )}
      {open.users && (
        <SelectUsersDialog
          open={open.users}
          defaultMembers={data.users}
          communityIds={data.communityId}
          onSave={(value: Member[]) => {
            if (value.length > 0 && errors.users) {
              setErrors({ ...errors, users: false });
            }
            setData({ ...data, users: value });
            setOpen({ ...open, users: false });
          }}
          onClose={() => setOpen({ ...open, users: false })}
        />
      )}
    </Box>
  );
}

export default CreateSendMessage;
