import { Close, EastSharp, KeyboardReturnRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  FormControl,
  FormLabel,
  Stack,
  Typography
} from "@mui/joy";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pagesName } from "../../../App";
import BackgroundRegister from "../../../components/BackgroundRegister";
import { BorderLinearProgress } from "../../../components/CustomProgress";
import {
  PlainButton,
  SolidButton,
} from "../../../components/button/CustomButton";
import { ProfileParams } from "../../../constants/interfaces";
import { useApi } from "../../../providers/ApiProvider";

interface Props { }
const keyRequired = ["brands", "products"];
function LightsterYourInterests(props: Props) {
  const { } = props;
  const navigate = useNavigate();
  const { profileService, handleToggle } = useApi();

  const [selected, setSelected] = useState({
    brand: "",
    product: "",
  });
  const [profile, setProfile] = useState(Array<ProfileParams>);

  const handleOnChange = (field: string, value: any, type: string) => {
    const updatedObject = profile.map((p: ProfileParams) => {
      if (p.key === field) {
        let newAnswers: string[] = [...p.answers];
  
        if (type === "delete") {
          newAnswers = newAnswers.filter((f) => f !== value);
        } else if (type === "add") {
          const newValue = (value ?? "").toString().trim();
          if (newValue !== "") {
            if (!newAnswers.includes(newValue)) {
              newAnswers.push(newValue);
            }
          }
        }

        newAnswers = Array.from(new Set(newAnswers));
        return {
          ...p,
          answers: newAnswers,
        };
      }
      return p;
    });
  
    setProfile(updatedObject);
  };
  


  const getYourProfile = async () => {
    const res = await profileService.getYourProfile();
    if (!res) return;
    const list: Array<ProfileParams> = [];

    res.map((p: any) => {
      if (keyRequired.includes(p.key)) {
        const data: ProfileParams = {
          profileId: p._id,
          key: p.key,
          answers: p.answers,
          keywordList: p.keywordList,
        };
        list.push(data);
      }
    });

    setProfile(list);
  };

  const getKeywordList = (key: string) => {
    const data = profile.find((f: ProfileParams) => f.key === key);
    return data?.keywordList ?? [];
  };

  const getProfileAnswers = (key: string) => {
    const data = profile.find((f) => f.key === key);
    return data?.answers ?? [];
  };

  const getLightsterParams = () => {
    let profiles: Array<any> = [];

    (profile as Array<ProfileParams>).map((pro) => {
      if (pro.answers.length > 0) {
        profiles.push({
          profileId: pro.profileId,
          answers: pro.answers,
        });
      }
    });
    if (profiles.length === 0) {
      return null;
    } else {
      return { profiles: profiles };
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    handleToggle(true);
    const data = getLightsterParams();

    if (data) {
      const res = await profileService.updateLightProfile(data);
      if (!res) return;
    }
    handleToggle(false);
    navigate(pagesName.lightster.success);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
    }
  };
  
  useEffect(() => {
    getYourProfile();
  }, []);

  return (
    <BackgroundRegister>
      <form noValidate onSubmit={handleSubmit}  onKeyDown={handleKeyDown}>
        <Stack direction={"column"} mt={"10vh"}>
          <BorderLinearProgress determinate value={80} sx={{ mb: 11 }} />
          <Stack direction={"column"} spacing={3}>
            <Box>
              <Typography level="h2" fontSize={28}>
                What are your interests?
              </Typography>
              <Typography level="title-md" mt={1}>
                Tell us more about what your interests are so that we can match
                you with the relevant Creators.
              </Typography>
            </Box>
            <FormControl>
              <FormLabel>Brands</FormLabel>
              <Autocomplete
                multiple
                autoFocus={true}
                value={getProfileAnswers("brands")}
                onChange={(_, value: string[]) => {
                  handleOnChange("brands", value[value.length - 1], "add");
                }}
                options={getKeywordList("brands")}
                renderTags={(tags, getTagProps) =>
                  tags.map((item, index) => (
                    <Chip
                      variant="solid"
                      color="primary"
                      endDecorator={<Close fontSize="small" />}
                      {...getTagProps({ index })}
                    >
                      {item}
                    </Chip>
                  ))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Interests</FormLabel>
              <Autocomplete
                multiple
                value={getProfileAnswers("products")}
                onChange={(_, value: string[]) => {
                  handleOnChange("products", value[value.length - 1], "add");
                }}
                options={getKeywordList("products")}
                renderTags={(tags, getTagProps) =>
                  tags.map((item, index) => (
                    <Chip
                      variant="solid"
                      color="primary"
                      endDecorator={<Close fontSize="small" />}
                      {...getTagProps({ index })}
                    >
                      {item}
                    </Chip>
                  ))
                }
              />
            </FormControl>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            mt={6}
            mb={6}
          >
            <PlainButton
              variant="plain"
              endDecorator={<KeyboardReturnRounded fontSize="small" />}
              onClick={() => navigate(-1)}
            >
              Back
            </PlainButton>
            <SolidButton
              variant="solid"
              endDecorator={<EastSharp />}
              sx={{ minWidth: 128 }}
              onClick={handleSubmit}
              type="submit"
            >
              Next
            </SolidButton>
          </Stack>
        </Stack>
      </form>
    </BackgroundRegister>
  );
}

export default LightsterYourInterests;
